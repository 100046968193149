import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import Button from '../Core/Button';
import Checkbox from '../Core/Checkbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../actions/authAction';
import { useTranslation } from 'react-i18next';
import { EMAIL_VALIDATION } from '../../utils/constants';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const { showLoginModal, setShowLoginModal, setShowForgotModal } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const {setShowEmployeeRegisterModal} = useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_VALIDATION, {
          message: t('Email.type'),
        })
        .required(t('Email.required')),
      password: Yup.string().required(t('Password.required')),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   t('Password.length'),
      // ),
    }),
    onSubmit: async (values) => {
      setSubmitted(true);
      dispatch(
        login({
          ...values,
          role: showLoginModal.role,
          setSubmitting: setSubmitted,
          callback: (role) => {
            setShowLoginModal({
              ...showLoginModal,
              visible: false,
              referral: undefined,
              showRegister: false,
            });

            if (showLoginModal.referral !== undefined) {
              history.push(`/${role}/${showLoginModal.referral}`);
            } else if (role === 'organization') {
              history.push(`/${role}/request-overview`);
            } else if (role === 'staff') {
              history.push(`/${role}/job-wall`);
            } else if (role === 'superuser') {
              history.push(`/${role}/eventslist`);
            }
            formik.resetForm();
          },
        }),
      );
    },
  });

  return (
    <Modal
      show={showLoginModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowLoginModal({ ...showLoginModal, visible: false, showRegister: false })}
      className="employee-login-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('loginLabel')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form form-login">
          <Row>
            <Col md="6">
              <Input
                type="email"
                label={`${t('emailContact')} *`}
                placeholder={t('emailContact')}
                name="email"
                onChange={(e) => {
                  if (e.target.value === '' || e.target.value?.length <= 60) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                touched={formik.touched.email}
                errors={formik.errors.email}
                value={formik.values.email}
              />
            </Col>
            <Col md="6">
              <Input
                type="password"
                label={`${t('passwordLogin')} *`}
                placeholder={t('passwordLogin')}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.password}
                errors={formik.errors.password}
                value={formik.values.password}
              />
            </Col>
            <Col>
              <Checkbox label={t('loginCheck')} />
            </Col>
          </Row>
          <div className="form-bottom">
            <div className="buttonGroup">
              <Button
                type="submit"
                label={t('loginLabel')}
                className="btn"
                disabled={submitted}
              />
              {(() => {
                  if (showLoginModal.showRegister) {
                    return (
                      <Button
                        type="submit"
                        label={t('register')}
                        className="btn"
                        disabled={submitted}
                        style={{marginLeft: '0'}}
                        onClick={() => {
                          setShowEmployeeRegisterModal(true);
                          setShowLoginModal({ ...showLoginModal, visible: false, showRegister: false });
                        }}
                      />
                    );
                  }
                }
              )()}
            </div>
            <span
              className="link"
              onClick={() => {
                setShowForgotModal(true);
                setShowLoginModal({ ...showLoginModal, visible: false, showRegister: false });
              }}>
              {t('forgot')}
            </span>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Login;
