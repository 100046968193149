import React, { useState, useContext, useRef } from 'react';
import moment from 'moment';
// import { IMaskInput } from 'react-imask';
// import IMask from 'imask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Input from '../Core/Input';
import Button from '../Core/Button';
import CustomSelect from '../Core/Select';
import {
  // updateMe,
  updatebillingaddress,
  updatepaymentdetails,
} from '../../actions/authAction';
import { NUMBER_ONLY, creditCardOptions } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
// import i18n from '../../i18n';
import PlacesAutocomplete, {
  geocodeByAddress,
} from '@dsr/react-places-autocomplete';
import GlobalContext from '../../context/GlobalContext';
import Loader from '../Core/Loader';

// const checkCardNumberError = (number) => {
//   // console.log(luhn.validate(number), ' luhn.validate(value)');
//   // if (number && number.toString() && luhn.validate(number)) {
//   //   return 'error';
//   // } else {
//   //   return false;
//   // }
//   if (!number) {
//     return 'Card Number is Required';
//   } else if (number && number.toString() && number.length < 16) {
//     return 'Card Number is incorrect';
//   } else {
//     return false;
//   }
// };

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const user = userData.data;
  const { employer } = user;
  const { paymentDetails, billingAddress, companyVatNumber } = employer || {};
  const [expirationDateError, setExpirationDateError] = useState(null);
  const [selectedcity, setSelectedCity] = useState(
    billingAddress?.billingAddressCity,
  );
  const [addressline, setAddressLine] = useState(
    billingAddress?.billingAddressStreet,
  );
  const formikFormRef = useRef();
  const { t, i18n } = useTranslation();

  const { countriesOptions } = useContext(GlobalContext);

  const getMethodValue = (methodVal) => {
    const method = creditCardOptions.filter((val) => val.value === methodVal);
    return method[0];
  };
  const [displaySpinner, setDisplaySpinner] = useState(false);
  // const handleAccept = (value, setFieldValue) => {
  //   if (value.length >= 5) {
  //     const newDate = moment(`01/${value}`, 'DD/MM/YYYY').format('x') * 1;
  //     const currTime = new Date().valueOf();
  //     if (newDate > currTime) {
  //       setExpirationDateError(null);
  //     } else {
  //       setExpirationDateError(t('cardexpired'));
  //     }
  //   } else {
  //     setExpirationDateError(null);
  //   }
  //   setFieldValue('validThrough', value);
  // };

  if (!employer) {
    return <p>loading...</p>;
  }

  // const getValidThroughDate = (validThrough) => {
  //   const mm = validThrough.split('/')[1];
  //   const x = validThrough.split('/')[2].slice(2, 4);
  //   return `${mm}/${x}`;
  // };

  const getSelectValue = (arr, val) => {
    let observed;
    arr.map((el) => {
      if (el.value === val) {
        observed = { ...el };
      }
      return false;
    });

    return observed;
  };

  const renderSuggestionItem = (suggestion, type) => {
    if (type === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };
  const handleError = (name, error) => {
    formikFormRef?.current?.setFieldError(name, error);
  };
  const handleSelect = async (address, name, type, suggestion) => {
    try {
      let returnAddress = address;
      if (type === 'sublocality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else if (type === 'locality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else {
        const res = await geocodeByAddress(address);
        if (type === 'sublocality' || type === 'locality') {
          returnAddress = res[0].address_components[0].long_name;
        }
      }

      if (name === 'city') {
        setSelectedCity(returnAddress);
        formikFormRef?.current?.validateForm();
      } else {
        setAddressLine(returnAddress);
        formikFormRef?.current?.validateForm();
      }
      // handleChange(name, returnAddress);
      formikFormRef?.current?.setFieldValue(name, returnAddress);
    } catch (error) {
      formikFormRef?.current?.setFieldError(name, error);
    }
  };
  const mystyle = {
    marginBottom: '0px',
  };
  return (
    <Row>
      <Col xs="12" xl="10">
        <Formik
          innerRef={formikFormRef}
          initialValues={{
            name:
              billingAddress && billingAddress.billingAddressName
                ? billingAddress.billingAddressName
                : '',
            vatNumber: companyVatNumber ? companyVatNumber : '',
            // country:
            //   billingAddress && billingAddress.billingAddressCountry
            //     ? billingAddress.billingAddressCountry
            //     : '',
            country: getSelectValue(
              countriesOptions,
              billingAddress && billingAddress.billingAddressCountry
                ? billingAddress.billingAddressCountry
                : '',
            ),
            city:
              billingAddress && billingAddress.billingAddressCity
                ? billingAddress.billingAddressCity
                : '',
            postalCode:
              billingAddress && billingAddress.billingAddressPostCode
                ? billingAddress.billingAddressPostCode
                : '',
            addressLineOne:
              billingAddress && billingAddress.billingAddressStreet
                ? billingAddress.billingAddressStreet
                : '',
            addressLineTwo:
              billingAddress && billingAddress.billingAddressStreetTwo
                ? billingAddress.billingAddressStreetTwo
                : '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(t('companyName')),
            // vatNumber: Yup.string().required(t('companyVatNumber')),
            vatNumber: Yup.string()
              .required(t('companyVatNumber'))
              // .matches(/^\bATU[0-9]*$/, t('vatNumberFormat'))
              // .min(11, t('vatNumberFormat'))
              // .max(11, t('vatNumberFormat')),
              .matches(
                /^(AT)?U[0-9]{8}$|^(BE)?0[0-9]{9}$|^(BG)?[0-9]{9,10}$|^(CY)?[0-9]{8}L$|^(CZ)?[0-9]{8,10}$|^(DE)?[0-9]{9}$|^(DK)?[0-9]{8}$|^(EE)?[0-9]{9}$|^(EL|GR)?[0-9]{9}$|^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$|^(FI)?[0-9]{8}$|^(FR)?[0-9A-Z]{2}[0-9]{9}$|^(GB)?[0-9]{9}$|^(HU)?[0-9]{8}$|^(IE)?[0-9]S[0-9]{5}L$|^(IT)?[0-9]{11}$|^(LT)?([0-9]{9}|[0-9]{12})$|^(LU)?[0-9]{8}$|^(LV)?[0-9]{11}$|^(MT)?[0-9]{8}$|^(NL)?[0-9]{9}B[0-9]{2}$|^(PL)?[0-9]{10}$|^(PT)?[0-9]{9}$|^(RO)?[0-9]{2,10}$|^(SE)?[0-9]{12}$|^(SI)?[0-9]{8}$|^(SK)?[0-9]{10}$/,
                t('vatNumberFormat'),
              ),
            // country: Yup.string().required(t('Country')),
            country: Yup.object().shape({
              label: Yup.string().required(i18n.t('Country')),
              value: Yup.string().required(i18n.t('Country')),
            }),
            city: Yup.string().required(t('City')),
            postalCode: Yup.string()
              .required(t('PostalCode'))
              .matches(/^[0-9]/, t('postalcodenotnegative'))
              .min(4, t('Postalcodevalidation'))
              .max(5, t('Postalcodevalidation')),
            addressLineOne: Yup.string().required(t('AddressLineOne')),
          })}
          onSubmit={(values, actions) => {
            setDisplaySpinner(true);
            const {
              name,
              vatNumber,
              country,
              city,
              postalCode,
              addressLineOne,
              addressLineTwo,
            } = values;
            actions.setSubmitting(true);
            const newValues = {
              companyVatNumber: vatNumber,
              billingAddress: {
                billingAddressName: name,
                billingAddressCountry: country.value,
                billingAddressCity: city,
                billingAddressPostCode: postalCode,
                billingAddressStreet: addressLineOne,
                billingAddressStreetTwo: addressLineTwo,
              },
            };
            dispatch(
              updatebillingaddress({
                formData: { ...newValues },
                callback: () => (
                  actions.setSubmitting(false), setDisplaySpinner(false)
                ),
                message: t('Billingdetails'),
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="form" onSubmit={handleSubmit}>
              {displaySpinner ? <Loader /> : ''}
              <Row>
                <div className="input-group--headline">
                  <h3>{t('profileOrg.billAddress')}</h3>
                </div>

                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group ${
                      errors?.name && errors?.name !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <Input
                      type="text"
                      label={`${t('company')} *`}
                      placeholder={t('companyplaceholder')}
                      name="name"
                      styles={mystyle}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.name}
                      // errors={(error) => handleError('name', error)}
                    />
                    {errors?.name !== '' && (
                      <div className="form-errors mit">{errors?.name}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group ${
                      errors?.vatNumber && errors?.vatNumber !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <Input
                      type="phone"
                      label={`${t('vat')} *`}
                      placeholder={t('vatNumber')}
                      name="vatNumber"
                      value={values.vatNumber}
                      styles={mystyle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.vatNumber}
                      // errors={(error) => handleError('vatNumber', error)}
                    />
                    {errors?.vatNumber !== '' && (
                      <div className="form-errors">{errors?.vatNumber}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group ${
                      errors?.country?.value && errors?.country?.value !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <CustomSelect
                      label={`${t('country')} *`}
                      name="country"
                      options={countriesOptions}
                      placeholder={t('countryplaceholder')}
                      handleChange={(value) => {
                        setFieldValue('country', value);
                      }}
                      styles={mystyle}
                      handleBlur={() => {
                        setFieldTouched('country', true);
                      }}
                      onBlur={handleBlur}
                      touched={touched.country?.value}
                      errors={
                        errors.country?.value ? errors.country?.value : ''
                      }
                      value={values.country}
                    />
                    {touched && errors && errors?.country?.value !== '' && (
                      <div
                        className="form-errors"
                        style={{
                          marginTop: '-55px',
                        }}>
                        {errors?.country?.value}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group ${
                      errors?.city && errors?.city !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <label className="form-label">{t('city')} *</label>
                    <PlacesAutocomplete
                      name={'city'}
                      id={'city'}
                      searchOptions={['(cities)']}
                      value={selectedcity}
                      onChange={(e) => {
                        handleSelect('', 'city', 'locality', '');
                        setSelectedCity(e);
                      }}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(data, 'city', 'locality', suggestion)
                      }
                      onError={(error) => handleError('city', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: `${t('cityplaceholder')}`,
                              className: 'location-search-input form-control',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'locality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {errors?.city !== '' && (
                      <div className="form-errors">{errors?.city}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group ${
                      errors?.postalCode && errors?.postalCode !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <Input
                      type="text"
                      label={`${t('postalCodeStaff')} *`}
                      placeholder={t('postalCodeplaceholder')}
                      name="postalCode"
                      onBlur={handleBlur}
                      value={values.postalCode}
                      styles={mystyle}
                      // onChange={handleChange}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          (NUMBER_ONLY.test(e.target.value) &&
                            e.target.value?.length <= 5)
                        ) {
                          handleChange(e);
                        }
                      }}
                      touched={touched.postalCode}
                      // errors={(error) => handleError('postalCode', error)}
                    />
                    {errors?.postalCode !== '' && (
                      <div className="form-errors">{errors?.postalCode}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  {/* <Input
                    type="text"
                    label={`${t('addressLineOne')} *`}
                    placeholder={t('addressLineOneplaceholder')}
                    name="addressLineOne"
                    value={values.addressLineOne}
                    onChange={handleChange}
                    touched={touched.addressLineOne}
                    errors={errors.addressLineOne}
                  /> */}
                  <div
                    className={`form-group ${
                      errors?.addressLineOne && errors?.addressLineOne !== ''
                        ? 'form-group-errors'
                        : ''
                    }`}>
                    <label className="form-label">{`${t(
                      'addressLineOne',
                    )} *`}</label>
                    <PlacesAutocomplete
                      name={'addressLineOne'}
                      id={'addressLineOne'}
                      placeholder={t('addressLineOneplaceholder')}
                      searchOptions={['address']}
                      value={addressline}
                      onChange={(value) => {
                        handleSelect('', 'addressLineOne', 'sublocality', '');
                        setAddressLine(value);
                      }}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(
                          data,
                          'addressLineOne',
                          'sublocality',
                          suggestion,
                        )
                      }
                      onError={(error) => handleError('addressLineOne', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: `${t('addressLineOneplaceholder')}`,
                              className: 'location-search-input form-control',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (!suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'sublocality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {errors?.addressLineOne !== '' && (
                      <div className="form-errors">
                        {errors?.addressLineOne}
                      </div>
                    )}
                    {/* <div className="form-errors">
                      <ErrorMessage name={`addressLineOne`} />
                    </div> */}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <Input
                    type="text"
                    label={t('addressLineTwo')}
                    placeholder={t('addressLineTwoplaceholder')}
                    name="addressLineTwo"
                    value={values.addressLineTwo}
                    onChange={handleChange}
                    touched={touched.addressLineTwo}
                    errors={errors.addressLineTwo}
                  />
                </Col>
                <div className="form-bottom justify-content-end">
                  <Button
                    type="submit"
                    label={t('save')}
                    className="btn primary-btn"
                    disabled={isSubmitting || Object.entries(errors).length > 0}
                  />
                </div>
              </Row>
            </form>
          )}
        </Formik>
        <Formik
          initialValues={{
            paymentMethod:
              paymentDetails && paymentDetails.paymentMethod
                ? getMethodValue(paymentDetails.paymentMethod)
                : '',
            cardName:
              paymentDetails && paymentDetails.cardName
                ? paymentDetails.cardName
                : '',
            cardNumber:
              paymentDetails && paymentDetails.cardNumber
                ? paymentDetails.cardNumber
                : '',
            validThrough:
              paymentDetails && paymentDetails.validThrough
                ? // ? getValidThroughDate(paymentDetails.validThrough)
                  // ? paymentDetails.validThrough
                  moment(`${paymentDetails.validThrough}`, 'DD/MM/YYYY').format(
                    'MM/YY',
                  )
                : '',
            // cvv: paymentDetails && paymentDetails.cvv ? paymentDetails.cvv : '',
          }}
          validationSchema={Yup.object({
            paymentMethod: Yup.object().shape({
              label: Yup.string().required(t('PaymentMethod')),
              value: Yup.string().required(t('PaymentMethod')),
            }),
            cardName: Yup.string().required(t('issuedrequired')),
            cardNumber: Yup.string()
              .min(16, t('card.length'))
              .required(t('card.required')),
            validThrough: Yup.string().required(t('card.validThrough')),
            // cvv: Yup.string()
            //   .length(3, t('card.cvvLength'))
            //   .matches(/[0-9]+/gi, 'CVV must be number')
            //   .required(t('card.cvvRequired')),
            // .number()
            //   .typeError('CVV must be number')
            //   .test('maxDigits', t('card.cvvLength'), (number) => {
            //     console.log(String(number), number, 'string number and number');
            //     return String(number).length === 3;
            //   }),
          })}
          onSubmit={(values, actions) => {
            const { paymentMethod, cardName, cardNumber, validThrough } =
              values;
            const newDate = moment(`01/${validThrough}`, 'DD/MM/YYYY').format(
              'x',
            );
            const currTime = new Date().valueOf();
            if (newDate <= currTime) {
              setExpirationDateError(t('cardexpired'));
              return;
            }
            actions.setSubmitting(true);
            const validThroughMonth = validThrough.split('/')[0];
            const validThroughYear = `20${validThrough.split('/')[1]}`;
            const date = `01/${validThroughMonth}/${validThroughYear}`;
            const newValues = {
              paymentDetails: {
                paymentMethod: paymentMethod.value,
                cardName,
                cardNumber,
                validThrough: date,
                // cvv,
              },
            };
            dispatch(
              updatepaymentdetails({
                formData: { ...newValues },
                callback: () => actions.setSubmitting(false),
                message: `${t('Paymentdetails')}`,
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form className="form" onSubmit={handleSubmit}>
              {/*<Row>
                <div className="input-group--headline">
                  <h3>{t('profileOrg.payment')}</h3>
                </div>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <CustomSelect
                    label={t('cardMethod')}
                    name="paymentMethod"
                    options={creditCardOptions}
                    handleChange={(value) => {
                      setFieldValue('paymentMethod', value);
                    }}
                    handleBlur={() => {
                      setFieldTouched('paymentMethod', true);
                    }}
                    touched={touched.paymentMethod}
                    errors={errors.paymentMethod && errors.paymentMethod.value}
                    value={values.paymentMethod}
                    classNamePrefix="job-select c-select"
                  />
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <Input
                    type="text"
                    label={`${t('cardName')} *`}
                    placeholder={t('cardNameplaceholder')}
                    name="cardName"
                    value={values.cardName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.cardName}
                    errors={errors.cardName}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group  ${
                      errors?.cardNumber && errors?.cardNumber !== ''
                        ? 'form-group-errors'
                        : ''
                    } card-number-group`}>
                    <label className="form-label">{`${t(
                      'cardNumber',
                    )} *`}</label>
                    <IMaskInput
                      mask={'0000 0000 0000 0000'}
                      unmask={true}
                      name="cardNumber"
                      placeholder={t('cardNumberplaceholder')}
                      autoComplete="cc-number"
                      onBlur={handleBlur}
                      value={`${values.cardNumber}`}
                      onAccept={(value, mask) => {
                        setFieldValue('cardNumber', value);
                      }}
                      className={`form-control`}
                    />
                    {touched.cardNumber && errors.cardNumber && (
                      <div className="form-errors">{errors.cardNumber}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4" xl="3">
                  <div
                    className={`form-group  ${
                      errors?.validThrough && errors?.validThrough !== ''
                        ? 'form-group-errors'
                        : ''
                    } date-picker-group`}>
                    <label className="form-label">{`${t(
                      'validThrough',
                    )} *`}</label>
                    <IMaskInput
                      value={values.validThrough}
                      mask={'MM/YY'}
                      blocks={{
                        YY: {
                          mask: '00',
                        },
                        MM: {
                          mask: IMask.MaskedRange,
                          from: 1,
                          to: 12,
                        },
                      }}
                      unmask={false}
                      name="validThrough"
                      onAccept={(value, mask) => {
                        handleAccept(value, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      placeholder={t('validThroughplaceholder')}
                      autoComplete="cc-exp"
                      className="form-control"
                    />
                    {touched.validThrough && errors.validThrough && (
                      <div className="form-errors">{errors.validThrough}</div>
                    )}
                    {expirationDateError && (
                      <div className="form-errors">{expirationDateError}</div>
                    )}
                  </div>
                </Col>
                <Col className="d-none d-xl-block" xl="3"></Col>
                    <Col className="d-none d-xl-block" xl="3"></Col>*/}

              {/* <Col xs="12" sm="6" lg="4" xl="3">
                  <div className="form-group card-number-group">
                    <label className="form-label">Number on card</label>
                    <IMaskInput
                      mask={'0000 0000 0000 0000'}
                      unmask={true}
                      name="cardNumber"
                      placeholder="Card Number"
                      autoComplete="cc-number"
                      value={`${values.cardNumber}`}
                      onAccept={(value, mask) => {
                        setFieldValue('cardNumber', value);
                      }}
                      className={`form-control`}
                    />
                    {touched.cardNumber && errors.cardNumber && (
                      <div className="form-errors">{errors.cardNumber}</div>
                    )}
                  </div>
                </Col> */}

              {/* <Col xs="12" sm="6" lg="4" xl="3">
                  <Input
                    type="password"
                    label="CVV"
                    placeholder="***"
                    name="cvv"
                    value={values.cvv}
                    onChange={(e) => setFieldValue('cvv', e.target.value)}
                    touched={touched.cvv}
                    errors={errors.cvv}
                  />
                </Col> */}
              {/*<div className="form-bottom justify-content-end">
                  <Button
                    type="submit"
                    label={t('save')}
                    className="btn primary-btn"
                    disabled={isSubmitting || Object.entries(errors).length > 0}
                  />
                </div>
              </Row>*/}
            </form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default PaymentMethod;
