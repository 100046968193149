import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../Core/Button';
import i18n from '../../i18n';

function InfoModal({ showInfoModal, setShowInfoModal }) {
  const history = useHistory();

  return (
    <Modal
      show={showInfoModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal"
      onHide={() => {
        setShowInfoModal(false);
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{i18n.t('information')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18n.t('completeclientregistration')}</p>
        <p>{i18n.t('wewillvalidate')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          label={i18n.t('nothanks')}
          onClick={() => setShowInfoModal(false)}
        />
        <Button
          className="btn"
          label={i18n.t('complete_registration')}
          onClick={() => {
            history.push(`/?modal=org-register`);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;
