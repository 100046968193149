import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { Col, OverlayTrigger, Row, Spinner } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { submitWorkHours } from '../../actions/jobAction';
import {
  calculateOutstandingAmount,
  // getTimeDifferent,
} from '../../utils/helper';
import { manageStartEndTime, convertMsToHM } from '../../utils/time';
import Button from '../Core/Button';
import { EndInfo } from '../CreateJob/CreateJobPosition';
import SignaturePad from 'react-signature-canvas';
import Input from '../Core/Input';
import defaultAvatar from '../../assets/images/default-avatar.png';
import api from '../../utils/api';
registerLocale('de', de);
registerLocale('en', enUS);

const ConfirmJobHoursForm = ({
  showConfirmHoursModal,
  setShowConfirmHoursModal,
  setEvent,
  event,
  job,
  position,
  forEmployerSign,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sigPad = React.useRef({});
  const [employerSign, setEmployerSign] = React.useState(false);
  const [isHoursSigned, setIsHoursSigned] = React.useState(false);
  const [image, setImage] = React.useState();
  const [employerSignImage, setEmployerSignImage] = React.useState();
  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );
  const userData = useSelector((state) => state.user);
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';
  React.useEffect(() => {
    setIsHoursSigned(position?.employerSign ?? false);
  }, [position?.employerSign]);

  React.useEffect(() => {
    if (position?.booked?.employee?.images.length) {
      if (
        typeof position.booked.employee.images[0] === 'string' &&
        !position.booked.employee.images[0].includes(
          'https://storage.googleapis.com',
        ) &&
        (position.booked.employee.images[0].startsWith('employee-') ||
          position.booked.employee.images[0].startsWith('user-'))
      ) {
        getImage(
          position.booked.employee.images[0],
          position.booked.employee._id,
        );
      }
    }
    if (
      position?.employerSign &&
      typeof position.employerSign === 'string' &&
      !position.employerSign.includes('https://storage.googleapis.com')
    ) {
      getImage(position.employerSign, position.booked.employee._id);
    }
    async function getImage(image, id) {
      if (image.startsWith('employee-') || image.startsWith('user-')) {
        const response = await api.post('users/getImageUrl', { image });
        const newimage = response.data.image;
        setImage(newimage);
      }
      if (image.startsWith('employersign')) {
        image = `${id}/${image}`;
        const response = await api.post('users/getImageUrl', {
          image,
          type: 'binary',
        });
        const newimage = response.data.image;
        setEmployerSignImage(newimage);
      }
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      start: new Date(
        position?.confirmJobStart
          ? position?.confirmJobStart
          : position?.jobStart,
      ),
      end: position?.confirmJobEnd
        ? new Date(position?.confirmJobEnd)
        : position?.jobEnd < position?.jobStart
        ? new Date(position?.jobEnd + 86400000)
        : new Date(position?.jobEnd),
      employerName: position?.employerName ? position?.employerName : '',
    },
    validationSchema: Yup.object({
      start: Yup.date().required(t('startTime.required')),
      end: Yup.date()
        .required(t('endTime.required'))
        .test('end', t('endTime.duration'), (value, fields) => {
          let duration = moment.duration(
            moment(fields.parent.end).diff(moment(fields.parent.start)),
          );
          let hours = duration._milliseconds;
          let diffMillis = moment.duration(hours);
          let diffMins = Number(
            diffMillis.asMinutes().toString().split('.')[0],
          );
          if (diffMins > 720 || diffMins < 1) {
            return false;
          } else {
            return true;
          }
          /** */
        }),
      employerName: Yup.string().required(t('employerName.required')),
    }),

    onSubmit: (values) => {
      if (forEmployerSign && !employerSign) {
        // set employer sign required message
        return false;
      }
      let updateJobPosition = {};
      const newJobPositions = job.jobPositions.map((jobPosition) => {
        let returnJobPosition = jobPosition;
        const currentStaffType = staffTypes?.find(
          (s) => s.name === job?.jobName,
        );
        if (jobPosition._id === position?._id) {
          const jobPositionCalcData = calculateOutstandingAmount(
            values,
            currentStaffType,
            jobPosition,
            job.jobBonus,
          );
          const endTime =
            parseInt(moment(values.end).format('x')) <
            parseInt(moment(values.start).format('x'))
              ? parseInt(moment(values.end).format('x')) + 86400000
              : parseInt(moment(values.end).format('x'));
          returnJobPosition = {
            ...jobPosition,
            ...jobPositionCalcData,
            confirmJobStart:
              typeof values.start === 'number'
                ? values.start
                : new Date(values.start).valueOf(),
            confirmJobEnd: endTime,
            employerSign: employerSign,
            employerName: values?.employerName,
            rejectedApplicants: jobPosition?.rejectedApplicants?.filter(
              (p) => p !== '',
            ),
          };
          updateJobPosition = returnJobPosition;
        } else {
          returnJobPosition = {
            ...jobPosition,
            rejectedApplicants: jobPosition?.rejectedApplicants?.filter(
              (p) => p !== '',
            ),
          };
        }
        return returnJobPosition;
      });
      const getNewJobs = (newPositions) => {
        return [event].map((j) => {
          if (j.jobId === job._id) {
            return {
              ...j,
              jobPositions: newPositions,
            };
          } else {
            return {
              ...j,
            };
          }
        });
      };
      dispatch(
        submitWorkHours({
          id: updateJobPosition._id,
          payload: {
            jobPosition: updateJobPosition,
            forEmployerSign: forEmployerSign,
          },
          callback: () => {
            setEvent({ ...event, jobs: getNewJobs(newJobPositions) });
            formik.setSubmitting(false);
            setShowConfirmHoursModal({
              visible: false,
            });
          },
          failCallback: () => {
            formik.setSubmitting(false);
            setShowConfirmHoursModal({
              visible: false,
            });
          },
        }),
      );
    },
  });

  const clear = () => {
    sigPad.current?.clear();
    setEmployerSign(false);
  };

  React.useEffect(() => {
    const newStartDate = new Date(position?.jobDate);
    const jobStartDate = position?.confirmJobStart
      ? new Date(position?.confirmJobStart)
      : new Date(position?.jobStart);
    const Hours = jobStartDate.getHours(),
      Minutes = jobStartDate.getMinutes();
    newStartDate.setHours(Hours);
    newStartDate.setMinutes(Minutes);
    newStartDate.setSeconds(0);
    formik.setFieldValue('start', newStartDate);

    const newEndDate = new Date(position?.jobDate);
    const jobEndDate = position?.confirmJobEnd
      ? new Date(position?.confirmJobEnd)
      : new Date(position?.jobEnd);
    const EndHours = jobEndDate.getHours(),
      EndMinutes = jobEndDate.getMinutes();
    newEndDate.setHours(EndHours);
    newEndDate.setMinutes(EndMinutes);
    newEndDate.setSeconds(0);
    if (newEndDate < newStartDate) {
      const d = newStartDate.getDate() + 1;
      newEndDate.setDate(d);
    }
    formik.setFieldValue('end', newEndDate);
  }, []);

  const allowInput =
    new Date() > new Date(position?.jobStart) &&
    new Date() <= new Date(position?.jobStart + 86400000) &&
    position?.employerData?.paidOutstandingAmountValue === 0;
  const onChangeStartTime = (e) => {
    let StartHours = e.getHours(),
      StartMinutes = e.getMinutes();
    const newStartDate = new Date(position?.jobDate);
    newStartDate.setHours(StartHours);
    newStartDate.setMinutes(StartMinutes);
    newStartDate.setSeconds(0);

    if (new Date(formik.values.end) < new Date(newStartDate)) {
      // const d = new Date(newStartDate).getDate() + 1;
      const d = new Date(newStartDate).getDate();
      newStartDate.setDate(d);
    }
    formik.setFieldValue('start', newStartDate);
  };

  const onChangeEndTime = (e) => {
    // End Time
    let EndHours = e.getHours(),
      EndMinutes = e.getMinutes();
    const newEndDate = new Date(position?.jobDate);
    newEndDate.setHours(EndHours);
    newEndDate.setMinutes(EndMinutes);
    newEndDate.setSeconds(0);

    if (new Date(newEndDate) < new Date(formik.values.start)) {
      const d = new Date(formik.values.start).getDate() + 1;
      newEndDate.setDate(d);
    }
    formik.setFieldValue('end', newEndDate);
  };

  if (!position) {
    return '';
  }
  return (
    <form onSubmit={formik.handleSubmit} className="form form-confirm-hours">
      <Row className="justify-content-between">
        <Col md="12">
          <div className="profile-book">
            <div className="profile-wrap size-large me-3">
              <span className="profile-image">
                <img
                  src={`${
                    position.booked.employee.images.length &&
                    typeof position.booked.employee.images[0] === 'string' &&
                    !position.booked.employee.images[0].includes(
                      'https://storage.googleapis.com',
                    ) &&
                    (position.booked.employee.images[0].startsWith(
                      'employee-',
                    ) ||
                      position.booked.employee.images[0].startsWith('user-'))
                      ? image
                      : String(defaultAvatar)
                  }`}
                  alt={t('profile')}
                />
              </span>
              {position?.booked?.employee?.gender === 'male' ? (
                <span className="profile-gender male"></span>
              ) : position?.booked?.employee?.gender === 'female' ? (
                <span className="profile-gender female"></span>
              ) : (
                <span className="profile-gender other"></span>
              )}
            </div>
            <span>{position?.booked?.employee?.name}</span>
          </div>
        </Col>
        <Col sm={4} md={4} lg={2}>
          <div className="form-group">
            <label className="form-label">{t('dateLabel')}</label>
            <p className="small-text mb-0 pb-0">
              {moment(position?.jobDate).format('dddd')}
            </p>
            <p className="small-text mb-0 pb-0">
              {moment(position?.jobDate).format('DD.MM.YY')}
            </p>
          </div>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <div className="form-group time-picker-group">
            <label className="form-label">{t('startLabel') + '*'}</label>
            <DatePicker
              locale={currentlanguage}
              selected={
                position?.confirmJobStart
                  ? position?.confirmJobStart
                  : formik.values.start
              }
              // selected={position?.jobStart}
              onChange={(e) => onChangeStartTime(e)}
              showTimeSelectOnly
              timeCaption={t('startTimeLabel')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="HH:mm"
              id="start_date"
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={
                position.isConfirmed ||
                position?.confirmJobEnd ||
                isHoursSigned ||
                !allowInput
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {formik.errors['start'] && (
              <div className="form-errors">{formik.errors['start']}</div>
            )}
          </div>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <div className="form-group time-picker-group">
            <label className="form-label">{t('endLabel') + '*'}</label>
            <DatePicker
              locale={currentlanguage}
              selected={
                position?.confirmJobEnd
                  ? position?.confirmJobEnd
                  : formik.values.end
              }
              // selected={position?.jobEnd}
              onChange={(e) => onChangeEndTime(e)}
              showTimeSelectOnly
              timeCaption={t('endTimeLabel')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="HH:mm"
              id="end_date"
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={
                position.isConfirmed ||
                position?.confirmJobEnd ||
                isHoursSigned ||
                !allowInput
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {formik.errors['end'] && (
              <div className="form-errors">
                {formik.errors['end']}
                {formik.errors['end'].includes('10h') && (
                  <OverlayTrigger
                    placement="auto-start"
                    delay={{
                      show: 100,
                      hide: 400,
                    }}
                    overlay={EndInfo}>
                    <svg
                      width="22"
                      height="23"
                      xmlns="http://www.w3.org/2000/svg"
                      className="popover-icon">
                      <g
                        transform="translate(0 .5)"
                        fill="none"
                        fillRule="evenodd">
                        <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                        <path
                          d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                          fill="#FFF"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </OverlayTrigger>
                )}
              </div>
            )}
          </div>
        </Col>
        {!forEmployerSign && (
          <Col md={4} lg={2}>
            <div className="form-group">
              <label className="form-label">{t('event.hours')}</label>
              <p className="field-info small-text">
                {/* {getTimeDifferent(formik.values.start, formik.values.end)} h */}
                {convertMsToHM(formik.values.end - formik.values.start)}
              </p>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {forEmployerSign ? (
          <>
            <Col sm={12}>
              <div className="form-label">
                {t('myJob.employersignHere') + '*'}
              </div>
              {position?.employerSign &&
              position?.employerSign !== 'false' &&
              position?.employerSign !== false ? (
                <>
                  {employerSignImage ? (
                    <img
                      src={
                        employerSignImage
                          ? 'data:image/jpeg;base64,' + employerSignImage
                          : String(defaultAvatar)
                      }
                      alt=""
                    />
                  ) : (
                    <Spinner />
                  )}
                  <p>{position?.employerName}</p>
                </>
              ) : (
                <>
                  <div style={{ width: 300, height: 125 }}>
                    <SignaturePad
                      canvasProps={{
                        width: 300,
                        height: 125,
                        className: 'sigCanvas',
                      }}
                      penColor="black"
                      onEnd={() => {
                        setEmployerSign(
                          sigPad?.current
                            ?.getTrimmedCanvas()
                            .toDataURL('image/jpg'),
                        );
                      }}
                      ref={sigPad}
                    />
                  </div>
                  <p
                    className="small-text d-inline-block"
                    role="button"
                    style={{ color: '#C91414' }}
                    onClick={clear}>
                    {t('contract.clearSignature')}
                  </p>
                  <div className="form-label">
                    {t('myJob.employername') + '*'}
                  </div>
                  <div style={{ width: 300, height: 125 }}>
                    <Input
                      type="text"
                      placeholder={`${t('myJob.employername')}`}
                      name="employerName"
                      onChange={(e) =>
                        formik.setFieldValue('employerName', e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      touched={formik.touched.employerName}
                      errors={formik.errors.employerName}
                      value={formik.values.employerName}
                    />
                  </div>
                </>
              )}
            </Col>

            {!(
              position?.employerSign &&
              position?.employerSign !== 'false' &&
              position?.employerSign !== false
            ) && (
              <Col sm={12}>
                <div className="form-bottom justify-content-end">
                  <Button
                    type="submit"
                    label={t('contact.btnSubmit')}
                    className="btn primary-btn"
                    disabled={
                      formik.isSubmitting ||
                      (forEmployerSign && employerSign === false) ||
                      formik.values.employerName === ''
                    }
                  />
                </div>
              </Col>
            )}
          </>
        ) : (
          <>
            <Col sm={12}>
              <div className="form-bottom justify-content-end">
                <Button
                  type="button"
                  label={t('requestOverview.modal.cancel')}
                  className="btn btn--outline"
                  onClick={() =>
                    setShowConfirmHoursModal({
                      visible: false,
                      data: null,
                    })
                  }
                />
                <Button
                  type="submit"
                  label={t('myJob.confirm')}
                  className="btn primary-btn"
                  disabled={formik.isSubmitting}
                />
              </div>
            </Col>
          </>
        )}
      </Row>
    </form>
  );
};

export default ConfirmJobHoursForm;
