import { combineReducers } from 'redux';
import authReducer from './authReducer';
import eventReducer from './eventReducer';
import testimonialReducer from './testimonialReducer';
import settingReducer from './settingReducer';
import jobReducer from './jobReducer';
import zipcodeReducer from './zipcodeReducer';
import contractReducer from './contractReducer';
export default combineReducers({
  user: authReducer,
  events: eventReducer,
  testimonials: testimonialReducer,
  settings: settingReducer,
  jobs: jobReducer,
  zipcode: zipcodeReducer,
  contract: contractReducer,
});
