import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';
import Button from '../Core/Button';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = () => {
  const { showImageConfirmationModal, setShowImageConfirmationModal } =
    useContext(GlobalContext);
  const { t, i18n } = useTranslation();

  return (
    <Modal
      show={showImageConfirmationModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowImageConfirmationModal({ visible: false })}
      className="modal-small">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('confirmationModal.title')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lead">{t('confirmationModal.text')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          onClick={() => {
            const { files, setFiles } = showImageConfirmationModal;
            const ids = files.map((o) => o.name);
            const filtered = files.filter(
              ({ name }, index) =>
                name === undefined || !ids.includes(name, index + 1),
            );
            setFiles(filtered);
            setShowImageConfirmationModal({
              visible: false,
            });
          }}
          label={t('delete')}
        />
        <Button
          className="btn"
          label={t('save')}
          onClick={() => {
            const { files, setFiles } = showImageConfirmationModal;
            setFiles(files);
            setShowImageConfirmationModal({
              visible: false,
            });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
