import { Switch, Route } from 'react-router-dom';
// import ProtectedRoute from './components/ProtectedRoute';
// import PublicRoute from './components/PublicRoute';
import EmployerRoute from './components/ProtectedRoute/EmployerRoute';
import EmployeeRoute from './components/ProtectedRoute/EmployeeRoute';
import SuperuserRoute from './components/ProtectedRoute/SuperuserRoute';
import HomePage from './pages/Home';
import RequestOverview from './pages/RequestOverview';
import NotFound from './pages/NotFound';
import WorkWithUs from './pages/WorkWithUs';
import ProfilePage from './pages/Profile';
// import ProfilePaymentPage from './pages/ProfilePayment';
// import ProfileBillPage from './pages/ProfileBill';
import NewRequest from './pages/NewRequest';
import RequestDetails from './pages/RequestDetails';
import MyEvents from './pages/MyEvents';
import JobWall from './pages/JobWall';
// import CompletedEvents from './pages/CompletedEvents';
import RequestBookAndPayment from './pages/RequestBookAndPayment';
import RequestPayment from './pages/RequestPayment';
import MyJobs from './pages/MyJobs';
import EventList from './pages/EventList';
import JobWallDetails from './pages/JobWallDetails';
import JobDetails from './pages/JobDetails';
import AppliedJobDetails from './pages/AppliedJobDetails';
import EmployeeRegistration from './pages/EmployeeRegistration';
import BookingJobDetails from './pages/BookingJobDetails';
import RequestBookPaymentSummary from './pages/RequestBookPaymentSummary';
import RequestPaymentSummary from './pages/RequestPaymentSummary';
import PaymentCompleted from './pages/PaymentCompleted';
import ProfileBillDetail from './pages/ProfileBillDetail';
import EmployeeRegistrationForm from './pages/EmployeeRegistrationForm';
import StaffDetails from './pages/StaffDetails';
import Test from './pages/Test';
import ContactPage from './pages/ContactPage';
import { labelRoles } from './utils/constants';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';
import ConfirmJobHours from './pages/ConfirmJobHours';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route
        exact
        path="/auth/reset-password/:resetToken"
        component={HomePage}
      />
      <Route exact path="/auth/registration/:userId" component={HomePage} />
      <Route exact path="/workwithus" component={WorkWithUs} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/imprint" component={Imprint} />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/privacy" component={Privacy} />
      <EmployerRoute exact path="/staff-details/:id" component={StaffDetails} />
      <SuperuserRoute exact path="/staff-detail/:id" component={StaffDetails} />
      <Route
        exact
        path={`/${labelRoles.employee}/complete-registration`}
        component={EmployeeRegistrationForm}
      />
      <Route
        path={`/${labelRoles.superuser}`}
        render={({ match: { url } }) => (
          <>
            <SuperuserRoute
              exact
              path={`${url}/eventslist`}
              component={EventList}
            />

            <SuperuserRoute
              exact
              path={`${url}/request-pay`}
              component={RequestPayment}
            />
            <SuperuserRoute
              path={`${url}/request-overview`}
              component={RequestOverview}
              exact
            />
            <SuperuserRoute path={`${url}/profile`} component={ProfilePage} />
            <SuperuserRoute
              exact
              path={`${url}/request-payment-summary`}
              component={RequestPaymentSummary}
            />
            <SuperuserRoute
              exact
              path={`${url}/payment-completed`}
              component={PaymentCompleted}
            />
            <SuperuserRoute
              exact
              path={`${url}/request-book-and-pay`}
              component={RequestBookAndPayment}
            />
            <SuperuserRoute
              exact
              path={`${url}/request-book-payment-summary`}
              component={RequestBookPaymentSummary}
            />
          </>
        )}
      />
      <Route
        path={`/${labelRoles.employer}`}
        render={({ match: { url } }) => (
          <>
            <EmployerRoute
              path={`${url}/request-overview`}
              component={RequestOverview}
              exact
            />
            <EmployerRoute
              exact
              path={`${url}/my-events`}
              component={MyEvents}
            />
            <EmployerRoute path={`${url}/profile`} component={ProfilePage} />
            <EmployerRoute
              exact
              path={`${url}/new-request`}
              component={NewRequest}
            />
            <EmployerRoute
              exact
              path={`${url}/my-events/:eventId`}
              component={BookingJobDetails}
            />
            <EmployerRoute
              exact
              path={`${url}/request-book-and-pay`}
              component={RequestBookAndPayment}
            />
            <EmployerRoute
              exact
              path={`${url}/request-pay`}
              component={RequestPayment}
            />
            <EmployerRoute
              exact
              path={`${url}/request-book-payment-summary`}
              component={RequestBookPaymentSummary}
            />
            <EmployerRoute
              exact
              path={`${url}/request-payment-summary`}
              component={RequestPaymentSummary}
            />
            <EmployerRoute
              exact
              path={`${url}/payment-completed`}
              component={PaymentCompleted}
            />
            <EmployerRoute
              exact
              path={`${url}/bills/:tid`}
              component={ProfileBillDetail}
            />
          </>
        )}
      />
      <Route
        path={`/${labelRoles.employee}`}
        render={({ match: { url } }) => (
          <>
            {/*<EmployeeRoute
              exact
              path={`${url}/bills/:tid`}
              component={ProfileBillDetail}
        />*/}
            <EmployeeRoute
              exact
              path={`${url}/profile`}
              component={EmployeeRegistration}
            />
            <EmployeeRoute exact path={`${url}/job-wall`} component={JobWall} />
            <EmployeeRoute exact path={`${url}/my-jobs`} component={MyJobs} />
            <EmployeeRoute
              exact
              path={`${url}/job-details/:id/:eventID/:bookeduser`}
              component={JobDetails}
            />
            <EmployeeRoute
              exact
              path={`${url}/job-details/:id/:eventID`}
              component={JobDetails}
            />
            <EmployeeRoute
              exact
              path={`${url}/job-details-applied/:id/:eventID`}
              component={AppliedJobDetails}
            />

            <EmployeeRoute
              exact
              path={`${url}/job-wall-details/:id/:eventID`}
              component={JobWallDetails}
            />
            <EmployeeRoute
              exact
              path={`${url}/confirm-job-hours/:id/:eventID/:bookeduser`}
              component={ConfirmJobHours}
            />
            <EmployeeRoute
              exact
              path={`${url}/confirm-job-hours/:id/:eventID`}
              component={ConfirmJobHours}
            />
          </>
        )}
      />
      <Route exact path="/request-details" component={RequestDetails} />
      // <Route exact path="/event-list" component={EventList} />
      <Route exact path="/test" component={Test} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
