import React from 'react';
import ReactMarkdown from 'react-markdown';

const Checkbox = ({
  label,
  value,
  onChange,
  checked,
  name,
  touched,
  errors,
  children,
}) => {
  return (
    <div className="form-group">
      <label className="custom-chk">
        {children ? children : <ReactMarkdown>{label}</ReactMarkdown>}
        <input
          type="checkbox"
          checked={checked}
          value={value}
          name={name}
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </label>
      {touched && errors && <div className="form-errors">{errors}</div>}
    </div>
  );
};

export default Checkbox;
