import { zipcodeConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';

// POST Request to Create Testimonial
export const getAllZipCodes = () => {

  const request = () => {
    return { type: zipcodeConstants.GET_ZIPCODE_REQUEST };
  };
  const success = (response) => {
    return {
      type: zipcodeConstants.GET_ZIPCODE_SUCCESS,
      payload: response?.data?.data?.Zipcodes
    };
  };
  const failure = (error) => {
    return { type: zipcodeConstants.GET_ZIPCODE_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
        let response = await api.get("/zipcodes");
    //   toast.success("Created testimonial successfully!");
      dispatch(success(response));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};