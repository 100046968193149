import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTable, useSortBy } from 'react-table';
import { labelRoles } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { CircularProgress } from '@material-ui/core';
import api from '../../utils/api';

const getTid = (id) => {
  if (!id || !id.trim()) {
    return;
  }
  const idArr = id.split('-');
  return idArr[idArr.length - 1].toUpperCase();
};

const Table = ({ columns, data, bills, paymentDetails }) => {
  // const { t, i18n } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table className="table remove-space" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows
            .sort((a, b) => b.id - a.id)
            .map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'orderNumber') {
                      return (
                        <td {...cell.getCellProps()}>
                          <Link
                            to={{
                              pathname: `/${labelRoles.employer}/bills/${cell.row.original.id}`,
                              state: {
                                // bill: bills[i],
                                paymentDetails: paymentDetails,
                              },
                            }}
                            className="link">
                            {getTid(cell.value)}
                          </Link>
                        </td>
                      );
                    } else if (cell.column.id === 'amount') {
                      return (
                        <td {...cell.getCellProps()}>
                          <span className="total-amount">
                            {cell.render('Cell')}
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>
                          <span>{cell.render('Cell')}</span>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const BillListing = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const [bills, setBills] = useState();
  const [loading, setLoading] = useState(true);
  let data;
  // const bills = user.data && user.data.employer ? user.data.employer.bills : [];
  const paymentDetails =
    user.data && user.data.employer ? user.data.employer.paymentDetails : [];
  useEffect(() => {
    api
      .get(`/orders/getEmployerBills/${user.data._id}`)
      .then((res) => {
        // setBill(res.data.data[0].bills);
        setLoading(false);
        setBills(res.data.data[0].bills);
        // setLoading(false)
      })
      .catch((e) => {
        setLoading(false);
        // handleError(e);
      });
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('ordernumber')}`;
        },
        accessor: 'orderNumber',
      },
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventId')}`;
        },
        accessor: 'eventId',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('Costs')}`;
        },
        accessor: 'amount',
      },
    ];
  }, []);

  // const data = bills.map((bill) => {
  //   return {
  //     id: bill._id,
  //     orderNumber: bill.transactionId,
  //     date: moment(bill.orderDate).format('DD.MM.YYYY'),
  //     eventId: bill.cartItems[0].eventID,
  //     eventName: bill.cartItems[0].eventName,
  //     amount: `€ ${bill.cost}`,
  //   };
  // });
  if (bills && bills.length) {
    data = bills.map((bill) => {
      return {
        id: bill._id,
        orderNumber: bill.transactionId,
        date: moment(bill.orderDate).format('DD.MM.YYYY'),
        eventId:
          bill.cartItems && bill.cartItems.length && bill.cartItems[0].eventID
            ? bill.cartItems[0].eventID
            : '',
        eventName:
          bill.cartItems && bill.cartItems.length && bill.cartItems[0].eventName
            ? bill.cartItems[0].eventName
            : '',
        amount: `€ ${parseFloat(bill.cost).toFixed(2)}`,
      };
    });
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : bills && bills.length ? (
        <Table
          columns={columns}
          data={data}
          bills={bills}
          paymentDetails={paymentDetails}
        />
      ) : (
        <h5>{t('profileOrg.bills')}</h5>
      )}
    </>
  );
};

export default BillListing;
