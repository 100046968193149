import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import CustomSelect from '../Core/Select';
import Button from '../Core/Button';
import { NUMBER_ONLY, labelRoles } from '../../utils/constants';
import useQuery from '../../utils/useQuery';
import api from '../../utils/api';
import { useTranslation } from 'react-i18next';

import PlacesAutocomplete, {
  geocodeByAddress,
  // getLatLng,
} from '@dsr/react-places-autocomplete';
import Loader from '../Core/Loader';

const EmployeeCompleteRegister = () => {
  let query = useQuery();
  // const formRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();

  const [userDetails, setUserDetails] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [formSubmtting, setFormSubmtting] = useState(false);
  const {
    showEmployeeCompleteRegisterModal,
    setShowEmployeeCompleteRegisterModal,
    countriesOptions,
    setShowLoginModal,
  } = useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      country: '',
      city: '',
      postalCode: '',
      addressLineOne: '',
      addressLineTwo: '',
    },
    validationSchema: Yup.object({
      country: Yup.object().shape({
        label: Yup.string().required(t('Country')),
        value: Yup.string().required(t('Country')),
      }),
      password: Yup.string()
        .required(t('Password.required'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        ),
      // .min(8, 'Your password must be minimum 8 characters.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], t('matchpassword'))
        .required(t('confirmPassword')),

      city: Yup.string().required(t('City')),
      postalCode: Yup.string()
        .required(t('PostalCode'))
        .matches(/^[0-9]/, t('postalcodenotnegative'))
        .min(4, t('Postalcodevalidation'))
        .max(5, t('Postalcodevalidation')),
      addressLineOne: Yup.string().required(t('AddressLineOne')),
    }),
    onSubmit: async (values) => {
      const role = query.get('role');
      const token = query.get('token');
      const { email, firstName, lastName, phone } = userDetails;
      history.push({
        pathname: `/${labelRoles.employee}/complete-registration`,
        state: {
          role,
          token,
          values: {
            ...values,
            email,
            firstName,
            lastName,
            phone,
          },
        },
      });
      setShowEmployeeCompleteRegisterModal(false);
    },
  });

  useEffect(() => {
    let mounted = true;
    setDetailsLoading(true);
    api
      .get('/users/getMe', {
        headers: {
          Authorization: `Bearer ${query.get('token')}`,
        },
      })
      .then((res) => {
        if (mounted) {
          const user = res.data.data.user;
          setDetailsLoading(false);

          if (user.employee || user.employer) {
            history.push('/');
            setShowEmployeeCompleteRegisterModal(false);
            setShowLoginModal({ visible: true, role: query.get('role') });
          } else {
            setUserDetails(user);
          }
        }
      })
      .catch((e) => {
        setDetailsLoading(false);
        setShowEmployeeCompleteRegisterModal(false);
        history.push('/');
        toast.error(t('Faileduserdetails'));
      });
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedcity, setSelectedCity] = useState(formik.values?.city || '');
  const [addressline, setAddressLine] = useState(
    formik.values?.addressLineOne || '',
  );

  useEffect(() => {
    if (selectedcity === '') {
      formik.setFieldValue('city', '');
    } else {
      // formik.validateForm();
      // formik.errors?.city = '';
    }
  }, [selectedcity]);
  useEffect(() => {
    if (addressline === '') {
      formik.setFieldValue('addressLineOne', '');
    } else {
      // formik.validateForm();
      // formik.errors?.addressLineOne = '';
    }
  }, [addressline]);
  const renderSuggestionItem = (suggestion, type) => {
    if (type === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };
  const handleError = (name, error) => {
    // formik.setFieldError(name, error);
  };
  useEffect(() => {
    formik.validateForm();
  }, [formik.values.country, formik.values.addressLineOne, formik.values.city]);

  const handleSelect = async (address, name, type, suggestion) => {
    try {
      let returnAddress = address;
      if (type === 'sublocality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else if (type === 'locality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else {
        const res = await geocodeByAddress(address);
        // const rLatLang = await getLatLng(res[0]);

        if (type === 'sublocality' || type === 'locality') {
          returnAddress = res[0].address_components[0].short_name;
        }
      }
      if (name === 'city') {
        setSelectedCity(returnAddress);
        // formik.validateForm();
      } else {
        setAddressLine(returnAddress);
        // formik.validateForm();
      }

      formik.setFieldValue(name, returnAddress);
      formik.validateForm();
    } catch (error) {
      formik.setFieldError(name, error);
    }
  };

  return (
    <>
      <Modal
        show={showEmployeeCompleteRegisterModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowEmployeeCompleteRegisterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {detailsLoading ? (
              <h3 className="visually-hidden">{t('step1/2')}</h3>
            ) : (
              <h3>{t('step1/2')}</h3>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailsLoading ? (
            <div className="d-flex align-items-center justify-content-center px-2 py-2">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              className="form form-complete-registration">
              <Row>
                <Col md="6">
                  <span>
                    <Input
                      type="password"
                      label={`${t('passwordLogin')} *`}
                      placeholder={t('passwordLogin')}
                      name="password"
                      tooltipTitle={t('passwordLogin')}
                      tooltipText={t('Password.length')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.password}
                      errors={formik.errors.password}
                      value={formik.values.password}
                    />
                    {/*<p
                          className="text-secondary"
                          style={{ fontSize: '14px', marginTop: '-25px' }}>
                          {t('Password.length')}
              </p>*/}
                  </span>
                </Col>
                <Col md="6">
                  <Input
                    type="password"
                    label={`${t('confirmPassword')} *`}
                    placeholder={t('passwordLogin')}
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.confirmPassword}
                    errors={formik.errors.confirmPassword}
                    value={formik.values.confirmPassword}
                  />
                </Col>
                <Col md="6">
                  <CustomSelect
                    label={`${t('country')} *`}
                    name="country"
                    placeholder={t('country')}
                    options={countriesOptions}
                    handleChange={(value) => {
                      formik.setFieldValue('country', value);
                      setTimeout(() => {
                        formik.validateForm();
                      }, 200);
                    }}
                    handleBlur={() => {
                      formik.setFieldTouched('country', true);
                    }}
                    touched={formik.touched.country}
                    errors={
                      formik.errors.country ? formik.errors.country.value : ''
                    }
                    value={formik.values.country}
                  />
                </Col>
                <Col md="6">
                  <div
                    className={`form-group
                        }`}>
                    <label className="form-label">{`${t('city')} *`}</label>
                    <PlacesAutocomplete
                      name={'city'}
                      id={'city'}
                      searchOptions={['(cities)']}
                      value={selectedcity}
                      onChange={(e) => {
                        formik.setFieldTouched('city', true);
                        handleSelect('', 'city', 'locality', '');
                        setSelectedCity(e);
                        setTimeout(() => {
                          formik.validateForm();
                        }, 200);
                      }}
                      onBlur={formik.handleBlur}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(data, 'city', 'locality', suggestion)
                      }
                      onError={(error) => handleError('city', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            {...getInputProps({
                              placeholder: `${t('city')}`,
                              className: 'location-search-input form-control',
                            })}
                            type="text"
                            // label={t('addressLineOne') + '*'}
                            placeholder={t('city')}
                            name="city"
                            // onChange={handleChange}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              getInputProps()?.onBlur(e);
                              setTimeout(() => {
                                if (
                                  formik?.values.city !== '' &&
                                  formik?.errors?.city &&
                                  formik?.errors?.city !== ''
                                ) {
                                  setSelectedCity('');
                                  formik.validateForm();
                                }
                              }, 200);
                            }}
                            touched={formik.touched.city}
                            errors={formik.errors.city}
                            value={formik.values.city}
                            formGroupClass={'location-search-input-wraper mb-0'}
                          />
                          {/* <input
                                {...getInputProps({
                                  placeholder: `${t('city')}`,
                                  className:
                                    'location-search-input form-control',
                                })}
                              /> */}
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'locality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {/* {errors?.city !== '' && (
                          <div class="form-errors">{errors?.city} </div>
                        )} */}
                  </div>
                </Col>
                <Col md="6">
                  <div
                    className={`form-group
                        }`}>
                    <label className="form-label">
                      {`${t('addressLineOne')} *`}
                    </label>
                    <PlacesAutocomplete
                      name={'addressLineOne'}
                      id={'addressLineOne'}
                      searchOptions={['address']}
                      value={addressline}
                      onChange={(value) => {
                        formik.setFieldTouched('addressLineOne', true);
                        handleSelect('', 'addressLineOne', 'sublocality', '');
                        setAddressLine(value);
                        setTimeout(() => {
                          formik.validateForm();
                        }, 200);
                      }}
                      onBlur={formik.handleBlur}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(
                          data,
                          'addressLineOne',
                          'sublocality',
                          suggestion,
                        )
                      }
                      onError={(error) => handleError('addressLineOne', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            {...getInputProps({
                              placeholder: 'Street line 1',
                              className: 'location-search-input form-control',
                            })}
                            type="text"
                            // label={t('addressLineOne') + '*'}
                            placeholder={t('addressLineOne')}
                            name="addressLineOne"
                            // onChange={formik.handleChange}
                            touched={formik.touched.addressLineOne}
                            errors={formik.errors.addressLineOne}
                            value={formik.values.addressLineOne}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              getInputProps()?.onBlur(e);
                              setTimeout(() => {
                                if (
                                  formik.values.addressLineOne !== '' &&
                                  formik?.errors?.addressLineOne &&
                                  formik?.errors?.addressLineOne !== ''
                                ) {
                                  setAddressLine('');
                                  formik.validateForm();
                                }
                              }, 200);
                            }}
                            formGroupClass={'location-search-input-wraper mb-0'}
                          />
                          {/* <input
                                {...getInputProps({
                                  placeholder: 'Street line 1',
                                  className:
                                    'location-search-input form-control',
                                })}
                              /> */}
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (!suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'sublocality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {/* {errors?.addressLineOne !== '' && (
                            <div class="form-errors">
                              {errors?.addressLineOne}
                            </div>
                          )} */}
                  </div>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={t('addressLineTwo')}
                    placeholder={t('addressLineTwo')}
                    name="addressLineTwo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.addressLineTwo}
                    errors={formik.errors.addressLineTwo}
                    value={formik.values.addressLineTwo}
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('postalCode')} *`}
                    placeholder={t('postalCode')}
                    name="postalCode"
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        (NUMBER_ONLY.test(e.target.value) &&
                          e.target.value?.length <= 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.postalCode}
                    errors={formik.errors.postalCode}
                    value={formik.values.postalCode}
                  />
                </Col>
              </Row>
              <div className="form-bottom d-flex justify-content-end">
                <Button
                  type="submit"
                  label={t('next')}
                  className="btn primary-btn"
                  disabled={
                    formSubmtting || Object.entries(formik?.errors).length > 0
                  }
                />
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployeeCompleteRegister;
