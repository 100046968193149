import React, { useState } from 'react';
import { Container, Tab, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Head from '../components/Head';
import PaymentMethod from '../components/Profile/PaymentMethod';
import EmployerProfile from '../components/Profile/EmployerProfile';
import SuperUserProfile from '../components/Profile/SuperUserProfile';
import BillListing from '../components/Profile/BillListing';
import useQuery from '../utils/useQuery';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
const ProfilePage = () => {
  let query = useQuery();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const user = useSelector((state) => state.user);
  const userData = user.data;
  const { t } = useTranslation();
  const [key, setKey] = useState('');

  return (
    <section className="content-section">
      <Head title={t('title.profile')} />
      <Container>
        <div className="box-wrapper position-relative">
          <Tab.Container
            id="left-tabs-example"
            activeKey={
              key
                ? key
                : query.get('tab')
                ? query.get('tab')
                : 'profile-details'
            }
            onSelect={(k) => setKey(k)}>
            <Nav className="tab-menu">
              <Nav.Item>
                {/*<Nav.Link eventKey="profile-details">Profile Details</Nav.Link>*/}
                <Nav.Link eventKey="profile-details">
                  {t('profileDetails')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {/*<Nav.Link eventKey="payment-method">Payment Method</Nav.Link>
               if (userData.role === 'superuser') {
        history.push(`/${labelRoles.superuser}/eventslist`);
      } else {
        history.push(`/${labelRoles.employer}/request-overview`);
      }*/}
                {userData.role === 'superuser' ? (
                  <p></p>
                ) : (
                  <Nav.Link eventKey="payment-method">
                    {t('paymentMethod')}
                  </Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {/*<Nav.Link eventKey="bills">Bills</Nav.Link>*/}
                {userData.role === 'superuser' ? (
                  <p></p>
                ) : (
                  <Nav.Link eventKey="bills">{t('bills')}</Nav.Link>
                )}
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="profile-details" className="tab-content">
                {userData.role === 'superuser' ? (
                  <SuperUserProfile />
                ) : (
                  <EmployerProfile />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="payment-method" className="tab-content">
                <PaymentMethod />
              </Tab.Pane>
              <Tab.Pane eventKey="bills" className="tab-content">
                {key === 'bills' ? <BillListing /> : ''}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
};

export default ProfilePage;
