import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Table from './Table';
import { fetchCompletedEvents } from '../../actions/eventAction';
import moment from 'moment';
import { stringDateSort } from '../../utils/helper';

const CompletedEvents = () => {
  const dispatch = useDispatch();
  const eventsData = useSelector((state) => state.events);
  const completedEvents = eventsData.completedEvents;
  const { currPage, pageCount, events } = completedEvents;
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (events && events.length) {
      return;
    }
    dispatch(fetchCompletedEvents({ currPage: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
        sortType: (rowA, rowB, columnId) =>
          stringDateSort(
            rowA.original[columnId],
            rowB.original[columnId],
            t('to'),
          ),
      },
      {
        Header: '',
        accessor: 'timeReportConfirmation',
      },
    ];
  }, []);

  const renderTable = (events) => {
    let datedEvents = events.map((event) => {
      let jobStartDates = [];
      let jobEndDates = [];
      event.jobs.map((job) => {
        job.jobPositions.map((jobPosition, key) => {
          jobStartDates[key] = jobPosition.jobStart;
          jobEndDates[key] = jobPosition.jobEnd;
        });
      });
      const eventEndDate = jobEndDates.reduce(
        (previousValue, currentValue) =>
          previousValue < currentValue ? currentValue : previousValue,
        jobEndDates[0],
      );
      const eventStartdate = jobStartDates.reduce(
        (previousValue, currentValue) =>
          previousValue > currentValue ? currentValue : previousValue,
        jobStartDates[0],
      );

      return {
        ...event,
        eventName: event.eventName,
        timeReportConfirmation: '',
        date:
          jobStartDates && jobEndDates
            ? moment(eventStartdate)
                .locale(i18n.language)
                .format('dddd DD.MM.yyyy') +
              ` ${t('to')} ` +
              moment(eventEndDate)
                .locale(i18n.language)
                .format('dddd DD.MM.yyyy')
            : '',
      };
    });
    return (
      <>
        {/* {eventLoading && <Loader />} */}
        <Table columns={columns} data={datedEvents} isCurrent={false} />
        {currPage >= pageCount ? (
          <></>
        ) : (
          <>
            {loadMoreLoading ? (
              <div className="d-flex align-items-center justify-content-center px-2 py-2">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t('event.loading')}</span>
                </Spinner>
              </div>
            ) : (
              <div className="load-more">
                <span
                  className="load-more-link"
                  onClick={() => {
                    setLoadMoreLoading(true);
                    dispatch(
                      fetchCompletedEvents({
                        currPage: currPage + 1,
                        callback: () => setLoadMoreLoading(false),
                      }),
                    );
                  }}>
                  {t('loadMore')}
                </span>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="table-responsive">
        {eventsData.loading ? (
          <div className="d-flex align-items-center justify-content-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {events && events.length ? (
              <>{renderTable(events)}</>
            ) : (
              <div className="d-flex  py-4">
                <h5>{t('event.noEventsFound')}</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CompletedEvents;
