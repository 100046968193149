import React, { useContext } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import Button from '../Core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { roles, labelRoles, EMAIL_VALIDATION } from '../../utils/constants';
import { login } from '../../actions/authAction';
import { useTranslation } from 'react-i18next';

const CompleteLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { showCompleteLoginModal, setShowCompleteLoginModal } =
    useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_VALIDATION, {
          message: t('Email.type'),
        })
        .required(t('Email.required')),
      password: Yup.string()
        .required(t('Password.required'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        ),
    }),
    onSubmit: (values) => {
      setSubmitted(true);
      dispatch(
        login({
          email: values.email,
          password: values.password,
          role: roles.employer,
          callback: () => {
            history.push(`/${labelRoles.employer}/profile`);
            setShowCompleteLoginModal({
              ...showCompleteLoginModal,
              visible: false,
            });
          },
          setSubmitting: setSubmitted,
        }),
      );
    },
  });

  return (
    <Modal
      show={showCompleteLoginModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() =>
        setShowCompleteLoginModal({ ...showCompleteLoginModal, visible: false })
      }
      className="employee-login-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('regicomplete')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form form-login">
          <Row>
            <Col md="12" className="mb-5 from-text">
              <p>{t('thankforregistration') + ',' + t('canlogin')}</p>
            </Col>
            <Col md="6">
              <Input
                type="email"
                label={t('emailContact') + '*'}
                placeholder={t('emailContact')}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.email}
                errors={formik.errors.email}
                value={formik.values.email}
              />
            </Col>
            <Col md="6">
              <Input
                type="password"
                label={t('passwordLogin') + '*'}
                placeholder={t('passwordLogin')}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.password}
                errors={formik.errors.password}
                value={formik.values.password}
              />
            </Col>
          </Row>
          <div className="form-bottom">
            <Button
              type="submit"
              label={t('loginLabel')}
              className="btn"
              disabled={submitted}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteLogin;
