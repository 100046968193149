import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AudioPlayer from 'react-h5-audio-player';
import { RecordState } from 'audio-react-recorder';
import AudioRecording from '../AudioRecording';
import PlayIcon from '../AudioRecording/Play';
import PauseIcon from '../AudioRecording/Pause';
import pauseIcon from '../../assets/images/icons/pause.svg';
import { useTranslation } from 'react-i18next';

let timeStore = 40;
let tempTimeRemaining = timeStore;
let interval;

function RecorderModel({
  recordState,
  setRecordState,
  setAudio,
  showRecorderModel,
  setShowRecorderModel,
}) {
  // console.log(timeStore, 'time store');
  const [tempAudio, setTempAudio] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(timeStore);
  const curPercentage = ((timeStore - timeRemaining) / timeStore) * 100;
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   console.log(timeRemaining, 'time remaining');
  // }, [timeRemaining]);

  // useEffect(
  //   () => () => {
  //     setTimeRemaining(timeStore);
  //     setTempAudio(null);
  //     setRecordState(null);
  //   },
  //   [],
  // );

  useEffect(() => {
    if (recordState === 'start') {
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        tempTimeRemaining = tempTimeRemaining - 1;
        setTimeRemaining(tempTimeRemaining);
      }, 1000);
    } else {
      clearInterval(interval);
    }
  }, [recordState]);

  useEffect(() => {
    if (!timeRemaining || timeRemaining < 0) {
      setRecordState(RecordState.STOP);
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining]);

  const resetTimeRemaining = () => {
    setTimeRemaining(timeStore);
    tempTimeRemaining = timeStore;
  };

  return (
    <Modal
      show={showRecorderModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowRecorderModel(false)}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('audioModal.recordVoiceSample')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lead">{t('audioModal.sampleTextOne')}</p>
        <p className="lead">{t('audioModal.sampleTextTwo')}</p>

        <div className="player mt-3">
          <p className="text-start mb-3">{t('profileStaffstatic.audio')}</p>
          {recordState === 'stop' ? (
            <>
              {tempAudio && (
                <AudioPlayer
                  autoPlay={false}
                  className="custom-audio-player"
                  customAdditionalControls={[]}
                  customProgressBarSection={[
                    'MAIN_CONTROLS',
                    'PROGRESS_BAR',
                    'DURATION',
                    'VOLUME_CONTROLS',
                  ]}
                  customControlsSection={[]}
                  customIcons={{
                    play: <PlayIcon />,
                    pause: <PauseIcon />,
                  }}
                  onPlayError={() =>
                    toast.error('Failed to play your voice recording!')
                  }
                  showJumpControls={false}
                  src={tempAudio.url}
                />
              )}
            </>
          ) : (
            <div className="audio-wrap">
              <span className="player__button">
                <img src={pauseIcon} alt="Pause" />
              </span>
              <div className="bar">
                <div
                  className="bar__progress"
                  style={{
                    background: `linear-gradient(to right, orange ${curPercentage}%, white 0)`,
                  }}>
                  <span
                    className="bar__progress__knob"
                    style={{ left: `${curPercentage - 2}%` }}
                  />
                </div>
                {/* <span className="audio-in"></span> */}
                <span className="bar__time">-0.{timeRemaining}</span>
              </div>
              <span className="ms-2">
                <svg viewBox="0 0 15 14" width="15" height="14">
                  <g fill="#7D7D7D" fillRule="evenodd">
                    <path d="M6.868 12.157l-4.12-2.748H0V4.602h2.747l4.121-2.747zM9.616 7.017c0 .673-.243 1.274-.687 1.714v1.639A3.76 3.76 0 0010.989 7a3.757 3.757 0 00-2.06-3.37v1.675c.444.435.687 1.041.687 1.712" />
                    <path d="M9.601.2a6.796 6.796 0 00-.672-.2v1.388c2.404.73 4.12 2.966 4.12 5.613a5.845 5.845 0 01-4.12 5.615V14a7.215 7.215 0 005.494-7.003A7.206 7.206 0 009.601.2" />
                  </g>
                </svg>
              </span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <AudioRecording
          tempAudio={tempAudio}
          setTempAudio={setTempAudio}
          timeStore={timeStore}
          setTimeRemaining={setTimeRemaining}
          recordState={recordState}
          setRecordState={setRecordState}
          setAudio={setAudio}
          resetTimeRemaining={resetTimeRemaining}
          setShowRecorderModel={setShowRecorderModel}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default RecorderModel;
