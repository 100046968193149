import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
// import ReCAPTCHA from 'react-google-recaptcha';
import Input from '../components/Core/Input';
import Textarea from '../components/Core/Textarea';
import Checkbox from '../components/Core/Checkbox';
import Head from '../components/Head';
import Button from '../components/Core/Button';
import api from '../utils/api';
import { useTranslation } from 'react-i18next';
import { ALPHABETS_ONLY, EMAIL_VALIDATION } from '../utils/constants';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

const ContactPage = () => {
  const userData = useSelector((state) => state.user);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const user = userData.data;
  // const captchaRef = useRef(null);
  // const [tremsRef, settermsRef] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (user && user.token) {
      setLoggedIn(true);
    }
  }, [user]);
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName ? user.firstName : '',
      lastName: user.lastName ? user.lastName : '',
      email: user.email ? user.email : '',
      phone: user.phone ? user.phone : '',
      message: '',
      captcha: user && user.firstName ? '1' : '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('FirstName')),
      lastName: Yup.string().required(t('LastName')),
      message: Yup.string().required(t('message')),
      email: Yup.string()
        .matches(EMAIL_VALIDATION, {
          message: t('Email.type'),
        })
        .required(t('Email.required')),
      phone: Yup.number()
        .typeError(t('Phone.type'))
        .positive(t('Phone.type'))
        .integer(t('Phone.type'))
        .required(t('Phone.required'))
        .test(
          'maxDigits',
          t('Phone.length9'),
          (number) => String(number).length >= 9,
        ),
      captcha: Yup.string().required(t('captcha')),
      acceptTerms: Yup.bool().oneOf([true], t('acceptTermscontact')),
    }),
    onSubmit: async (values, action) => {
      setFormSubmitting(true);
      const { firstName, lastName, email, phone, message } = values;
      let newValues = {
        firstName,
        lastName,
        email,
        phone,
        message,
      };
      if (loggedIn) {
        api
          .post('/send-message', newValues)
          .then(() => {
            setFormSubmitting(false);
            formik.resetForm();
            // captchaRef.current.reset();
            formik.values.acceptTerms = false;
            toast.success(t('Message.successfully'));
          })
          .catch((e) => {
            setFormSubmitting(false);
            formik.resetForm();
            toast.success(t('Message.Failed'));
          });
      } else {
        if (validateCaptcha(values.captcha) === true) {
          api
            .post('/send-message', newValues)
            .then(() => {
              setFormSubmitting(false);
              formik.resetForm();
              // captchaRef.current.reset();
              formik.values.acceptTerms = false;
              toast.success(t('Message.successfully'));
            })
            .catch((e) => {
              setFormSubmitting(false);
              formik.resetForm();
              toast.success(t('Message.Failed'));
            });
        } else {
          document.getElementById('wrongCaptcha').innerHTML = t('wrongCaptcha');
          setFormSubmitting(false);
        }
      }
    },
  });

  // const onChange = (value) => {
  //   formik.setFieldValue('captcha', value);
  // };

  return (
    <section className="content-section">
      <Head title={`${t('contactPage')} | Superstaff`} />
      <Container>
        <div className="box-wrapper contact-wrapper">
          <div className="form-back-wrapper">
            <div
              className="form-back"
              onClick={(e) => {
                history.push('');
              }}></div>
          </div>
          <h3 className="contact-title">{t('home.contact')}</h3>
          <form onSubmit={formik.handleSubmit} className="form form-contact">
            <Row>
              {!loggedIn && (
                <>
                  <Col md="6">
                    <Input
                      type="text"
                      label={`${t('firstName')} *`}
                      placeholder={t('firstName')}
                      name="firstName"
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          ALPHABETS_ONLY.test(e.target.value)
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.firstName}
                      errors={formik.errors.firstName}
                      value={formik.values.firstName}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      label={`${t('lastName')} *`}
                      placeholder={t('lastName')}
                      name="lastName"
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          ALPHABETS_ONLY.test(e.target.value)
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.lastName}
                      errors={formik.errors.lastName}
                      value={formik.values.lastName}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      type="email"
                      label={`${t('emailContact')} *`}
                      placeholder={t('emailContact')}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.email}
                      errors={formik.errors.email}
                      value={formik.values.email}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      label={`${t('phone')} *`}
                      placeholder={t('phoneContactplaceholder')}
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.phone}
                      errors={formik.errors.phone}
                      value={formik.values.phone}
                    />
                  </Col>
                </>
              )}
              <Col md="12">
                <Textarea
                  type="text"
                  label={`${t('contact.message')} *`}
                  placeholder={t('contact.messagePlaceholder')}
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.message}
                  errors={formik.errors.message}
                  value={formik.values.message}
                />
              </Col>
              {/*<Col md="6">
                <div className="form-group">
                  <p className="small-text">{t('contact.note')}</p>
                  <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={onChange}
                    hl="de"
                    className="recaptcha"
                    ref={captchaRef}
                  />
                  {formik.touched.captcha && formik.errors.captcha && (
                    <div className="form-errors">{formik.errors.captcha}</div>
                  )}
                </div>
              </Col>*/}
              {!loggedIn && (
                <Col md="6">
                  <div className="form-group">
                    <LoadCanvasTemplate />
                    <Input
                      type="text"
                      placeholder="Enter captcha"
                      name="captcha"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.captcha}
                      errors={formik.errors.captcha}
                      value={formik.values.captcha}
                    />

                    {formik.values.captcha === '' ? (
                      ''
                    ) : (
                      <div
                        id="wrongCaptcha"
                        className="form-errors"
                        style={{ marginTop: '-20px' }}></div>
                    )}
                  </div>
                </Col>
              )}
              <Col md="6">
                <Checkbox
                  checked={formik.values.acceptTerms}
                  // value={formik.values.acceptTerms}
                  onChange={() => {
                    formik.setFieldValue(
                      'acceptTerms',
                      !formik.values.acceptTerms,
                    );
                  }}
                  touched={formik.touched.acceptTerms}
                  errors={formik.errors.acceptTerms}
                  label={t('contact.captcha')}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Button
                  type="submit"
                  label={t('contact.btnsend')}
                  className="btn primary-btn"
                  disabled={formSubmitting}
                />
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default ContactPage;
