import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Button from '../Core/Button';

const ImageText = (props) => {
  const { src, title, text, buttonText, buttonLink, isPopup } = props;
  const { setShowEmployerRegisterModal } = useContext(GlobalContext);
  return (
    <div className="image-text-teaser">
      <div className="image-text-teaser__image">
        <img src={src} alt="dummy" loading="lazy" height="auto" width="100" />
      </div>
      <div className="image-text-teaser__content">
        <p className="image-text-teaser__title">{title}</p>
        <ReactMarkdown className="image-text-teaser__text">
          {text}
        </ReactMarkdown>

        {isPopup ? (
          <Button
            className="btn"
            variant="primary"
            label={buttonText}
            type="button"
            onClick={() => setShowEmployerRegisterModal(true)}
          />
        ) : (
          <Link to={buttonLink} className="btn">
            {buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ImageText;
