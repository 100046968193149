import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { handleLogoClick } from '../Header';
import { roles, labelRoles } from '../../utils/constants';
import logo from '../../assets/images/logo.svg';
// import { getSettings } from '../../actions/settingAction';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import { languages } from '../../utils/languages';
import Form from 'react-bootstrap/Form';
import api from '../../utils/api';

const Footer = () => {
  // const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const settingsData = useSelector((state) => state.settings);
  const { settingData } = settingsData;
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState();
  const [footerLogo, setFooterLogo] = useState();

  // const [languagesOptions] = useState(
  //   languages.map((lang) => ({
  //     label: lang.name,
  //     value: lang.code,
  //   })),
  // );

  const languagesOptions = [
    {
      label: 'DE',
      value: 'de',
    },
    {
      label: 'EN',
      value: 'en',
    },
  ];
  const handleChange = (event) => {
    setSelected(event.target.value);
    changeLanguage(event.target.value);
    localStorage.setItem('lang', event.target.value);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setSelected(localStorage.getItem('lang'));
    if (settingsData && settingsData.length) {
      return;
    }
  }, []);

  useEffect(() => {
    async function getFooterLogo() {
      if (
        settingData?.footerLogo &&
        settingData.footerLogo !== 'null' &&
        settingData?.footerLogo?.startsWith('public/upload/settings/')
      ) {
        let image = settingData.footerLogo;
        const i = await api.post('global-settings/getLogoImage', {
          image,
        });
        setFooterLogo(i.data.image);
      }
    }
    if (
      settingData?.footerLogo &&
      settingData.footerLogo !== 'null' &&
      settingData?.footerLogo?.startsWith('public/upload/settings/')
    ) {
      getFooterLogo();
    }
  }, [settingData?.footerLogo]);

  return (
    <footer className="page-footer">
      <Container>
        <Row>
          <Col lg="8">
            <div className="footer-block">
              <div className="footer-logo-teaser">
                <div className="footer-logo-teaser__logo">
                  <Link
                    to={handleLogoClick(userData)}
                    className="brand-logo"
                    style={
                      userData.data.role === roles.employee
                        ? { pointerEvents: 'none' }
                        : {}
                    }>
                    <img
                      src={
                        settingData?.footerLogo &&
                        settingData.footerLogo !== 'null' &&
                        settingData?.footerLogo?.startsWith(
                          'public/upload/settings/',
                        ) &&
                        footerLogo &&
                        footerLogo.length
                          ? footerLogo
                          : logo
                      }
                      alt="logo"
                      height="auto"
                      width="100"
                    />
                  </Link>
                </div>
                <div className="footer-logo-teaser__content">
                  <h4 className="footer-title">{t('home.footerTitle')}</h4>
                  <p>{settingData?.address}</p>
                </div>
              </div>
              <div className="footer-contact-teaser">
                <h4 className="footer-title">{t('home.footerContactTitle')}</h4>
                <div className="footer-contact__links">
                  <a
                    href={`tel:${settingData?.phone}`}
                    className="footer-contact__link">
                    <span className="footer-contact__icon">
                      <svg viewBox="0 0 35 35">
                        <g fill="none" fillRule="evenodd">
                          <circle cx="17.5" cy="17.5" r="17.5" fill="#41A9C7" />
                          <path
                            fill="#FFF"
                            d="M9.308 11.682c-.017.235.006.96.616 2.548l.02.056c.814 2.926 6.713 9.378 10.018 10.961 2.472 1.183 3.908 1.409 4.187 1.335.318-.126 1.246-1.24 1.642-1.713.192-.23.365-.44.502-.592.116-.13.111-.204.11-.23-.007-.126-.125-.278-.197-.348l-3.322-2.252a1.493 1.493 0 00-.883-.004l-2.365 1.287-.33-.235c-3.618-2.552-5.849-6.035-5.942-6.183l-.195-.308.16-.33c.034-.066.831-1.688 1.218-2.344.1-.17-.057-.687-.258-1.018-.612-.948-1.745-2.595-2.138-2.93-.177-.152-.4-.035-.486.022-.986.852-2.123 1.934-2.357 2.278zM24.03 27.89c-1.139 0-3.059-.718-4.63-1.47-3.593-1.717-9.772-8.509-10.703-11.752-.552-1.44-.783-2.57-.667-3.27.037-.217.112-.67 2.809-3.008l.035-.027c.387-.29 1.329-.66 2.127.027.65.556 2.109 2.786 2.394 3.226.096.156.838 1.426.276 2.378-.26.439-.747 1.404-1.002 1.917.588.848 2.43 3.33 5.063 5.279l1.719-.93c.09-.04.911-.392 1.945-.018l.147.074 3.49 2.37c.064.051.62.543.672 1.264.02.3-.038.748-.438 1.196-.13.144-.293.34-.473.557-.939 1.121-1.65 1.934-2.26 2.121a1.742 1.742 0 01-.504.066z"
                          />
                        </g>
                      </svg>
                    </span>

                    <span className="footer-contact__text">
                      {settingData?.phone}
                    </span>
                  </a>
                  <a
                    href={`mailto:${settingData?.email}`}
                    className="footer-contact__link">
                    <span className="footer-contact__icon">
                      <svg viewBox="0 0 35 35">
                        <g fill="none" fillRule="evenodd">
                          <circle cx="17.5" cy="17.5" r="17.5" fill="#41A9C7" />
                          <path
                            fill="#FFF"
                            d="M26.008 23.227H9.59a.233.233 0 01-.227-.234v-9.948l8.062 5.417a.67.67 0 00.752-.002l8.057-5.52v10.053a.233.233 0 01-.227.234zM24.565 12.4l-6.77 4.638-6.9-4.638h13.67zm2.533-.95a.678.678 0 00-.313-.233 1.541 1.541 0 00-.777-.217H9.59C8.714 11 8 11.733 8 12.633v10.36c0 .901.714 1.634 1.59 1.634h16.418c.877 0 1.59-.733 1.59-1.634v-10.36c0-.466-.193-.884-.5-1.183z"
                          />
                        </g>
                      </svg>
                    </span>

                    <span className="footer-contact__text">
                      {settingData?.email}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="3" md="9" sm="9" xs="12">
            <div className="footer-block-right pb-sm-0 pb-5">
              <Link to="/imprint" className="footer-block-right__link">
                {t('imprint')}
              </Link>
              <Link
                to="/terms-and-conditions"
                className="footer-block-right__link">
                {t('terms')}
              </Link>
              <Link to="/privacy" className="footer-block-right__link">
                {t('privacy1')}&shy;{t('privacy2')}
              </Link>
            </div>
          </Col>
          <Col lg="1" md="3" sm="3" xs="12">
            <div className="footer-block-right pb-sm-0 pb-5">
              <Form.Select
                size="sm"
                className="formsize"
                value={selected ?? ''}
                onChange={handleChange}>
                {languagesOptions.map((result) => (
                  <option
                    key={result.value}
                    title={result.value}
                    value={result.value}>
                    {result.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
