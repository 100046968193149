import { toast } from 'react-toastify';

export const handleError = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    toast.error(`${error.response.data.message}`);
  } else {
    toast.error('Something went wrong');
  }
};
