import {ErrorMessage, Field, Form, Formik} from 'formik';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Core/Checkbox';
import * as Yup from 'yup';
import {toast} from "react-toastify";
import i18n from "../../i18n";
import {createSubscription} from "../../actions/newsletterAction"
import {useDispatch} from "react-redux";
import {ALPHABETS_ONLY_WITH_SPACE} from "../../utils/constants";

const Newsletter = ({ onSubscribeSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialValues = {
    email: '',
    name: '',
    privacyPolicyAccepted: false,
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Email.type'))
      .required(t('Email.required')),
    name: Yup.string().required(t('nameLabel'))
      .matches(/^[A-Za-z\u00C0-\u017F\s'-]+$/, t('home.newsNameRestriction'))
      .max(70, t('home.newsNameLength')),
    privacyPolicyAccepted: Yup.boolean()
      .oneOf([true], t('home.newsPrivacyPolicyRequired')),
  });

  return(
    <div className="container small">
      <div className="box-wrapper no-shadow small-padding">
        <div className='main-container'>
          <div className="header-container">
            <h2 className="news-header">{t('home.newsHeader')}</h2>
            <p className="medium">{t('home.newsSubText')}</p>
          </div>
          <div className='form-container'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(false);
                localStorage.setItem('hasSubscribed', 'true');
                toast.success(i18n.t('home.newsSuccess'));
                const newSubscription = {
                  firstname: values.name.split(' ')[0],
                  lastname: values.name.split(' ')[1],
                  email: values.email,
                };
                dispatch(
                  createSubscription({
                    payload: newSubscription,
                  }),
                );
                actions.resetForm();
              }}>
              {({ values, setFieldValue, handleChange }) => (
                <Form className="subscription-form">
                  <div className="form-content">
                    <div className="row first">
                      <div className="col">
                        <label className='form-label'>Name:</label>
                        <Field
                          type="text"
                          name='name'
                          className="form-control"
                          onChange={(e) => {
                            if (
                              e.target.value === '' ||
                              (ALPHABETS_ONLY_WITH_SPACE.test(e.target.value) &&
                                e.target.value?.length <= 70)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder={t('home.newsNameLabel')}
                        />
                        <ErrorMessage name="name" component="div" className="form-errors" />
                      </div>
                      <div className="col">
                        <label className='form-label'>{t('emailStaff')}</label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder={t('home.newsEmailLabel')}
                        />
                        <ErrorMessage name="email" component="div" className="form-errors" />
                      </div>
                    </div>
                  </div>
                  <div className="second">
                    <div >
                      <div className="radio-wrapper right">
                        <div className="checkbox-container">
                          <div className="checkbox-content">
                          <Checkbox
                            name="privacyPolicyAccepted"
                            id="privacyPolicyAccepted"
                            checked={values.privacyPolicyAccepted}
                            onChange={() => setFieldValue("privacyPolicyAccepted", !values.privacyPolicyAccepted)}
                          />
                              <div className="checkbox-label">
                                {t('home.newsAgree')}
                                <br />
                                <a href="/privacy">{t('home.newsPrivacyPolicy')}</a>
                              </div>
                          </div>
                        </div>
                        <div className="error-container">
                          <ErrorMessage name="privacyPolicyAccepted" component="div" className="form-errors checkbox-error" />
                        </div>

                      </div>
                    </div>
                    <div>
                      <div className="form-button">
                        <button
                          type="submit"
                          onClick={()=> {
                            validationSchema.validate(values, {abortEarly: false})
                              .then(result => {
                                document.getElementsByClassName('exit-popup')[0].classList.remove('show');
                              })
                            }
                          }
                          className='btn primary-btn'
                        >{t('home.newsSubscribe')}</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
