import Select, { components, createFilter } from 'react-select';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import i18n from '../../i18n';

const Input = ({ autoComplete, ...props }) => (
  <components.Input
    {...props}
    autoComplete="nope"
    onFocus={(e) => e.target.removeAttribute('readonly')}
    readOnly
  />
);

const CustomSelect = (props) => {
  const {
    label,
    name,
    options,
    handleChange,
    handleBlur,
    touched,
    errors,
    value,
    tooltipTitle,
    tooltipText,
    customClassName,
    styles,
  } = props;
  const tooltiptext = tooltipText;
  const filterConfig = {
    ignoreCase: true,
    matchFrom: 'start',
  };

  if (
    label === 'nationality *' ||
    label === 'Nationalität *' ||
    label === 'Muttersprache *' ||
    label === 'mother tongue *'
  ) {
    options.sort((a, b) => {
      const nameA = i18n.t(a.label); // ignore upper and lowercase
      const nameB = i18n.t(b.label); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  const popover = (
    <Popover>
      <Popover.Body>
        <h5>{tooltipTitle}</h5>
        <p>{tooltiptext}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      className={`form-group custom-select-outer ${
        customClassName ? customClassName : ''
      } ${touched && errors ? 'form-group-errors' : ''}`}
      // style={{ marginBottom: `${styles ? styles.marginBottom : 'auto'}` }}
    >
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
          {tooltiptext && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={popover}>
              <svg
                width="22"
                height="23"
                xmlns="http://www.w3.org/2000/svg"
                className="popover-icon">
                <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
                  <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                  <path
                    d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </OverlayTrigger>
          )}
        </label>
      )}
      <Select
        id={name}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        className="custom-select"
        classNamePrefix="c-select"
        value={value}
        placeholder={i18n.t('select')}
        filterOption={createFilter(filterConfig)}
        getOptionLabel={(option) => i18n.t(option.label)}
        hideSelectedOptions={false}
        components={{
          Input,
        }}
        {...props}
      />
      {touched && errors && <div className="form-errors">{errors}</div>}
    </div>
  );
};

export default CustomSelect;
