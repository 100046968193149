import React from 'react';

const Radio = ({ label, value, checked, name, onChange }) => {
  return (
    <div className="form-group">
      <label className="custom-radio">
        {label}
        <input
          type="radio"
          checked={checked}
          value={value}
          name={name}
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default Radio;
