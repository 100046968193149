import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import { labelRoles } from '../../utils/constants';

const Table = ({ columns: userColumns, data, isCurrent }) => {
  const { t, i18n } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // eslint-disable-next-line no-unused-vars
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
      disableSortRemove: true,
    },
    useSortBy, // We can useExpanded to track the expanded state
    // for sub components too!
  );

  return (
    <>
      <table className="table remove-space event-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    column.id === 'timeReportConfirmation'
                      ? 'time-report-status-head'
                      : ''
                  } ${column.id === 'eventName' ? 'event-name-head' : ''}`}>
                  {column.render('Header')}
                  {column.render('Header') && (
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'sort-arrow sort-arrow-descending'
                            : 'sort-arrow sort-arrow-accending'
                          : 'sort-arrow'
                      }`}></span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cid) => {
                  if (cell.column.id === 'eventName') {
                    return (
                      <td {...cell.getCellProps()} className="events-name">
                        {isCurrent ? (
                          <Link
                            to={{
                              pathname: `/${labelRoles.employer}/my-events/${data[i]._id}`,
                              state: { event: data[i] },
                            }}
                            className="link">
                            {cell.render('Cell')}
                          </Link>
                        ) : (
                          <span style={{ cursor: 'default' }} className="link">
                            {cell.render('Cell')}
                          </span>
                        )}
                      </td>
                    );
                  } else if (cell.column.id === 'date') {
                    return (
                      <td {...cell.getCellProps()} className="events-date">
                        {cell.render('Cell')}
                      </td>
                    );
                  } else if (cell.column.id === 'timeReportConfirmation') {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="time-report-status">
                        {cell.value && (
                          <span
                            className={`${
                              cell.value === `${t('event.confirmed')}`
                                ? 'confirmed'
                                : cell.value === `${t('event.confirmationNeeded')}`
                                ? 'confirm-need'
                                : cell.value === `${t('event.eventReady')}`
                                ? 'event-ready'
                                : cell.value === `${t('event.OutstandingBalance')}`
                                ? 'open-amount'
                                : 'in-prepare'
                            }`}>
                            {cell.value}
                          </span>
                        )}
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
