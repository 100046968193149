import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PdfUpload from '../Core/PdfUpload';
import Button from '../Core/Button';
import Loader from '../Core/Loader';
import { updateJobPosition } from '../../actions/jobAction';
import { useTranslation } from 'react-i18next';

const isFileTypeValid = (name) => {
  const type = name.split('.').pop();
  if (
    type.includes('txt') ||
    type.includes('doc') ||
    type.includes('docx') ||
    type.includes('ppt') ||
    type.includes('pdf')
  ) {
    return true;
  } else {
    return false;
  }
};

function Brief({ showBrief, setShowBrief }) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [briefingOne, setBriefingOne] = useState(null);
  const [briefingTwo, setBriefingTwo] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const { jobPosition } = showBrief;
    for (let i = 0; i < 2; i++) {
      if (jobPosition && jobPosition.briefings && jobPosition.briefings[i]) {
        const str1 = jobPosition.briefings[i].split('?')[0];
        const str = str1.split('/');
        const strLength = str.length;
        if (i === 0) {
          setBriefingOne(str[strLength - 1]);
        } else {
          setBriefingTwo(str[strLength - 1]);
        }
      }
    }

    return () => {
      setBriefingOne(null);
      setBriefingTwo(null);
    };
  }, [showBrief]);

  const handleFileChange = (e, func) => {
    if (e.target && e.target.files) {
      if (isFileTypeValid(e.target.files[0].name)) {
        func(e.target.files[0]);
      } else {
        toast.error(t('invalidfile'));
      }
    }
  };

  return (
    <Modal
      show={showBrief.visible}
      size="xxl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="add-briefing-modal"
      onHide={() =>
        setShowBrief({
          visible: false,
        })
      }>
      {uploading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('requestOverview.modal.addBriefing')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form">
          <div className="add-briefing-wrapper">
            <PdfUpload
              type="file"
              file={briefingOne}
              label={t('requestOverview.modal.Briefing1')}
              placeholder={t('requestOverview.modal.browse')}
              className="pdf-uploader"
              removeFile={() => setBriefingOne(null)}
              onChange={(e) => {
                handleFileChange(e, setBriefingOne);
              }}
            />
            <PdfUpload
              type="file"
              file={briefingTwo}
              label={t('requestOverview.modal.Briefing2')}
              placeholder={t('requestOverview.modal.browse')}
              className="pdf-uploader"
              removeFile={() => setBriefingTwo(null)}
              onChange={(e) => {
                handleFileChange(e, setBriefingTwo);
              }}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="d-block">
        <p className="small-text">{t('requestOverview.modal.briefingNone')}</p>
        <div className="mt-4 d-flex justify-content-end">
          <Button
            type="button"
            className="btn"
            label={t('requestOverview.modal.upload')}
            disabled={
              typeof briefingOne === 'string' && typeof briefingTwo === 'string'
            }
            onClick={() => {
              // if (!briefingOne && !briefingTwo) {
              //   toast.error('Please atleast add one briefing!');
              // } else {
              setUploading(true);
              const { jobPosition } = showBrief;
              const newJobPosition = {
                ...jobPosition,
              };
              const currentbrief = newJobPosition.briefings;
              delete newJobPosition.applications;
              delete newJobPosition.booked;
              delete newJobPosition.briefings;
              let formData = new FormData();

              Object.entries(newJobPosition).map((val) => {
                if (
                  val[0] === 'location' ||
                  val[0] === 'staffToBook' ||
                  val[0] === 'bookedStaffDetails' ||
                  val[0] === 'readyToBook' ||
                  val[0] === 'rejectedApplicants' ||
                  val[0] === 'contractId' ||
                  val[0] === 'jobPlaceAddress' ||
                  val[0] === 'employerData'
                ) {
                  formData.append(val[0], JSON.stringify(val[1]));
                } else {
                  formData.append(val[0], val[1]);
                }
              });

              if (briefingOne) {
                if (
                  currentbrief &&
                  currentbrief.length &&
                  currentbrief[0] &&
                  currentbrief[0].includes(briefingOne)
                ) {
                  formData.append('briefings', currentbrief[0]);
                  if (typeof briefingOne === 'string') {
                    formData.append('briefingOne', false);
                  }
                } else {
                  formData.append('briefings', briefingOne);
                  if (typeof briefingOne === 'string') {
                    formData.append('briefingOne', false);
                  }
                }
              }

              if (briefingTwo) {
                if (
                  currentbrief &&
                  currentbrief.length &&
                  currentbrief[1] &&
                  currentbrief[1].includes(briefingTwo)
                ) {
                  formData.append('briefings', currentbrief[1]);
                  if (typeof briefingTwo === 'string') {
                    formData.append('briefingTwo', false);
                  }
                } else {
                  formData.append('briefings', briefingTwo);
                  if (typeof briefingTwo === 'string') {
                    formData.append('briefingTwo', false);
                  }
                }
              }

              dispatch(
                updateJobPosition({
                  id: newJobPosition._id,
                  payload: formData,
                  callback: () => {
                    setUploading(false);
                    toast.success(t('brief.briefingsUploadedSuccessfully'));
                    setShowBrief({
                      visible: false,
                    });
                  },
                  failCallback: () => {
                    setUploading(false);
                    setShowBrief({
                      visible: false,
                    });
                  },
                }),
              );
              // }
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Brief;
