import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import defaultAvatar from '../../assets/images/default-avatar.png';

const BookedStaffImage = ({
  position,
  job,
  showStaffModal,
  setShowStaffModal,
  setShowBookingList,
  constructGCSImageUrl,
}) => {
  const { t } = useTranslation();
  const { applications, booked, staffToBook } = position;
  const [bookedProfileImage, setBookedProfileImage] = useState(defaultAvatar);
  const [staffToBookProfileImage, setStaffToBookProfileImage] =
    useState(defaultAvatar);

  useEffect(() => {
    if (booked) {
      const bookedEmployeeImage =
        position.booked.employee.profileImage ||
        position?.booked?.employee?.images[0];
      const imageResult =
        constructGCSImageUrl(bookedEmployeeImage) || defaultAvatar;
      setBookedProfileImage(imageResult);
    }

    if (staffToBook && staffToBook.applicant) {
      const staffToBookImage = position?.staffToBook?.applicant?.images[0];
      const imageResult =
        constructGCSImageUrl(staffToBookImage) || defaultAvatar;
      setStaffToBookProfileImage(imageResult);
    }
  }, [position, booked, staffToBook]);

  if (booked) {
    return (
      <div className="profile-book">
        <Link
          to={`/staff-details/${booked?.employee?._id}`}
          target="_blank"
          className="profile-wrap">
          <span className="profile-image">
            <img
              loading="lazy"
              src={bookedProfileImage}
              alt={t('requestOverview.staff')}></img>
          </span>
          <span
            className={`profile-gender ${
              position?.booked?.employee?.gender === 'male'
                ? 'male'
                : position?.booked?.employee?.gender === 'female'
                ? 'female'
                : 'other'
            }`}></span>
        </Link>
        <span className="profile-text">{t('requestOverview.booked')}</span>
      </div>
    );
  } else if (staffToBook && staffToBook.applicant) {
    return (
      <div className="profile-book">
        <Link
          className="profile-wrap"
          to={`/staff-details/${staffToBook.applicant._id}`}
          target="_blank">
          <span className="profile-image">
            <img
              src={staffToBookProfileImage}
              alt={t('requestOverview.staff')}
            />
          </span>
          <span
            className={`profile-gender ${
              staffToBook.applicant.gender === 'male'
                ? 'male'
                : staffToBook.applicant.gender === 'female'
                ? 'female'
                : 'other'
            }`}></span>
        </Link>
        <span
          className="detail-link profile-text"
          onClick={() => setShowBookingList(true)}>
          {t('requestOverview.addedToList')}
        </span>
      </div>
    );
  } else if (!applications || !applications.length) {
    return (
      <div className="profile-book">
        <span className="profile-text">
          {t('requestOverview.noApplications')}
        </span>
      </div>
    );
  } else if (applications.length) {
    return (
      <div className="aplpication-wrap">
        <span className="info-icon">!</span>
        <span
          onClick={() =>
            setShowStaffModal({
              ...showStaffModal,
              visible: true,
              data: {
                position,
                job,
              },
            })
          }
          className="detail-link text-medium">
          <strong>
            {applications.length} {t('requestOverview.applications')}
          </strong>
        </span>
      </div>
    );
  } else {
    return <></>;
  }
};

export default BookedStaffImage;
