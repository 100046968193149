import React, { useContext } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import Button from '../Core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../../actions/authAction';
// import { roles, labelRoles } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showResetPassword,
    setShowResetPassword,
    showLoginModal,
    setShowLoginModal,
  } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t('field'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        ),
      passwordConfirm: Yup.string()
        .required(t('field'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        )
        .when('password', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            t('confirmpassword.match'),
          ),
        }),
    }),
    onSubmit: (values) => {
      formik.setSubmitting(true);
      dispatch(
        resetPassword({
          ...values,
          setSubmitting: formik.setSubmitting,
          resetToken: showResetPassword.resetToken,
          callback: (role) => {
            setShowResetPassword({ ...showResetPassword, visible: false });
            setShowLoginModal({
              ...showLoginModal,
              visible: true,
            });
            formik.resetForm();
          },
        }),
      );
    },
  });

  return (
    <Modal
      show={showResetPassword}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShowResetPassword(false);
        history.push('/');
      }}
      className="employee-login-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('resetPasswordLogin')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form form-login">
          <Row>
            <Col md="6">
              <Input
                type="password"
                label={`${t('newPasswordLogin')} *`}
                placeholder={t('newPasswordLogin')}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.password}
                errors={formik.errors.password}
                value={formik.values.password}
              />
            </Col>
            <Col md="6">
              <Input
                type="password"
                label={`${t('confirmPasswordLogin')} *`}
                placeholder={t('confirmPasswordLogin')}
                name="passwordConfirm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.passwordConfirm}
                errors={formik.errors.passwordConfirm}
                value={formik.values.passwordConfirm}
              />
            </Col>
          </Row>
          <div className="form-bottom">
            <Button
              type="submit"
              label={t('contact.btnReset')}
              className="btn"
              disabled={formik.isSubmitting}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPassword;
