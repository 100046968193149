import React from 'react';
// import i18n from '../i18n';
import { Trans } from 'react-i18next';
// import { t } from 'i18next';

export const roles = {
  employer: 'employer',
  employee: 'employee',
  superuser: 'superuser',
  admin: 'admin',
};

export const labelRoles = {
  employer: 'organization',
  employee: 'staff',
  superuser: 'superuser',
};

export const fiveMinutes = 300000;

export const creditCardOptions = [
  { label: 'Visa Card', value: 'visa' },
  { label: 'Master Card', value: 'masterCard' },
];

export const genderOptions = [
  { value: 'male', label: 'male' },
  { value: 'female', label: 'female' },
  { value: 'other', label: 'Other' },
];

export const FILTER_SIZE_OPTIONS = [
  { label: 'XS', value: 'x-small' },
  { label: 'S', value: 'small' },
  { label: 'M', value: 'medium' },
  { label: 'L', value: 'large' },
  { label: 'XL', value: 'x-large' },
  { label: 'XXL', value: 'xx-large' },
];
export const sizeOptions = [
  {
    label: <Trans i18nKey="female" />,
    options: [
      { value: 'x-small', label: 'XS | 35 - 36' },
      { value: 'small', label: 'S | 36 - 38' },
      { value: 'medium', label: 'M | 38 - 40' },
      { value: 'large', label: 'L | 40 - 42' },
      { value: 'x-large', label: 'XL | 42 - 44' },
      { value: 'xx-large', label: 'XXL | 44 - 46' },
    ],
  },
  {
    label: <Trans i18nKey="male" />,
    options: [
      { value: 'x-small1', label: 'XS | 44 - 46', category: 'M' },
      { value: 'small1', label: 'S | 46 - 48', category: 'M' },
      { value: 'medium1', label: 'M | 48 - 50', category: 'M' },
      { value: 'large1', label: 'L | 50 - 52', category: 'M' },
      { value: 'x-large1', label: 'XL | 52 - 54', category: 'M' },
      { value: 'xx-large1', label: 'XXL | 54 - 56', category: 'M' },
    ],
  },
];

// export const shoeSizeOptions = [
//   { value: '5', label: '5' },
//   { value: '6', label: '6' },
//   { value: '7', label: '7' },
//   { value: '8', label: '8' },
//   { value: '9', label: '9' },
//   { value: '10', label: '10' },
//   { value: '11', label: '11' },
// ];

export const shoeSizeOptions = [
  { value: '35', label: '35' },
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '47', label: '47' },
  { value: '48', label: '48' },
];

export const shoeSizeAUWomensOptions = [
  { value: '35', label: '35' },
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
];

export const shoeSizeAUMensOptions = [
  { value: '35.5', label: '35.5' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40.5', label: '40.5' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '42.5', label: '42.5' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '44.5', label: '44.5' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '47', label: '47' },
  { value: '48', label: '48' },
  { value: '49.5', label: '49.5' },
];

export const hairColorOptions = [
  { value: 'none', label: 'None' },
  { value: 'red', label: 'Red' },
  { value: 'black', label: 'Black' },
  { value: 'brown', label: 'Brown' },
  { value: 'blond', label: 'Blond' },
  { value: 'white', label: 'White' },
  { value: 'gray', label: 'Gray' },
  { value: 'other', label: 'Other_color' },
];

export const jobRegions = [
  // { value: 'Vienna, Austria', label: 'Vienna (Wien)' },
  // { value: 'Netherlands', label: 'Netherlands' },
  // { value: 'Rajkot, Gujarat, India', label: 'Rajkot, Gujarat, India' },
  // { value: 'Bhavnagar, Gujarat, India', label: 'Bhavnagar, Gujarat, India' },
  // { value: 'Oberosterreich, Austria', label: 'Oberösterreich (Upper Austria)' },
  // { value: 'Carinthia, Austria', label: 'Kärnten (Carinthia)' },
  // { value: 'Tirol, Austria', label: 'Tirol (Tirol)' },
  // { value: 'Vorarlberg, Austria', label: 'Vorarlberg (Vorarlberg)' },
  // { value: 'Salzburg, Austria', label: 'Salzburg (Salzburg)' },

  { value: 'Vienna (Wien)', zipStartWith: [1], label: 'Wien (Vienna)' },
  {
    value: 'Lower Austria (Niederösterreich)',
    zipStartWith: [2, 3],
    label: 'Niederösterreich (Lower Austria)',
  },
  {
    value: 'Upper Austria (Oberösterreich)',
    zipStartWith: [4],
    label: 'Oberösterreich (Upper Austria)',
  },
  {
    value: 'Salzburg (Salzburg)',
    zipStartWith: [5],
    label: 'Salzburg (Salzburg)',
  },
  { value: 'Tyrol (Tirol)', zipStartWith: [6], label: 'Tirol (Tyrol)' },
  {
    value: 'Vorarlberg (Vorarlberg)',
    zipStartWith: [6],
    label: 'Vorarlberg (Vorarlberg)',
  },
  {
    value: 'Burgenland (Burgenland)',
    zipStartWith: [7],
    label: 'Burgenland (Burgenland)',
  },
  {
    value: 'Steiermark (Styria)',
    zipStartWith: [8],
    label: 'Steiermark (Styria)',
  },
  {
    value: 'Kärnten (Carinthia)',
    zipStartWith: [9],
    label: 'Kärnten (Carinthia)',
  },
];
export const IMAGETYPE = ['image/jpeg', 'image/png', 'image/jpg'];

export const NUMBER_ONLY = /^[0-9]+$/;
export const ALPHABETS_ONLY = /^[A-Za-zöÖäÄüÜß-]+$/;
export const ALPHABETS_ONLY_WITH_SPACE = /^[A-Za-z öÖäÄüÜß-]+$/;
export const ALPHABETS_ONLY1 = /^[A-Za-z öÖäÄüÜß-]+$/;
export const ALPHABETS_AND_NUMBERS = /^[a-zA-ZäöüÄÖÜ0-9\/\-.]+$/;
export const EMAIL_VALIDATION =
  /([\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)/g;
