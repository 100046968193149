import { settingConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';
import i18n from '../i18n';

export const fetchJobs = () => {
  const success = ({ jobs }) => {
    return { type: settingConstants.GET_JOB_NAMES_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: settingConstants.GET_JOB_NAMES_FAILURE, error };
  };

  return async (dispatch) => {
    try {
      let response = await api.get('/jobs/job-positions');
      let resJobs = response.data.data.filteredJobs;
      const filteredJobs = resJobs.map((job) => ({
        label: job,
        value: job,
      }));
      dispatch(
        success({
          jobs: filteredJobs,
        }),
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const getSettings = () => {
  const request = () => {
    return { type: settingConstants.GET_SETTINGS_REQUEST };
  };
  const success = (settings) => {
    return { type: settingConstants.GET_SETTINGS_SUCCESS, settings };
  };
  const failure = (error) => {
    return { type: settingConstants.GET_SETTINGS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const settings = await api.get('/global-settings');
      dispatch(success(settings.data.settings[0]));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};
