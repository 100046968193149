import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { Col, Modal, OverlayTrigger, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { submitWorkHours } from '../../actions/jobAction';
import { fetchCurrentEvents } from '../../actions/eventAction';
import {
  calculateOutstandingAmount,
  getTimeDifferent,
} from '../../utils/helper';
import { manageStartEndTime, convertMsToHM } from '../../utils/time';
import Button from '../Core/Button';
import { EndInfo } from '../CreateJob/CreateJobPosition';
import SignaturePad from 'react-signature-canvas';
import defaultAvatar from '../../assets/images/default-avatar.png';
import api from '../../utils/api';
registerLocale('de', de);
registerLocale('en', enUS);

const ConfirmHoursModal = ({
  showConfirmHoursModal,
  setShowConfirmHoursModal,
  setEvent,
  event,
  jobs,
  forEmployerSign,
}) => {
  const dispatch = useDispatch();
  const { position, job } = showConfirmHoursModal.data;
  const sigPad = React.useRef({});
  const [employerSign, setEmployerSign] = React.useState(false);
  const [hoursValidation, setHoursValidation] = React.useState(false);
  const [image, setImage] = React.useState();
  // const [startTime, setStartTime] = useState(position.jobStart);
  // const [endTime, setEndTime] = useState(position.jobEnd);
  const { t } = useTranslation();
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';
  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );
  const formik = useFormik({
    initialValues: {
      start: new Date(position.jobStart),
      end:
        position.jobEnd < position.jobStart
          ? new Date(position.jobEnd + 86400000)
          : new Date(position.jobEnd),
    },
    validationSchema: Yup.object({
      start: Yup.date().required(t('startTime.required')),
      end: Yup.date().required(t('endTime.required')),
      // .test('end', t('endTime.duration'), (value, fields) => {
      //   // let startDate = parseInt(moment(fields.parent.start).format('x'));
      //   // let endDate = parseInt(moment(fields.parent.end).format('x'));
      //   // if (startDate > endDate) {
      //   //   endDate = endDate + 86400000;
      //   // }
      //   // if (endDate - startDate > 36000000) {
      //   //   return false;
      //   // } else {
      //   //   return true;
      //   // }

      //   /** */
      //   // let duration = moment.duration(
      //   //   moment(fields.parent.end).diff(moment(fields.parent.start)),
      //   // );
      //   // let hours = duration._milliseconds;
      //   // let diffMillis = moment.duration(hours);
      //   // let diffMins = Number(
      //   //   diffMillis.asMinutes().toString().split('.')[0],
      //   // );
      //   // // console.log("=>", a.asHours(), ":", Number(a.asMinutes().toString().split('.')[0]));
      //   // if (diffMins > 600 || diffMins < 1) {
      //   //   return false;
      //   // } else {
      //   //   return true;
      //   // }
      // }),
      // .test('end', t('endTime.length'), (value, fields) => {
      //   let startDate = parseInt(moment(fields.parent.start).format('x'));
      //   let endDate = parseInt(moment(fields.parent.end).format('x'));

      //   if (startDate > endDate) {
      //     return false;
      //     // endDate = endDate;
      //   } else {
      //     return true;
      //   }
      // })
    }),
    onSubmit: (values) => {
      let updateJobPosition = {};
      const newJobPositions = job.jobPositions.map((jobPosition) => {
        let returnJobPosition = jobPosition;
        if (jobPosition._id === position._id) {
          const currentStaffType = staffTypes?.find(
            (s) => s.name === job?.jobName,
          );
          const jobPositionCalcData = calculateOutstandingAmount(
            values,
            currentStaffType,
            jobPosition,
            job.jobBonus,
          );
          const endTime =
            parseInt(moment(values.end).format('x')) <
            parseInt(moment(values.start).format('x'))
              ? parseInt(moment(values.end).format('x')) + 86400000
              : parseInt(moment(values.end).format('x'));

          returnJobPosition = {
            ...jobPosition,
            ...jobPositionCalcData,
            confirmJobStart: manageStartEndTime(
              values.start,
              position?.jobDate,
            ),

            confirmJobEnd: manageStartEndTime(endTime, position?.jobDate),
            // employerSign: employerSign,
            rejectedApplicants: jobPosition?.rejectedApplicants?.filter(
              (p) => p !== '',
            ),
          };
          updateJobPosition = returnJobPosition;
        } else {
          returnJobPosition = {
            ...jobPosition,
          };
        }
        return returnJobPosition;
      });
      const getNewJobs = (newPositions) => {
        return event.jobs.map((j) => {
          if (j._id === job._id) {
            return {
              ...j,
              jobPositions: newPositions,
            };
          } else {
            return {
              ...j,
            };
          }
        });
      };
      dispatch(
        submitWorkHours({
          id: updateJobPosition._id,
          payload: { jobPosition: updateJobPosition, forEmployerSign: false },
          callback: () => {
            setEvent({ ...event, jobs: getNewJobs(newJobPositions) });
            formik.setSubmitting(false);
            setShowConfirmHoursModal({
              visible: false,
            });
            dispatch(fetchCurrentEvents({ currPage: 1 }));
          },
          failCallback: () => {
            formik.setSubmitting(false);
            setShowConfirmHoursModal({
              visible: false,
            });
          },
        }),
      );
    },
  });

  // const clear = () => {
  //   sigPad.current?.clear();
  //   setEmployerSign(sigPad?.current?.getTrimmedCanvas().toDataURL('image/jpg'));
  // };

  React.useEffect(() => {
    if (position.booked.employee.images.length) {
      if (
        typeof position.booked.employee.images[0] === 'string' &&
        !position.booked.employee.images[0].includes(
          'https://storage.googleapis.com',
        ) &&
        (position.booked.employee.images[0].startsWith('employee-') ||
          position.booked.employee.images[0].startsWith('user-'))
      ) {
        getImage(position.booked.employee.images[0]);
      }
    }
    async function getImage(image) {
      const response = await api.post('users/getImageUrl', { image });
      const newimage = response.data.image;
      setImage(newimage);
    }
  }, []);

  React.useEffect(() => {
    const newStartDate = new Date(position?.jobDate);
    const jobStartDate = position.confirmJobStart
      ? new Date(position.confirmJobStart)
      : new Date(position?.jobStart);
    const Hours = jobStartDate.getHours(),
      Minutes = jobStartDate.getMinutes();
    newStartDate.setHours(Hours);
    newStartDate.setMinutes(Minutes);
    newStartDate.setSeconds(0);
    formik.setFieldValue('start', newStartDate);

    const newEndDate = new Date(position?.jobDate);
    const jobEndDate = position.confirmJobEnd
      ? new Date(position.confirmJobEnd)
      : new Date(position?.jobEnd);
    const EndHours = jobEndDate.getHours(),
      EndMinutes = jobEndDate.getMinutes();
    newEndDate.setHours(EndHours);
    newEndDate.setMinutes(EndMinutes);
    newEndDate.setSeconds(0);
    if (newEndDate < newStartDate) {
      const d = newStartDate.getDate() + 1;
      newEndDate.setDate(d);
    }
    formik.setFieldValue('end', newEndDate);
  }, []);

  const onChangeTime = (e, name) => {
    // start Time
    let StartHours =
        name === 'start' ? e.getHours() : formik?.values?.start?.getHours(),
      StartMinutes =
        name === 'start' ? e.getMinutes() : formik?.values?.start?.getMinutes();
    const newStartDate = new Date(position?.jobDate);
    newStartDate.setHours(StartHours);
    newStartDate.setMinutes(StartMinutes);
    newStartDate.setSeconds(0);

    // End Time
    let EndHours =
        name === 'end' ? e.getHours() : formik?.values?.end?.getHours(),
      EndMinutes =
        name === 'end' ? e.getMinutes() : formik?.values?.end?.getMinutes();
    const newEndDate = new Date(position?.jobDate);
    newEndDate.setHours(EndHours);
    newEndDate.setMinutes(EndMinutes);
    newEndDate.setSeconds(0);

    if (new Date(newEndDate) < new Date(newStartDate)) {
      const d = new Date(newStartDate).getDate() + 1;
      newEndDate.setDate(d);
    }
    formik.setValues({
      ...formik.values,
      start: newStartDate,
      end: newEndDate,
    });
  };
  const userData = useSelector((state) => state.user);
  React.useEffect(() => {
    const diff = getTimeDifferent(formik.values.start, formik.values.end);
    if (diff > 12 || diff === 0) {
      setHoursValidation(t('endTime.duration'));
    } else {
      setHoursValidation('');
    }
  }, [formik.values.start, formik.values.end]);
  const allowInput =
    new Date() > new Date(position?.jobStart) &&
    new Date() <= new Date(position?.jobStart + 129600000) &&
    position?.employerData?.paidOutstandingAmountValue === 0;
  return (
    <>
      <Modal
        show={showConfirmHoursModal.visible}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() =>
          setShowConfirmHoursModal({ ...showConfirmHoursModal, visible: false })
        }>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{t('Confirm_hours_worked')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={formik.handleSubmit}
            className="form form-confirm-hours">
            <Row className="justify-content-between">
              <Col md="12">
                <div className="profile-book">
                  <div className="profile-wrap size-large me-3">
                    <span className="profile-image">
                      <img
                        src={`${
                          position.booked.employee.images.length &&
                          typeof position.booked.employee.images[0] ===
                            'string' &&
                          !position.booked.employee.images[0].includes(
                            'https://storage.googleapis.com',
                          ) &&
                          (position.booked.employee.images[0].startsWith(
                            'employee-',
                          ) ||
                            position.booked.employee.images[0].startsWith(
                              'user-',
                            ))
                            ? image
                            : String(defaultAvatar)
                        }`}
                        alt={t('profile')}
                      />
                    </span>
                    {position.booked.employee.gender === 'male' ? (
                      <span className="profile-gender male"></span>
                    ) : position.booked.employee.gender === 'female' ? (
                      <span className="profile-gender female"></span>
                    ) : (
                      <span className="profile-gender other"></span>
                    )}
                    {/* <span className="profile-gender"></span> */}
                  </div>
                  <span>{position.booked.employee.name}</span>
                </div>
              </Col>
              <Col md={4} lg={2}>
                <div className="form-group">
                  <label className="form-label">{t('dateLabel')}</label>
                  <p className="small-text">
                    {moment(position.jobDate).format('dddd DD.MM.YY')}
                  </p>
                </div>
              </Col>
              <Col md={4} lg={3}>
                <div className="form-group time-picker-group">
                  <label className="form-label">{t('startLabel') + '*'}</label>
                  <DatePicker
                    locale={currentlanguage}
                    selected={formik.values.start}
                    // selected={position.jobStart}
                    onChange={(e) => onChangeTime(e, 'start')}
                    showTimeSelectOnly
                    timeCaption={t('startTimeLabel')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    id="start_date"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    disabled={!allowInput}
                  />
                  {formik.errors['start'] && (
                    <div className="form-errors">{formik.errors['start']}</div>
                  )}
                </div>
              </Col>
              <Col md={4} lg={3}>
                <div className="form-group time-picker-group">
                  <label className="form-label">{t('endLabel') + '*'}</label>
                  <DatePicker
                    locale={currentlanguage}
                    selected={formik.values.end}
                    // selected={position.jobEnd}
                    onChange={(e) => onChangeTime(e, 'end')}
                    showTimeSelectOnly
                    timeCaption={t('endTimeLabel')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    id="end_date"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    disabled={!allowInput}
                  />
                  {hoursValidation !== '' && (
                    <div className="form-errors">{hoursValidation}</div>
                  )}
                  {formik.errors['end'] && (
                    <div className="form-errors">
                      {
                        formik.errors['end']
                        // && (
                        //   <OverlayTrigger
                        //     placement="auto-start"
                        //     delay={{
                        //       show: 100,
                        //       hide: 400,
                        //     }}
                        //     overlay={EndInfo}>
                        //     <svg
                        //       width="22"
                        //       height="23"
                        //       xmlns="http://www.w3.org/2000/svg"
                        //       className="popover-icon">
                        //       <g
                        //         transform="translate(0 .5)"
                        //         fill="none"
                        //         fillRule="evenodd">
                        //         <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                        //         <path
                        //           d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                        //           fill="#FFF"
                        //           fillRule="nonzero"
                        //         />
                        //       </g>
                        //     </svg>
                        //   </OverlayTrigger>
                        // )
                      }

                      {formik.errors['end'].includes('10h') && (
                        <OverlayTrigger
                          placement="auto-start"
                          delay={{
                            show: 100,
                            hide: 400,
                          }}
                          overlay={EndInfo}>
                          <svg
                            width="22"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                            className="popover-icon">
                            <g
                              transform="translate(0 .5)"
                              fill="none"
                              fillRule="evenodd">
                              <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                              <path
                                d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                                fill="#FFF"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </OverlayTrigger>
                      )}
                    </div>
                  )}
                </div>
              </Col>
              {!forEmployerSign && (
                <Col md={4} lg={2}>
                  <div className="form-group">
                    <label className="form-label">{t('event.hours')}</label>
                    <p className="field-info small-text">
                      {/* {convertToIntHour(moment(position.jobEnd).format('HH:mm')) -
                      convertToIntHour(
                        moment(position.jobStart).format('HH:mm'),
                      )}
                    h */}
                      {convertMsToHM(formik.values.end - formik.values.start)}{' '}
                      {/*{getTimeDifferent(
                        formik.values.start,
                        formik.values.end,
                      )}{' '}*/}
                      {getTimeDifferent(
                        formik.values.start,
                        formik.values.end,
                      ) > 6
                        ? '*'
                        : ''}
                    </p>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              {forEmployerSign ? (
                <>
                  <Col sm={12}>
                    <div className="form-label">{t('contract.signHere')}</div>
                    <div style={{ width: 300, height: 125 }}>
                      <SignaturePad
                        canvasProps={{
                          width: 300,
                          height: 125,
                          className: 'sigCanvas',
                        }}
                        penColor="black"
                        onEnd={() => {
                          setEmployerSign(
                            sigPad?.current
                              ?.getTrimmedCanvas()
                              .toDataURL('image/jpg'),
                          );
                        }}
                        ref={sigPad}
                      />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="form-bottom justify-content-end">
                      <Button
                        type="submit"
                        label={t('myJob.confirm')}
                        className="btn primary-btn"
                        disabled={formik.isSubmitting}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  {new Date() > new Date(position?.jobStart) &&
                  new Date() <= new Date(position?.jobStart + 129600000) &&
                  position?.employerData?.paidOutstandingAmountValue === 0 ? (
                    <Col sm={12}>
                      <div className="form-bottom justify-content-end">
                        <Button
                          type="button"
                          label={t('requestOverview.modal.cancel')}
                          className="btn btn--outline"
                          onClick={() =>
                            setShowConfirmHoursModal({
                              visible: false,
                              data: null,
                            })
                          }
                        />
                        <Button
                          type="submit"
                          label={t('myJob.confirm')}
                          className="btn primary-btn"
                          disabled={formik.isSubmitting || hoursValidation}
                        />
                      </div>
                    </Col>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmHoursModal;
