import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Head from '../components/Head';
import Loader from '../components/Core/Loader';
import api from '../utils/api';
import { handleError } from '../utils/handleError';
import { labelRoles } from '../utils/constants';
import EventDetails from '../components/EventDetails';
import { useTranslation } from 'react-i18next';
// import PositionRow from '../components/EventDetails/PositionRow';

const BookingJobDetails = (props) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bookedPositions, setBookedPositions] = useState([]);
  const eventId = props.match.params.eventId;
  const { t, i18n } = useTranslation();
  const [totalOutstandingAmount, setTotalOutstandingAmount] = useState(0);
  // let paidOutstandingAmountAll = [];
  const [paidOutstandingAmountAll, setPaidOutstandingAmountAll] = useState([]);

  useEffect(() => {
    // if (props.location.state && props.location.state.event) {
    //   setEvent(props.location.state.event);
    // } else {
    async function fetchMyAPI() {
      setLoading(true);
      try {
        let response = await api.get(`events/getEvent/${eventId}`);
        setEvent(response?.data?.data?.event);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleError(e);
      }
    }

    fetchMyAPI();
    // }
  }, [eventId, props.location.state]);

  useEffect(() => {
    if (event) {
      const { jobs } = event;
      let allPositionTotal = 0;
      let tempBookedPositions = [];
      let tempPaidOutstandingAmountAll = [];
      jobs.map((job) => {
        const tempJobPositions = job.jobPositions.map((position) => ({
          ...position,
          eventName: job.eventName,
          eventID: job.eventID,
          eventId: event?.eventID,
          jobName: job.jobName,
        }));
        const positions = tempJobPositions;
        const filteredPositions = positions.filter(
          (p) => p.booked && Object.values(p.booked),
        );
        tempBookedPositions = [...tempBookedPositions, ...filteredPositions];
        filteredPositions.map((position, index) => {
          // allPositionTotal += Number(position.jobPrice + position.jobTravelCost);

          if (position?.employerData?.paidOutstandingAmount !== true) {
            allPositionTotal += position?.employerData?.outstandingAmount
              ? position?.employerData?.outstandingAmount
              : 0;
          }
          if (
            position?.employerData?.outstandingAmount > 0 &&
            position?.employerData?.paidOutstandingAmountValue === 0
          ) {
            tempPaidOutstandingAmountAll.push({
              paidStatus: position?.employerData?.paidOutstandingAmount,
              paidAmount: position?.employerData?.outstandingAmount
                ? position?.employerData?.outstandingAmount
                : 0,
            });
          }
        });
      });
      setPaidOutstandingAmountAll(tempPaidOutstandingAmountAll);
      setBookedPositions(tempBookedPositions);
      setTotalOutstandingAmount(Number(allPositionTotal).toFixed(2));
    }
  }, [event]);
  // console.log("paidOutstandingAmountAll:", paidOutstandingAmountAll);
  // console.log(":-", paidOutstandingAmountAll.find((i) => i.paidAmount !== 0));

  const handlePositionConfirmed = (positions) => {
    const flag = positions.every((p) => p.isConfirmed);
    return flag;
  };

  return (
    <section className="content-section">
      <Head title={`${t('eventdetails')} | Superstaff`} />
      <Container>
        <div className="box-wrapper" style={{ position: 'relative' }}>
          {loading ? (
            <Loader />
          ) : (
            <EventDetails event={event} currEvent={event} setEvent={setEvent} />
          )}
        </div>

        {totalOutstandingAmount > 0 &&
        bookedPositions &&
        bookedPositions.length &&
        handlePositionConfirmed(bookedPositions) ? (
          <div className="box-wrapper" style={{ position: 'relative' }}>
            <div className="form-wrapper">
              <h3 className="headline-with-line">{t('OutstandingAmount')}</h3>
              <div className="table-responsive">
                <table className="table table-layout-fixed mb-0">
                  <tbody>
                    <tr className="event-details-item">
                      <td
                        className="ps-0"
                        style={{
                          borderBottom: 0,
                          minWidth: '250px',
                          maxWidth: '350px',
                        }}>
                        <p className="mb-0">{t('Duetoextrahours')}</p>
                      </td>
                      <td
                        style={{
                          borderBottom: 0,
                          minWidth: '350px',
                        }}
                        className="pe-0">
                        <div className="d-flex justify-content-end align-items-center">
                          {totalOutstandingAmount > 0 &&
                            paidOutstandingAmountAll.length > 0 && (
                              <>
                                <Link
                                  to={{
                                    pathname: `/${labelRoles.employer}/request-pay`,
                                    state: {
                                      jobs: bookedPositions,
                                      payOutStanding: true,
                                    },
                                  }}
                                  className="btn">
                                  {t('PayNow')}
                                </Link>
                                <p className="small-text placeholder-text">
                                  {t('TotalVAT')}
                                </p>
                                <strong className="total-amount">
                                  € {totalOutstandingAmount}
                                </strong>
                              </>
                            )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </section>
  );
};

export default BookingJobDetails;
