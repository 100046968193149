import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Index = ({ title }) => {
  useEffect(() => {
    //matomo tracking start
    let _paq = (window._paq = window._paq || []);
    //require user cookie consent before storing and using any cookies
    _paq.push(['requireCookieConsent']);

    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    let u = 'https://superstaff.matomo.cloud/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', process.env.REACT_APP_MATOMO]);
    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/plain'; // set type attribute
    g.dataset.cookieconsent = 'statistics'; // set data-cookieconsent attribute
    g.async = true;
    g.src = '//cdn.matomo.cloud/superstaff.matomo.cloud/matomo.js';
    s.parentNode.insertBefore(g, s);
    //matomo tracking end
  }, []);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="apple-touch-icon" href="favicon.ico" />
      <title>{title}</title>
    </Helmet>
  );
};

export default Index;
