import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../context/GlobalContext';
import moment from 'moment';
import { Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import profileImage1 from '../assets/images/table-book-profile.png';
import JobPosition from '../components/Modal/JobPosition';
import NewJob from '../components/Modal/NewJob';
import StaffSelection from '../components/Modal/StaffSelection';
import Head from '../components/Head';

const Table = () => {
  const {
    showNewJobModal,
    setShowNewJobModal,
    showStaffModal,
    setShowStaffModal,
  } = useContext(GlobalContext);

  const [openDetail, setOpenDetail] = useState(null);
  const [showJobModal, setShowJobModal] = useState(false);
  const [place, setPlace] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [addJobPosition, setAddJobPosition] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const handleJobPositionSubmit = (e) => {
    e.preventDefault();
    setShowJobModal(false);
    setStartDate(new Date());
    setStartDate1(new Date());
    setPlace(null);
    setStartTime(null);
    setEndTime(null);
    setAddJobPosition([
      ...addJobPosition,
      { startDate, place, endTime, startTime },
    ]);
  };

  const handleJobPositionCancel = (e) => {
    e.preventDefault();
    setShowJobModal(false);
    setStartDate(new Date());
    setPlace(null);
    setStartTime(null);
    setEndTime(null);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      {showJobModal && (
        <JobPosition
          showJobModal={showJobModal}
          setShowJobModal={setShowJobModal}
          startDate={startDate}
          setStartDate={setStartDate}
          place={place}
          setPlace={setPlace}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          handleJobPositionSubmit={handleJobPositionSubmit}
          handleJobPositionCancel={handleJobPositionCancel}
        />
      )}
      {showNewJobModal && <NewJob />}
      {showStaffModal && <StaffSelection />}

      <div className="table-responsive">
        <table className="table request-list">
          <thead>
            <tr>
              <th role="columnheader" title="Toggle SortBy">
                Event ID<span className="sort-arrow"></span>
              </th>
              <th role="columnheader" title="Toggle SortBy">
                Event Name<span className="sort-arrow"></span>
              </th>
              <th role="columnheader" title="Toggle SortBy">
                Expiration Date
                <span className="sort-arrow sort-arrow-accending"></span>
              </th>
              <th role="columnheader" title="Toggle SortBy">
                Staff
              </th>
              <th role="columnheader" title="Toggle SortBy">
                Amount Booked
              </th>
              <th role="columnheader" title="Toggle SortBy"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6" style={{ padding: 0 }}>
                <table width="100%">
                  <tbody>
                    <tr
                      className={`parent-tab ${
                        openDetail === 'lateNightShopping' ? 'tab--open' : ''
                      }`}>
                      <td>214.232</td>
                      <td>Late Night Shopping</td>
                      <td>
                        08.09.2021
                        <span className="edit-icon"></span>
                      </td>
                      <td>4/4 booked</td>
                      <td>
                        <span className="total-amount">€ XYZ*</span>
                      </td>
                      <td className="text-right">
                        <span
                          className="link"
                          onClick={() => {
                            if (openDetail === 'lateNightShopping') {
                              setOpenDetail(null);
                            } else {
                              setOpenDetail('lateNightShopping');
                            }
                          }}>
                          {openDetail === 'lateNightShopping'
                            ? 'Hide Detail'
                            : 'Show Detail'}
                        </span>
                      </td>
                    </tr>
                    <tr
                      className={`${
                        openDetail === 'lateNightShopping' ? '' : 'd-none'
                      } sub-table-data`}>
                      <td colSpan="6" style={{ padding: 0 }}>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <span className="detail-link text-medium">
                                  Hostess m/f
                                </span>
                              </td>
                              <td className="text-medium">
                                <span className="weight-normal">
                                  12.09.2021 -<br /> 13. 09. 2021
                                </span>
                              </td>
                              <td>
                                <span className="weight-normal">
                                  1/2 booked
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="text-uppercase">
                                  Description
                                </span>
                                <br />
                                <br />
                                <span className="weight-normal">
                                  In search for hostesses to welcome our guests
                                  at our outdoors garden party.
                                </span>
                              </td>
                              <td>
                                <span className="text-uppercase">
                                  Staff requirement/Outfit
                                </span>
                                <br />
                                <br />
                                <span className="weight-normal">
                                  Please wear jeans, white t-shirt and sneakers.
                                </span>
                              </td>
                              <td>
                                <span className="text-uppercase">Bonus</span>
                                <br />
                                <br />
                                <span>20%</span>
                              </td>
                            </tr>
                            <tr className="sub-table-data sub-table">
                              <td colSpan="3">
                                <table width="100%">
                                  <thead>
                                    <tr className="sub-table-data sub-table">
                                      <th>
                                        <span>position</span>
                                      </th>
                                      <th>
                                        <span>Place / Address</span>
                                      </th>
                                      <th>
                                        <span>Date</span>
                                      </th>
                                      <th>
                                        <span>Start</span>
                                      </th>
                                      <th>
                                        <span>End</span>
                                      </th>
                                      <th>
                                        <span>Price</span>
                                      </th>
                                      <th>
                                        <span>Travel Costs</span>
                                      </th>
                                      <th>
                                        <span>Total Costs</span>
                                      </th>
                                      <th>
                                        <span>Staff</span>
                                      </th>
                                      <th>
                                        <span>Briefing</span>
                                      </th>
                                      <th>
                                        <span>Read</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {addJobPosition.map((el, id) => (
                                      <tr key={id}>
                                        <td>
                                          <div className="numberList">
                                            {id + 1}/{addJobPosition.length}
                                          </div>
                                        </td>
                                        <td>
                                          <span>{el.place}</span>
                                        </td>
                                        <td>
                                          <span>
                                            {el.startDate.getDay() === 0 &&
                                              'Sunday'}
                                            {el.startDate.getDay() === 1 &&
                                              'Monday'}
                                            {el.startDate.getDay() === 2 &&
                                              'Tuesday'}
                                            {el.startDate.getDay() === 3 &&
                                              'Wednesday'}
                                            {el.startDate.getDay() === 4 &&
                                              'Thursday'}
                                            {el.startDate.getDay() === 5 &&
                                              'Friday'}
                                            {el.startDate.getDay() === 6 &&
                                              'Saterday'}
                                            <br />
                                            {(
                                              '0' + el.startDate.getDate()
                                            ).slice(-2)}
                                            {'.'}
                                            {el.startDate.getMonth()}
                                            {'.'}
                                            {el.startDate.getFullYear()}
                                          </span>
                                        </td>
                                        <td>
                                          <span>{el.startTime}</span>
                                        </td>
                                        <td>
                                          <span>{el.endTime}</span>
                                        </td>
                                        <td>
                                          <span>€ XYZ</span>
                                        </td>
                                        <td>
                                          <span>€ XYZ</span>
                                        </td>
                                        <td>
                                          <span className="total-amount">
                                            <strong>€ XYZ*</strong>
                                          </span>
                                        </td>
                                        <td>
                                          <div className="profile-book">
                                            <div className="profile-wrap">
                                              <span className="profile-image">
                                                <img
                                                  src={profileImage1}
                                                  alt=""
                                                />
                                              </span>
                                              <span className="profile-gender"></span>
                                            </div>
                                            <span className="profile-text">
                                              Booked
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <span className="btn">Add</span>
                                        </td>
                                        <td>
                                          <span className="detail-link text-medium">
                                            <strong>
                                              {id + 1}/{addJobPosition.length}
                                            </strong>
                                          </span>
                                        </td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td>
                                        <div className="numberList">1/2</div>
                                      </td>
                                      <td>
                                        <span>
                                          London | Shoreditch Gardens, Garden
                                          Atrium Kingsland Rd, , E2 8EA
                                        </span>
                                      </td>
                                      <td>
                                        <span>Sunday 12.09.2021</span>
                                      </td>
                                      <td>
                                        <span>10:00</span>
                                      </td>
                                      <td>
                                        <span>15:30</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <div className="aplpication-wrap">
                                          <span className="info-icon">!</span>
                                          <span
                                            onClick={() =>
                                              setShowStaffModal(true)
                                            }
                                            className="detail-link text-medium">
                                            <strong>5 Applications</strong>
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="21"
                                            height="23"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g
                                              transform="translate(.5)"
                                              fill="none"
                                              fillRule="evenodd">
                                              <rect
                                                stroke="#41A9C7"
                                                strokeWidth="2"
                                                x="1"
                                                y="5"
                                                width="13"
                                                height="17"
                                                rx="2"
                                              />
                                              <g fill="#41A9C7">
                                                <path d="M6 10h3v7H6z" />
                                                <path d="M4 15v-3h7v3z" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="19"
                                            height="22"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                              fill="#41A9C7"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <div className="numberList">1/1</div>
                                      </td>
                                      <td>
                                        <span>
                                          London | Shoreditch Gardens, Garden
                                          Atrium Kingsland Rd, , E2 8EA
                                        </span>
                                      </td>
                                      <td>
                                        <span>Sunday 12.09.2021</span>
                                      </td>
                                      <td>
                                        <span>10:00</span>
                                      </td>
                                      <td>
                                        <span>15:30</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td>
                                        <span className="total-amount">
                                          <strong>€ XYZ*</strong>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="profile-book">
                                          <div className="profile-wrap">
                                            <span className="profile-image">
                                              <img src={profileImage1} alt="" />
                                            </span>
                                            <span className="profile-gender"></span>
                                          </div>
                                          <span className="profile-text">
                                            Added to list
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="21"
                                            height="23"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g
                                              transform="translate(.5)"
                                              fill="none"
                                              fillRule="evenodd">
                                              <rect
                                                stroke="#41A9C7"
                                                strokeWidth="2"
                                                x="1"
                                                y="5"
                                                width="13"
                                                height="17"
                                                rx="2"
                                              />
                                              <g fill="#41A9C7">
                                                <path d="M6 10h3v7H6z" />
                                                <path d="M4 15v-3h7v3z" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="19"
                                            height="22"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                              fill="#41A9C7"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <div className="numberList">1/1</div>
                                      </td>
                                      <td>
                                        <span>
                                          London | Shoreditch Gardens, Garden
                                          Atrium Kingsland Rd, , E2 8EA
                                        </span>
                                      </td>
                                      <td>
                                        <span>Sunday 12.09.2021</span>
                                      </td>
                                      <td>
                                        <span>10:00</span>
                                      </td>
                                      <td>
                                        <span>15:30</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td>
                                        <span className="total-amount">
                                          <strong>€ XYZ*</strong>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="request-book">
                                          <div className="book-wrap">
                                            <span className="profile-image">
                                              <img src={profileImage1} alt="" />
                                            </span>
                                            <span className="book-time">
                                              7h
                                            </span>
                                          </div>
                                          <span className="btn">book now</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="21"
                                            height="23"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g
                                              transform="translate(.5)"
                                              fill="none"
                                              fillRule="evenodd">
                                              <rect
                                                stroke="#41A9C7"
                                                strokeWidth="2"
                                                x="1"
                                                y="5"
                                                width="13"
                                                height="17"
                                                rx="2"
                                              />
                                              <g fill="#41A9C7">
                                                <path d="M6 10h3v7H6z" />
                                                <path d="M4 15v-3h7v3z" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="19"
                                            height="22"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                              fill="#41A9C7"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <div className="numberList">1/1</div>
                                      </td>
                                      <td>
                                        <span>
                                          London | Shoreditch Gardens, Garden
                                          Atrium Kingsland Rd, , E2 8EA
                                        </span>
                                      </td>
                                      <td>
                                        <span>Sunday 12.09.2021</span>
                                      </td>
                                      <td>
                                        <span>10:00</span>
                                      </td>
                                      <td>
                                        <span>15:30</span>
                                      </td>
                                      <td>
                                        <span>€ XYZ</span>
                                      </td>
                                      <td></td>
                                      <td>
                                        <span className="total-amount">
                                          <strong>€ XYZ*</strong>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="profile-book">
                                          <span className="profile-text">
                                            No Applications
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="21"
                                            height="23"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g
                                              transform="translate(.5)"
                                              fill="none"
                                              fillRule="evenodd">
                                              <rect
                                                stroke="#41A9C7"
                                                strokeWidth="2"
                                                x="1"
                                                y="5"
                                                width="13"
                                                height="17"
                                                rx="2"
                                              />
                                              <g fill="#41A9C7">
                                                <path d="M6 10h3v7H6z" />
                                                <path d="M4 15v-3h7v3z" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="field-action-icon">
                                          <svg
                                            width="19"
                                            height="22"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                              fill="#41A9C7"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colSpan="11" className="text-right">
                                        <span
                                          onClick={() => {
                                            setShowJobModal(true);
                                          }}
                                          className="detail-link text-medium">
                                          <strong>Add job position</strong>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="detail-link text-medium">
                                  Service Staff m/f
                                </span>
                              </td>
                              <td className="text-medium">
                                <span className="weight-normal">
                                  12.09.2021 -<br /> 13. 09. 2021
                                </span>
                              </td>
                              <td>
                                <span className="weight-normal">
                                  1/3 booked
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right last-table-row">
                                <span
                                  onClick={() => setShowNewJobModal(true)}
                                  className="detail-link text-medium">
                                  <strong>Add job</strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <span>214.233</span>
              </td>
              <td>
                <span>h&amp;m Garden Party</span>
              </td>
              <td>
                <span>12.09.2021</span>
                <span className="edit-icon"></span>
              </td>
              <td>
                <span>2/5 booked</span>
              </td>
              <td>
                <span className="total-amount">€ XYZ*</span>
              </td>
              <td>
                <span className="detail-link">Show Detail</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>214.234</span>
              </td>
              <td>
                <span>Winter Collection Presentation</span>
              </td>
              <td>
                <span>
                  <span>{moment(startDate).format('DD.MM.YYYY')}</span>
                  <span className="edit-icon" onClick={handleClick}></span>
                  {isOpen && (
                    <DatePicker
                      selected={startDate}
                      onChange={handleChange}
                      className="form-control"
                      inline
                    />
                  )}
                </span>
              </td>
              <td>
                <span>0/2 booked</span>
              </td>
              <td>
                <span className="total-amount">€ XYZ*</span>
              </td>
              <td>
                <span className="detail-link">Show Detail</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>214.235</span>
              </td>
              <td>
                <span>Halloween Party</span>
              </td>
              <td>
                <span>
                  <span>{moment(startDate1).format('DD.MM.YYYY')}</span>
                  <span className="edit-icon" onClick={handleClick}></span>
                  {isOpen && (
                    <DatePicker
                      selected={startDate1}
                      onChange={handleChange}
                      className="form-control"
                      inline
                    />
                  )}
                </span>
              </td>
              <td>
                <span>0/8 booked</span>
              </td>
              <td>
                <span className="total-amount">€ XYZ*</span>
              </td>
              <td>
                <span className="detail-link">Show Detail</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const RequestOverview = () => {
  return (
    <section className="content-section">
      <Head title="Request Overview | Superstaff" />
      <Container>
        <div className="box-wrapper">
          <div className="add-new-request">
            <Link to="/cart" className="cart-icon">
              <svg viewBox="0 0 30 22" width="30" height="22">
                <path
                  fill="#41A9C7"
                  fillRule="evenodd"
                  d="M12.008 17.943c1.13 0 2.048.912 2.048 2.032 0 1.113-.919 2.025-2.048 2.025-1.13 0-2.048-.912-2.048-2.025 0-1.12.919-2.032 2.048-2.032zm12.35 0c1.129 0 2.047.912 2.047 2.032 0 1.113-.918 2.025-2.048 2.025-1.129 0-2.047-.912-2.047-2.025 0-1.12.918-2.032 2.047-2.032zm1.344-4.184H10.29L8.022 5.23l19.672.02-1.992 8.51zm4.075-10.138a1.044 1.044 0 00-.798-.382l-21.492-.02L6.832.75A1.02 1.02 0 005.843 0h-4.82C.456 0 0 .45 0 1.006s.457 1.006 1.022 1.006h4.032l.65 2.44c.003.02.007.034.011.047l2.797 10.52c.118.443.524.752.99.752h17.013c.476 0 .89-.322.996-.778l2.463-10.52a.994.994 0 00-.197-.852z"
                />
              </svg>
            </Link>
            <Link to="/new-request" className="btn">
              new request
            </Link>
          </div>
          <Table />
          <div className="load-more">
            <span className="load-more-link">Load more</span>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RequestOverview;
