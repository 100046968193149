import React, { useEffect, useState } from 'react';
import { roles } from '../utils/constants';
import { countries } from '../utils/countries';
import { languages } from '../utils/languages';
import { handleError } from '../utils/handleError';
import api from '../utils/api';
import { settingConstants } from '../actions/types';

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(null);
  const [jobPositionsLoading, setJobPositionsLoading] = useState(false);
  const [jobPositions, setJobPositions] = useState([]);
  const [previousJobPositions, setPreviousJobPositions] = useState([]);
  const [showEmployerRegisterModal, setShowEmployerRegisterModal] =
    useState(false);
  const [showInfoImageSaveModal, setInfoImageSaveModal] = useState(false);
  const [
    showEmployerCompleteRegisterModal,
    setShowEmployerCompleteRegisterModal,
  ] = useState(false);
  const [showEmployeeRegisterModal, setShowEmployeeRegisterModal] =
    useState(false);
  const [
    showEmployeeCompleteRegisterModal,
    setShowEmployeeCompleteRegisterModal,
  ] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState({
    visible: false,
    role: null,
  });
  const [showCompleteLoginModal, setShowCompleteLoginModal] = useState({
    visible: false,
    role: roles.employer,
  });
  const [showNewJobModal, setShowNewJobModal] = useState({
    visible: false,
  });
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showBookingList, setShowBookingList] = useState(false);
  const [listLoadingExtern, setListLoadingExtern] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState({
    visible: false,
    resetToken: null,
  });
  const [countriesOptions] = useState(
    countries.map((country) => ({
      label: country.name,
      value: country.code,
    })),
  );
  const [languagesOptions] = useState(
    languages.map((lang) => ({
      label: lang.name,
      value: lang.code,
    })),
  );
  const [showRecorderModel, setShowRecorderModel] = useState(false);
  const [showImageConfirmationModal, setShowImageConfirmationModal] = useState({
    visible: false,
  });
  const [showEmployeeImageModal, setShowEmployeeImageModal] = useState(false);
  const [showDeleteVoiceModal, setShowDeleteVoiceModal] = useState(false);
  const [showDeleteJobPositionModal, setShowDeleteJobPositioModal] = useState({
    show: false,
    id: null,
    item: null,
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      setJobPositionsLoading(true);
      try {
        // let responsePrevousJob = await api.get('/jobs/job-positions');
        // let resPrevousJobs = responsePrevousJob.data.data.filteredJobs;
        // const filteredPrivousJobs = resPrevousJobs.map((job) => ({
        //   label: job,
        //   value: job,
        // }));
        let response = await api.get('/global-settings/getstafftypes');
        let resJobs = response.data.stafftypes;
        const filteredJobs = resJobs.map((job) => ({
          label: job.name,
          value: job.name,
        }));

        setJobPositionsLoading(false);
        setJobPositions(filteredJobs);
        // setPreviousJobPositions(filteredPrivousJobs);
      } catch (e) {
        setJobPositionsLoading(false);
        handleError(e);
      }
    };
    fetchJobs();
  }, []);

  const updateWidth = () => {
    if (typeof window !== undefined) {
      setWindowWidth(window.innerWidth);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        windowWidth,
        jobPositions,
        previousJobPositions,
        jobPositionsLoading,
        showEmployerRegisterModal,
        setShowEmployerRegisterModal,
        showInfoImageSaveModal,
        setInfoImageSaveModal,
        showEmployerCompleteRegisterModal,
        setShowEmployerCompleteRegisterModal,
        showEmployeeRegisterModal,
        setShowEmployeeRegisterModal,
        showEmployeeCompleteRegisterModal,
        setShowEmployeeCompleteRegisterModal,
        showLoginModal,
        setShowLoginModal,
        showCompleteLoginModal,
        setShowCompleteLoginModal,
        showNewJobModal,
        setShowNewJobModal,
        showForgotModal,
        setShowForgotModal,
        showResetPassword,
        setShowResetPassword,
        showBookingList,
        setShowBookingList,
        listLoadingExtern,
        setListLoadingExtern,
        countriesOptions,
        languagesOptions,
        showRecorderModel,
        setShowRecorderModel,
        showImageConfirmationModal,
        setShowImageConfirmationModal,
        showEmployeeImageModal,
        setShowEmployeeImageModal,
        showDeleteVoiceModal,
        setShowDeleteVoiceModal,
        showDeleteJobPositionModal,
        setShowDeleteJobPositioModal,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
