import { contractConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';
import i18n from '../i18n';

export const getActiveContract = () => {
  const request = () => {
    return { type: contractConstants.GET_ACTIVE_CONTRACT };
  };
  const success = (contract) => {
    return { type: contractConstants.GET_ACTIVE_CONTRACT_SUCCESS, contract };
  };
  const failure = (error) => {
    return { type: contractConstants.GET_ACTIVE_CONTRACT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await api.get('contracts/getActiveContract');
      // console.log('res', res);
      dispatch(success(res.data.contract));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};
