import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const Input = (props) => {
  const {
    type,
    label,
    value,
    onBlur,
    onChange,
    touched,
    className,
    formGroupClass,
    errors,
    placeholder,
    name,
    tooltipTitle,
    tooltipText,
    styles,
    // inputProps,
  } = props;

  const tooltiptext = tooltipText;

  const popover = (
    <Popover>
      <Popover.Body>
        <h5>{tooltipTitle}</h5>
        <p>{tooltiptext}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      className={`form-group ${formGroupClass} ${
        touched && errors ? 'form-group-errors' : ''
      }`}
      style={{ marginBottom: `${styles ? styles.marginBottom : ''}` }}>
      {label && (
        <label className="form-label">
          {label}
          {tooltiptext && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 400 }}
              overlay={popover}>
              <svg
                width="22"
                height="23"
                xmlns="http://www.w3.org/2000/svg"
                className="popover-icon">
                <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
                  <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                  <path
                    d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </OverlayTrigger>
          )}
        </label>
      )}
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        error={errors}
        onChange={onChange}
        onBlur={onBlur}
        touched={
          touched === true || touched === false ? touched.toString() : 'false'
        }
        className={`form-control ${className}`}
        {...props}
      />
      {touched && errors && <div className="form-errors">{errors}</div>}
    </div>
  );
};

export default Input;
