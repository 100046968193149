import React, { useState, useEffect } from 'react';
// import { jsPDF } from 'jspdf';
import { useSelector, useDispatch } from 'react-redux';
// import ReactMarkdown from 'react-markdown';
import { toast } from 'react-toastify';
import { Modal, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SignaturePad from 'react-signature-canvas';
import Input from '../Core/Input';
import Button from '../Core/Button';
import Loader from '../Core/Loader';
import { submitContract, updateJobPosition } from '../../actions/jobAction';
import superstaffLogo from '../../assets/images/logo.jpg';
import cgkLogo from '../../assets/images/cgk_logo_new.png';
import cgkEmployerSign from '../../assets/images/cgk_sign_new.jpeg';
import { useTranslation } from 'react-i18next';
// import html2PDF from 'jspdf-html2canvas';
import moment from 'moment';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import api from '../../utils/api';

const ContractModal = ({
  callbackToContractModal,
  jobPosition,
  checkIfStaffIsBooked,
  activeContract,
  companyName,
  scrollToSection,
}) => {
  const sigPad = React.useRef({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const user = userData.data;
  const [contractUploading, setContractUploading] = useState(false);
  const [invalidSign, setInvalidSign] = useState(true);
  const [employeeSign, setEmployeeSign] = useState(false);
  const [contractAPIData, setContractAPIData] = useState([]);
  const [contractTexts, setContractTexts] = useState(
    activeContract?.contract ?? '',
  );
  const [contractSigned, setContractSigned] = useState(false);
  const { t } = useTranslation();
  const [showContractModal, setShowContractModal] = useState({
    visible: false,
    data: null,
  });
  const [blockInvalidSign, setBlockInvalidSign] = useState([]);

  const formik = useFormik({
    initialValues: {
      fName: user.firstName,
      lName: user.lastName,
      sign: '',
    },
    validationSchema: Yup.object({
      fName: Yup.string().trim().required(t('FirstName')),
      lName: Yup.string().trim().required(t('LastName')),
    }),
    onSubmit: async (values, actions) => {
      setContractUploading(true);
      // const signature = sigPad.current.getTrimmedCanvas().toDataURL('image/jpg');
      const contractId = activeContract?._id ?? '';
      if (!contractId || contractId === '') {
        toast.error(t('contracterror1'));
        setContractUploading(false);
        clear();
        return;
      }

      // let doc = new jsPDF();
      // doc.addImage(logo, 'JPEG', 90, 10, 25, 25);
      // doc.text(`I, ${values.fName} ${values.lName}, signed to below contract.`, 20, 60,);
      // const splitTitle = doc.splitTextToSize(contractTexts?.toString('html'), 180);
      // doc.text(splitTitle, 20, 80);
      // console.log("data doc", doc);
      // doc.addImage(signature, 'JPEG', 165, 185, 30, 30, 'right');
      // doc.text('Signature', 190, 225, null, null, 'right');
      // let contractFile = '';
      let contractId1 = {
        contract: contractId,
      };
      const position = {
        ...showContractModal.data.position,
        contractId: contractId1,
      };
      delete position.applications;
      delete position.booked;
      let formData = new FormData();
      Object.entries(position).map((val) => {
        if (
          val[0] === 'location' ||
          val[0] === 'staffToBook' ||
          val[0] === 'bookedStaffDetails' ||
          val[0] === 'readyToBook' ||
          val[0] === 'rejectedApplicants' ||
          val[0] === 'contractId' ||
          val[0] === 'jobPlaceAddress' ||
          val[0] === 'employerData'
        ) {
          formData.append(val[0], JSON.stringify(val[1]));
        } else {
          formData.append(val[0], val[1]);
        }
      });

      const element = document.getElementById('contract_final');
      element.style.width = '800px';

      try {
        await api
          .post('contracts/createContractPDF', {
            // contract: `<!DOCTYPE html>
            // <html>
            // <head>
            //     <title>HTML content</title>
            // </head>
            // <body>
            //     <div id="contract_final" className="clearfix" style="padding: 15px;">
            //       ${contractTexts}
            //     </div>
            //     </body></html>`,
            contractData: contractAPIData,
            position: jobPosition._id,
            contractId: contractId,
          })
          .then((res) => {
            if (res?.data?.contract) {
              setContractUploading(false);
              scrollToSection();
              toast.success(t('contractSignedMessage'));
              setShowContractModal({
                visible: false,
                data: null,
              });
              callbackToContractModal();
              setContractSigned(true);
              /*formData.append('signedContract', res?.data?.contract);
              dispatch(
                updateJobPosition({
                  id: position._id,
                  payload: formData,
                  callback: () => {
                    setContractUploading(false);
                    scrollToSection();
                    toast.success(t('contractSignedMessage'));
                    setShowContractModal({
                      visible: false,
                      data: null,
                    });
                    callbackToContractModal();
                    setContractSigned(true);
                    clear();
                  },
                  failCallback: () => {
                    setContractUploading(false);
                    clear();
                  },
                }),
              );*/
            }
            clear();
          })
          .catch((err) => {
            toast.error(t('contracterror1'));
            clear();
          });
      } catch (e) {
        toast.error(t('contracterror1'));
        clear();
      }
      // const defaultOptions = {
      //   jsPDF: {
      //     unit: 'px',
      //     format: 'a4',
      //   },
      //   html2canvas: {
      //     imageTimeout: 15000,
      //     logging: true,
      //     useCORS: false,
      //   },
      //   imageType: 'image/jpeg',
      //   imageQuality: 1,
      //   margin: {
      //     top: 42,
      //     right: 30,
      //     bottom: 42,
      //     left: 30,
      //   },
      //   output: 'jspdf-generate.pdf',
      //   init: function () { },
      //   success: function (pdf) {
      //     // pdf.save(this.output);
      //     contractFile = pdf.output('blob');
      //     formData.append('signedContract', contractFile);
      //     dispatch(
      //       updateJobPosition({
      //         id: position._id,
      //         payload: formData,
      //         callback: () => {
      //           setContractUploading(false);
      //           scrollToSection();
      //           toast.success('Contract Signed successfully!');
      //           setShowContractModal({
      //             visible: false,
      //             data: null,
      //           });
      //           callbackToContractModal();
      //           setContractSigned(true);
      //         },
      //         failCallback: () => {
      //           setContractUploading(false);
      //         },
      //       }),
      //     );
      //   }
      // };
      // html2PDF(element, defaultOptions);
      // setContractUploading(false);
    },
  });

  const submitContractToEmployer = () => {
    setContractUploading(true);
    let formData = new FormData();

    formData.append('contractSubmited', true);
    dispatch(
      submitContract({
        id: jobPosition._id,
        payload: formData,
        callback: () => {
          setContractUploading(false);
          toast.success(t('contractsuccess'));
          callbackToContractModal();
        },
        failCallback: () => {
          setContractUploading(false);
        },
      }),
    );
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const parseDynamicVariableToContract = async () => {
    let currentDate = moment().format('DD.MM.YYYY');
    let contractData = activeContract?.contract
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(activeContract?.contract)),
        )
      : EditorState.createEmpty();
    contractData = draftToHtml(convertToRaw(contractData.getCurrentContent()));
    let superstaffLogoBaseData = await getBase64FromUrl(superstaffLogo);
    let cgkLogoBaseData = await getBase64FromUrl(cgkLogo);
    let cgkEmployerSignBaseData = await getBase64FromUrl(cgkEmployerSign);
    let precontent = `<div style="display:flex;justify-content:space-between;margin-bottom:24px">
      <div>
        <img src="${superstaffLogoBaseData}" style="width:100px" alt="" />
      </div>
      <div>
        <img src="${cgkLogoBaseData}" style="width:100px" alt="" />
      </div>
    </div>`;
    if (contractData) {
      const mappingVariables = [
        { key: '{{employerName}}', value: companyName },
        {
          key: '{{employeeName}}',
          value: `${formik?.values?.fName} ${formik?.values?.lName}`,
        },
        // { key: '{{priceTotal}}', value: `.....` },
        { key: '{{employeeSSN}}', value: jobPosition?.bookedStaffDetails?.ssn },
        {
          key: '{{employeeBankAccountOwner}}',
          value: jobPosition?.bookedStaffDetails?.issuedName,
        },
        {
          key: '{{employeeBankName}}',
          value: jobPosition?.bookedStaffDetails?.nameOfInstitute,
        },
        {
          key: '{{employeeBankCode}}',
          value: jobPosition?.bookedStaffDetails?.iban,
        },
        {
          key: '{{employeeBankNumber}}',
          value: jobPosition?.bookedStaffDetails?.bic,
        },
        { key: '{{date}}', value: currentDate },
        {
          key: '{{employeeCity}}',
          value: user?.employee?.city,
        },
        {
          key: '{{employeeSignature}}',
          value: employeeSign
            ? `<img src="${employeeSign}" alt="${formik?.values?.fName}" style="height: 75px; width:175px;" />`
            : '',
          // value: `!['${formik?.values?.fName}'](${employeeSign} "${formik?.values?.fName}")`,
        },
        {
          key: '{{employerSignature}}',
          value: `<img src="${cgkEmployerSignBaseData}" alt="" style="height: 75px; width:175px;" />`,
          // value: `![KK_Stempel Und Unterschrift CGK GmbH & Co KG](${cgkSign} "KK_Stempel Und Unterschrift CGK GmbH & Co KG")`,
        },
      ];
      const contractAPID = [
        { key: '{{employerName}}', value: companyName },
        {
          key: '{{employeeName}}',
          value: `${formik?.values?.fName} ${formik?.values?.lName}`,
        },
        { key: '{{employeeSSN}}', value: jobPosition?.bookedStaffDetails?.ssn },
        {
          key: '{{employeeBankAccountOwner}}',
          value: jobPosition?.bookedStaffDetails?.issuedName,
        },
        {
          key: '{{employeeBankName}}',
          value: jobPosition?.bookedStaffDetails?.nameOfInstitute,
        },
        {
          key: '{{employeeBankCode}}',
          value: jobPosition?.bookedStaffDetails?.iban,
        },
        {
          key: '{{employeeBankNumber}}',
          value: jobPosition?.bookedStaffDetails?.bic,
        },
        { key: '{{date}}', value: currentDate },
        {
          key: '{{employeeCity}}',
          value: user?.employee?.city,
        },
        {
          key: '{{employeeSignature}}',
          value: employeeSign ? employeeSign : '',
        },
      ];
      setContractAPIData(contractAPID);
      mappingVariables?.map((item) => {
        contractData = contractData.replaceAll(item.key, `${item.value}`);
        return item;
      });
    }
    if (precontent !== '') {
      contractData = `${precontent} <div>${contractData}</div>`;
    }
    setContractTexts(contractData);
    blockInvalidSign.pop();
    if (blockInvalidSign[blockInvalidSign.length - 1] === undefined) {
      setInvalidSign(sigPad?.current?.toData()?.length < 1);
    }
  };

  useEffect(() => {
    parseDynamicVariableToContract();
  }, [
    activeContract?.contract,
    jobPosition,
    formik.values,
    employeeSign,
    blockInvalidSign,
  ]);

  const clear = () => {
    sigPad.current?.clear();
    setEmployeeSign(sigPad?.current?.getTrimmedCanvas().toDataURL('image/jpg'));
    setInvalidSign(true);
  };

  const getSignaturePad = React.useMemo(() => {
    return (
      <SignaturePad
        canvasProps={{
          width: 300,
          height: 125,
          className: 'sigCanvas',
        }}
        penColor="black"
        onEnd={() => {
          setEmployeeSign(
            sigPad?.current?.getTrimmedCanvas().toDataURL('image/jpg'),
          );
          setInvalidSign(true);
          blockInvalidSign.push(5);
        }}
        ref={sigPad}
      />
    );
  }, []);

  const renderpdfcontract = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Contract-' +
        moment().format('DD.MM.YYYY') +
        '-' +
        user.firstName +
        ' ' +
        user.lastName +
        '.pdf',
    );
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };
  const getContactUrl = async (positionid) => {
    await api
      .post('contracts/fetchcontracturl', {
        jobPositionId: positionid,
      })
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.contracturl[0].data).buffer]),
        );
        renderpdfcontract(url);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  const renderContacts = () => {
    if (jobPosition.booked && checkIfStaffIsBooked(jobPosition.booked)) {
      return (
        <>
          <div
            className={`small-boxs__item ${
              jobPosition.signedContract ? 'completed' : ''
            } ${jobPosition?.bookedStaffDetails ? '' : 'disabled'}`}
            onClick={() => {
              if (jobPosition.signedContract) {
                // getContactUrl(jobPosition.signedContract);
                getContactUrl(jobPosition._id);
                // window.open(jobPosition.signedContract, '_blank');
              } else {
                setShowContractModal({
                  visible: true,
                  // data: {
                  //   positionId: jobPosition._id,
                  //   position: jobPosition,
                  //   contract: jobs[0].jobContract,
                  // },
                  data: {
                    positionId: jobPosition._id,
                    position: jobPosition,
                    contractId: activeContract?._id,
                    contract: activeContract?.contract,
                  },
                });
              }
            }}
            style={{
              pointerEvents: !jobPosition?.bookedStaffDetails ? 'none' : '',
            }}>
            C
          </div>
        </>
      );
    } else {
      return <div className="small-boxs__item">C</div>;
    }
  };

  return (
    <>
      <div className="form-wrapper mt-4">
        <div className="ce-text">
          <h3>{t('myjobsStaff.contractTitle')}</h3>
          <p>
            {jobPosition?.contractSubmited
              ? t('myjobsStaff.submittedContractDesc')
              : t('myjobsStaff.contractDesc')}
          </p>
        </div>
        <div className="small-boxs">
          {renderContacts()}
          {!jobPosition?.contractSubmited && jobPosition?.signedContract && (
            <span className="text-start text-success">
              {/*{t('myjobsStaff.submitContractButtonText')}*/}
              <i>{t('myjobsStaff.submitContract')}</i>
            </span>
          )}
          {jobPosition?.bookedStaffDetails && !jobPosition?.signedContract && (
            <span className="text-start text-success">
              {/*{t('myjobsStaff.submitContractButtonText')}*/}
              <i>{t('myjobsStaff.opne&signContract')}</i>
            </span>
          )}
        </div>
      </div>
      {!jobPosition?.contractSubmited && (
        <div className="form-group text-end">
          <Button
            type="button"
            onClick={() => {
              submitContractToEmployer();
              scrollToSection();
            }}
            // label={t('contact.btnSubmit')}
            label={t('contact.confirmjobnow')}
            disabled={!jobPosition?.signedContract}
            className={`btn ${
              !jobPosition?.signedContract ? 'btn-secondary' : 'primary-btn'
            }`}
          />
        </div>
      )}
      <Modal
        show={showContractModal.visible}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          if (contractUploading) {
            return;
          }
          setShowContractModal({
            visible: false,
            data: null,
          });
          clear();
        }}
        className="contract-modal">
        {contractUploading && <Loader />}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{t('contract.title')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="8" xl="9">
              <p className="small-text text-uppercase">
                <strong>{t('contract')}</strong>
              </p>
              <div className="contract-details">
                <div
                  id="contract_final"
                  className="clearfix"
                  style={{ padding: '15px' }}>
                  <div dangerouslySetInnerHTML={{ __html: contractTexts }} />
                  {/* <ReactMarkdown children={contractTexts?.toString('markdown')} components={{img: ({node, ...props}) => <img style={{maxWidth: '200px'}} {...props} />}}/> */}
                  {/* <div
                    className="float-end"
                    style={{ textAlign: 'center', width: '150px' }}>
                    <img
                      alt=""
                      src={employeeSign}
                      style={{ width: '175px', height: '75px' }}
                    />
                    <p>Signature</p>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg="4" xl="3">
              <form
                className="form form-contract"
                onSubmit={formik.handleSubmit}>
                <Input
                  type="text"
                  label={t('FirstName')}
                  placeholder={t('FirstName')}
                  name="fName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.fName}
                  errors={formik.errors.fName}
                  value={formik.values.fName}
                />
                <Input
                  type="text"
                  label={t('LastName')}
                  placeholder={t('LastName')}
                  name="lName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.lName}
                  errors={formik.errors.lName}
                  value={formik.values.lName}
                />
                <div className="form-label">{t('contract.signHere')}</div>
                <div>
                  {getSignaturePad}
                  {/* <Button
                  type="button"
                  label="Clear"
                  onClick={clear}
                /> */}
                  <p
                    className="small-text d-inline-block"
                    role="button"
                    style={{ color: '#C91414' }}
                    onClick={clear}>
                    {t('contract.clearSignature')}
                  </p>
                </div>
                <div className="form-bottom justify-content-end">
                  <Button
                    disabled={
                      formik.errors.fName ||
                      formik.errors.lName ||
                      invalidSign ||
                      contractUploading
                    }
                    type="submit"
                    label={t('contact.btnSubmit')}
                    className="btn"
                  />
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractModal;
