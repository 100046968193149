/* eslint-disable no-undef */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { GlobalProvider } from './context/GlobalContext';
import Loader from './components/Core/Loader';
import App from './App';
// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default-member
import reportWebVitals from './reportWebVitals';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import './scss/main.scss';
import './i18n';
import { overrideGeocodeImplementation } from '@dsr/react-places-autocomplete';

require('dotenv').config();
overrideGeocodeImplementation(process.env.REACT_APP_API_V2_URL);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk)),
);

ReactDOM.render(
  <Suspense fallback={Loader}>
    <React.StrictMode>
      <Provider store={store}>
        <GlobalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GlobalProvider>
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
