import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../Core/Button';
// import { useHistory } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
const StartNowModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const { showLoginModal, setShowLoginModal } = useContext(GlobalContext);
  const { showEmployerRegisterModal, setShowEmployerRegisterModal } =
    useContext(GlobalContext);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      className="employee-login-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('startnowlabel')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <strong>{t('startnowmessage')}</strong>
          </p>
          <div className="float-end mt-5">
            <Button
              type="button"
              label={t('register')}
              className="btn primary-btn"
              onClick={(event) => {
                onHide();
                setShowEmployerRegisterModal({
                  ...showEmployerRegisterModal,
                  visible: true,
                });
              }}
            />
            <Button
              type="button"
              label={t('login')}
              className="btn primary-btn startNowLoginBtn"
              onClick={(event) => {
                onHide();
                setShowLoginModal({
                  ...showLoginModal,
                  visible: true,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StartNowModal;
