import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';
import closeIcon from '../../assets/images/icons/close.svg';
import arrowIcon from '../../assets/images/icons/arrow.svg';
import defaultAvatar from '../../assets/images/default-avatar.png';

const StaffMedia = ({ binaryImages, readonly }) => {
  const [modalImg, setModalImg] = useState(null);
  const [images, setImages] = useState([]);
  const { setShowEmployeeImageModal } = useContext(GlobalContext);
  const { t } = useTranslation();
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 1,
      zIndex: 1040,
      background: 'rgba(63, 63, 70, .9)',
    },
    content: {
      position: 'absolute',
      display: 'flex',
      justifyConent: 'center',
      alignItems: 'center',
      transform: 'translate(0%, 0%)',
      background: 'transparent',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      border: 'none',
      outline: 'none',
    },
  };
  const imageStyle = {
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  };

  const ImagesInfoPopover = (
    <Popover>
      <Popover.Body>
        <h5>{t('staffProfile.image')}</h5>
        <p>{t('staffImageModal.imageNote')}</p>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const imgs = binaryImages.map((img, id) => {
      if (img && typeof img.image === 'string') {
        return {
          id,
          image: img.image,
        };
      }
      if (typeof img === 'string') {
        return {
          id,
          image: img,
        };
      }
      return {
        id: img.id,
        image:
          typeof img.image === 'string'
            ? img.image
            : URL.createObjectURL(img.image),
      };
    });
    setImages(imgs);
  }, [binaryImages]);

  const renderImages = () => {
    return images.slice(1).map((file, id) => {
      return (
        <Col xs={3} key={id}>
          <div className="position-relative">
            <img
              src={file.image}
              alt="Profile"
              onClick={() => setModalImg(id + 1)}
              loading="lazy"
            />
          </div>
        </Col>
      );
    });
  };

  return (
    <div className={`employee-registration__profile__wrap`}>
      <div className={`employee-registration__profile `}>
        <Row className="g-3">
          {images?.length ? (
            <>
              <Col xs={12}>
                <div className="position-relative employee-profile-image--big ">
                  <img
                    src={images[0].image}
                    onClick={() => setModalImg(0)}
                    alt="Profile"
                    loading="lazy"
                  />
                </div>
              </Col>
              {renderImages()}
              {!readonly && (
                <div className="employee-registration__profile-image-edit-btn-container">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEmployeeImageModal(true);
                    }}
                    className="btn primary-btn">
                    {t('staffProfile.editImages')}
                  </button>
                  <div className="images-info">
                    {ImagesInfoPopover && (
                      <OverlayTrigger
                        placement="auto-start"
                        delay={{ show: 100, hide: 400 }}
                        overlay={ImagesInfoPopover}>
                        <svg
                          width="22"
                          height="23"
                          xmlns="http://www.w3.org/2000/svg"
                          className="popover-icon">
                          <g
                            transform="translate(0 .5)"
                            fill="none"
                            fillRule="evenodd">
                            <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                            <path
                              d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                              fill="#FFF"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : !readonly ? (
            <>
              <Col xs={12}>
                <div
                  className="employee-profile-image employee-profile-image--big plus-icon"
                  onClick={() => setShowEmployeeImageModal(true)}>
                  <p className="upload-image-info-text">
                    {t('uploadImageInfo')}
                  </p>
                </div>
              </Col>
              <Col xs={3}>
                <div className="employee-profile-image"></div>
              </Col>
              <Col xs={3}>
                <div className="employee-profile-image"></div>
              </Col>
              <Col xs={3}>
                <div className="employee-profile-image"></div>
              </Col>
              <Col xs={3}>
                <div className="employee-profile-image"></div>
              </Col>
              <Col xs={3}>
                <div className="employee-profile-image"></div>
              </Col>
            </>
          ) : (
            <Col xs={12}>
              <div className="position-relative employee-profile-image--big">
                <img src={defaultAvatar} alt="" />
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Modal
        isOpen={modalImg !== null}
        onRequestClose={() => setModalImg(null)}
        style={customStyles}>
        <div
          onClick={() => setModalImg(null)}
          className="delete-image"
          style={{
            position: 'absolute',
            top: '0px',
            right: '5px',
            height: '10px',
            cursor: 'pointer',
          }}>
          <img src={closeIcon} alt="" />
        </div>
        <img
          src={arrowIcon}
          className="modalImageNext"
          alt=""
          onClick={() => setModalImg((img) => ++img % images.length)}
        />
        <img
          src={arrowIcon}
          className="modalImagePrev"
          alt=""
          onClick={() =>
            setModalImg((img) => (img - 1 < 0 ? images.length - 1 : img - 1))
          }
        />
        {images[modalImg] && (
          <img
            src={images[modalImg].image}
            alt="Profile"
            className="modalPreviewImage"
            style={imageStyle}
          />
        )}
      </Modal>
    </div>
  );
};

export default StaffMedia;
