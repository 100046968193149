import React from 'react';
// import GlobalContext from '../context/GlobalContext';
import Head from '../components/Head';
import { Container, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import Button from '../components/Core/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  return (
    <section className="workpage-section content-section">
      <Head title={t('title.termsAndConditions')} />
      <Container>
        <div className="box-wrapper">
          <h1 style={{ 'margin-bottom': '20px' }}>{t('terms')}</h1>
          <Card>
            <Card.Body>
              <Card.Title>Allgemeine Geschäftsbedingungen</Card.Title>
              <Card.Subtitle className="mb-2 text-muted text-capitalize">
                Superstaff eine Marke der CGK Live Marketing für
                Personalbereitstellung
                <br />
              </Card.Subtitle>
              <Card.Text>
                <div>
                  1. Die nachstehenden Geschäftsbedingungen gelten für
                  Vertragsverhältnisse, die Superstaff eine Marke der CGK Live
                  Marketing GmbH & Co KG (Auftragnehmer) im Rahmen der
                  Personaldienstleistung eingeht. Bei Kollision mit
                  Geschäftsbedingungen des Vertragspartners (Auftraggeber) gehen
                  die nachstehenden Geschäftsbedingungen vor. <br />
                  <br />
                  2. Die Erfüllung des Vertrages erfolgt durch arbeitswillige
                  und für die jeweilige Dienstleistung geeignete Arbeitskräfte
                  entsprechend den in dem mit dem Auftragnehmer vereinbarten
                  Vertrag erteilten Umfang des Auftraggebers. <br />
                  <br />
                  3. Die Rechnungslegung erfolgt vorbehaltlich einer
                  ausdrücklichen Vereinbarung eines anderen Zeitraumes im
                  Vertrag prompt nach Buchung der Dienstleistung. Sollte der
                  Dienstleistungsvertrag sich über den Zeitraum von einem Monat
                  erstrecken, erfolgt die Rechnungslegung am Monatsende im
                  Nachhinein. <br />
                  <br />
                  4. Für die Erbringung der im Vertrag festgelegten
                  Dienstleistungen verrechnet der Auftragnehmer vorbehaltlich
                  entgegengesetzter Vereinbarung im Vertrag einen im Vertrag
                  festgelegten Stundensatz (zuzüglich gesetzlicher Umsatzsteuer)
                  pro Arbeitskraft und Stunde bzw. eine Pauschale (zuzüglich
                  gesetzlicher Umsatzsteuer). Über das Ausmaß der Arbeitszeit
                  führt die Arbeitskraft eine Zeitaufzeichnung. Dieser
                  Tätigungsnachweis ist für die Auftragnehmerin auch die
                  Grundlage der Abrechnung der finanziellen Ansprüche des
                  Auftragnehmers gegenüber dem Auftraggeber. Zur Abrechnung
                  kommt die gebuchte Einsatzzeit plus eventueller Mehrarbeit
                  laut Zeitaufzeichnung. <br />
                  <br />
                  5. Der Auftraggeber verpflichtet sich zur prompten Zahlung der
                  gebuchten Dienstleistung, bzw. monatlich oder nach Projektende
                  ohne Abzug 10 Tage nach Rechnungsdatum bzw. nach abweichender
                  schriftlicher Vereinbarung. Der Auftragnehmer ist im Falle des
                  Zahlungsverzuges des Auftraggebers berechtigt, 14 %
                  Verzugszinsen pro Jahr sowie anfallende Inkasso- und
                  Rechtsanwaltskosten zu begehren. Ein Aufrechnungsverbot des
                  Auftraggebers wird ausdrücklich vereinbart. Eingehende
                  Zahlungen werden immer auf die älteste offene Faktura
                  angerechnet. <br />
                  <br />
                  6. Fahrtkostenersatz: Bei Einsätzen, die außerhalb von Wien,
                  Linz, Salzburg-Stadt, Innsbruck, Klagenfurt oder Graz
                  durchgeführt werden oder nach Rücksprache mit dem Kunden
                  überregional abgedeckt werden müssen, kalkuliert die
                  Superstaff online Anwendung einen Standardwert auf Basis der
                  eingegeben Daten. Sollten diese mit den tatsächlichen
                  Mitarbeiter Adressdaten nicht korrelieren werden diese nach
                  dem Einsatz nachverrechnet. Die Fahrtenkostenberechnung
                  erfolgt vom Ausgangspunkt der Mitarbeiter Heimadresse zum
                  Zielpunkt der Einsatzadresse. Die Berechnung erfolgt mit Hilfe
                  von Google Maps <br />
                  <br /> An- und Abfahrtsstunde: Die An- und Abfahrtsstunde wird
                  mit EUR 23,00 pro Mitarbeiter verrechnet. Kilometergeld: Für
                  das eingesetzte KFZ wird pro Kilometer EUR 0,55 verrechnet.
                  <br />
                  <br /> Bei Einsätzen die mehr als 120 km vom Mitarbeiter:innen
                  Wohnort entfernt sind, ist ein Taggeld von EUR 26,40 pro
                  Mitarbeiter zu vergüten. Ggf. ist eine Unterkunft kostenfrei
                  zur Verfügung zu stellen (nach vorheriger Absprache mit dem
                  Auftragnehmer.) Sollte im Rahmen der Erfüllung des Vertrages
                  die Übernachtung der Arbeitskräfte notwendig sein, so werden
                  den MitarbeiterInnen die Kosten der Übernachtung inklusive
                  Frühstück in einem 3 Sterne Hotel ersetzt. Die Abrechnung der
                  Spesen erfolgt über den Auftragnehmer unter Auflistung der
                  entsprechenden Aufwendungen (Zugtickets, Fahrscheine,
                  Hotelrechnungen, Kilometergeld, etc.). <br />
                  <br />
                  Sollte die Arbeitskraft mit der Bahn anreisen, werden ihr die
                  Kosten eines Zugtickets 2. Klasse ersetzt. Bei Benutzung der
                  öffentlichen Verkehrsmittel werden deren Kosten verrechnet.{' '}
                  <br />
                  <br />
                  7. Der Auftraggeber darf mit einer Arbeitskraft des
                  Auftragnehmers während der Dauer des Vertragsverhältnisses und
                  zwölf Monate nach dessen Ende ein Arbeitsverhältnis oder ein
                  Beschäftigungsverhältnis in anderer Form weder direkt noch
                  über Dritte nur mit schriftlicher Zustimmung des
                  Auftragnehmers vereinbaren. Verstößt der Auftraggeber gegen
                  diese Vereinbarung, ist er verpflichtet, dem Auftragnehmer
                  einen pauschalierten Schadenersatzbetrag in der Höhe von €
                  5.000,00 pro Dienstnehmer zu leisten.
                  <br />
                  <br /> 8. Im Sinne des Datenschutzes sind Sedkarten und
                  Informationen über Mitarbeiter besonders vertraulich zu
                  behandeln und nicht an Dritte weiterzugeben.
                  <br />
                  <br /> 9. Der Auftragnehmer haftet nicht für Schäden, die
                  durch leichte Fahrlässigkeit seiner Arbeitskräfte bei der
                  Erfüllung vertraglicher Verpflichtungen entstehen sollten und
                  auch nicht für Folgeschäden. Der Auftragnehmer haftet weiters
                  auch nicht für Schäden, die in Folge der Erfüllung einer
                  Weisung des Auftraggebers durch die Arbeitskräfte entstehen.
                  <br />
                  <br />
                  10. Fällt eine Arbeitskraft des Auftragnehmers für die
                  Erfüllung des Vertrages aus wichtigen Gründen, insbesondere
                  infolge von Krankheit, aus, so hat sich der Auftragnehmer um
                  einen entsprechenden Ersatz zu bemühen. Gelingt es nicht, eine
                  Ersatzarbeitskraft zu organisieren, so wird der Auftragnehmer
                  den Auftraggeber unverzüglich davon verständigen; in diesem
                  Fall hat der Auftraggeber für den Teil der
                  Personaldienstleistungen, die durch die ausgefallene
                  Arbeitskraft durchgeführt werden sollten, kein Entgelt zu
                  zahlen. Darüber hinausgehende Ansprüche des Auftraggebers
                  bestehen im Zusammenhang mit dem Ausfall einer Arbeitskraft
                  nicht. <br />
                  <br />
                  11. Der Haftungsanspruch gegen den Auftragnehmer erlischt,
                  wenn der Auftraggeber den Schaden nicht unverzüglich
                  schriftlich anzeigt und ihm im Falle der Ablehnung der Haftung
                  durch den Auftragnehmer nicht binnen sechs Monaten gerichtlich
                  geltend macht.
                  <br />
                  <br /> 12. Die Punkte 9 und 10 gelten sinngemäß auch für die
                  Geltendmachung von Gewährleistungsansprüchen. Schadenersatz-
                  und Gewährleistungsansprüche, die über die in den Punkten 9
                  und 10 festgelegten Grenzen hinausgehen, werden ausdrücklich
                  ausgeschlossen. <br />
                  <br />
                  13. Die Arbeitskräfte tragen die im Vertrag vorgesehene
                  Arbeitskleidung. Der Auftraggeber ist damit einverstanden,
                  dass die Arbeitskräfte an ihrer Oberbekleidung einen Anstecker
                  befestigen, der auf das Unternehmen des Auftragnehmers
                  hinweist. <br />
                  <br />
                  14. Der Auftraggeber und seine Mitarbeiter verpflichten sich
                  gegenüber dem Auftragnehmer zur Wahrung aller Betriebs- und
                  Geschäftsgeheimnisse gegenüber jedermann und zu jeder Zeit.
                  Ebenso verpflichtet sich der Auftragnehmer gegenüber dem
                  Auftraggeber zur Wahrung aller Betriebs- und
                  Geschäftsgeheimnisse gegenüber jedermann und zu jeder Zeit.
                  Insbesondere wird der Auftraggeber die Daten der
                  Arbeitskräfte, die ihm im Rahmen der Zusammenarbeit mit dem
                  Auftragnehmer bekannt werden, an keinen Dritten weitergeben
                  sowie nach Beendigung des Vertragsverhältnisses Aufzeichnungen
                  über diese Daten dem Auftragnehmer übergeben oder vernichten.
                  <br />
                  <br />
                  15. Bei Erhöhung der Personalkosten aufgrund gesetzlicher
                  (Finanzamt, Gebietskrankenkasse, etc.) oder
                  kollektivvertraglicher Maßnahmen ist der Auftragnehmer
                  berechtigt, die vereinbarten Verkaufspreise auch während eines
                  laufenden Projektes und ab jenem Zeitpunkt zu erhöhen, ab dem
                  die gesetzliche oder kollektivvertragliche Maßnahme wirksam
                  wird. <br />
                  <br />
                  16. Im Falle einer Stornierung des Auftrags seitens des
                  Auftraggebers fallen Stornokosten in folgender Höhe an: bis 14
                  Tage vor Projektstart 25% der Projektsumme bis 7 Tage vor
                  Projektstart 50% der Projektsumme bis 5 Tage vor Projektstart
                  80% der Projektsumme Ab 5 Tage vor Projektstart und während
                  des laufenden Projekts 100% der Projektsumme. <br />
                  <br />
                  17. Der Auftragnehmer hat das Recht, diesen Vertrag an Dritte
                  auftragnehmerseitig zu übertragen. <br />
                  <br />
                  18. Dieser Vertrag unterliegt österreichischem Recht. Für alle
                  Streitigkeiten, die sich im Zusammenhang mit diesem Vertrag
                  ergeben, ist das sachlich zuständige Gericht in Wien
                  zuständig. <br />
                  <br />
                  19. Mindestabnahmezeit / Einsatzdauer: In der angeführten
                  Personalkategorie sind mindestens 4 Arbeitsstunden pro
                  Mitarbeiter pro Schicht zu berechnen. Ab der 8. Einsatzstunde
                  verrechnen wir einen 50%igen Aufschlag auf die Normalstunde.
                  <br />
                  <br />
                  20. Zuschläge / Zulagen / Aufwendungen / Feiertage:
                  <br /> Am 24.12. und 31.12. sowie an gesetzlichen Feiertagen
                  werden die geleisteten Stunden von CGK Mitarbeitern mit einem
                  Aufschlag von 100% verrechnet. Es werden alle anfallenden
                  Kosten wie kollektivvertraglich oder in einer
                  Betriebsvereinbarung geregelt (z.B. Sonn- &
                  Feiertagszuschläge, Zulagen, Zuschläge, etc.), wie folgt im
                  Nachhinein weiterverrechnet: <br />
                  Sozialversicherungsfrei (Faktor 1,1)
                  <br />
                  Sozialversicherungspflichtig (Faktor 2,0)
                  <br />
                  Sonderzahlungspflichtig (Faktor 2,0)
                  <br />
                  <br /> Ostersonntag, Ostermontag, Staatsfeiertag, Christi
                  Himmelfahrt, Pfingstmontag, Fronleichnam, Maria Himmelfahrt,
                  Nationalfeiertag, Allerheiligen, Maria Empfängnis, Heiliger
                  Abend, Christtag, Stephanitag, Silvester, Neujahrstag,
                  Dreikönigstag <br />
                  <br />
                  <br />
                  Stand 2022
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </section>
  );
};

export default TermsAndConditions;
