import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import i18n from '../../i18n';

function EmployeeThankYouModal({
  showEmployeeThankYouModal,
  setShowEmployeeThankYouModal,
}) {
  const history = useHistory();

  return (
    <Modal
      show={showEmployeeThankYouModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShowEmployeeThankYouModal(false);
        history.push('/');
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{i18n.t('allset')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-uppercase">
          <strong>{i18n.t('thankforregistration')}</strong>
        </p>
        <p>
          <br /> {i18n.t('afterreview')}
        </p>
        <p> {i18n.t('viaemail')}</p>
      </Modal.Body>
    </Modal>
  );
}

export default EmployeeThankYouModal;
