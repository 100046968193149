import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import Table from './Table';
import { fetchCurrentEvents } from '../../actions/eventAction';
import { stringDateSort } from '../../utils/helper';

const CurrentEvents = () => {
  const dispatch = useDispatch();
  const eventsData = useSelector((state) => state.events);
  const currentEvents = eventsData.currentEvents;
  const { currPage, pageCount, events } = currentEvents;
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // if (events && events.length) {
    //   return;
    // }
    dispatch(fetchCurrentEvents({ currPage: 1 }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
        sortType: (rowA, rowB, columnId) =>
          stringDateSort(
            rowA.original[columnId],
            rowB.original[columnId],
            t('to'),
          ),
      },
      {
        Header: () => {
          return `${t('event.status')}`;
        },
        accessor: 'timeReportConfirmation',
      },
    ];
  }, []);

  const renderTable = (events) => {
    const currentDate = new Date();
    const yesterdayDate = currentDate.getTime() - 24 * 60 * 60 * 1000;
    const newEvents = [];
    events.map((event, id) => {
      let positionCount = 0;
      let confirmPositionCount = 0;
      let bookedPositionCount = 0;
      let jobStartDates = [];
      let jobEndDates = [];
      let allJobsConfirmed = [];

      let outstandingAmount = 0;
      let outStandingPaid = 0;
      event?.jobs?.map((job) => {
        job.jobPositions.map((jobPosition, key) => {
          positionCount++;
          if (jobPosition.isConfirmed === true) {
            confirmPositionCount++;
          }
          if (jobPosition.booked) {
            bookedPositionCount++;
          }
          if (
            jobPosition.employerData.outstandingAmount > 0 &&
            jobPosition?.employerData?.paidOutstandingAmountValue === 0
          ) {
            outstandingAmount++;
          } else {
            outStandingPaid++;
          }

          jobStartDates.push(jobPosition.jobStart);
          jobEndDates.push(jobPosition.jobEnd);
          allJobsConfirmed.push(jobPosition.isConfirmed);
        });
      });

      const eventEndDate = jobEndDates.reduce(
        (previousValue, currentValue) =>
          previousValue < currentValue ? currentValue : previousValue,
        jobEndDates[0],
      );
      const eventStartdate = jobStartDates.reduce(
        (previousValue, currentValue) =>
          previousValue > currentValue ? currentValue : previousValue,
        jobStartDates[0],
      );
      if (
        event &&
        bookedPositionCount !== 0 &&
        bookedPositionCount < positionCount
      ) {
        event.eventStatus = `${t('event.inPreparation')}`;
      } else if (
        eventStartdate < currentDate.getTime() &&
        confirmPositionCount !== positionCount
      ) {
        event.eventStatus = `${t('event.confirmationNeeded')}`;
      } else if (
        positionCount === bookedPositionCount &&
        eventStartdate > currentDate.getTime()
      ) {
        event.eventStatus = `${t('event.eventReady')}`;
      } else if (outstandingAmount > 0) {
        event.eventStatus = `${t('event.OutstandingBalance')}`;
      } else if (
        (positionCount === confirmPositionCount &&
          eventEndDate < yesterdayDate) ||
        outStandingPaid === positionCount
      ) {
        event.eventStatus = `${t('event.confirmed')}`;
      }
      if (
        bookedPositionCount > 0 &&
        event.isConfirmed === false
        // (outstandingAmount > 0 || eventEndDate > yesterdayDate)
      ) {
        const e1 = {
          ...event,
          eventName: event.eventName,
          date:
            jobStartDates && jobEndDates
              ? moment(eventStartdate)
                  .locale(i18n.language)
                  .format('dddd DD.MM.yyy') +
                ` ${t('to')} ` +
                moment(eventEndDate)
                  .locale(i18n.language)
                  .format('dddd DD.MM.yyyy')
              : '',
          timeReportConfirmation: event.eventStatus,
        };
        newEvents.push(e1);
      }
    });

    return (
      <>
        <Table columns={columns} data={newEvents} isCurrent={true} />
        {currPage >= pageCount ? (
          <></>
        ) : (
          <>
            {loadMoreLoading ? (
              <div className="d-flex align-items-center justify-content-center px-2 py-2">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t('event.loading')}</span>
                </Spinner>
              </div>
            ) : (
              <div className="load-more">
                <span
                  className="load-more-link"
                  onClick={() => {
                    setLoadMoreLoading(true);
                    dispatch(
                      fetchCurrentEvents({
                        currPage: currPage + 1,
                        callback: () => setLoadMoreLoading(false),
                      }),
                    );
                  }}>
                  {t('loadMore')}
                </span>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="table-responsive">
        {eventsData.loading ? (
          <div className="d-flex align-items-center justify-content-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t('event.loading')}</span>
            </Spinner>
          </div>
        ) : (
          <>
            {events && events.length ? (
              <>{renderTable(events)}</>
            ) : (
              <div className="d-flex  py-4">
                <h5>{t('event.noEventsFound')}</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CurrentEvents;
