import axios from 'axios';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_V2_URL}`,
  responseType: 'json',
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.error?.name === 'TokenExpiredError') {
      localStorage.removeItem('token');
      delete API.defaults.headers.common.Authorization;
      window.location.replace('/');
    }
    return Promise.reject(error);
  },
);

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete API.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default API;
