export const zipcodesMap = [
  {
    state: 'Vienna (Wien)',
    zipcodes: [
      1010, 1020, 1030, 1040, 1010, 1050, 1060, 1040, 1070, 1070, 1010, 1080,
      1090, 1090, 1010, 1180, 1100, 1030, 1040, 1110, 1110, 1030, 1100, 1120,
      1130, 1130, 1120, 1140, 1140, 1130, 1160, 1150, 1140, 1160, 1170, 1180,
      1190, 1180, 1170, 1190, 1190, 1090, 1180, 1210, 1200, 1210, 1220, 1220,
      1210, 1230, 1100,
    ],
  },
  {
    state: 'Lower Austria (Niederösterreich)',
    zipcodes: [
      4300, 3350, 4482, 4303, 4392, 4431, 4432, 4300, 4441, 4482, 2000, 2003,
      2002, 2032, 2003, 2011, 2105, 2004, 2003, 2011, 2000, 2013, 2020, 2042,
      2020, 2014, 2031, 2032, 2024, 2033, 2023, 2034, 2063, 2041, 2022, 2023,
      2042, 2042, 2051, 2073, 2052, 2053, 2054, 2054, 2053, 2061, 2062, 2070,
      2074, 2081, 2073, 2074, 2070, 2083, 2081, 2082, 2092, 2084, 2081, 2091,
      3742, 3752, 2091, 2093, 2094, 3752, 3753, 2095, 2094, 2100, 2100, 2105,
      2111, 2100, 2102, 2100, 2102, 2201, 2202, 2103, 1210, 2100, 2102, 2104,
      2111, 2112, 2114, 2113, 2115, 2113, 2116, 2116, 2115, 2120, 2122, 2122,
      2123, 2123, 2125, 2123, 2112, 2114, 2124, 2125, 2126, 2125, 2130, 2132,
      2192, 2133, 2134, 2141, 2135, 2136, 2136, 2064, 2133, 2135, 2143, 2144,
      2145, 2151, 2152, 2032, 2153, 2154, 2154, 2162, 2163, 2164, 2163, 2165,
      2170, 2135, 2141, 2161, 2193, 2171, 2172, 2181, 2182, 2183, 2184, 2185,
      2191, 2223, 2193, 2130, 2185, 2201, 2202, 2203, 2211, 2212, 2213, 2231,
      2214, 2221, 2222, 2223, 2224, 2225, 2230, 2231, 2232, 2232, 2232, 2241,
      2242, 2243, 2215, 2223, 2244, 2245, 2251, 2253, 2261, 2252, 2262, 2263,
      2264, 2265, 2272, 2273, 2274, 2275, 2276, 2281, 2282, 2282, 2282, 2283,
      2284, 2285, 2286, 2291, 2293, 2292, 2294, 2293, 2294, 2295, 2301, 2301,
      2304, 2304, 2305, 2304, 2320, 2320, 1300, 2322, 2325, 2326, 2326, 2331,
      2334, 2332, 2333, 2340, 2344, 2345, 2351, 2352, 2353, 2353, 2361, 2351,
      2362, 2351, 2371, 2393, 2372, 2380, 1230, 2345, 2381, 2384, 2391, 2393,
      2392, 2393, 2401, 2402, 2403, 2404, 2405, 2405, 2410, 2412, 2413, 2431,
      2431, 2433, 2432, 2434, 2435, 2435, 2440, 2452, 2440, 2441, 2440, 2441,
      2441, 2440, 2443, 2444, 2451, 2451, 2452, 2434, 2453, 2434, 2454, 2463,
      2460, 2462, 2464, 2465, 2471, 2472, 2471, 2473, 2481, 2482, 2483, 2442,
      2486, 2485, 2490, 2601, 2603, 2492, 2493, 2601, 2604, 2492, 2491, 2493,
      2700, 7202, 2500, 2504, 2511, 2514, 2512, 2521, 2522, 2523, 2524, 2525,
      2525, 2544, 2602, 2531, 2532, 2500, 2534, 2533, 3033, 3053, 2534, 2500,
      2532, 2533, 2565, 2540, 2542, 2540, 2544, 2544, 2551, 2544, 2552, 2552,
      2560, 2560, 2563, 2564, 2564, 2564, 2565, 2571, 2571, 2564, 2565, 2572,
      2572, 2601, 2602, 2602, 2603, 2604, 2620, 2620, 2620, 2624, 2625, 2822,
      2630, 2640, 2630, 2620, 2631, 2632, 2732, 2733, 2734, 2630, 2632, 2640,
      2632, 2620, 2630, 2632, 2640, 2632, 2640, 2671, 2640, 2630, 2640, 2641,
      2650, 2640, 2671, 2673, 2651, 2662, 2661, 2663, 2673, 2641, 2651, 2680,
      2673, 2700, 2751, 2752, 2721, 2700, 2752, 2722, 2700, 2722, 2723, 2731,
      2724, 2722, 2723, 2731, 2624, 2732, 2731, 2732, 2732, 2724, 2733, 2733,
      2733, 2734, 2733, 2761, 2751, 2603, 2752, 2751, 2753, 2721, 2754, 2724,
      2753, 2755, 2761, 2761, 2763, 2763, 2761, 2763, 2761, 2770, 2801, 2700,
      2802, 2803, 2802, 2811, 2803, 2812, 2813, 2833, 2813, 2812, 2842, 2851,
      2821, 7212, 2822, 2823, 2833, 2822, 2802, 2821, 2823, 2822, 2824, 2831,
      2831, 2813, 2832, 2833, 2831, 2840, 2880, 2833, 2802, 2811, 2813, 2822,
      2840, 2831, 2842, 2840, 2842, 2813, 2840, 2851, 2870, 2851, 2852, 2853,
      2871, 2852, 2853, 2860, 2812, 2851, 2853, 2870, 2870, 2872, 2873, 2871,
      2851, 2852, 2870, 8244, 2872, 7421, 2873, 2870, 2880, 2880, 2640, 2870,
      2881, 2880, 2880, 2870, 2881, 3001, 1140, 3400, 3002, 1140, 3011, 3012,
      3003, 3004, 3012, 2381, 3021, 3013, 3004, 3011, 3021, 3443, 3021, 2533,
      3012, 3013, 3031, 3032, 3033, 3443, 3032, 3033, 3032, 3051, 3052, 3053,
      3034, 3032, 3033, 3040, 3040, 3034, 3051, 3061, 3072, 3041, 3034, 3443,
      3451, 3042, 3452, 3052, 3073, 3053, 3052, 3073, 3062, 3061, 3071, 3072,
      3140, 3072, 3071, 3074, 3144, 3073, 3053, 3072, 3074, 3074, 3073, 3100,
      3104, 3105, 3107, 3140, 3151, 3385, 3110, 3121, 3123, 3124, 3122, 3125,
      3125, 3130, 3454, 3131, 3133, 3134, 3141, 3142, 3143, 3071, 3104, 3144,
      3150, 3153, 3160, 3161, 3150, 3160, 3162, 3163, 3163, 3170, 2572, 3171,
      2663, 3163, 3192, 3172, 3170, 3180, 3184, 3180, 3183, 3192, 3183, 3193,
      3195, 3222, 8630, 8694, 3200, 3202, 3150, 3203, 3233, 3203, 3204, 3205,
      3211, 3204, 3212, 3213, 3212, 3214, 3214, 3221, 3223, 3222, 3223, 3224,
      3223, 3231, 3232, 3233, 3233, 3203, 3240, 3240, 3233, 3241, 3240, 3243,
      3242, 3241, 3243, 3240, 3241, 3244, 3243, 3252, 3250, 3250, 3251, 3252,
      3281, 3371, 3373, 3251, 3252, 3253, 3380, 3381, 3254, 3261, 3325, 3371,
      3261, 3371, 3262, 3325, 3263, 3264, 3340, 3364, 3264, 3264, 3341, 3264,
      3270, 3283, 3281, 3241, 3242, 3282, 3242, 3283, 3213, 3214, 3242, 3270,
      3282, 3292, 3292, 3223, 3264, 3291, 3293, 3294, 3295, 8924, 3293, 3300,
      3311, 3361, 3362, 3363, 3300, 3363, 3304, 3311, 3300, 3312, 3313, 3321,
      3362, 3313, 3314, 3354, 3321, 3300, 3322, 3323, 3324, 3325, 3372, 3331,
      3361, 3332, 3331, 3333, 3340, 3340, 3263, 3341, 3340, 3342, 3341, 3343,
      3344, 3345, 3350, 3351, 3352, 3350, 4442, 4443, 3353, 3331, 3332, 3333,
      3361, 3353, 3354, 3314, 3355, 3361, 3362, 3312, 3361, 3364, 3365, 3370,
      3374, 3371, 3370, 3373, 3372, 3375, 3374, 3376, 3380, 3253, 3381, 3382,
      3382, 3390, 3383, 3382, 3384, 3385, 3385, 3384, 3385, 3386, 3390, 3392,
      3122, 3382, 3642, 3393, 3244, 3253, 3390, 3400, 1140, 1170, 1190, 3413,
      3420, 3421, 3423, 3413, 3422, 3424, 3426, 3430, 3425, 3433, 3434, 3001,
      3430, 3435, 3454, 3441, 3442, 3435, 3443, 3004, 3041, 3434, 3441, 3451,
      3451, 3441, 3452, 3452, 3454, 3462, 3463, 3430, 3464, 3701, 3464, 2000,
      3465, 3462, 3701, 3470, 3465, 3474, 3471, 3470, 3472, 3473, 3711, 3481,
      3482, 3484, 3483, 3491, 3492, 3485, 3493, 3494, 3485, 3495, 3500, 3506,
      3541, 3508, 3511, 3512, 3122, 3642, 3512, 3521, 3541, 3610, 3611, 3613,
      3522, 3524, 3542, 3613, 3525, 3524, 3631, 3914, 3532, 3543, 3541, 3500,
      3542, 3521, 3553, 3542, 3532, 3543, 3553, 3572, 3543, 3544, 3550, 3553,
      3561, 3552, 3552, 3500, 3552, 3541, 3562, 3564, 3571, 3573, 3572, 3573,
      3571, 3580, 3744, 3580, 3751, 3580, 3761, 3591, 3592, 3593, 3593, 3594,
      3595, 3601, 3602, 3621, 3610, 3611, 3613, 3620, 3622, 3623, 3622, 3631,
      3664, 3631, 3632, 3633, 3632, 3641, 3643, 3642, 3392, 3643, 3620, 3644,
      3652, 3653, 3650, 3653, 3663, 3652, 3653, 3654, 3650, 3653, 3660, 3652,
      3661, 3652, 3662, 3650, 3661, 3663, 3664, 3665, 3665, 3633, 3665, 3671,
      3672, 3672, 3661, 3680, 3681, 3683, 3684, 3680, 3683, 3691, 3701, 3702,
      3704, 3701, 3710, 3710, 3711, 3712, 3713, 3721, 3714, 3710, 3721, 3720,
      3722, 3730, 3730, 3713, 3730, 3741, 3742, 3743, 3730, 3744, 3730, 3751,
      3751, 3742, 3752, 3753, 3754, 3761, 3762, 3814, 3763, 3814, 3800, 3811,
      3804, 3812, 3813, 3820, 2094, 2095, 3823, 3824, 3822, 3830, 3830, 3902,
      3834, 3841, 3900, 3842, 3830, 3843, 3843, 3844, 3851, 3844, 3851, 3852,
      3852, 3842, 3860, 3834, 3873, 3861, 3860, 3862, 3863, 3874, 3871, 3873,
      3872, 3860, 3874, 3874, 3873, 3900, 3902, 3932, 3903, 3804, 3900, 3902,
      3910, 3532, 3533, 3911, 3923, 3924, 3931, 3932, 3911, 3920, 3912, 3913,
      3910, 3914, 3531, 3920, 3921, 3924, 3921, 3973, 3922, 3923, 3925, 3920,
      3925, 3633, 4372, 3931, 3922, 3923, 3932, 3932, 3942, 3943, 3872, 3944,
      3945, 3950, 3871, 3950, 3961, 3962, 3961, 3945, 3970, 3971, 3970, 3962,
      3970, 3971, 3970, 3972, 3922, 3973,
    ],
  },
  {
    state: 'Upper Austria (Oberösterreich)',
    zipcodes: [
      3334, 3335, 3335, 4464, 8934, 5120, 5121, 5131, 5120, 5121, 5121, 5120,
      5121, 5122, 5121, 5122, 5122, 5134, 5131, 5132, 5121, 5131, 5133, 5133,
      5134, 5141, 5142, 5143, 5142, 5144, 5134, 5144, 5145, 5134, 5280, 5282,
      5163, 5166, 5211, 5212, 5221, 5222, 5222, 5223, 5224, 5225, 5230, 5231,
      5222, 5261, 5232, 5233, 5241, 5242, 5261, 5242, 5241, 5251, 5251, 5241,
      5252, 5272, 5252, 4931, 4932, 4933, 5251, 5261, 5241, 5270, 5271, 5270,
      5271, 4952, 5270, 5272, 5272, 5273, 5272, 5273, 5274, 4963, 5145, 5261,
      5270, 5280, 5282, 5280, 5282, 5310, 4866, 5311, 5310, 5310, 5310, 4893,
      5360, 4020, 4030, 4040, 4052, 4040, 4201, 4048, 4050, 4063, 4053, 4052,
      4055, 4060, 4020, 4061, 4061, 4062, 4063, 4064, 4070, 4070, 4075, 4612,
      4070, 4070, 4081, 4072, 4073, 4060, 4061, 4074, 4070, 4081, 4730, 4731,
      4076, 4732, 4081, 4070, 4074, 4082, 4083, 4084, 4083, 4730, 4085, 4724,
      4090, 4725, 4091, 4092, 4100, 4101, 4113, 4102, 4111, 4112, 4113, 4114,
      4115, 4114, 4120, 4116, 4120, 4121, 4121, 4120, 4122, 4121, 4131, 4114,
      4132, 4121, 4134, 4152, 4132, 4131, 4133, 4131, 4132, 4134, 4132, 4133,
      4141, 4141, 4085, 4142, 4144, 4142, 4085, 4133, 4143, 4085, 4144, 4144,
      4152, 4154, 4150, 4170, 4151, 4152, 4134, 4152, 4150, 4153, 4162, 4154,
      4152, 4155, 4160, 4161, 4161, 4160, 4162, 4163, 4162, 4153, 4155, 4163,
      4164, 4163, 4170, 4170, 4170, 4170, 4171, 4184, 4171, 4170, 4171, 4170,
      4184, 4172, 4184, 4173, 4172, 4174, 4175, 4174, 4113, 4120, 4172, 4173,
      4175, 4175, 4101, 4181, 4180, 4202, 4180, 4181, 4201, 4181, 4182, 4183,
      4184, 4170, 4184, 4182, 4183, 4184, 4184, 4191, 4190, 4191, 4183, 4184,
      4190, 4192, 4193, 4240, 4201, 4202, 4201, 4040, 4100, 4111, 4112, 4202,
      4202, 4040, 4201, 4203, 4040, 4202, 4210, 4204, 4204, 4204, 4180, 4209,
      4222, 4210, 4212, 4210, 4211, 4202, 4210, 4212, 4211, 4221, 4040, 4222,
      4310, 4312, 4222, 4223, 4223, 4224, 4312, 4224, 4225, 4481, 4230, 4224,
      4292, 4232, 4230, 4240, 4240, 4193, 4242, 4192, 4193, 4251, 4263, 4252,
      3925, 4261, 4193, 4240, 4262, 4263, 4264, 4261, 4271, 4251, 4294, 4272,
      4273, 4273, 4274, 4293, 4294, 4280, 4281, 4282, 4282, 4274, 4281, 4283,
      4274, 4293, 4284, 4230, 4293, 4311, 4291, 4240, 4264, 4271, 4292, 4292,
      4212, 4240, 4291, 4293, 4230, 4292, 4294, 4294, 4272, 4293, 4310, 4312,
      4311, 4312, 4312, 4311, 4320, 4284, 4320, 4322, 4320, 4323, 4323, 4342,
      4352, 4324, 4331, 4311, 4320, 4332, 4341, 4323, 4342, 4342, 4341, 4351,
      4343, 4351, 4352, 4352, 4360, 4362, 4323, 4352, 4360, 4363, 4363, 4281,
      4362, 4371, 4372, 4364, 4282, 4322, 4362, 4363, 4371, 4372, 4371, 4381,
      4382, 4391, 4360, 4382, 4392, 4400, 4407, 4400, 4442, 4443, 4451, 4407,
      4484, 4421, 4523, 4594, 4443, 4442, 4463, 4451, 4400, 4461, 4523, 4452,
      4451, 4453, 4596, 4460, 4461, 4460, 4463, 4462, 4463, 4443, 4470, 4481,
      4483, 4484, 4470, 4483, 4490, 4491, 4492, 4491, 4493, 4501, 4053, 4511,
      4531, 4502, 4491, 4521, 4511, 4501, 4622, 4521, 4493, 4522, 4522, 4523,
      4531, 4531, 4532, 4550, 4532, 4531, 4540, 4550, 4533, 4540, 4540, 4552,
      4541, 4542, 4550, 4532, 4540, 4621, 4551, 4643, 4552, 4553, 4554, 4560,
      4562, 4643, 4563, 4564, 4571, 4572, 4565, 4572, 4573, 4572, 4574, 4574,
      4573, 4575, 4575, 4580, 4581, 4582, 4580, 4581, 4580, 4591, 4582, 4591,
      4594, 4592, 4593, 4595, 4596, 4421, 4600, 4600, 4600, 4611, 4612, 4613,
      4631, 4612, 4614, 4615, 4616, 4621, 4622, 4616, 4623, 4600, 4650, 4624,
      4625, 4672, 4625, 4624, 4714, 4631, 4702, 4632, 4631, 4633, 4701, 4702,
      4633, 4632, 4713, 4641, 4642, 4643, 4644, 4645, 4817, 4645, 4644, 4650,
      4650, 4651, 4652, 4641, 4652, 4655, 4653, 4654, 4655, 4656, 4817, 4661,
      4655, 4663, 4656, 4662, 4664, 4816, 4671, 4671, 4672, 4673, 4674, 4676,
      4682, 4692, 4714, 4902, 4675, 4680, 4676, 4680, 4681, 4741, 4682, 4690,
      4690, 4690, 4800, 4846, 4690, 4693, 4690, 4690, 4691, 4690, 4692, 4690,
      4691, 4902, 4693, 4800, 4694, 4812, 4701, 4702, 4611, 4631, 4701, 4707,
      4632, 4710, 4713, 4710, 4732, 4710, 4713, 4715, 4710, 4712, 4715, 4712,
      4720, 4713, 4714, 4625, 4672, 4715, 4716, 4741, 4720, 4720, 4720, 4721,
      4755, 4722, 4722, 4722, 4723, 4762, 4724, 4084, 4085, 4730, 4724, 4722,
      4723, 4725, 4794, 4730, 4712, 4731, 4731, 4070, 4074, 4730, 4732, 4732,
      4733, 4741, 4720, 4742, 4743, 4754, 4751, 4752, 4753, 4751, 4754, 4971,
      4972, 4755, 4760, 4761, 4762, 4771, 4794, 4762, 4760, 4770, 4772, 4771,
      4772, 4752, 4753, 4974, 4773, 4775, 4980, 4774, 4773, 4775, 4774, 4791,
      4776, 4777, 4780, 4782, 4783, 4784, 4784, 4783, 4785, 4791, 4792, 4785,
      4784, 4786, 4791, 4775, 4776, 4792, 4793, 4091, 4725, 4792, 4794, 4723,
      4725, 4793, 4800, 4801, 4802, 4810, 4812, 4810, 4845, 4813, 4801, 4810,
      4812, 4814, 4816, 4664, 4810, 4817, 4817, 4644, 4820, 4821, 5351, 4822,
      4824, 4825, 4830, 4823, 4831, 8972, 4840, 4800, 4841, 4840, 4841, 4850,
      4841, 4850, 4842, 4843, 4844, 4800, 4812, 4845, 4860, 4846, 4849, 4850,
      4841, 4860, 4851, 4871, 4852, 4861, 4853, 4860, 4861, 4814, 4844, 4861,
      4863, 4851, 4880, 4864, 4865, 4866, 4870, 4871, 4892, 4870, 4871, 4890,
      4872, 4850, 4871, 4873, 4873, 4871, 4873, 4880, 4863, 4880, 4881, 4882,
      4890, 4891, 4890, 5204, 4891, 5212, 4892, 4890, 4893, 4894, 4893, 5202,
      4901, 4902, 4903, 4904, 4906, 4910, 4925, 4910, 4941, 4911, 4912, 4920,
      4921, 4922, 4680, 4923, 4924, 4925, 4926, 4931, 4932, 4941, 4910, 4971,
      4942, 4943, 4942, 4943, 4951, 4950, 4951, 4943, 4952, 4950, 4961, 4961,
      4962, 4963, 4962, 5274, 5280, 4970, 4971, 4910, 4972, 4973, 4972, 4754,
      4772, 4971, 4973, 4973, 4973, 4974, 4975, 4980, 4981, 4973, 4974, 4980,
      4982, 4943, 4982, 4973, 4984, 4982, 4983, 4943, 4984,
    ],
  },
  {
    state: 'Salzburg (Salzburg)',
    zipcodes: [
      5020, 5023, 5026, 5061, 5071, 5081, 5082, 5061, 5071, 5081, 5082, 5083,
      5084, 5090, 5091, 5092, 5093, 5101, 5102, 5110, 5111, 5112, 5110, 5113,
      5114, 5151, 5162, 5165, 5152, 5161, 5162, 5163, 5164, 5165, 5201, 5162,
      5202, 5203, 5204, 5205, 5300, 5301, 5302, 5302, 5303, 5321, 5023, 5322,
      5323, 5324, 5324, 5325, 5330, 5340, 4854, 4866, 5310, 5342, 5360, 5350,
      5342, 5351, 5400, 5083, 5422, 5411, 5400, 5412, 5411, 5421, 5323, 5424,
      5423, 5424, 5425, 5431, 5424, 5440, 5440, 5441, 5441, 5442, 5524, 5442,
      5450, 5451, 5452, 5505, 5452, 5450, 5500, 5453, 5500, 5505, 5511, 5521,
      5522, 5524, 5523, 5531, 5532, 5541, 5542, 5550, 8974, 5552, 5561, 5562,
      5563, 5562, 5570, 5571, 5572, 5573, 5574, 5575, 5580, 5581, 5582, 5583,
      5584, 5585, 5591, 8863, 5592, 5600, 5602, 5602, 5603, 5611, 5612, 5620,
      5622, 5621, 5622, 5620, 5621, 5651, 5630, 5632, 5640, 5645, 5651, 5660,
      5652, 5660, 5651, 5662, 5661, 5671, 5662, 5672, 5700, 5710, 5721, 5722,
      5723, 5724, 5730, 5731, 5731, 5733, 5731, 5732, 5741, 5741, 5742, 5743,
      5751, 5752, 5753, 5754, 5760, 5761, 5771,
    ],
  },
  {
    state: 'Tyrol (Tirol)',
    zipcodes: [
      9782, 9900, 9903, 9904, 9905, 9906, 9907, 9908, 9909, 9911, 9912, 9913,
      9918, 9919, 9920, 9931, 9932, 9941, 9942, 9943, 9951, 9952, 9954, 9961,
      9962, 9963, 9971, 9972, 9974, 9981, 9990, 9991, 9992, 6020, 6080, 6060,
      6063, 6065, 6063, 6067, 6068, 6069, 6070, 6071, 6073, 6072, 6073, 6074,
      6075, 6082, 6083, 6091, 6092, 6094, 6095, 6100, 6103, 6100, 6105, 6108,
      6111, 6112, 6113, 6114, 6115, 6116, 6121, 6122, 6123, 6130, 6133, 6134,
      6215, 6135, 6200, 6136, 6141, 6020, 6142, 6143, 6143, 6150, 6143, 6145,
      6150, 6150, 6152, 6154, 6154, 6156, 6157, 6161, 6020, 6091, 6162, 6020,
      6165, 6166, 6167, 6167, 6170, 6173, 6175, 6176, 6176, 6178, 6179, 6181,
      6182, 6184, 6200, 6210, 6212, 6213, 6215, 6215, 6215, 6220, 6222, 6230,
      6232, 6233, 6234, 6235, 6260, 6236, 6240, 6241, 6250, 6252, 6260, 6261,
      6200, 6262, 6261, 6263, 6264, 6263, 6264, 6265, 6271, 6272, 6273, 6274,
      6275, 6275, 6276, 6277, 6278, 6280, 6280, 6280, 6283, 6281, 6283, 6283,
      6290, 6292, 6284, 6290, 6290, 6295, 6292, 6295, 6293, 6294, 6300, 6305,
      6306, 6330, 6351, 6311, 6313, 6314, 6320, 6321, 6322, 6323, 6324, 6330,
      6334, 6335, 6336, 6341, 6330, 6342, 6343, 6344, 6345, 6346, 6347, 6351,
      6352, 6353, 6361, 6314, 6363, 6364, 6365, 6370, 6370, 6352, 6353, 6365,
      6371, 6372, 6373, 6380, 6382, 6383, 6384, 6385, 6391, 6392, 6393, 6395,
      6401, 6402, 6403, 6404, 6405, 6406, 6408, 6170, 6410, 6100, 6413, 6414,
      6416, 6421, 6422, 6182, 6423, 6424, 6183, 6425, 6430, 6433, 6426, 6432,
      6433, 6441, 6444, 6450, 6456, 6458, 6460, 6462, 6463, 6460, 6464, 6465,
      6471, 6460, 6473, 6474, 6473, 6481, 6491, 6492, 6493, 6500, 6500, 6511,
      6653, 6521, 6473, 6500, 6522, 6524, 6525, 6526, 6527, 6528, 6531, 6532,
      6533, 6534, 6541, 6542, 6541, 6542, 6543, 6542, 6544, 6551, 6591, 6552,
      6553, 6553, 6555, 6555, 6553, 6561, 6562, 6563, 6571, 6572, 6574, 6580,
      6591, 6500, 6600, 6600, 6671, 6600, 6600, 6600, 6600, 6600, 6604, 6610,
      6611, 6621, 6622, 6621, 6623, 6631, 6632, 6633, 6642, 6644, 6645, 6646,
      6647, 6650, 6651, 6652, 6653, 6653, 6654, 6654, 6655, 6655, 6767, 6670,
      6671, 6672, 6672, 6673, 6675, 6677, 6677, 6682, 6691,
    ],
  },
  {
    state: 'Vorarlberg (Vorarlberg)',
    zipcodes: [
      6700, 6751, 6700, 6700, 6706, 6707, 6708, 6710, 6820, 6712, 6713, 6714,
      6719, 6721, 6722, 6723, 6731, 6733, 6884, 6741, 6751, 6752, 6764, 6754,
      6762, 6764, 6763, 6767, 6771, 6773, 6774, 6780, 6781, 6782, 6791, 6787,
      6793, 6794, 6800, 6811, 6812, 6820, 6822, 6822, 6822, 6824, 6822, 6822,
      6824, 6830, 6830, 6832, 6832, 6833, 6833, 6833, 6837, 6834, 6835, 6836,
      6840, 6841, 6842, 6844, 6845, 6850, 6858, 6858, 6861, 6863, 6866, 6867,
      6850, 6870, 6870, 6874, 6881, 6882, 6883, 6884, 6886, 6888, 6890, 6900,
      6900, 6911, 6911, 6900, 6912, 6914, 6921, 6922, 6923, 6932, 6933, 6934,
      6941, 6942, 6943, 6951, 6952, 6952, 6960, 6971, 6972, 6890, 6971, 6973,
      6973, 6974, 6991, 6992, 6993,
    ],
  },
  {
    state: 'Burgenland (Burgenland)',
    zipcodes: [
      2413, 2421, 2422, 2423, 2424, 2425, 2443, 2443, 2443, 2460, 2462, 2473,
      2474, 2475, 2485, 2491, 8291, 8292, 8292, 8293, 8380, 8282, 8382, 8382,
      8383, 8350, 8380, 8384, 8385, 8384, 8385, 8384, 8385, 7000, 7011, 7012,
      7013, 7020, 7021, 7021, 7022, 7023, 7023, 7024, 7031, 7032, 7033, 7202,
      7034, 7033, 7035, 7035, 2491, 7041, 7042, 7051, 7052, 7053, 2491, 7061,
      7062, 7063, 7064, 7071, 7072, 7081, 7082, 7083, 7091, 7092, 7093, 7100,
      7111, 2471, 7121, 7100, 7122, 7123, 7131, 7123, 7132, 7141, 7142, 7143,
      7132, 7161, 7151, 7152, 7161, 7162, 7163, 7201, 7202, 7203, 7202, 7210,
      7212, 7221, 7222, 7223, 7301, 7302, 7361, 7304, 7311, 7312, 7321, 7321,
      7321, 7322, 7323, 7331, 7332, 7341, 7341, 2812, 7342, 7343, 7344, 7350,
      7304, 7361, 7452, 7361, 7371, 7372, 7371, 7373, 7374, 7400, 7410, 7411,
      7412, 7422, 7423, 7423, 7423, 7423, 7431, 7432, 2852, 7400, 7431, 7433,
      7434, 2852, 7435, 7434, 7441, 7442, 7444, 7443, 7451, 7453, 7461, 7400,
      7463, 7461, 7503, 7464, 7471, 7472, 7503, 7472, 7473, 7474, 7474, 7501,
      7501, 7501, 7503, 7503, 7511, 7512, 7512, 7521, 7522, 7521, 7522, 7522,
      7531, 7532, 7533, 8292, 7534, 7535, 7535, 7536, 7537, 7540, 7542, 7540,
      7540, 7540, 7540, 7540, 7540, 7542, 7540, 7543, 7544, 7551, 7551, 7551,
      7551, 7552, 7561, 7562, 7563, 7571, 7564, 7572,
    ],
  },
  {
    state: 'Steiermark (Styria)',
    zipcodes: [
      8010, 8020, 8036, 8041, 8042, 8043, 8044, 8045, 8046, 8047, 8051, 8052,
      8053, 8054, 8055, 8073, 8074, 8010, 8044, 8047, 8063, 8301, 8044, 8045,
      8061, 8062, 8046, 8045, 8051, 8052, 8113, 8151, 8054, 8055, 8073, 8144,
      8061, 8062, 8044, 8061, 8063, 8160, 8181, 8063, 8010, 8044, 8062, 8200,
      8301, 8302, 8071, 8072, 8410, 8073, 8401, 8074, 8075, 8047, 8301, 8076,
      8081, 8077, 8081, 8302, 8081, 8072, 8412, 8081, 8082, 8081, 8083, 8083,
      8082, 8091, 8324, 8341, 8421, 8091, 8083, 8421, 8092, 8093, 8421, 8093,
      8342, 8101, 8046, 8102, 8114, 8102, 8163, 8111, 8103, 8112, 8113, 8114,
      8153, 8113, 8151, 8113, 8111, 8112, 8113, 8120, 8114, 8130, 8121, 8103,
      8114, 8122, 8124, 8124, 8122, 8153, 8130, 8121, 8122, 8131, 8132, 8131,
      8614, 8141, 8054, 8143, 8144, 8401, 8142, 8143, 8141, 8142, 8410, 8411,
      8503, 8504, 8144, 8151, 8151, 8052, 8054, 8113, 8144, 8152, 8561, 8152,
      8113, 8151, 8570, 8152, 8113, 8153, 8160, 8160, 8160, 8160, 8061, 8162,
      8160, 8181, 8162, 8102, 8160, 8172, 8163, 8130, 8162, 8171, 8160, 8163,
      8172, 8160, 8181, 8160, 8181, 8160, 8182, 8183, 8212, 8183, 8184, 8223,
      8184, 8172, 8182, 8183, 8190, 8190, 8172, 8184, 8191, 8192, 8190, 8673,
      8200, 8181, 8211, 8200, 8261, 8311, 8321, 8200, 8063, 8200, 8261, 8302,
      8321, 8211, 8181, 8200, 8261, 8212, 8211, 8261, 8262, 8265, 8212, 8182,
      8222, 8221, 8212, 8222, 8223, 8224, 8265, 8223, 8222, 8224, 8224, 8222,
      8265, 8271, 8272, 8273, 8224, 8225, 8225, 8225, 8224, 8230, 8230, 8225,
      8230, 8230, 8225, 8274, 8232, 8225, 8230, 8233, 8234, 8250, 8233, 8230,
      8234, 8233, 8241, 8250, 8240, 8243, 8241, 8242, 8234, 8251, 8243, 2872,
      7421, 7423, 8244, 2852, 2870, 2871, 7421, 8243, 8250, 8234, 8251, 8252,
      8253, 8254, 8252, 8251, 8253, 8254, 8253, 8255, 8673, 8674, 8261, 8200,
      8211, 8262, 8311, 8262, 8261, 8265, 8311, 8263, 8262, 8264, 8362, 8265,
      8264, 8271, 8264, 8272, 8283, 8273, 8272, 8274, 8272, 8294, 8280, 8282,
      8362, 8282, 8280, 8283, 8291, 8292, 8294, 8274, 8293, 8295, 8230, 8294,
      8301, 8075, 8302, 8302, 8063, 8200, 8301, 8323, 8311, 8312, 8312, 8262,
      8321, 8311, 8322, 8323, 8322, 8311, 8332, 8323, 8081, 8082, 8302, 8322,
      8324, 8324, 8082, 8322, 8323, 8332, 8082, 8330, 8332, 8333, 8334, 8344,
      8332, 8311, 8330, 8333, 8312, 8313, 8330, 8334, 8361, 8341, 8324, 8342,
      8342, 8083, 8091, 8330, 8341, 8343, 8345, 8345, 8344, 8343, 8345, 8483,
      8484, 8350, 8313, 8330, 8333, 8352, 8353, 8361, 8362, 8352, 8350, 8361,
      8362, 8353, 8344, 8350, 8354, 8355, 8355, 8493, 8362, 8280, 8401, 8402,
      8403, 8403, 8430, 8410, 8412, 8411, 8403, 8504, 8412, 8081, 8410, 8413,
      8413, 8403, 8413, 8410, 8421, 8413, 8422, 8423, 8421, 8422, 8435, 8481,
      8424, 8430, 8451, 8430, 8451, 8462, 8431, 8435, 8430, 8442, 8441, 8451,
      8443, 8452, 8444, 8441, 8442, 8443, 8505, 8521, 8522, 8451, 8452, 8441,
      8443, 8451, 8453, 8462, 8463, 8453, 8443, 8452, 8454, 8544, 8454, 8455,
      8443, 8453, 8454, 8544, 8461, 8462, 8451, 8461, 8463, 8463, 8452, 8453,
      8454, 8462, 8472, 8423, 8461, 8471, 8473, 8423, 8471, 8480, 8483, 8345,
      8490, 8492, 8492, 8355, 8484, 8493, 8493, 8355, 8492, 8501, 8144, 8502,
      8503, 8511, 8503, 8143, 8504, 8503, 8505, 8411, 8504, 8521, 8510, 8503,
      8504, 8511, 8522, 8524, 8511, 8510, 8562, 8563, 8521, 8444, 8504, 8522,
      8444, 8521, 8523, 8530, 8530, 8510, 8523, 8524, 8541, 8530, 8542, 8542,
      8541, 8543, 8443, 8522, 8542, 8544, 8551, 8544, 8551, 8551, 8541, 8542,
      8552, 8553, 8552, 8455, 8551, 8553, 8554, 8561, 8152, 8564, 8565, 8562,
      8563, 8564, 8564, 8152, 8563, 8570, 8570, 8152, 8564, 8572, 8582, 8572,
      8570, 8580, 8582, 8593, 8573, 8572, 8593, 8580, 8563, 8570, 8583, 8580,
      8572, 8582, 8591, 8592, 8593, 8582, 8570, 8580, 8583, 8580, 8584, 8583,
      9451, 8591, 8580, 8583, 8592, 8593, 8741, 8600, 8605, 8600, 8641, 8642,
      8611, 8612, 8614, 8616, 8621, 8622, 8623, 8624, 8623, 8621, 8624, 8625,
      8624, 8636, 8630, 3294, 8632, 8634, 8635, 8641, 8600, 8642, 8605, 8625,
      8644, 8652, 8650, 8643, 8644, 8652, 8653, 8653, 8654, 8673, 8662, 8661,
      8663, 8664, 8665, 8670, 8680, 8682, 8670, 8662, 8671, 8672, 8671, 8673,
      8673, 8672, 8674, 8674, 8680, 8682, 8684, 2680, 8680, 8685, 8692, 2651,
      2654, 8691, 8693, 8694, 8700, 8792, 8712, 8712, 8713, 8714, 8714, 8720,
      8734, 8720, 8714, 8715, 8723, 8724, 8720, 8740, 8731, 8732, 8720, 8724,
      8733, 8715, 8734, 8592, 8741, 8740, 8741, 8734, 8740, 8742, 8750, 8742,
      9463, 8750, 8742, 8753, 8720, 8755, 8756, 8800, 8761, 8753, 8754, 8762,
      8763, 8762, 8765, 8764, 8763, 8770, 8713, 8772, 8770, 8792, 8773, 8772,
      8792, 8793, 8774, 8775, 8781, 8783, 8781, 8782, 8784, 8785, 8786, 8784,
      8790, 8792, 8793, 8794, 8794, 8795, 8920, 8800, 8811, 8813, 8812, 8820,
      8812, 8822, 9323, 9360, 8822, 8820, 8831, 8811, 8832, 8831, 8833, 8812,
      8841, 8842, 8850, 8843, 8832, 8842, 8844, 8850, 8813, 8842, 8852, 8853,
      8844, 8854, 8861, 8850, 8862, 8862, 8863, 8864, 8900, 8786, 8903, 8900,
      8940, 8904, 8911, 8912, 8913, 8924, 8931, 8920, 8921, 8922, 8923, 8933,
      8911, 8932, 8934, 8934, 8940, 8900, 8942, 8943, 8950, 8951, 8982, 8952,
      8953, 8960, 8961, 8962, 8962, 8962, 8952, 8954, 8960, 8961, 8965, 8962,
      8966, 8965, 8967, 8967, 8966, 8970, 8972, 8970, 8971, 8973, 8974, 8972,
      8983, 8982, 8984, 8990, 8992, 8993,
    ],
  },
  {
    state: 'Kärnten (Carinthia)',
    zipcodes: [
      9020, 9061, 9063, 9073, 9201, 9062, 9061, 9201, 9210, 9560, 9063, 9020,
      9061, 9064, 9064, 9020, 9063, 9065, 9020, 9071, 9073, 9161, 9072, 9181,
      9074, 9081, 9073, 9082, 9100, 9064, 9102, 9103, 9111, 9112, 9113, 9121,
      9371, 9103, 9102, 9111, 9112, 9112, 9113, 9122, 9130, 9131, 9132, 9123,
      9131, 9133, 9173, 9133, 9135, 9141, 9125, 9142, 9141, 9143, 9143, 9150,
      9150, 9155, 9161, 9170, 9162, 9163, 9170, 9173, 9173, 9181, 9162, 9184,
      9182, 9183, 9201, 9061, 9210, 9210, 9062, 9201, 9212, 9062, 9220, 9220,
      9231, 9536, 9232, 9184, 9581, 9241, 9220, 9300, 9063, 9556, 9311, 9300,
      9300, 9312, 9556, 9314, 9300, 9313, 9321, 9321, 9314, 9330, 9334, 9372,
      9373, 9322, 9360, 9330, 9322, 9330, 9300, 9312, 9341, 9334, 9330, 9360,
      9374, 9375, 9341, 9342, 9341, 9344, 9344, 9343, 9345, 9346, 9345, 9360,
      9361, 9375, 9363, 8850, 9362, 9371, 9064, 9102, 9103, 9372, 9103, 9371,
      9373, 9372, 9374, 9375, 8820, 8822, 9335, 9376, 9400, 9411, 9412, 9413,
      9421, 9431, 9433, 9441, 9461, 9462, 9413, 9400, 9423, 9470, 9433, 9104,
      9112, 9421, 9422, 9431, 9470, 9451, 9462, 9441, 9461, 9463, 9463, 9462,
      9470, 9112, 9433, 9472, 9473, 9473, 9423, 9472, 9500, 9241, 9504, 9523,
      9524, 9580, 9585, 9586, 9587, 9521, 9520, 9541, 9542, 9551, 9530, 9531,
      9535, 9220, 9542, 9543, 9544, 9545, 9546, 9872, 9873, 9546, 9551, 9552,
      9560, 9554, 9556, 9555, 9556, 9560, 9556, 9300, 9554, 9555, 9560, 9062,
      9560, 9562, 9552, 9560, 9563, 9563, 9565, 9564, 9570, 9231, 9560, 9571,
      9345, 9572, 9571, 9584, 9580, 9581, 9582, 9583, 9585, 9586, 9587, 9601,
      9586, 9587, 9602, 9611, 9531, 9612, 9623, 9613, 9613, 9601, 9602, 9620,
      9615, 9624, 9631, 9622, 9620, 9623, 9614, 9615, 9711, 9632, 9631, 9633,
      9634, 9635, 9640, 9640, 9635, 9651, 9652, 9653, 9652, 9654, 9655, 9702,
      9701, 9873, 9711, 9701, 9710, 9714, 9712, 9702, 9713, 9701, 9711, 9714,
      9721, 9500, 9710, 9722, 9751, 9813, 9753, 9754, 9753, 9761, 9762, 9771,
      9761, 9772, 9773, 9781, 9781, 9800, 9701, 9702, 9805, 9800, 9811, 9800,
      9813, 9811, 9812, 9814, 9814, 9815, 9816, 9821, 9822, 9831, 9832, 9833,
      9841, 9841, 9833, 9842, 9841, 9843, 9844, 9981, 9852, 9853, 9853, 9854,
      9853, 9861, 9862, 9863, 9862, 9871, 9800, 9851, 9872, 9872,
    ],
  },
];
