import {subscriptionConstants} from './types';
import { toast } from 'react-toastify';
import apiv2 from '../utils/apiv2';
import i18n from '../i18n';

export const createSubscription = ({ payload, callback }) => {

  const request = () => {
    return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_REQUEST };
  };
  const failure = (error) => {
    return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());
    try {
      await apiv2.post(`newsletter/subscribe`, {
        ...payload,
      });
      if (callback) {
        callback();
      }

    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else if (error?.response) {
        dispatch(failure(`${error.response}`));
        toast.error(`${error.response}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

