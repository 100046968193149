import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { labelRoles } from '../../utils/constants';
import JobRow from './JobRow';

const EventDetails = ({ currEvent, event, setEvent }) => {
  const [totalOutstandingAmount, setTotalOutstandingAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [conirmedTotalPrice, setconfirmedTotalPrice] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();
  let confirmedStaff = 0;
  const bookedStaff = [];

  useEffect(() => {
    let price = 0;
    let confirmedPrice = 0;
    let outstandingAmount =0;
    if (currEvent) {
      const { jobs } = currEvent;
      if (jobs && jobs.length) {
        jobs.map((job) => {

          if (job.jobPositions && job.jobPositions.length) {
            const filteredPositions = job.jobPositions.filter(
              (p) => p.booked && Object.values(p.booked),
            );
            filteredPositions.map((position) => {
              if(position.employerData.outstandingAmount !== undefined && position.employerData.outstandingAmount !==0) {
                outstandingAmount += Number(position.employerData.outstandingAmount);
              }
              else{
                outstandingAmount += 0;
              }
              price += Number(
                (position?.employerData?.jobPrice
                  ? position?.employerData?.jobPrice
                  : position?.jobPrice) +
                  (position?.employerData?.jobTravelCost
                    ? position?.employerData?.jobTravelCost
                    : position?.jobTravelCost || 0),
              );
              if (position.confirmJobPrice > 0) {
                confirmedPrice += Number(
                  position.jobTravelCost + position.confirmJobPrice,
                );
              } else {
                confirmedPrice += Number(
                  position.jobTravelCost + position.jobPrice,
                );
              }
              return true;
            });
          }
          return true;
        });
      }
    }
    setTotalPrice(price);
    setTotalOutstandingAmount(outstandingAmount);
    setconfirmedTotalPrice(confirmedPrice);
  }, [currEvent]);

  if (!currEvent) {
    return <></>;
  }

  const { eventName, eventID, jobs } = currEvent;

  return (
    <>
      <div className="form-back-wrapper">
        <div
          className="form-back"
          onClick={() =>
            history.push({
              pathname: `/${labelRoles.employer}/my-events`,
            })
          }></div>
        <div className="contact-btn-wrapper">
          <p>
            {t('needHelp')} <br /> {t('contact')}
          </p>
          <Link to="/contact" className="btn">
            {t('home.contact')}
          </Link>
        </div>
      </div>
      <div className="form-wrapper">
        <h3>{eventName}</h3>
        <div className="table-responsive">
          <table className="table table-layout-fixed job-details-table mb-0">
            <thead>
              <tr>
                <th className="ps-0">{t('requestOverview.eventId')}</th>
                <th>{t('requestOverview.eventName')}</th>
                <th colSpan="3">{t('event.amountBooked')}</th>
                <th className="right-align" >{t('requestOverview.finalAmount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="event-details-item">
                <td className="ps-0">
                  <span>{eventID}</span>
                </td>
                <td>
                  <span>{eventName}</span>
                </td>
                <td colSpan="3" style={{ whiteSpace: 'nowrap' }}>
                  <strong className="total-amount">
                    € {Number(totalPrice).toFixed(2)}
                  </strong>
                </td>
                <td className="right-align" style={{ whiteSpace: 'nowrap' }}>
                  <strong className="final-amount total-amount" >
                    € {Number(totalPrice + totalOutstandingAmount).toFixed(2)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="5" style={{ borderBottom: 0 }} className="py-0">
                  <p className="small-text placeholder-text ms-0">
                    {t('jobWallStaff.textThree')}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {jobs && jobs.length ? (
        <>
          {jobs.map((job, index) => {
            if (job.jobPositions && job.jobPositions.length) {
              return (
                <JobRow
                  key={index}
                  job={job}
                  currEvent={currEvent}
                  event={event}
                  setEvent={setEvent}
                />
              );
            }
          })}
        </>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table remove-space job-details-table mb-0">
              <tbody>
                <tr>
                  <td colSpan="5" style={{ borderBottom: 0 }} className="py-0">
                    <p className="small-text placeholder-text ms-0">
                      {t('jobWallStaff.textOne')}
                    </p>
                    <p className="small-text placeholder-text ms-0">
                      {t('jobWallStaff.textThree')}
                    </p>
                  </td>
                  <td colSpan="2" style={{ borderBottom: 0 }} className="py-0">
                    {confirmedStaff}/{bookedStaff.length} {t('event.confirmed')}
                  </td>
                  <td
                    colSpan="2"
                    style={{ whiteSpace: 'nowrap', borderBottom: 0 }}
                    className="py-0">
                    <span className="placeholder-text">
                      {t('event.totalExcl')}
                    </span>
                    <strong className="total-amount">
                      € {conirmedTotalPrice}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default EventDetails;
