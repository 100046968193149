import React, { useRef, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Head from '../components/Head';
import Button from '../components/Core/Button';
import Checkbox from '../components/Core/Checkbox';
import EmployeeImageModal from '../components/Register/EmployeeImages';
import EmployeeThankYouModal from '../components/Modal/EmployeeThankYouModal';
import RegisterPendingModal from '../components/Modal/RegisterPendingModal';
import DeleteVoiceModal from '../components/Modal/DeleteVoiceModal';
import ConfirmationModal from '../components/Modal/ConfirmationModal';
import RecorderModel from '../components/Modal/RecorderModel';
import { roles } from '../utils/constants';
import GlobalContext from '../context/GlobalContext';
import { completeRegistration } from '../actions/authAction';
import StaffForm from '../components/Staff/StaffForm';
import api from '../utils/api';
import { toast } from 'react-toastify';
import {
  getNewValues,
  getStaffInitialValues,
  getStaffValidationSchema,
} from '../components/Staff/staffFormik';
import StaffMedia from '../components/Staff/StaffMedia';
import Loader from '../components/Core/Loader';
import { useTranslation } from 'react-i18next';
import InfoImageSaveModal from '../components/Modal/InfoImageSaveModal';

const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    'Are you sure you want to leave? All provided data will be lost.';
  e.returnValue = message;
  return message;
};

const maxFiles = 10;
const EmployeeRegistrationForm = () => {
  const formRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const {
    jobPositions,
    jobPositionsLoading,
    showEmployeeCompleteRegisterModal,
    setShowEmployeeCompleteRegisterModal,
    showEmployeeImageModal,
    setShowEmployeeImageModal,
    showInfoImageSaveModal,
    setInfoImageSaveModal,
    showRecorderModel,
    setShowRecorderModel,
    showImageConfirmationModal,
    showDeleteVoiceModal,
    setShowDeleteVoiceModal,
  } = useContext(GlobalContext);
  const [showEmployeeThankYouModal, setShowEmployeeThankYouModal] =
    useState(false);
  const [registerPendingModal, setRegisterPendingModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [recordState, setRecordState] = useState(null);
  const [audio, setAudio] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [preferedRegions, setPreferedRegions] = useState(null);
  const [preferedRegionsError, setPreferedRegionsError] = useState(null);
  const { t, i18n } = useTranslation();
  const [binaryImages, setBinaryImages] = useState([]);
  // const constractData = useSelector((state) => state.contract?.data);

  useEffect(() => {
    if (showEmployeeCompleteRegisterModal) {
      setShowEmployeeCompleteRegisterModal(false);
    }
    if (
      location.state &&
      location.state.values &&
      location.state.role &&
      location.state.token
    ) {
      return;
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      handleBeforeUnload(e);
    });
    return () => {
      window.removeEventListener('beforeunload', (e) => {
        handleBeforeUnload(e);
      });
    };
  }, []);

  React.useEffect(() => {
    // setBinaryImages([]); // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getnewimagees() {
      const imagedata = await Promise.all(
        files.map(async (file, id) => {
          let data = {};
          if (typeof file === 'string') {
            const response = await api.post('users/getImageUrl', {
              image: file,
            });
            const newimage = response.data.image;
            data = {
              id: id,
              image: newimage,
            };
          } else {
            data = {
              id: id,
              image: file,
            };
          }
          return data;
        }),
      );
      setBinaryImages(imagedata);
    }
    getnewimagees();
  }, [files]);
  const renderpdfcontract = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ActiveContract.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };
  const getContactUrl = async () => {
    await api
      .get('contracts/getActiveContract')
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.contracturl[0].data).buffer]),
        );
        renderpdfcontract(url);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  useEffect(() => {
    if (formSubmitted) {
      setFormSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const {
    addressLineOne,
    addressLineTwo,
    city,
    country,
    password,
    email,
    firstName,
    lastName,
    phone,
    postalCode,
  } = location.state ? location.state.values : {};

  if (jobPositionsLoading || !jobPositions.length) {
    return <Loader />;
  }

  return (
    <>
      {showImageConfirmationModal.visible && (
        <ConfirmationModal files={files} setFiles={setFiles} />
      )}
      {showEmployeeImageModal && (
        <EmployeeImageModal
          files={files}
          setFiles={setFiles}
          maxFiles={maxFiles}
          showEmployeeImageModal={showEmployeeImageModal}
          setShowEmployeeImageModal={setShowEmployeeImageModal}
        />
      )}
      {showEmployeeThankYouModal && (
        <EmployeeThankYouModal
          showEmployeeThankYouModal={showEmployeeThankYouModal}
          setShowEmployeeThankYouModal={setShowEmployeeThankYouModal}
        />
      )}
      {registerPendingModal && (
        <RegisterPendingModal
          registerPendingModal={registerPendingModal}
          setRegisterPendingModal={setRegisterPendingModal}
        />
      )}
      {showDeleteVoiceModal && (
        <DeleteVoiceModal
          showDeleteVoiceModal={showDeleteVoiceModal}
          setShowDeleteVoiceModal={setShowDeleteVoiceModal}
          setAudio={setAudio}
        />
      )}
      {showRecorderModel && (
        <RecorderModel
          recordState={recordState}
          setRecordState={setRecordState}
          setAudio={setAudio}
          showRecorderModel={showRecorderModel}
          setShowRecorderModel={setShowRecorderModel}
        />
      )}
      <Formik
        innerRef={formRef}
        initialValues={getStaffInitialValues({
          addressLineOne,
          addressLineTwo,
          city,
          country,
          email,
          firstName,
          lastName,
          phone,
          postalCode,
          preferedJobRegion: [],
          // preferedJobRegion: {
          //   value: '',
          // },
        })}
        validationSchema={Yup.object(getStaffValidationSchema())}
        onSubmit={(formValues, action) => {
          const values = { ...formValues };
          setFormSubmitted(true);
          const newValues = getNewValues({
            ...values,
            ...location.state.values,
            images: files,
            audio,
          });
          let formData = new FormData();
          Object.entries(newValues).map(([key, value]) => {
            if (key === 'images') {
              for (const image of value) {
                formData.append(key, image);
              }
            } else {
              formData.append(key, value);
            }
            return true;
          });
          dispatch(
            completeRegistration({
              role: roles.employee,
              formData,
              token: location.state.token,
              callback: () => {
                setShowEmployeeThankYouModal(true);
              },
              setSubmitting: setFormSubmitted,
            }),
          );
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          setSubmitting,
        }) => (
          <div>
            {showInfoImageSaveModal && (
              <InfoImageSaveModal
                showInfoImageSaveModal={showInfoImageSaveModal}
                setInfoImageSaveModal={setInfoImageSaveModal}
                handleSubmitForm={handleSubmit}
              />
            )}
            <Form className="form">
              <section className="content-section employee-registration-section">
                <Head title={`${t('Staffregistration')} | Superstaff`} />
                <Container>
                  <div className="box-wrapper--inner">
                    <StaffMedia
                      files={files}
                      setFiles={setFiles}
                      audio={audio}
                      formSubmitted={formSubmitted}
                      binaryImages={binaryImages}
                    />

                    <StaffForm
                      title={t('step2/2')}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      preferedRegions={preferedRegions}
                      setPreferedRegions={setPreferedRegions}
                      preferedRegionsError={preferedRegionsError}
                      setPreferedRegionsError={setPreferedRegionsError}
                    />
                  </div>

                  <Row className="justify-content-end">
                    <Col md="8" xl="6">
                      <div className="form-bottom">
                        <div className="employee-registration-note d-flex justify-content-end align-items-start">
                          <div className="employee-registration-note__icon">
                            <img
                              src="data:image/svg+xml,%3Csvg%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2070%2070%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M32.96%2012.867c.89-1.6%203.19-1.6%204.08%200l23.333%2042c.864%201.555-.26%203.466-2.04%203.466H11.667c-1.78%200-2.904-1.91-2.04-3.466l23.333-42zM35%2018.805L15.632%2053.667h38.736L35%2018.805zm-2.333%2011.528a2.333%202.333%200%20114.666%200v9.334a2.333%202.333%200%2011-4.666%200v-9.334zm0%2016.334a2.333%202.333%200%20114.666%200V49a2.333%202.333%200%2011-4.666%200v-2.333z%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fillRule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cuse%20fill%3D%22%23000%22%20fillRule%3D%22nonzero%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3Cg%20fill%3D%22%2341A9C7%22%20mask%3D%22url(%23b)%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                              alt=""
                              width="70"
                              height="70"
                            />
                          </div>
                          <div className="employee-registration-note__content">
                            <p className="text-uppercase mb-2">
                              <strong>{t('note')}</strong>
                            </p>
                            <p className="small-text">
                              {t('registration.note')}
                            </p>
                          </div>
                        </div>
                        <Checkbox
                          value={values.acceptTerms}
                          touched={touched.acceptTerms}
                          errors={errors.acceptTerms}
                          onChange={() =>
                            setFieldValue('acceptTerms', !values.acceptTerms)
                          }
                          // label="I agree to the [basic contract of employment in case
                          // of employment](/contact)"
                        >
                          <p>
                            {t('home.regTerms1')}{' '}
                            <span
                              className="text-info"
                              onClick={() => {
                                getContactUrl();
                              }}>
                              {t('home.basiccontracttext')}
                            </span>
                            {' ' + t('home.basiccontractendtext')}
                          </p>
                        </Checkbox>

                        <div className="form-group">
                          <Button
                            type="submit"
                            label={t('apply1')}
                            className="btn"
                            disabled={
                              !Object.values(errors).length && formSubmitted
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                              setTimeout(() => {
                                let lowestTopValue = window.innerHeight;
                                let elementWithLowestTopValue = null;
                                var allErrors =
                                  document.getElementsByClassName(
                                    'form-errors',
                                  );
                                var allErrorsArray = [...allErrors];
                                const filteredErrors = allErrorsArray.filter(
                                  (element) => {
                                    return (
                                      element.textContent.trim().length !== 0
                                    );
                                  },
                                );
                                for (
                                  let i = 0;
                                  i < filteredErrors.length;
                                  i++
                                ) {
                                  var topValue =
                                    filteredErrors[i].getBoundingClientRect()
                                      .top;
                                  if (topValue < lowestTopValue) {
                                    lowestTopValue = topValue;
                                    elementWithLowestTopValue =
                                      filteredErrors[i];
                                    elementWithLowestTopValue.scrollIntoView({
                                      behavior: 'smooth',
                                      block: 'center',
                                      inline: 'start',
                                    });
                                  }
                                }
                              }, 100);
                            }}
                          />
                          <Button
                            type="button"
                            label={t('completelater')}
                            className="btn btn-primary"
                            disabled={
                              !Object.values(errors).length && formSubmitted
                            }
                            onClick={async () => {
                              setFormSubmitted(true);
                              const newValues = getNewValues({
                                ...values,
                                images: files,
                                audio,
                              });
                              //set to false until user is fully registerd
                              newValues.acceptTerms = false;
                              if (
                                newValues.dateOfBirth === 0 ||
                                isNaN(newValues.dateOfBirth) ||
                                newValues.dateOfBirth === null ||
                                newValues.dateOfBirth === undefined
                              ) {
                                newValues.dateOfBirth = '';
                              }
                              if (password && password.length > 0) {
                                newValues.password = password;
                              }
                              let formData = new FormData();
                              Object.entries(newValues).map(([key, value]) => {
                                if (key === 'images') {
                                  for (const image of value) {
                                    formData.append(key, image);
                                  }
                                } else {
                                  if (
                                    value !== '' &&
                                    value !== null &&
                                    value !== undefined
                                  ) {
                                    if (
                                      typeof value === 'object' &&
                                      'value' in value
                                    ) {
                                      formData.append(key, value.value);
                                    } else {
                                      formData.append(key, value);
                                    }
                                  }
                                }
                                return true;
                              });
                              dispatch(
                                completeRegistration({
                                  role: roles.employee,
                                  formData,
                                  message: t('registrationpending'),
                                  token: location.state.token,
                                  callback: () => {
                                    setRegisterPendingModal(true);
                                  },
                                  setSubmitting: setFormSubmitted,
                                }),
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default EmployeeRegistrationForm;
