import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import Button from '../Core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from '../../actions/authAction';
import { useTranslation } from 'react-i18next';
import { EMAIL_VALIDATION } from '../../utils/constants';

const ForgotLogin = () => {
  const dispatch = useDispatch();
  const { showForgotModal, setShowForgotModal } = useContext(GlobalContext);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_VALIDATION, {
          message: t('Email.type'),
        })
        .required(t('Email.required')),
    }),
    onSubmit: (values) => {
      formik.setSubmitting(true);
      dispatch(
        forgotPassword({
          email: values.email,
          setSubmitting: formik.setSubmitting,
        }),
      );
    },
  });

  return (
    <Modal
      show={showForgotModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowForgotModal(false)}
      className="employee-login-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('loginForgot')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form form-login">
          <Row>
            <Col>
              <Input
                type="email"
                label={t('emailContact') + '*'}
                placeholder={t('emailContact')}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.email}
                errors={formik.errors.email}
                value={formik.values.email}
              />
            </Col>
          </Row>
          <div className="form-bottom">
            <Button
              type="submit"
              label={t('contact.btnSubmit')}
              className="btn"
              disabled={formik.isSubmitting}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotLogin;
