export const userConstants = {
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  COMPLETE_REGISTRATION_REQUEST: 'COMPLETE_REGISTRATION_REQUEST',
  COMPLETE_REGISTRATION_SUCCESS: 'COMPLETE_REGISTRATION_SUCCESS',
  COMPLETE_REGISTRATION_FAILURE: 'COMPLETE_REGISTRATION_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  UDPATE_ME_REQUEST: 'UDPATE_ME_REQUEST',
  UDPATE_ME_SUCCESS: 'UDPATE_ME_SUCCESS',
  UDPATE_ME_FAILURE: 'UDPATE_ME_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  UPDATE_ME_REQUEST: 'UPDATE_ME_REQUEST',
  UPDATE_ME_SUCCESS: 'UPDATE_ME_SUCCESS',
  UPDATE_ME_FAILURE: 'UPDATE_ME_FAILURE',

  ADD_EVENT: 'ADD_EVENT',
  GET_ME: 'GET_ME',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  DELETE_USERS_REQUEST: 'DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
  DELETE_USERS_FAILURE: 'DELETE_USERS_FAILURE',
  UPDATE_USERS_REQUEST: 'UPDATE_USERS_REQUEST',
  UPDATE_USERS_SUCCESS: 'UPDATE_USERS_SUCCESS',
  UPDATE_USERS_FAILURE: 'UPDATE_USERS_FAILURE',
};

export const contractConstants = {
  GET_ACTIVE_CONTRACT: 'GET_ACTIVE_CONTRACT',
  GET_ACTIVE_CONTRACT_SUCCESS: 'GET_ACTIVE_CONTRACT_SUCCESS',
  GET_ACTIVE_CONTRACT_FAILURE: 'GET_ACTIVE_CONTRACT_FAILURE',
};

export const eventConstants = {
  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: 'CREATE_EVENT_FAILURE',
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',
  UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILURE: 'UPDATE_EVENT_FAILURE',
  GET_CURRENT_EVENTS_REQUEST: 'GET_CURRENT_EVENTS_REQUEST',
  GET_CURRENT_EVENTS_SUCCESS: 'GET_CURRENT_EVENTS_SUCCESS',
  GET_CURRENT_EVENTS_FAILURE: 'GET_CURRENT_EVENTS_FAILURE',
  GET_COMPLETED_EVENTS_REQUEST: 'GET_COMPLETED_EVENTS_REQUEST',
  GET_COMPLETED_EVENTS_SUCCESS: 'GET_COMPLETED_EVENTS_SUCCESS',
  GET_COMPLETED_EVENTS_FAILURE: 'GET_COMPLETED_EVENTS_FAILURE',
  GET_ALLEVENTS_REQUEST: 'GET_ALLEVENTS_REQUEST',
  GET_ALLEVENTS_SUCCESS: 'GET_ALLEVENTS_SUCCESS',
  GET_ALLEVENTS_FAILURE: 'GET_ALLEVENTS_FAILURE',
};

export const settingConstants = {
  GET_JOB_NAMES_SUCCESS: 'GET_JOB_NAMES_SUCCESS',
  GET_JOB_NAMES_FAILURE: 'GET_JOB_NAMES_FAILURE',
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
};

export const jobConstants = {
  GET_ALL_JOBS_REQUEST: 'GET_ALL_JOBS_REQUEST',
  GET_ALL_JOBS_SUCCESS: 'GET_ALL_JOBS_SUCCESS',
  GET_ALL_JOBS_FAILURE: 'GET_ALL_JOBS_FAILURE',

  GET_MY_JOBS_REQUEST: 'GET_MY_JOBS_REQUEST',
  GET_MY_JOBS_SUCCESS: 'GET_MY_JOBS_SUCCESS',
  GET_MY_JOBS_FAILURE: 'GET_MY_JOBS_FAILURE',

  APPLY_FOR_JOB_REQUEST: 'APPLY_FOR_JOB_REQUEST',
  APPLY_FOR_JOB_SUCCESS: 'APPLY_FOR_JOB_SUCCESS',
  APPLY_FOR_JOB_FAILURE: 'APPLY_FOR_JOB_FAILURE',

  UPDATE_JOB_REQUEST: 'UPDATE_JOB_REQUEST',
  UPDATE_JOB_SUCCESS: 'UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAILURE: 'UPDATE_JOB_FAILURE',

  UPDATE_JOB_POSITION_REQUEST: 'UPDATE_JOB_POSITION_REQUEST',
  UPDATE_JOB_POSITION_SUCCESS: 'UPDATE_JOB_POSITION_SUCCESS',
  UPDATE_JOB_POSITION_FAILURE: 'UPDATE_JOB_POSITION_FAILURE',

  APPLY_FOR_JOBS_SUCCESS: 'APPLY_FOR_JOBS_SUCCESS',
  BOOK_STAFF_SUCCESS: 'BOOK_STAFF_SUCCESS',

  HANDLE_OVERLAPPING_JOBS_REQUEST: 'HANDLE_OVERLAPPING_JOBS_REQUEST',
  HANDLE_OVERLAPPING_JOBS_SUCCESS: 'HANDLE_OVERLAPPING_JOBS_SUCCESS',
  HANDLE_OVERLAPPING_JOBS_FAILURE: 'HANDLE_OVERLAPPING_JOBS_FAILURE',

  GET_TEASER_JOBS_REQUEST: 'GET_TEASER_JOBS_REQUEST',
  GET_TEASER_JOBS_SUCCESS: 'GET_TEASER_JOBS_SUCCESS',
  GET_TEASER_JOBS_FAILURE: 'GET_TEASER_JOBS_FAILURE',
};

export const subscriptionConstants = {
  UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE',
}

export const testimonialConstants = {
  GET_TESTIMONIALS_REQUEST: 'GET_TESTIMONIALS_REQUEST',
  GET_TESTIMONIALS_SUCCESS: 'GET_TESTIMONIALS_SUCCESS',
  GET_TESTIMONIALS_FAILURE: 'GET_TESTIMONIALS_FAILURE',
};

export const zipcodeConstants = {
  GET_ZIPCODE_REQUEST: 'GET_ZIPCODE_REQUEST',
  GET_ZIPCODE_SUCCESS: 'GET_ZIPCODE_SUCCESS',
  GET_ZIPCODE_FAILURE: 'GET_ZIPCODE_FAILURE',
};
