import axios from 'axios';
import { toast } from 'react-toastify';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  responseType: 'json',
});
API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { name } = error?.response?.data?.error || {};

    if (name === 'TokenExpiredError' || name === 'JsonWebTokenError') {
      localStorage.removeItem('token');
      delete API.defaults.headers.common.Authorization;

      const errorMessage =
        name === 'TokenExpiredError'
          ? 'Your session has expired. Please log in again.'
          : 'Invalid token. Please log in again.';

      toast.error(errorMessage);

      setTimeout(() => {
        window.location.replace('/');
      }, 1300);
    } else {
      return Promise.reject(error);
    }
  },
);
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete API.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error),
);

export default API;
