import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import 'croppie/croppie.css';
import Croppie from 'croppie';
import i18n from '../../i18n';
import { toast } from 'react-toastify';

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'none',
  width: 'auto',
  height: '100%',
  objectFit: 'cover',
};

const FileUploadEmployer = ({
  type,
  label,
  value,
  onBlur,
  onChange,
  touched,
  className,
  errors,
  placeholder,
  files,
  setFiles,
  name,
  labelRight,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 2097152,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    onDropRejected: (rejectedFiles) => {
      // errors(rejectedFiles[0].errors[0]);
      // errors = rejectedFiles[0].errors[0].code;
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        toast.error(`${i18n.t('imageModel.uploadNote')}`);
      }
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        toast.error(`${i18n.t('imageModel.uploadNote')}`);
      }
      return;
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <label className="form-label">{i18n.t('Logo_crop_preview')}</label>
      <div style={thumbInner}>
        <img id="testImage" src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      const el = document.getElementById('testImage');
      if (el) {
        const vanilla = new Croppie(el, {
          viewport: { width: 180, height: 180, type: 'circle' },
          boundary: { width: 180, height: 180 },
          showZoomer: true,
          enableOrientation: true,
        });

        files.map((file, id) =>
          vanilla.bind({
            url: file.preview,
            orientation: 4,
            points: [77, 469, 280, 739],
          }),
        );

        //on button click
        vanilla.result('blob').then(function (blob) {
          return <img src={blob} style={img} alt="" />;
        });
      }
    },
    [files],
  );

  return (
    <Row>
      <Col md="6">
        <div
          {...getRootProps({ className: 'dropzone' })}
          className={`form-group ${
            touched && errors ? 'form-group-errors' : ''
          }`}>
          {label && <label className="form-label">{label}</label>}
          <div className="file-upload-btn-wrapper">
            <div className="file-upload-content">
              <div className="file-upload-icon"></div>
              <div className="file-upload-text">
                <p className="text-uppercase text-bold mb-4">
                  {i18n.t('DragDropimage')}
                  <br /> {i18n.t('hereor')}{' '}
                  <span>{i18n.t('requestOverview.modal.browse')}</span>
                </p>
                <p>{i18n.t('imageModel.uploadNote')}</p>
              </div>
            </div>
            <input
              type={type}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              name={name}
              className={`form-control ${className}`}
              {...getInputProps()}
              multiple={null}
            />
          </div>
          {touched && errors && <div className="form-errors">{errors}</div>}
        </div>
      </Col>
      <Col md="6" lg="4">
        {thumbs}
      </Col>
    </Row>
  );
};

export default FileUploadEmployer;
