import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Core/Button';
import { useTranslation } from 'react-i18next';

function DeleteJobModal({
  showDeleteJobPositionModal,
  setShowDeleteJobPositioModal,
  removeJobPosition,
}) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showDeleteJobPositionModal.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal"
      onHide={() => {
        setShowDeleteJobPositioModal({ show: false, id: null });
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('deleteJobModal.warning')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('deleteJobModal.warningText')}</p>
        <p>{t('deleteJobModal.warningText2')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          label={t('requestOverview.modal.cancel')}
          onClick={() =>
            setShowDeleteJobPositioModal({ show: false, id: null })
          }
        />
        <Button
          className="btn"
          label={t('delete')}
          onClick={() => removeJobPosition(showDeleteJobPositionModal.id)}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteJobModal;
