import { userConstants } from './types';
import { toast } from 'react-toastify';
import axios from 'axios';
import api from '../utils/api';
import { labelRoles } from '../utils/constants';
import i18n from '../i18n';

export const signup = (data) => {
  const { firstName, lastName, email, phone, role, callback, setSubmitting } =
    data;

  const request = () => {
    return { type: userConstants.SIGNUP_REQUEST };
  };
  const success = () => {
    return { type: userConstants.SIGNUP_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.SIGNUP_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());
    try {
      const user = await api.post('/users/signup', {
        firstName,
        lastName,
        email,
        phone,
        role,
      });
      dispatch(
        success({
          ...user.data.data.user,
          token: user.data.token,
        }),
      );
      setSubmitting(false);
      // localStorage.setItem('user', JSON.stringify(user.data.data.user));
      // localStorage.setItem('token', user.data.token);
      callback();
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === 'This e-mail is already in use.') {
          toast.error(i18n.t('emailalreadyused'));
          dispatch(failure(i18n.t('emailalreadyused')));
        } else {
          dispatch(failure(`${error.response.data.message}`));
          toast.error(`${error.response.data.message}`);
        }
      } else {
        toast.error(i18n.t('somethingwentwrong'));
        dispatch(failure(i18n.t('somethingwentwrong')));
      }
    }
  };
};

export const login = (data) => {
  const { email, password, role, callback, setSubmitting } = data;
  const request = () => {
    return { type: userConstants.LOGIN_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.LOGIN_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.LOGIN_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.post('users/login', {
        email,
        password,
        role,
      });

      dispatch(
        success({
          ...user.data.data.user,
          token: user.data.token,
        }),
      );
      localStorage.removeItem('user');
      localStorage.removeItem('isErrorMessageShown');
      localStorage.setItem('user', JSON.stringify(user.data.data.user));
      localStorage.setItem('token', user.data.token);
      toast.success(i18n.t('loginsuccess'));
      callback(labelRoles[user.data.data.user.role]);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === 'User not found!') {
          toast.error(i18n.t('usernotfound'));
        } else if (
          error.response.data.message === 'Incorrect email or password'
        ) {
          toast.error(i18n.t('invaliduserandpass'));
        } else if (error.response.data.message === 'This account has been deactivated!') {
          toast.error(i18n.t('userisarchived'));
        } else {
          toast.error(`${error.response.data.message}`);
        }
        dispatch(failure(`${error.response.data.message}`));
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const completeRegistration = (data) => {
  const { formData, token, message, callback, role, setSubmitting } = data;
  const request = () => {
    return { type: userConstants.COMPLETE_REGISTRATION_REQUEST };
  };
  const success = () => {
    return { type: userConstants.COMPLETE_REGISTRATION_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.COMPLETE_REGISTRATION_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await axios.post(
        `${process.env.REACT_APP_API_URL}${role}s/createMe`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      dispatch(success());
      setSubmitting(false);
      localStorage.removeItem('user', JSON.stringify(user.data.data.user));
      localStorage.removeItem('token', user.data.token);
      toast.success(`${message ? message : i18n.t('registrationsuccess')}`);
      callback();
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const logout = (callback, checkLogin) => {
  const request = () => {
    return { type: userConstants.LOGOUT_REQUEST };
  };
  const success = () => {
    return { type: userConstants.LOGOUT_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.LOGOUT_FAILURE, error };
  };

  return async (dispatch) => {
    const isAuthenticated =
      localStorage.getItem('token') && localStorage.getItem('user');
    let role;
    if (isAuthenticated && JSON.parse(localStorage.getItem('user'))) {
      role = JSON.parse(localStorage.getItem('user')).role;
      if (role) {
        dispatch(request());
        api
          .get('users/logout')
          .then((res) => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            dispatch(success());
            !checkLogin && toast.success(i18n.t('logoutsuccess'));
            callback();
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              dispatch(failure(`${error.response.data.message}`));
              toast.error(`${error.response.data.message}`);
            } else {
              dispatch(failure(i18n.t('somethingwentwrong')));
              toast.error(i18n.t('somethingwentwrong'));
            }
          });
      }
    }
  };
};

export const forgotPassword = ({ email, setSubmitting }) => {
  const request = () => {
    return { type: userConstants.FORGET_PASSWORD_REQUEST };
  };
  const success = () => {
    return { type: userConstants.FORGET_PASSWORD_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.FORGET_PASSWORD_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    api
      .post('users/forgotPassword', { email })
      .then((res) => {
        setSubmitting(false);
        dispatch(success());
        if (res.data && res.data.message) {
          if (res.data.message === 'Token sent to email!') {
            toast.success(i18n.t('tokensent1'));
          } else {
            toast.success(i18n.t('tokensent1'));
            // toast.success(`${res.data.message}`);
          }
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(failure(`${error.response.data.message}`));
          toast.success(i18n.t('tokensent1'));
          // toast.error(`${error.response.data.message}`);
        } else {
          dispatch(failure(i18n.t('somethingwentwrong')));
          toast.success(i18n.t('tokensent1'));
          // toast.error(i18n.t('somethingwentwrong'));
        }
      });
  };
};

export const resetPassword = ({
  resetToken,
  password,
  passwordConfirm,
  callback,
  setSubmitting,
}) => {
  const request = () => {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.patch(`users/resetPassword/${resetToken}`, {
        password,
        passwordConfirm,
      });
      dispatch(
        success({
          ...user.data.data.user,
          token: user.data.token,
        }),
      );
      setSubmitting(false);
      localStorage.setItem('user', JSON.stringify(user.data.data.user));
      localStorage.setItem('token', user.data.token);
      toast.success(i18n.t('passwordchanged'));
      callback(user.data.data.user.role);
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updateMe = ({ formData, callback, message }) => {
  const request = () => {
    return { type: userConstants.UPDATE_ME_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.UPDATE_ME_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.UPDATE_ME_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.patch('users/updateMe', formData);

      if (user?.data?.status === 'success') {
        dispatch(
          success({
            ...user.data.data.user,
            token: localStorage.getItem('token'),
          }),
        );
        localStorage.setItem('user', JSON.stringify(user.data.data.user));
        message &&
          toast.success(`${message}`, {
            toastId: 'customId',
          });
        if (callback) {
          callback({ status: true, user: user });
        }
      } else {
        callback({ status: false, error: user?.data?.msg });
        dispatch(failure(user?.data?.msg));
      }
    } catch (error) {
      if (callback) {
        callback({ status: false, error: error });
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const addAppliedEvents = (events) => {
  return {
    type: userConstants.ADD_EVENT,
    events,
  };
};

export const updatebillingaddress = ({ formData, callback, message }) => {
  const request = () => {
    return { type: userConstants.UPDATE_ME_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.UPDATE_ME_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.UPDATE_ME_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.post('employers/updatebillingaddress', formData);

      if (user?.data?.status === 'success') {
        dispatch(
          success({
            ...user.data.data.user,
            token: localStorage.getItem('token'),
          }),
        );
        localStorage.setItem('user', JSON.stringify(user.data.data.user));
        message &&
          toast.success(`${message}`, {
            toastId: 'customId',
          });
        if (callback) {
          callback({ status: true, user: user });
        }
      } else {
        callback({ status: false, error: user?.data?.msg });
        dispatch(failure(user?.data?.msg));
      }
    } catch (error) {
      if (callback) {
        callback({ status: false, error: error });
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updatepaymentdetails = ({ formData, callback, message }) => {
  const request = () => {
    return { type: userConstants.UPDATE_ME_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.UPDATE_ME_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.UPDATE_ME_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.post('employers/updatepaymentdetails', formData);

      if (user?.data?.status === 'success') {
        dispatch(
          success({
            ...user.data.data.user,
            token: localStorage.getItem('token'),
          }),
        );
        localStorage.setItem('user', JSON.stringify(user.data.data.user));
        message &&
          toast.success(`${message}`, {
            toastId: 'customId',
          });
        if (callback) {
          callback({ status: true, user: user });
        }
      } else {
        callback({ status: false, error: user?.data?.msg });
        dispatch(failure(user?.data?.msg));
      }
    } catch (error) {
      if (callback) {
        callback({ status: false, error: error });
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};
