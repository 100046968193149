import { Field, FieldArray } from 'formik';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getTimeDifferent,
  printPriceValue,
  showExactTime,
} from '../../utils/helper';
import { EndInfo, RenderEndWarning } from '../CreateJob/CreateJobPosition';
import SearchLocationInput from '../SearchLocationInput';

export const initialJobPosition = {
  date: '',
  place: {
    state: '',
    zipcode: '',
    value: '',
    address: '',
    coordinates: '',
  },
  start: moment().toDate(),
  end: moment().toDate(),
  price: 0,
  travelCosts: 0,
};
registerLocale('de', de);
registerLocale('en', enUS);
const JobPositionForm = ({
  values,
  index,
  errors,
  touched,
  setFieldValue,
  addAnotherJob,
  places,
  jobName,
  jobBonus,
  setFieldTouched,
}) => {
  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );
  const { t } = useTranslation();
  const [pricePerHour, setPricePerHour] = React.useState(0);
  const [effectivePricePerHour, setEffectivePricePerHour] = React.useState(0);
  const allZipCodes = useSelector((state) => state?.zipcode?.zipcodeList);
  const [newJobsTotal, setnewJobsTotal] = useState(0);
  const [bonus, setBonus] = useState(0);
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';
  const handleFieldChange = ({ id, name, value }) => {
    const newValues = values.jobs.map((v, vid) => {
      if (vid === id) {
        let otherFieldToSave = {};
        if (name === 'start' || name === 'end') {
          let hours = 4;
          if (name === 'start') {
            hours = getTimeDifferent(value, v.end); // getPriceByDurationHours(v.end, value, pricePerHour);
          } else if (name === 'end') {
            hours = getTimeDifferent(v.start, value); // getPriceByDurationHours(value, v.start, pricePerHour);
          } else {
            hours = getTimeDifferent(v.start, v.end);
          }
          hours = hours < 4 ? 4 : hours;
          otherFieldToSave = {
            price:
              hours * pricePerHour +
              (bonus && bonus > 0 ? hours * pricePerHour * (bonus / 100) : 0),
            hourlyRate: pricePerHour,
            employerData: {
              ...v.employerData,
              hours: hours,
              jobPrice:
                hours * effectivePricePerHour +
                (bonus && bonus > 0
                  ? hours * effectivePricePerHour * (bonus / 100)
                  : 0),
              hourlyRate: effectivePricePerHour,
            },
          };
        }
        return {
          ...v,
          [name]: value,
          ...otherFieldToSave,
        };
      } else {
        return { ...v };
      }
    });
    setFieldValue('jobs', newValues);
  };

  React.useEffect(() => {
    setBonus(jobBonus);
    const currentStaffType = staffTypes?.find((s) => s.name === jobName);
    setPricePerHour(currentStaffType?.pricePerHour);
    setEffectivePricePerHour(currentStaffType?.effectivePricePerHour);
  }, [staffTypes, jobName, jobBonus]);

  React.useEffect(() => {
    const newValues = values.jobs.map((v, vid) => {
      let hours = 4;
      hours = getTimeDifferent(v.start, v.end);
      hours = hours < 4 ? 4 : hours;
      const price = Number(
        hours * pricePerHour +
          (bonus && bonus > 0 ? hours * pricePerHour * (bonus / 100) : 0),
      ).toFixed(2);
      const effectivePrice = Number(
        hours * effectivePricePerHour +
          (bonus && bonus > 0
            ? hours * effectivePricePerHour * (bonus / 100)
            : 0),
      ).toFixed(2);

      return {
        ...v,
        price: isNaN(price) ? 0 : price,
        hourlyRate: pricePerHour,
        employerData: {
          ...v.employerData,
          jobPrice: effectivePrice,
          hourlyRate: effectivePricePerHour,
        },
      };
    });
    setFieldValue('jobs', newValues);
  }, [pricePerHour, effectivePricePerHour, bonus]);

  useEffect(() => {
    let localNewJobsTotal = 0;
    values?.jobs &&
      values?.jobs?.length &&
      values?.jobs?.map((position, i) => {
        localNewJobsTotal +=
          parseFloat(
            Number(
              position?.employerData?.jobPrice &&
                position?.employerData?.jobPrice !== 'NaN'
                ? position?.employerData?.jobPrice
                : 0,
            ).toFixed(2),
          ) +
          parseFloat(
            Number(
              position?.employerData?.jobTravelCost &&
                position?.employerData?.jobTravelCost !== 'NaN'
                ? position?.employerData?.jobTravelCost
                : 0,
            ).toFixed(2),
          );
      });
    setnewJobsTotal(localNewJobsTotal.toFixed(2));
  }, [values]);

  return (
    <FieldArray
      name="jobs"
      render={(arrayHelpers) => {
        return (
          <>
            {values.jobs && values.jobs.length
              ? values.jobs.map((job, index) => (
                  <React.Fragment>
                    <div key={index} className="job-list__item">
                      <Row>
                        <Col md="6" xl="5">
                          <Row>
                            <Col xs="3" sm="2" xl="2">
                              <div className="numberList">
                                {index + 1}/{values.jobs.length}
                              </div>
                            </Col>
                            <Col xs="9" sm="10" xl="5">
                              <div className="form-group date-picker-group">
                                <label className="form-label">
                                  {`${t('dateLabel')} *`}
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  name={`jobs.${index}.date`}
                                  minDate={moment().toDate()}
                                  selected={
                                    values.jobs &&
                                    values.jobs[index] &&
                                    values.jobs[index].date
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  dateFormat="dd.MM.yyyy"
                                  onChange={(date) => {
                                    const time = new Date(date).valueOf();
                                    handleFieldChange({
                                      id: index,
                                      name: 'date',
                                      value: time,
                                    });
                                    setFieldTouched(`jobs.${index}.date`, true);
                                  }}
                                  calendarStartDay={1}
                                  className="form-control"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onBlur={(event) => {
                                    setFieldTouched(`jobs.${index}.date`, true);
                                  }}
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].date &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].date && (
                                    <div className="form-errors">
                                      {errors.jobs[index].date}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col sm="12" xl="5">
                              <div className="form-group">
                                <label className="form-label">
                                  {`${t('placeLabel')} *`}
                                </label>
                                <Field
                                  name={`jobs.${index}.place`}
                                  travelCostFieldName={`jobs.${index}.travelCosts`}
                                  effectiveTravelCostFieldName={`jobs.${index}.employerData.jobTravelCost`}
                                  allZipCodes={allZipCodes}
                                  component={SearchLocationInput}
                                  onBlur={(event) => {
                                    setFieldTouched(
                                      `jobs.${index}.place`,
                                      true,
                                    );
                                  }}
                                  index={index}></Field>
                                {touched?.jobs?.[index]?.place &&
                                  errors?.jobs?.[index]?.place && (
                                    <div className="form-errors">
                                      {errors?.jobs?.[index]?.place?.address}
                                    </div>
                                  )}
                                {/*<div className="form-errors">
                                 <ErrorMessage
                                  name={`jobs.${index}.place.address`}
                                />
                                </div>*/}
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="8" md="6" xl="5">
                          <Row>
                            <Col xs="6" xl="4" xxl="3">
                              <div className="form-group time-picker-group">
                                <label className="form-label">
                                  {`${t('startLabel')} *`}
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  selected={
                                    // values.jobs &&
                                    // values.jobs[index] &&
                                    // values.jobs[index].start
                                    showExactTime(values.jobs[index].start)
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onChange={(date) => {
                                    if (date === null || date === '') {
                                      date = values.jobs[index].date;
                                    }
                                    // const time = new Date(date).valueOf();
                                    handleFieldChange({
                                      id: index,
                                      name: 'start',
                                      value: date,
                                    });
                                    setFieldTouched(
                                      `jobs.${index}.start`,
                                      true,
                                    );
                                  }}
                                  showTimeSelectOnly
                                  timeCaption={t('startTimeLabel')}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  dateFormat="HH:mm"
                                  className="form-control"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  name={`jobs.${index}.start`}
                                  onBlur={(event) => {
                                    setFieldTouched(
                                      `jobs.${index}.start`,
                                      true,
                                    );
                                  }}
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].start &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].start && (
                                    <div className="form-errors">
                                      {errors.jobs[index].start}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs="6" xl="4" xxl="3">
                              <div className="form-group time-picker-group time-picker-group-end">
                                <label className="form-label form-label-end-time">
                                  <span>{`${t('endLabel')} *`}</span>
                                  <>
                                    {values.jobs &&
                                      values.jobs[index] &&
                                      values.jobs[index].end &&
                                      values.jobs[index].start && (
                                        <RenderEndWarning
                                          start={values.jobs[index].start}
                                          end={values.jobs[index].end}
                                        />
                                      )}
                                  </>
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  selected={
                                    // values.jobs &&
                                    // values.jobs[index] &&
                                    // values.jobs[index].end
                                    showExactTime(values.jobs[index].end)
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onChange={(date) => {
                                    if (date === null || date === '') {
                                      date = values.jobs[index].date;
                                    }
                                    // const time = new Date(date).valueOf();
                                    handleFieldChange({
                                      id: index,
                                      name: 'end',
                                      value: date,
                                    });
                                    setFieldTouched(`jobs.${index}.end`, true);
                                  }}
                                  showTimeSelectOnly
                                  timeCaption={t('endTimeLabel')}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  dateFormat="HH:mm"
                                  className="form-control"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  name={`jobs.${index}.end`}
                                  onBlur={(event) => {
                                    setFieldTouched(`jobs.${index}.end`, true);
                                  }}
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].end &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].end && (
                                    <div className="form-errors">
                                      {errors.jobs[index].end}
                                      {/* {errors.jobs[index].grater} */}
                                      {errors.jobs[index].end.includes(
                                        '10h',
                                      ) && (
                                        <OverlayTrigger
                                          placement="auto-start"
                                          delay={{
                                            show: 100,
                                            hide: 400,
                                          }}
                                          overlay={EndInfo}>
                                          <svg
                                            width="22"
                                            height="23"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="popover-icon">
                                            <g
                                              transform="translate(0 .5)"
                                              fill="none"
                                              fillRule="evenodd">
                                              <circle
                                                fill="#41A9C7"
                                                cx="11"
                                                cy="11"
                                                r="11"
                                              />
                                              <path
                                                d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                                                fill="#FFF"
                                                fillRule="nonzero"
                                              />
                                            </g>
                                          </svg>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs="6" xl="2" xxl="3">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('requestOverview.price')}
                                </label>
                                <div className="fields-text">
                                  €{' '}
                                  {!isNaN(job?.employerData?.jobPrice)
                                    ? printPriceValue(
                                        job?.employerData?.jobPrice,
                                      )
                                    : !isNaN(job?.price)
                                    ? printPriceValue(job?.price)
                                    : 0}
                                </div>
                              </div>
                            </Col>
                            <Col xs="6" xl="2" xxl="3">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('requestOverview.travelCosts1')}&shy;
                                  {t('requestOverview.travelCosts2')}
                                </label>
                                <div className="fields-text">
                                  €{' '}
                                  {job?.employerData?.jobTravelCost
                                    ? printPriceValue(
                                        job?.employerData?.jobTravelCost,
                                      )
                                    : job?.travelCosts
                                    ? printPriceValue(job.travelCosts)
                                    : 0}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="4" md="12" xl="2">
                          <Row>
                            <Col
                              xs="6"
                              sm="12"
                              md="6"
                              className="text-sm-center">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('requestOverview.modal.duplicate')}
                                </label>
                                <div
                                  className="field-action-icon"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      ...values.jobs[index],
                                    });
                                  }}>
                                  <svg
                                    width="21"
                                    height="23"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g
                                      transform="translate(.5)"
                                      fill="none"
                                      fillRule="evenodd">
                                      <rect
                                        stroke="#41A9C7"
                                        strokeWidth="2"
                                        x="1"
                                        y="5"
                                        width="13"
                                        height="17"
                                        rx="2"
                                      />
                                      <g fill="#41A9C7">
                                        <path d="M6 10h3v7H6z" />
                                        <path d="M4 15v-3h7v3z" />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Col>
                            <Col
                              xs="6"
                              sm="12"
                              md="6"
                              className="text-sm-center">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('delete')}
                                </label>
                                <div
                                  // className="field-action-icon"
                                  className={`field-action-icon ${
                                    values.jobs.length !== 1 ? '' : 'disable'
                                  }`}
                                  onClick={() => arrayHelpers.remove(index)}>
                                  <svg
                                    width="19"
                                    height="22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                      fill="#41A9C7"
                                      fillRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                ))
              : null}
            {addAnotherJob && (
              <Row>
                <Col className="text-right">
                  <span
                    className="link"
                    onClick={() => {
                      arrayHelpers.push({
                        ...initialJobPosition,
                      });
                    }}>
                    {t('home.linkJob')}
                  </span>
                </Col>
              </Row>
            )}
            <Row className="mb-3 align-items-center">
              <Col className="text-left">
                <div>{t('amountVAT')}</div>
              </Col>
              <Col className="text-right">
                <div className="total-amount">
                  € {newJobsTotal && newJobsTotal !== 'NaN' ? newJobsTotal : 0}
                </div>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};

export default JobPositionForm;
