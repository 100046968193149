import { userConstants } from '../actions/types';

let token = localStorage.getItem('token');
let user = localStorage.getItem('user');
let parsedUser;
if (user) {
  parsedUser = JSON.parse(user);
} else {
  parsedUser = {};
}
const initialState = token
  ? { loading: false, data: { ...parsedUser, token }, error: '' }
  : { loading: false, data: { ...parsedUser, token: '' }, error: '' };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        data: {
          token: '',
        },
        error: '',
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.COMPLETE_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        data: {
          token: '',
        },
        error: '',
      };
    case userConstants.COMPLETE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case userConstants.COMPLETE_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        data: {
          token: '',
        },
        error: '',
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.user,
        error: '',
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        data: {
          token: '',
        },
        error: action.error,
      };
    case userConstants.GET_ME:
      return {
        ...state,
        loading: false,
        data: action.user,
        error: '',
      };
    case userConstants.UPDATE_ME_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case userConstants.UPDATE_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.user,
        error: '',
      };
    case userConstants.UPDATE_ME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.ADD_EVENT:
      const employee = { ...state.data.employee, appliedEvents: action.events };
      const data = { ...state.data, employee };

      return {
        ...state,
        loading: false,
        data,
        error: '',
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {},
        error: '',
      };
    case userConstants.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case userConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case userConstants.FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        data: {
          token: '',
        },
        error: '',
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.user,
        error: '',
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        data: {
          token: '',
        },
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default authReducer;
