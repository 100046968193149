import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, ErrorMessage } from 'formik';
// import SearchLocationInput from '../SearchLocationInput';
import * as Yup from 'yup';
import EmployerImageChange from '../Register/EmployerImageChange';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Input from '../Core/Input';
import Button from '../Core/Button';
import Loader from '../Core/Loader';
import { updateMe } from '../../actions/authAction';
// import { getUnsignedURL } from '../../utils/helper';
import PlacesAutocomplete, {
  geocodeByAddress,
} from '@dsr/react-places-autocomplete';
import { toast } from 'react-toastify';
import {
  NUMBER_ONLY,
  ALPHABETS_ONLY,
  EMAIL_VALIDATION,
} from '../../utils/constants';
import CustomSelect from '../Core/Select';
import GlobalContext from '../../context/GlobalContext';

export const setPasswordConfirmError = (
  password,
  passwordConfirm,
  touchedPasswordConfirm,
  setFieldError,
) => {
  if (password && !passwordConfirm && touchedPasswordConfirm) {
    setFieldError('passwordConfirm', 'Please re enter password');
    return true;
  } else {
    return false;
  }
};

const EmployerProfile = () => {
  const { t: profile } = useTranslation('profile');
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const user = userData.data;
  const [showEmployerImageModal, setShowEmployerImageModal] = useState(false);
  const [uploadStep, setUploadStep] = useState(1);
  const { email, photo, phone, firstName, lastName, employer } = user;
  const [companyPhoto, setCompanyPhoto] = useState(null);

  const [saveProfileLoading, setSaveProfileLoading] = useState(false);
  const formikFormRef = useRef();
  const [addressline, setAddressLine] = useState(employer.addressLineOne);
  const [selectedcity, setSelectedCity] = useState(employer.city);
  const { countriesOptions } = useContext(GlobalContext);
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  useEffect(() => {
    if (addressline === '') {
      formikFormRef?.current?.setFieldValue('addressLineOne', '');
    } else {
      formikFormRef?.current?.validateForm();
    }
  }, [addressline]);
  useEffect(() => {
    if (selectedcity === '') {
      formikFormRef?.current?.setFieldValue('city', '');
    } else {
      formikFormRef?.current?.validateForm();
      // console.log("clear error", formikFormRef);
      // formikFormRef?.current?.errors?.city = '';
    }
  }, [selectedcity]);

  if (userData.loading) {
    return <Loader />;
  }

  const getSelectValue = (arr, val) => {
    let observed;
    arr.map((el) => {
      if (el.value === val) {
        observed = { ...el };
      }
      return false;
    });

    return observed;
  };

  const renderSuggestionItem = (suggestion, type) => {
    if (type === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };

  const handleError = (name, error) => {
    formikFormRef?.current?.setFieldError(name, error);
  };

  const handleSelect = async (address, name, type, suggestion) => {
    try {
      let returnAddress = address;
      if (type === 'sublocality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else if (type === 'locality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else {
        const res = await geocodeByAddress(address);
        if (type === 'sublocality' || type === 'locality') {
          returnAddress = res[0].address_components[0].long_name;
        }
      }

      if (name === 'city') {
        setSelectedCity(returnAddress);
        formikFormRef?.current?.validateForm();
      } else {
        setAddressLine(returnAddress);
        formikFormRef?.current?.validateForm();
      }
      // handleChange(name, returnAddress);
      formikFormRef?.current?.setFieldValue(name, returnAddress);
    } catch (error) {
      formikFormRef?.current?.setFieldError(name, error);
    }
  };

  return (
    <Formik
      innerRef={formikFormRef}
      initialValues={{
        companyName:
          employer && employer.companyName ? employer.companyName : '',
        email: email ? email : '',
        phone: phone ? phone : '',
        firstName: firstName ? firstName : '',
        photo: photo ? photo : '',
        lastName: lastName ? lastName : '',
        // country: employer && employer.country ? employer.country : '',
        // country: employer && employer.country ? employer.country : '',
        country: getSelectValue(
          countriesOptions,
          employer && employer.country ? employer.country : '',
        ),
        city: employer && employer.city ? employer.city : '',
        postalCode: employer && employer.postalCode ? employer.postalCode : '',
        addressLineOne:
          employer && employer.addressLineOne ? employer.addressLineOne : '',
        addressLineTwo:
          employer && employer.addressLineTwo ? employer.addressLineTwo : '',
        contactPerson:
          employer && employer.contactPerson ? employer.contactPerson : '',
        password: '',
        passwordConfirm: '',
      }}
      validationSchema={Yup.object({
        companyName: Yup.string().required(t('companyName')),
        firstName: Yup.string().required(t('FirstName')),
        lastName: Yup.string().required(t('LastName')),
        email: Yup.string()
          .matches(EMAIL_VALIDATION, {
            message: t('Email.type'),
          })
          .required(t('Email.required')),
        phone: Yup.number()
          .typeError(t('Phone.type'))
          .positive(t('Phone.type'))
          .integer(t('Phone.type'))
          .required(t('Phone.required'))
          .test(
            'maxDigits',
            t('Phone.length9'),
            (number) => String(number).length >= 9,
          ),
        password: Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        ),
        passwordConfirm: Yup.string().when('password', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            t('confirmpassword.match'),
          ),
        }),
        contactPerson: Yup.string()
          .required(t('contactPersonplaceholder'))
          .matches(/^([^0-9]*)$/, t('contactperson.match')),
        postalCode: Yup.string()
          .required(t('PostalCode'))
          .matches(/^[0-9]/, t('postalcodenotnegative'))
          .min(4, t('Postalcodevalidation'))
          .max(5, t('Postalcodevalidation')),
        city: Yup.string().required(t('City')),
        // country: Yup.string().required(t('Country')),
        country: Yup.object().shape({
          label: Yup.string().required(i18n.t('Country')),
          value: Yup.string().required(i18n.t('Country')),
        }),
        addressLineOne: Yup.string().required(t('AddressLineOne')),
      })}
      onSubmit={(values, actions) => {
        // actions.setSubmitting(true);
        const passwordError = setPasswordConfirmError(
          values.password,
          values.passwordConfirm,
          true,
          actions.setFieldError,
        );
        if (passwordError) {
          actions.setSubmitting(false);
          return;
        } else {
          let newValues = {};
          let formData = new FormData();
          if (companyPhoto) {
            newValues = {
              ...values,
              country: values?.country?.value,
              photo: companyPhoto,
              // typeof companyPhoto === 'string' &&
              // companyPhoto.includes(process.env.REACT_APP_GCS_BUCKET)
              //   ? getUnsignedURL(companyPhoto)
              //   : companyPhoto,
            };
          } else {
            newValues = {
              ...values,
              country: values?.country?.value,
              // photo: getUnsignedURL(photo),
            };
          }

          if (!values.password) {
            delete newValues.password;
          }
          delete newValues.passwordConfirm;
          // eslint-disable-next-line array-callback-return
          Object.entries(newValues).map((val) => {
            formData.append(val[0], val[1]);
          });

          dispatch(
            updateMe({
              formData,
              callback: (res) => {
                if (res?.status === false) {
                  toast.error(res?.error);
                }
                actions.setSubmitting(false);
              },
              message: `${t('employerProfile.userUpdatedSuccessfully')}`,
            }),
          );
        }
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <form className="form" onSubmit={handleSubmit}>
          {showEmployerImageModal && (
            <EmployerImageChange
              showEmployerImageModal={showEmployerImageModal}
              setShowEmployerImageModal={setShowEmployerImageModal}
              uploadStep={uploadStep}
              setUploadStep={setUploadStep}
              companyPhoto={companyPhoto}
              setCompanyPhoto={setCompanyPhoto}
            />
          )}
          {saveProfileLoading && <Loader />}
          <Row>
            <Col xs="12" sm="5" lg="3" xl="3">
              <div className="profile-pic">
                {companyPhoto ? (
                  <div
                    className="profile-img-in"
                    style={{
                      backgroundImage: `url(${URL.createObjectURL(
                        companyPhoto,
                      )})`,
                    }}></div>
                ) : photo &&
                  photo !== 'undefined' &&
                  typeof user.photo !== 'undefined' ? (
                  <div
                    className="profile-img-in"
                    style={{
                      backgroundColor: '#ddd',
                      backgroundImage: `url(data:image/jpeg;base64,${photo})`,
                    }}></div>
                ) : (
                  <div
                    className="profile-img-in staff-profile-image--big plus-icon"
                    style={{
                      backgroundColor: '#ddd',
                    }}></div>
                )}
                <Button
                  className="btn"
                  variant="primary"
                  label={t('orgImageModal.changeImage')}
                  type="button"
                  onClick={() => setShowEmployerImageModal(true)}
                />
              </div>
            </Col>
            <Col xs="12" lg="9" xl="7" className="padding-left-30">
              {/*<div className="LanguageSelector mb-5">
                <Button
                  type="button"
                  className="btn primary-btn"
                  onClick={() => changeLanguage('de')}
                  label="de"
                />
                <Button
                  type="button"
                  className="btn primary-btn"
                  onClick={() => changeLanguage('en')}
                  label="en"
                  />
              </div>*/}
              <Row>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="text"
                    label={`${t('company')} *`}
                    placeholder={t('companyplaceholder')}
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    touched={touched.companyName}
                    errors={errors.companyName}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="email"
                    label={`${t('email')} *`}
                    placeholder={t('emailplaceholder')}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    touched={touched.email}
                    errors={errors.email}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="phone"
                    label={`${t('phone')} *`}
                    placeholder={t('phoneplaceholder')}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    touched={touched.phone}
                    errors={errors.phone}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="text"
                    label={`${t('firstName')} *`}
                    placeholder={t('fname')}
                    name="firstName"
                    value={values.firstName}
                    // onChange={handleChange}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        ALPHABETS_ONLY.test(e.target.value)
                      ) {
                        handleChange(e);
                      }
                    }}
                    touched={touched.firstName}
                    errors={errors.firstName}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="text"
                    label={`${t('lastName')} *`}
                    placeholder={t('lname')}
                    name="lastName"
                    value={values.lastName}
                    // onChange={handleChange}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        ALPHABETS_ONLY.test(e.target.value)
                      ) {
                        handleChange(e);
                      }
                    }}
                    touched={touched.lastName}
                    errors={errors.lastName}
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <div className="form-group">
                    <label className="form-label">{`${t(
                      'addressLineOne',
                    )} *`}</label>
                    <PlacesAutocomplete
                      name={'addressLineOne'}
                      id={'addressLineOne'}
                      placeholder={t('addressLineOneplaceholder')}
                      searchOptions={['address']}
                      value={addressline}
                      onChange={(value) => {
                        handleSelect('', 'addressLineOne', 'sublocality', '');
                        setAddressLine(value);
                      }}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(
                          data,
                          'addressLineOne',
                          'sublocality',
                          suggestion,
                        )
                      }
                      onError={(error) => handleError('addressLineOne', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: `${t('addressLineOneplaceholder')}`,
                              className: 'location-search-input form-control',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (!suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'sublocality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="form-errors">
                      <ErrorMessage name={`addressLineOne`} />
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <Input
                    type="text"
                    //label="Street Line 2"
                    label={t('addressLineTwo')}
                    placeholder={t('addressLineTwoplaceholder')}
                    name="addressLineTwo"
                    value={values.addressLineTwo}
                    onChange={handleChange}
                    touched={touched.addressLineTwo}
                    errors={errors.addressLineTwo}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="text"
                    label={`${t('postalCode')} *`}
                    placeholder={t('postalCodeplaceholder')}
                    // onChange={handleChange}
                    name="postalCode"
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        (NUMBER_ONLY.test(e.target.value) &&
                          e.target.value?.length <= 5)
                      ) {
                        handleChange(e);
                      }
                    }}
                    value={values.postalCode}
                    touched={touched.postalCode}
                    errors={errors.postalCode}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <div className="form-group">
                    {/* <label className="form-label">City</label>
                    <Field
                      name={`city`}
                      inputProps={{ placeholder: 'Please enter city' }}
                      types={'locality'}
                      component={SearchLocationInput}
                      index={0}></Field>
                    <div className="form-errors">
                      <ErrorMessage name={`city`} />
                    </div> */}
                    <label className="form-label">{t('city')} *</label>
                    <PlacesAutocomplete
                      name={'city'}
                      id={'city'}
                      searchOptions={['(cities)']}
                      value={selectedcity}
                      onChange={(e) => {
                        handleSelect('', 'city', 'locality', '');
                        setSelectedCity(e);
                      }}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(data, 'city', 'locality', suggestion)
                      }
                      onError={(error) => handleError('city', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: `${t('cityplaceholder')}`,
                              className: 'location-search-input form-control',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'locality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {errors?.city !== '' && (
                      <div className="form-errors">{errors?.city}</div>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="6" lg="4">
                  {/* <Input
                    type="text"
                    //label="Country"
                    label={`${t('country')} *`}
                    placeholder={t('countryplaceholder')}
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    touched={touched.country}
                    errors={errors.country}
                  /> */}
                  <CustomSelect
                    label={`${t('country')} *`}
                    name="country"
                    options={countriesOptions}
                    placeholder={t('countryplaceholder')}
                    handleChange={(value) => {
                      setFieldValue('country', value);
                    }}
                    handleBlur={() => {
                      setFieldTouched('country', true);
                    }}
                    touched={touched.country}
                    errors={errors.country ? errors.country.value : ''}
                    value={values.country}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="text"
                    //label="Contact Person"
                    label={`${t('contactPerson')} *`}
                    placeholder={t('contactPersonplaceholder')}
                    name="contactPerson"
                    value={values.contactPerson}
                    onChange={handleChange}
                    touched={touched.contactPerson}
                    errors={errors.contactPerson}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="password"
                    // label="Password"
                    label={t('password')}
                    placeholder={t('passwordplaceholder')}
                    name="password"
                    value={values.password}
                    autoComplete="new-password"
                    onChange={handleChange}
                    touched={touched.password}
                    errors={errors.password}
                  />
                </Col>
                <Col xs="12" sm="6" lg="4">
                  <Input
                    type="password"
                    // label="Re-Enter Password"
                    label={t('confirmPassword')}
                    placeholder={t('confirmPasswordplaceholder')}
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    autoComplete="new-password"
                    onChange={(e) => {
                      setFieldValue('passwordConfirm', e.target.value);
                      setPasswordConfirmError(
                        values.password,
                        values.passwordConfirm,
                        true,
                      );
                    }}
                    touched={touched.passwordConfirm}
                    errors={errors.passwordConfirm}
                  />
                </Col>
                <div className="form-bottom justify-content-end">
                  <Button
                    // onClick={() => {
                    //   setSaveProfileLoading(true);
                    // }}
                    type="submit"
                    // label="save"
                    label={t('save')}
                    className="btn primary-btn"
                    disabled={isSubmitting || Object.entries(errors).length > 0}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default EmployerProfile;
