import React, { useLayoutEffect, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, NavLink } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Button from '../Core/Button';
import { roles, labelRoles } from '../../utils/constants';
import { logout } from '../../actions/authAction';
import logo from '../../assets/images/logo.svg';
import defaultAvatar from '../../assets/images/default-avatar.png';
import { getAllZipCodes } from '../../actions/zipcodeAction';
import { useTranslation } from 'react-i18next';
import api from '../../utils/api';

export const handleLogoClick = () => {
  return '/';
};

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const userData = useSelector((state) => state.user);
  const { showLoginModal, setShowLoginModal } = useContext(GlobalContext);
  const user = userData.data;
  const settingsData = useSelector((state) => state.settings);
  const zipcodeData = useSelector((state) => state?.zipcode?.zipcodeList);
  const { settingData } = settingsData;
  const { t } = useTranslation();
  const [binaryImages, setBinaryImages] = useState([]);
  const [logoImage, setLogo] = useState([]);

  useEffect(() => {
    async function getlogo() {
      if (
        settingData?.logo &&
        settingData.logo !== 'null' &&
        settingData?.logo?.startsWith('public/upload/settings/')
      ) {
        let image = settingData.logo;
        const i = await api.post('global-settings/getLogoImage', {
          image,
        });
        setLogo(i.data.image);
      }
    }

    if (
      settingData?.logo &&
      settingData.logo !== 'null' &&
      settingData?.logo?.startsWith('public/upload/settings/')
    ) {
      getlogo();
    }
  }, [settingData?.logo]);

  useEffect(() => {
    async function getimage() {
      if (userData && userData.data) {
        if (
          userData.data.role === 'employee' &&
          userData.data?.employee?.images &&
          userData.data?.employee?.images.length
        ) {
          const image = userData.data?.employee?.images[0];
          const response = await api.post('users/getImageUrl', { image });
          const newimage = response.data.image;
          setBinaryImages(newimage);
        }
      }
    }
    getimage();
  }, [userData]);

  useEffect(() => {
    const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
    const isValidHTTPURL = (url) => urlPattern.test(url);
    async function getimage() {
      if (user && user.photo && !isValidHTTPURL(user.photo)) {
        const image = user.photo;
        const response = await api.post('users/getImageUrl', { image });
        const newimage = response.data.image;
        user.photo = newimage;
      }
    }
    getimage();
  }, [user]);

  useEffect(() => {
    if (zipcodeData && zipcodeData.length) {
      return;
    }
    dispatch(getAllZipCodes());
  }, []);

  const handleToggle = () => {
    setIsOpened(!isOpened);
  };

  const handleLogout = () => {
    dispatch(
      logout(() => {
        history.push('/');
      }),
    );
  };

  const sticky = useStickyHeader(25);
  return (
    <>
      <header
        className={`page-header ${isOpened ? 'menu-open' : ''} ${
          sticky ? 'page-header--sticky' : ''
        }`}>
        <div className="main-header">
          <div className="brand-logo">
            <Link
              to={handleLogoClick(userData)}
              style={
                userData.data.role === roles.employee
                  ? { pointerEvents: 'none' }
                  : {}
              }>
              <img
                src={
                  settingData?.logo &&
                  settingData.logo !== 'null' &&
                  settingData?.logo?.startsWith('public/upload/settings/') &&
                  logoImage &&
                  logoImage.length
                    ? logoImage
                    : logo
                }
                alt="logo"
                height="auto"
                width="100"
              />
            </Link>
          </div>
          {userData.data && userData.data.token ? (
            <>
              <div className="navigation">
                <nav>
                  {user && user.role === roles.employer ? (
                    <ul>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employer}/request-overview`}
                          activeClassName="active"
                          className={`header-link${
                            history.location.pathname ===
                            `/${labelRoles.employer}/new-request`
                              ? ' active'
                              : ''
                          }`}>
                          {t('requestStaff')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employer}/my-events`}
                          activeClassName="active"
                          className={`header-link${
                            history.location.state &&
                            history.location.state.event
                              ? ' active'
                              : ''
                          }`}>
                          {t('myEvents')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employer}/profile`}
                          activeClassName="active"
                          className="header-link">
                          {t('profile')}
                        </NavLink>
                      </li>
                    </ul>
                  ) : user && user.role === roles.employee ? (
                    <ul>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employee}/job-wall`}
                          activeClassName="active"
                          className={`header-link${
                            history.location.pathname.includes(
                              `/${labelRoles.employee}/job-wall-details`,
                            )
                              ? ' active'
                              : ''
                          }`}>
                          {t('jobWall')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employee}/my-jobs`}
                          activeClassName="active"
                          className={`header-link${
                            history.location.pathname.includes(
                              `/${labelRoles.employee}/job-details`,
                            )
                              ? ' active'
                              : ''
                          }`}>
                          {t('myJobs')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employee}/profile`}
                          activeClassName="active"
                          className="header-link">
                          {t('profileStaff')}
                        </NavLink>
                      </li>
                      {/*<li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.employee}/`}
                          activeClassName="active"
                          className="header-link">
                          {t('settings')}
                        </NavLink>
                        </li>*/}
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.superuser}/eventslist`}
                          activeClassName="active"
                          className={`header-link${
                            history.location.pathname ===
                            `/${labelRoles.superuser}/eventslist`
                              ? ' active'
                              : ''
                          }`}>
                          {t('events')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setIsOpened(false)}
                          exact
                          to={`/${labelRoles.superuser}/profile`}
                          activeClassName="active"
                          className="header-link">
                          {t('profile')}
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </nav>
              </div>
              <div className="header-right-links">
                <div
                  className="d-block d-lg-none menu-trigger"
                  onClick={handleToggle}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
                <Link to="/contact" className="help-btn-link">
                  <span>{t('help')}</span>
                </Link>
                <div className="profile-details">
                  {user && user.role === roles.employer ? (
                    <Link
                      to={`/${labelRoles.employer}/profile`}
                      className="profile-link  d-none d-lg-flex">
                      <div className="profile-avatar">
                        {user.photo &&
                        !user.photo.includes('undefined') &&
                        user.photo !== 'null' ? (
                          <>
                            <img src={user.photo} alt={t('profile')} />
                          </>
                        ) : (
                          <img src={defaultAvatar} alt={t('profile')} />
                        )}
                      </div>
                      <div className="profile-content">
                        {user && user.employer && (
                          <>
                            <span>{user.employer.companyName}</span>
                            <span>{user.employer.city}</span>
                          </>
                        )}
                      </div>
                    </Link>
                  ) : user && user.role === roles.superuser ? (
                    <Link
                      to={`/${labelRoles.superuser}/profile`}
                      className="profile-link  d-none d-lg-flex">
                      <div className="profile-avatar">
                        {user.photo && !user.photo.includes('undefined') ? (
                          <img src={user.photo} alt={t('profile')} />
                        ) : (
                          <img src={defaultAvatar} alt={t('profile')} />
                        )}
                      </div>
                      <div className="profile-content">
                        {user && user.employer && (
                          <>
                            <span>{user.employer.companyName}</span>
                            <span>{user.employer.city}</span>
                          </>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={`/${labelRoles.employee}/profile`}
                      className="profile-link  d-none d-lg-flex">
                      <div className="profile-avatar">
                        {user.employee &&
                        user.employee.images &&
                        user.employee.images.length ? (
                          <>
                            <img src={binaryImages} alt={t('profile')} />
                          </>
                        ) : (
                          <img src={defaultAvatar} alt={t('profile')} />
                        )}
                      </div>
                      <div className="profile-content">
                        <span>
                          {user.firstName} {user.lastName}
                        </span>

                        {user.employee && <span>{user.employee.city}</span>}
                      </div>
                    </Link>
                  )}
                  <span onClick={() => handleLogout()} className="d-lg-none">
                    <svg viewBox="0 0 384.971 384.971" width="30" fill="#fff">
                      <path d="M180.455 360.91H24.061V24.061h156.394c6.641 0 12.03-5.39 12.03-12.03s-5.39-12.03-12.03-12.03H12.03C5.39.001 0 5.39 0 12.031V372.94c0 6.641 5.39 12.03 12.03 12.03h168.424c6.641 0 12.03-5.39 12.03-12.03.001-6.641-5.389-12.03-12.029-12.03z" />
                      <path d="M381.481 184.088l-83.009-84.2a11.942 11.942 0 00-17.011 0c-4.704 4.74-4.704 12.439 0 17.179l62.558 63.46H96.279c-6.641 0-12.03 5.438-12.03 12.151s5.39 12.151 12.03 12.151h247.74l-62.558 63.46c-4.704 4.752-4.704 12.439 0 17.179a11.931 11.931 0 0017.011 0l82.997-84.2c4.644-4.68 4.692-12.512.012-17.18z" />
                    </svg>
                  </span>
                  <Button
                    onClick={() => handleLogout()}
                    className="arrow-link d-none d-lg-block"
                    //label="Logout"
                    label={t('logout')}
                  />
                </div>
              </div>
            </>
          ) : (
            // user is logged in header
            <>
              <div className="header-right-links without-login-links">
                <div className="profile-details without-login-detail">
                  <p className="link-label d-none d-sm-block">
                    {t('home.headerLogin')}
                  </p>
                  <Button
                    className="arrow-link"
                    variant="primary"
                    //label="Log in"
                    label={t('login')}
                    type="button"
                    onClick={() =>
                      setShowLoginModal({
                        ...showLoginModal,
                        visible: true,
                        // role: roles.employer,
                      })
                    }
                  />
                </div>
                <div className="profile-details without-login-detail">
                  <p className="link-label d-none d-sm-block">
                    {t('home.headerWorkWithUs')}
                  </p>
                  <Link to="/workwithus" className="arrow-link">
                    {t('workWithus')}
                  </Link>
                </div>
              </div>
            </>
            // user is not logged in header
          )}
        </div>
      </header>
    </>
  );
};

function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);

  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return stick;
}

export default Header;
