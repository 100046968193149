import React, { useState } from 'react';
import Head from '../components/Head';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  fetchEvents,
  getEvents,
  fetchCurrentEvents,
} from '../actions/eventAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PaymentCompleted = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const userData = user.data;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [orderid, setorderid] = useState('');
  React.useEffect(() => {
    if (userData.role === 'superuser') {
      dispatch(getEvents({ currPage: 1 }, true, false));
      // dispatch(fetchCurrentEvents({ currPage: 1 }, true, false));
    } else {
      dispatch(fetchEvents({ currPage: 1 }, true, false));
      // dispatch(fetchCurrentEvents({ currPage: 1 }, true, false));
    }
    if (!location.state || !location.state.stripeorderid) {
      setorderid('');
    } else {
      setorderid(location.state.stripeorderid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="content-section">
      <Head title={t('title.paymentComplete')} />
      <Container>
        <div className="box-wrapper">
          <Row>
            <Col lg={6}>
              <h3>{t('payment_completed')}</h3>
              <p className="lead">{t('Thank_you')}.</p>
              <p className="lead">
                {t('confirmation_mail_text')}. {t('order_number')}:{' '}
                <strong>{orderid}</strong>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default PaymentCompleted;
