import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Core/Button';
import i18n from '../../i18n';

function InfoImageSaveModal({
  showInfoImageSaveModal,
  setInfoImageSaveModal,
  handleSubmitForm,
}) {
  const handleFormSubmit = () => {
    // Call the handleSubmitForm function
    handleSubmitForm();

    // Close the modal
    setInfoImageSaveModal(false);
  };
  return (
    <Modal
      className="image-upload-modal-content"
      show={showInfoImageSaveModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal"
      onHide={() => {
        setInfoImageSaveModal(false);
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{i18n.t('information')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18n.t('imageInfoModalText')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          label={i18n.t('save')}
          onClick={handleFormSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default InfoImageSaveModal;
