import React from 'react';
import pdf from '../../assets/images/icons/pdf.svg';
import txt from '../../assets/images/icons/txt.svg';
import doc from '../../assets/images/icons/doc.svg';

// const removeItem = {
//   position: 'absolute',
//   top: '-11px',
//   right: '-7px',
// };

const PdfUpload = ({
  className,
  file,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  removeFile,
}) => {
  const getImage = (file) => {
    const name = typeof file === 'string' ? file : file.name;
    const type = name ? name.split('.').pop() : '';
    if (type.includes('pdf')) {
      return <img src={pdf} alt="Extension" />;
    } else if (type.includes('txt')) {
      return <img src={txt} alt="Extension" />;
    } else {
      return <img src={doc} alt="Extension" />;
    }
  };

  return (
    <>
      <div className={`form-group`}>
        {label && <label className="form-label">{label}</label>}
        <div className="file-upload-btn-wrapper">
          {!file && (
            <input
              type={type}
              value={value}
              placeholder={placeholder}
              name={name}
              onChange={onChange}
              className={`form-control ${className ? className : ''}`}
            />
          )}
          <div className="file-upload-content">
            {file ? (
              <span className="extension-in">{getImage(file)}</span>
            ) : (
              <span className="btn btn-primary">{placeholder}</span>
            )}
          </div>
          <span className="delete-btn" onClick={removeFile}>
            <svg viewBox="0 0 19 22" width="19" height="22">
              <path
                fill="#41A9C7"
                fillRule="evenodd"
                d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 01-.841-.849V7.605c0-.47.376-.849.84-.849zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 01-.841-.849V7.605c0-.47.375-.849.84-.849zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849zm2.662 13.264a.282.282 0 01-.28.282H3.784a.286.286 0 01-.28-.282V4.81h11.911v15.21zM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 00-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 001.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 00-.84-.848h-4.558z"
              />
            </svg>
          </span>
        </div>
        {file && (
          <div className="mt-3 text-primary ">
            {typeof file === 'string' ? file : file.name}
          </div>
        )}
      </div>
    </>
  );
};

export default PdfUpload;
