import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/profile.json';
import translationDE from './locales/de/profile.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'de',
    lng: localStorage.getItem('lang') === 'en' ? 'en' : 'de',
    debug: false,

    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: false, // we use content as keys
    // react: {
    //   useSuspense: false,
    //   wait: false,
    // },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
