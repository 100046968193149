import React from 'react';
import { toast } from 'react-toastify';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import Button from '../Core/Button';
import { useTranslation } from 'react-i18next';

const AudioRecording = ({
  tempAudio,
  setTempAudio,
  setTimeRemaining,
  timeStore,
  recordState,
  setRecordState,
  resetTimeRemaining,
  setAudio,
  setShowRecorderModel,
}) => {
  const { t, i18n } = useTranslation();
  const start = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        if (stream.getAudioTracks().length > 0) {
          setRecordState(RecordState.START);
        }
      })
      .catch(function (error) {
        toast.error('Please provide audio permission to record your voice');
      });
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    setTempAudio(audioData);
  };

  return (
    <div className="mt-4 mx-0">
      <AudioReactRecorder state={recordState} onStop={onStop} />
      {recordState ? (
        <>
          {recordState === 'start' ? (
            <Button
              className="btn btn--red"
              label={t('audioModal.stopRecording')}
              onClick={stop}
            />
          ) : (
            <div className="d-flex justify-content-end">
              <Button
                className="btn"
                label={t('audioModal.deleteRecording')}
                onClick={() => {
                  setRecordState(null);
                  setTempAudio(null);
                  resetTimeRemaining();
                }}
              />
              <Button
                className="btn"
                label={t('audioModal.saveRecording')}
                onClick={() => {
                  setAudio(tempAudio);
                  setRecordState(null);
                  setTimeRemaining(timeStore);
                  setShowRecorderModel(false);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <Button
          className="btn"
          label={t('audioModal.startRecording')}
          onClick={start}
        />
      )}
    </div>
  );
};

export default AudioRecording;
