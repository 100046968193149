import React, { useEffect, useState, useRef, useCallback } from 'react';
import Newsletter from './NewsletterForm';
import i18n from "../../i18n";


const ExitIntentPopup = ({ disabled }) => {
  const [showPopup, setShowPopup] = useState(false);
  const hasSubscribed = localStorage.getItem('hasSubscribed');
  const isGerman = i18n.language === 'de';
  let isMobile;
  if (navigator.userAgentData) {
    isMobile = navigator.userAgentData.mobile;
  } else {
    isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const handleMouseLeave = useCallback((event,popupDisabled) => {
    if ((localStorage.getItem('hasClosedPopup') === 'false' || localStorage.getItem('hasClosedPopup') == null) &&
      !showPopup &&
      !popupDisabled &&
      (event.clientY <= 0 || event.relatedTarget === null) &&
      (localStorage.getItem('hasSubscribed') === 'false' || localStorage.getItem('hasSubscribed') == null)
    ) {
      setShowPopup(true);
    }
    if(!showPopup &&
      !popupDisabled &&
      isMobile &&
      ((window.innerHeight + window.pageYOffset + document.getElementsByClassName('page-footer')[0].clientHeight) >= document.body.offsetHeight) &&
      (localStorage.getItem('hasClosedPopup') === 'false' || localStorage.getItem('hasClosedPopup') == null) &&
      (localStorage.getItem('hasSubscribed') === 'false' || localStorage.getItem('hasSubscribed') == null)
    ) {
      setShowPopup(true);
    }
  }, [isMobile, showPopup]);

  const mouseLeaveRef = useRef(handleMouseLeave);

  useEffect(() => {
    const handleMouseOut = (event) => {
      mouseLeaveRef.current(event, disabled);
    };

    const handleTouchMove = (event) => {
      mouseLeaveRef.current(event, disabled);
    };

    document.addEventListener('mouseout', handleMouseOut);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('mouseout', handleMouseOut);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleMouseLeave, disabled, hasSubscribed, isMobile, showPopup]);
  const handleClose = () => {
    localStorage.setItem('hasClosedPopup', 'true');
    setShowPopup(false);
  };

  const handleSubscribeSuccess = () => {
    handleClose();
  };
  return (
    <div className={`exit-popup ${isGerman ? 'german' : 'english'} ${showPopup ? 'show' : ''}`}>
      <div className="button-container">
        <button type="button" onClick={handleClose} className="btn-close" aria-label="Close"></button>
      </div>
      <Newsletter onSubscribeSuccess={handleSubscribeSuccess} className="exit-intent-style" />
    </div>
  );
};

export default ExitIntentPopup;
