import { userConstants, jobConstants } from '../actions/types';

const initialState = {
  loading: false,
  allJobs: [],
  myJobs: {
    jobs: [],
    currPage: 0,
  },
  error: '',
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobConstants.GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
        allJobs: [],
        error: '',
      };
    case jobConstants.GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        allJobs: action.jobs,
        error: '',
      };
    case jobConstants.GET_ALL_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        allJobs: [],
        error: action.error,
      };
    case jobConstants.GET_MY_JOBS_REQUEST:
      return {
        ...state,
        loading: state.myJobs.jobs.length ? false : true,
        error: '',
      };
    case jobConstants.GET_MY_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        myJobs: {
          jobs: action.jobs,
          currPage: action.currPage,
          pageCount: action.pageCount,
        },
        error: '',
      };
    case jobConstants.GET_MY_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        myJobs: {
          jobs: [],
          currPage: 0,
        },
        error: action.error,
      };
    case jobConstants.APPLY_FOR_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        allJobs: action.allJobs,
        myJobs: action.myJobs,
        error: action.error,
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        allJobs: [],
        myJobs: {
          jobs: [],
          currPage: 0,
        },
        error: '',
      };
    case jobConstants.GET_TEASER_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
        teaserJobs: [],
        error: '',
      };
    case jobConstants.GET_TEASER_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        teaserJobs: action.jobs,
        error: '',
      };
    case jobConstants.GET_TEASER_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        teaserJobs: [],
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default jobReducer;
