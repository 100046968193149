import { settingConstants } from '../actions/types';

const initialState = {
  error: '',
  jobs: [],
  settingLoading: false,
  settingData: [],
  settingError: '',
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.GET_JOB_NAMES_SUCCESS:
      return {
        ...state,
        jobs: action.jobs,
        error: '',
      };
    case settingConstants.GET_JOB_NAMES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
      case settingConstants.GET_SETTINGS_REQUEST:
      return {
        ...state,
        settingLoading: true,
        settingData: [],
        settingError: '',
      };
    case settingConstants.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settingLoading: false,
        settingData: action.settings,
        settingError: '',
      };
    case settingConstants.GET_SETTINGS_FAILURE:
      return {
        ...state,
        settingLoading: false,
        settingData: [],
        settingError: action.error,
      };
    default:
      return { ...state };
  }
};

export default settingReducer;
