import { userConstants, eventConstants } from '../actions/types';

const initialState = {
  loading: false,
  allEvents: {
    events: [],
    currPage: 0,
  },
  currentEvents: {
    events: [],
    currPage: 0,
  },
  completedEvents: {
    events: [],
    currPage: 0,
  },
  error: '',
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case eventConstants.CREATE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case eventConstants.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allEvents: {
          ...state.allEvents,
          events: [action.event, ...state.allEvents.events],
        },
        error: '',
      };
    case eventConstants.CREATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: [],
        },
        error: action.error,
      };
    case eventConstants.GET_EVENTS_REQUEST:
      return {
        ...state,
        // loading: state.allEvents.currPage > 0 ? false : true,
        loading: true,
        error: '',
      };
    case eventConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: action.data.events,
          currPage: action.data.currPage,
          pageCount: action.data.pageCount,
        },
        error: '',
      };
    case eventConstants.GET_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: [],
        },
        error: action.error,
      };
    case eventConstants.GET_ALLEVENTS_REQUEST:
      return {
        ...state,
        loading: state.allEvents.currPage > 0 ? false : true,
        error: '',
      };
    case eventConstants.GET_ALLEVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: action.data.events,
          currPage: action.data.currPage,
          pageCount: action.data.pageCount,
        },
        error: '',
      };
    case eventConstants.GET_ALLEVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: [],
        },
        error: action.error,
      };
    case eventConstants.GET_CURRENT_EVENTS_REQUEST:
      return {
        ...state,
        loading: state.currPage > 0 ? false : true,
        error: '',
      };
    case eventConstants.GET_CURRENT_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEvents: {
          events: action.data.events,
          currPage: action.data.currPage,
          pageCount: action.data.pageCount,
        },
        error: '',
      };
    case eventConstants.GET_CURRENT_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        currentEvents: {
          events: [],
        },
        error: action.error,
      };
    case eventConstants.GET_COMPLETED_EVENTS_REQUEST:
      return {
        ...state,
        loading: state.currPage > 0 ? false : true,
        error: '',
      };
    case eventConstants.GET_COMPLETED_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        completedEvents: {
          events: action.data.events,
          currPage: action.data.currPage,
          pageCount: action.data.pageCount,
        },
        error: '',
      };
    case eventConstants.GET_COMPLETED_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        completedEvents: {
          events: [],
        },
        error: action.error,
      };
    case eventConstants.UPDATE_EVENT_REQUEST:
      return {
        ...state,
        error: '',
      };
    case eventConstants.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          events: action.events.allEvents
            ? action.events.allEvents
            : state.allEvents.events,
        },
        currentEvents: {
          ...state.currentEvents,
          events: action.events.currentEvents
            ? action.events.currentEvents
            : state.currentEvents.events,
        },
        completedEvents: {
          ...state.completedEvents,
          events: action.events.completedEvents
            ? action.events.completedEvents
            : state.completedEvents.events,
        },
        error: '',
      };
    case eventConstants.UPDATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        allEvents: {
          events: [],
          currPage: 0,
        },
        currentEvents: {
          events: [],
          currPage: 0,
        },
        completedEvents: {
          events: [],
          currPage: 0,
        },
        error: '',
      };
    default:
      return { ...state };
  }
};

export default eventReducer;
