import { zipcodeConstants } from '../actions/types';

const initialState = {
  loading: false,
  zipcodeList: [],
  error: '',
};

const zipcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case zipcodeConstants.GET_ZIPCODE_REQUEST:
      return {
        ...state,
        loading: true,
        zipcodeList: [],
        error: '',
      };
    case zipcodeConstants.GET_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        zipcodeList: action.payload,
        error: '',
      };
    case zipcodeConstants.GET_ZIPCODE_FAILURE:
      return {
        ...state,
        loading: false,
        zipcodeList: [],
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default zipcodeReducer;
