import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from '@dsr/react-places-autocomplete';
import Loader from '../Core/Loader';
import i18n from '../../i18n';
import { getAddressFromLatLng } from '../../utils/helper';
import { zipcodesMap } from '../../utils/zipcodes';
class FormikPlacesAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.field.name,
      address: props.field.value ? props.field.value : '',
    };
  }

  handleError = (error) => {
    this.props.form.setFieldError(this.state.name, error);
  };

  handleChange = (address) => {
    this.setState(() => {
      this.props.form.setFieldTouched(`${this.state.name}.value`);
      this.props.form.setFieldTouched(`${this.state.name}.address`);
      this.props.form.setFieldValue(this.state.name, { value: address });
      return { address };
    });
  };

  setAddressToState = (address, res, rLatLang, zipcode, selectedZip) => {
    // console.log("setAddressToState=",zipcode)
    let returnAddress = address;
    if (
      this.props?.types === 'sublocality' ||
      this.props.types === 'locality'
    ) {
      returnAddress = res[0].address_components[0].short_name;
    }
    this.setState(() => {
      const region = {
        value: returnAddress,
        state: zipcode?.[0]?.state,
        city: zipcode?.[0]?.city,
        zipcode: zipcode?.[0]?.zipcode,
        address: res[0].address_components[0].long_name,
        coordinates: [rLatLang?.lat, rLatLang?.lng],
      };
      this.props.form.setFieldValue(this.state.name, region);

      if (selectedZip) {
        this.props.form.setFieldValue(
          this.props?.travelCostFieldName,
          selectedZip?.Reisekosten,
        );
        this.props.form.setFieldValue(
          this.props?.effectiveTravelCostFieldName,
          selectedZip?.effectiveTravelCost
            ? selectedZip?.effectiveTravelCost
            : 0,
        );
      } else {
        this.props.form.setFieldValue(this.props?.travelCostFieldName, 0);
        this.props.form.setFieldValue(
          this.props?.effectiveTravelCostFieldName,
          0,
        );
      }

      if (typeof this.props.handlePreferedRegions === 'function') {
        this.props.setPreferedRegionsError('');
        this.props.form.setFieldValue(this.state.name, { value: '' });
        this.props.handlePreferedRegions(
          res[0].address_components[0].long_name
            ? res[0].address_components[0].long_name
            : returnAddress,
        );
        return {
          address: '',
        };
      } else {
        return { returnAddress };
      }
    });
  };

  handleSelect = async (address) => {
    try {
      const geocodRes = await geocodeByAddress(address);
      const rLatLang = {
        lat: geocodRes?.[0]?.geometry?.location?.lat,
        lng: geocodRes?.[0]?.geometry?.location?.lng,
      };

      getAddressFromLatLng(
        rLatLang?.lat,
        rLatLang?.lng,
        (res) => {
          // console.log('hi handle select place', address, geocodRes, res?.city);
          let filterZipcodes = [];
          let selectedZip = false;
          if (res?.postal_code) {
            selectedZip = this.props?.allZipCodes?.find((zip) => {
              return String(zip.PLZ) === String(res?.postal_code);
            });
            filterZipcodes = zipcodesMap.filter((z) => {
              const foundIndex = z.zipcodes.indexOf(Number(res?.postal_code));
              return foundIndex !== -1;
            });
          } else if (res?.state) {
            filterZipcodes = [{ state: res?.state }];
          }
          this.setAddressToState(
            address,
            geocodRes,
            rLatLang,
            [
              {
                state: filterZipcodes?.[0]?.state,
                zipcode: res?.postal_code,
                city: res?.city,
              },
            ],
            selectedZip,
          );
        },
        (err) => {
          console.error(err);
          this.setAddressToState(address, geocodRes, rLatLang, [], false);
        },
      );
    } catch (error) {
      console.error(error);
      this.props.form.setFieldError(this.state.name, error);
    }
  };

  renderSuggestionItem = (suggestion) => {
    if (this.props?.types === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (this.props.types === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };

  render() {
    const {
      field: { name, ...field }, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      classes,
      label,
      ...props
    } = this.props;

    // const error = errors[name];
    // const touch = touched[name];

    return (
      <PlacesAutocomplete
        name={name}
        id={name}
        {...props}
        //  componentRestrictions={ country: ['us'] }
        shouldFetchSuggestions={this.props.field.value.value.length >= 2}
        searchOptions={{ types: this.props.types ? [this.props.types] : [] }}
        value={this.props.field.value.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onSelect={this.handleSelect}
        onError={this.handleError}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                name: name,
                placeholder: this.props?.inputProps?.placeholder
                  ? this.props?.inputProps?.placeholder
                  : i18n.t('placeplaceholder'),
                className: 'location-search-input form-control',
                onBlur: (event) => {
                  this.props.form.setFieldTouched(name, true);
                },
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <Loader />}
              {suggestions.map((suggestion) => {
                // if (!suggestion?.types?.includes('locality')) {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}>
                    <span>{this.renderSuggestionItem(suggestion)}</span>
                  </div>
                );
                // }
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default FormikPlacesAutoComplete;
