import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Core/Button';
import { useTranslation } from 'react-i18next';

function DeleteVoiceModal({
  showDeleteVoiceModal,
  setShowDeleteVoiceModal,
  setAudio,
}) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showDeleteVoiceModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="delete-voice-modal"
      onHide={() => setShowDeleteVoiceModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('audioModal.deleteVoiceSample')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lead">{t('audioModal.deleteVoiceSampleText')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          onClick={() => setShowDeleteVoiceModal(false)}
          label={t('requestOverview.modal.cancel')}
        />
        <Button
          className="btn"
          label={t('delete')}
          onClick={() => {
            setAudio(null);
            setShowDeleteVoiceModal(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteVoiceModal;
