import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { roles } from '../../utils/constants';
import {
  checkUserRoleAndTokenExpiration,
  useHandleErrorMessage,
} from '../../utils/helper';
import { useTranslation } from 'react-i18next';

function EmployerRoute({ component: Component, ...restOfProps }) {
  const { t } = useTranslation();
  const isAuthenticated =
    localStorage.getItem('token') && localStorage.getItem('user');
  const handleErrorMessage = useHandleErrorMessage();
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const {
      role: userRole,
      tokenExpired,
      invalidToken,
    } = checkUserRoleAndTokenExpiration(isAuthenticated);
    setRole(userRole);
    setLoading(false);

    if (!isAuthenticated || !userRole || tokenExpired) {
      handleErrorMessage(
        tokenExpired ? t('expiredTokenError') : t('authTokenError'),
      );
    } else if (invalidToken) {
      handleErrorMessage(t('invalidTokenError'));
    }
  }, [isAuthenticated, handleErrorMessage, t]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const condition = isAuthenticated && role === roles.employee;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        condition ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default EmployerRoute;
