import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../components/Head';
import { Container, Tab, Nav } from 'react-bootstrap';
import CurrentEvents from '../components/Events/CurrentEvents';
import CompletedEvents from '../components/Events/CompletedEvents';
import { useLocation } from 'react-router-dom';
const keys = {
  currentEvents: 'currentEvents',
  completedEvents: 'completedEvents',
};

const MyEvents = () => {
  const [currKey, setCurrKey] = useState(keys.currentEvents);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  return (
    <section className="content-section">
      <Head title={t('title.myEvents')} />
      <Container>
        <div className="box-wrapper fixed-box">
          <Tab.Container
            id="left-tabs-example"
            activeKey={currKey}
            onSelect={(event) => setCurrKey(event)}
            defaultActiveKey={`${keys.currentEvents}`}
          >
            <Nav className="tab-menu">
              <Nav.Item>
                <Nav.Link eventKey={`${keys.currentEvents}`}>
                  {`${t('currentEvents')}`}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={`${keys.completedEvents}`}>
                  {`${t('completedEvents')}`}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={`${keys.currentEvents}`}>
                {currKey === keys.currentEvents && <CurrentEvents />}
              </Tab.Pane>
              <Tab.Pane eventKey={`${keys.completedEvents}`}>
                {currKey === keys.completedEvents && <CompletedEvents />}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
};

export default MyEvents;
