import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import moment from 'moment';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import Input from '../Core/Input';
import Textarea from '../Core/Textarea';
import CustomSelect from '../Core/Select';
import Radio from '../Core/Radio';
import { setPasswordConfirmError } from '../Profile/EmployerProfile';
import PlacesAutocomplete, {
  geocodeByAddress,
} from '@dsr/react-places-autocomplete';
import Loader from '../Core/Loader';
import {
  hairColorOptions,
  sizeOptions,
  genderOptions,
  shoeSizeOptions,
  jobRegions,
  NUMBER_ONLY,
  ALPHABETS_ONLY,
  ALPHABETS_AND_NUMBERS,
} from '../../utils/constants';
import GlobalContext from '../../context/GlobalContext';
import BlackPantsuit from '../../assets/images/black-pantsuit.jpg';
import BlackSkirtBlazerImage from '../../assets/images/Black-Skirt-Blazer.jpg';
import 'moment/locale/de';

registerLocale('de', de);
registerLocale('en', enUS);

const StaffForm = ({
  title,
  values,
  errors,
  employee,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  showPassword,
  preferedRegions,
  setPreferedRegions,
  preferedRegionsError,
  setPreferedRegionsError,
  formikFormRef,
}) => {
  const { jobPositions, countriesOptions, languagesOptions } =
    useContext(GlobalContext);
  const [addressline, setAddressLine] = useState(values.addressLineOne);
  const [selectedcity, setSelectedCity] = useState(values.city);

  useEffect(() => {
    if (addressline === '') {
      formikFormRef?.current?.setFieldValue('addressLineOne', '');
    } else {
      formikFormRef?.current?.validateForm();
    }
  }, [addressline]);
  useEffect(() => {
    if (selectedcity === '') {
      formikFormRef?.current?.setFieldValue('city', '');
    } else {
      formikFormRef?.current?.validateForm();
    }
  }, [selectedcity]);

  const { t } = useTranslation();

  const Datepopover = (
    <Popover>
      <Popover.Body>
        <h5>{t('dob')}</h5>
        <p>{t('staffProfile.potentialEmployers')}</p>
      </Popover.Body>
    </Popover>
  );

  const PrivatePantSuit = (
    <Popover className="blacksuit-image">
      <Popover.Body>
        <h5>{t('staffProfile.blackPantsuit')}</h5>
        <img src={BlackPantsuit} alt="" />
      </Popover.Body>
    </Popover>
  );

  const BlackSkirtBlazer = (
    <Popover>
      <Popover.Body>
        <h5>{t('staffProfile.blackCostume')}</h5>
        <img src={BlackSkirtBlazerImage} alt="" />
      </Popover.Body>
    </Popover>
  );

  const renderSuggestionItem = (suggestion, type) => {
    if (type === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };

  const handleError = (name, error) => {
    formikFormRef?.current?.setFieldError(name, error);
  };

  const handleSelect = async (address, name, type, suggestion) => {
    try {
      let returnAddress = address;
      if (type === 'sublocality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else if (type === 'locality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else {
        const res = await geocodeByAddress(address);
        // const rLatLang = await getLatLng(res[0]);

        if (type === 'sublocality' || type === 'locality') {
          returnAddress = res[0].address_components[0].long_name;
        }
      }

      if (name === 'city') {
        setSelectedCity(returnAddress);
        formikFormRef?.current?.validateForm();
      } else {
        setAddressLine(returnAddress);
        formikFormRef?.current?.validateForm();
      }
      handleChange(name, returnAddress);
      setFieldValue(name, returnAddress);
      formikFormRef?.current?.setFieldValue(name, returnAddress);
    } catch (error) {
      formikFormRef?.current?.setFieldError(name, error);
    }
  };

  return (
    <div className="employee-registration__content mt-5 mt-lg-0">
      <div className="profile-headline">
        <h3>{title}</h3>
        {employee && (
          <>
            {employee.isApproved ? (
              <span className="status-label approved">
                {t('staffProfile.approved')}
              </span>
            ) : (
              <span className="status-label approval-pending">
                {t('staffProfile.approvedPending')}{' '}
              </span>
            )}
          </>
        )}
      </div>
      <Row>
        <Col sm="6" xl="3">
          <Input
            type="text"
            label={`${t('fnameStaff')} *`}
            placeholder={t('fnameStaff')}
            name="firstName"
            // onChange={handleChange}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (ALPHABETS_ONLY.test(e.target.value) &&
                  e.target.value?.length <= 30)
              ) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.firstName}
            errors={errors.firstName}
            value={values.firstName}
          />
          <CustomSelect
            label={`${t('gender')} *`}
            name="gender"
            options={genderOptions}
            placeholder={t('gender')}
            handleChange={(value) => {
              setFieldValue('gender', value);
            }}
            handleBlur={() => {
              setFieldTouched('gender', true);
            }}
            touched={touched.gender}
            errors={errors.gender ? errors.gender.value : ''}
            value={values.gender}
          />
          <div
            className={`form-group date-picker-group ${
              touched.dateOfBirth && errors.dateOfBirth
                ? 'form-group-errors'
                : ''
            }`}>
            <label className="form-label">
              {`${t('dob')} *`}
              {Datepopover && (
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 100, hide: 400 }}
                  overlay={Datepopover}>
                  <svg
                    width="22"
                    height="23"
                    xmlns="http://www.w3.org/2000/svg"
                    className="popover-icon">
                    <g
                      transform="translate(0 .5)"
                      fill="none"
                      fillRule="evenodd">
                      <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                      <path
                        d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                        fill="#FFF"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </OverlayTrigger>
              )}
            </label>
            <DatePicker
              selected={
                values.dateOfBirth &&
                new Date(values.dateOfBirth).getFullYear() !==
                  new Date().getFullYear()
                  ? new Date(values.dateOfBirth)
                  : moment().subtract(16, 'years').toDate()
              }
              className="form-control"
              dateFormat="EEEE, dd.MM.yy"
              calendarStartDay={1}
              name="dateOfBirth"
              locale={localStorage.getItem('i18nextLng') ?? 'de'}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={moment().subtract(16, 'years').toDate()}
              onChange={(e) => {
                setFieldValue('dateOfBirth', e);
              }}
              onSelect={(e) => {
                setFieldValue('dateOfBirth', e);
              }}
            />
            {touched.dateOfBirth && errors.dateOfBirth && (
              <div className="form-errors">{errors.dateOfBirth}</div>
            )}
          </div>
          <CustomSelect
            label={`${t('nationality')} *`}
            name="nationality"
            options={countriesOptions}
            placeholder={t('nationality')}
            handleChange={(value) => {
              setFieldValue('nationality', value);
            }}
            handleBlur={() => {
              setFieldTouched('nationality', true);
            }}
            touched={touched.nationality}
            errors={errors.nationality ? errors.nationality.value : ''}
            value={values.nationality}
          />
          <CustomSelect
            label={`${t('hairColor')} *`}
            name="hairColor"
            options={hairColorOptions}
            placeholder={t('hairColor')}
            handleChange={(value) => {
              setFieldValue('hairColor', value);
            }}
            handleBlur={() => {
              setFieldTouched('hairColor', true);
            }}
            touched={touched.hairColor}
            errors={errors.hairColor ? errors.hairColor.value : ''}
            value={values.hairColor}
          />
          <Input
            type="text"
            label={`${t('height')} *`}
            name="height"
            placeholder={t('height')}
            onBlur={handleBlur}
            touched={touched.height}
            errors={errors.height}
            value={values.height}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (NUMBER_ONLY.test(e.target.value) &&
                  e.target.value?.length <= 3)
              ) {
                handleChange(e);
              }
            }}
          />
          <Input
            type="text"
            label={`${t('weight')} *`}
            name="weight"
            placeholder={t('weight')}
            onBlur={handleBlur}
            touched={touched.weight}
            errors={errors.weight}
            value={values.weight}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (NUMBER_ONLY.test(e.target.value) &&
                  e.target.value?.length <= 3)
              ) {
                handleChange(e);
              }
            }}
          />
          <CustomSelect
            label={`${t('country')} *`}
            name="country"
            placeholder={t('countryplaceholder')}
            options={countriesOptions}
            handleChange={(value) => {
              setFieldValue('country', value);
            }}
            handleBlur={() => {
              setFieldTouched('country', true);
            }}
            touched={touched.country}
            errors={errors.country ? errors.country.value : ''}
            value={values.country}
          />
          <Input
            type="text"
            label={`${t('postalCodeStaff')} *`}
            placeholder={t('postalCodeplaceholder')}
            name="postalCode"
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (NUMBER_ONLY.test(e.target.value) &&
                  e.target.value?.length <= 5)
              ) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.postalCode}
            errors={errors.postalCode}
            value={values.postalCode}
          />
        </Col>
        <Col sm="6" xl="3">
          <Input
            type="text"
            label={`${t('lnameStaff')} *`}
            placeholder={t('lnameStaff')}
            name="lastName"
            // tooltipTitle={t('lnameStaff')}
            // tooltipText={t('staffProfile.LastNameForStaff')}
            // onChange={handleChange}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (ALPHABETS_ONLY.test(e.target.value) &&
                  e.target.value?.length <= 50)
              ) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.lastName}
            errors={errors.lastName}
            value={values.lastName}
          />
          <CustomSelect
            label={`${t('motherTongue')} *`}
            name="motherTongue"
            options={languagesOptions}
            placeholder={t('motherTongue')}
            handleChange={(value) => {
              setFieldValue('motherTongue', value);
            }}
            handleBlur={() => {
              setFieldTouched('motherTongue', true);
            }}
            touched={touched.motherTongue}
            errors={errors.motherTongue ? errors.motherTongue.value : ''}
            value={values.motherTongue}
          />
          <CustomSelect
            label={t('foreignLanguages')}
            name="foreignLanguages"
            options={languagesOptions}
            placeholder={t('foreignLanguages')}
            handleChange={(value) => {
              setFieldValue('foreignLanguages', value);
            }}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            handleBlur={() => {
              setFieldTouched('foreignLanguages', true);
            }}
            touched={touched.foreignLanguages}
            value={values.foreignLanguages}
          />
          <CustomSelect
            label={`${t('size')} *`}
            name="size"
            options={sizeOptions}
            placeholder={t('size')}
            handleChange={(value) => {
              setFieldValue('size', value);
            }}
            handleBlur={() => {
              setFieldTouched('size', true);
            }}
            touched={touched.size}
            errors={errors.size ? errors.size.value : ''}
            value={values.size}
          />
          <CustomSelect
            label={`${t('shoeSize')} *`}
            placeholder={t('shoeSize')}
            name="shoeSize"
            // options={
            //   values.gender?.value === 'female'
            //     ? shoeSizeAUWomensOptions
            //     : shoeSizeAUMensOptions
            // }
            options={shoeSizeOptions}
            handleChange={(value) => {
              setFieldValue('shoeSize', value);
            }}
            handleBlur={() => {
              setFieldTouched('shoeSize', true);
            }}
            touched={touched.shoeSize}
            errors={errors.shoeSize ? errors.shoeSize.value : ''}
            value={values.shoeSize}
          />
          <div className="radio-wrapper">
            <label className="form-label">{t('piercings')} *</label>
            <div
              className={`radio-boxes ${
                touched.piercings && errors.piercings ? 'form-group-errors' : ''
              }`}>
              <Radio
                name="piercings"
                label={t('staffProfile.yes')}
                checked={values.piercings}
                onChange={() => setFieldValue('piercings', true)}
              />
              <Radio
                name="piercings"
                label={t('staffProfile.no')}
                checked={values.piercings === false}
                onChange={() => setFieldValue('piercings', false)}
              />
            </div>
            {touched.piercings && errors.piercings && (
              <div className="form-errors">{errors.piercings}</div>
            )}
          </div>
          <div className="form-group ">
            <label className="form-label">{t('city')} *</label>
            <PlacesAutocomplete
              name={'city'}
              id={'city'}
              searchOptions={['(cities)']}
              value={selectedcity}
              onChange={(e) => {
                handleSelect('', 'city', 'locality', '');
                setSelectedCity(e);
              }}
              onSelect={(data, placeid, suggestion) =>
                handleSelect(data, 'city', 'locality', suggestion)
              }
              onError={(error) => handleError('city', error)}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: `${t('cityplaceholder')}`,
                      className: 'location-search-input form-control',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <Loader />}
                    {suggestions.map((suggestion) => {
                      if (suggestion?.types?.includes('locality')) {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}>
                            <span>
                              {renderSuggestionItem(suggestion, 'locality')}
                              <small style={{ color: 'gray' }}>
                                {suggestion?.formattedSuggestion?.mainText
                                  ? suggestion?.description?.replace(
                                      suggestion?.formattedSuggestion?.mainText,
                                      '',
                                    )
                                  : suggestion?.description}
                              </small>
                            </span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errors?.city !== '' && (
              <div className="form-errors">{errors?.city}</div>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">{t('addressLineOne')} *</label>
            <PlacesAutocomplete
              name={'addressLineOne'}
              id={'addressLineOne'}
              searchOptions={['address']}
              value={addressline}
              onChange={(value) => {
                handleSelect('', 'addressLineOne', 'sublocality', '');
                setAddressLine(value);
              }}
              onSelect={(data, placeid, suggestion) => {
                handleSelect(data, 'addressLineOne', 'sublocality', suggestion);
              }}
              onError={(error) => handleError('addressLineOne', error)}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: `${t('addressLineOneplaceholder')}`,
                      className: 'location-search-input form-control',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <Loader />}
                    {suggestions.map((suggestion) => {
                      if (!suggestion?.types?.includes('locality')) {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}>
                            <span>
                              {renderSuggestionItem(suggestion, 'sublocality')}
                              <small style={{ color: 'gray' }}>
                                {suggestion?.formattedSuggestion?.mainText
                                  ? suggestion?.description?.replace(
                                      suggestion?.formattedSuggestion?.mainText,
                                      '',
                                    )
                                  : suggestion?.description}
                              </small>
                            </span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errors?.addressLineOne !== '' && (
              <div className="form-errors">{errors?.addressLineOne}</div>
            )}
          </div>
        </Col>
        <Col sm="6" xl="3">
          <Input
            type="email"
            label={`${t('emailStaff')} *`}
            placeholder={t('emailStaff')}
            name="email"
            onChange={(e) => {
              if (e.target.value === '' || e.target.value?.length <= 60) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.email}
            errors={errors.email}
            value={values.email}
          />
          <div className="radio-wrapper">
            <label className="form-label">{t('visibleTattoos')} *</label>
            <div
              className={`radio-boxes ${
                touched.visibleTattoos && errors.visibleTattoos
                  ? 'form-group-errors'
                  : ''
              }`}>
              <Radio
                name="visibleTattoos"
                label={t('staffProfile.yes')}
                checked={values.visibleTattoos}
                onChange={() => setFieldValue('visibleTattoos', true)}
              />
              <Radio
                name="visibleTattoos"
                label={t('staffProfile.no')}
                checked={values.visibleTattoos === false}
                onChange={() => setFieldValue('visibleTattoos', false)}
              />
            </div>
            {touched.visibleTattoos && errors.visibleTattoos && (
              <div className="form-errors">{errors.visibleTattoos}</div>
            )}
          </div>
          <div className="radio-wrapper">
            <label className="form-label">{t('driversLicense')} *</label>
            <div
              className={`radio-boxes ${
                touched.driversLicense && errors.driversLicense
                  ? 'form-group-errors'
                  : ''
              }`}>
              <Radio
                name="driversLicense"
                label={t('staffProfile.yes')}
                checked={values.driversLicense}
                onChange={() => setFieldValue('driversLicense', true)}
              />
              <Radio
                name="driversLicense"
                label={t('staffProfile.no')}
                checked={values.driversLicense === false}
                onChange={() => setFieldValue('driversLicense', false)}
              />
            </div>
            {touched.driversLicense && errors.driversLicense && (
              <div className="form-errors">{errors.driversLicense}</div>
            )}
          </div>
          <div className="radio-wrapper">
            <label className="form-label">{t('availableCar')} *</label>
            <div
              className={`radio-boxes ${
                touched.carAvailable && errors.carAvailable
                  ? 'form-group-errors'
                  : ''
              }`}>
              <Radio
                name="carAvailable"
                label={t('staffProfile.yes')}
                checked={values.carAvailable}
                onChange={() => setFieldValue('carAvailable', true)}
              />
              <Radio
                name="carAvailable"
                label={t('staffProfile.no')}
                checked={values.carAvailable === false}
                onChange={() => setFieldValue('carAvailable', false)}
              />
            </div>
            {touched.carAvailable && errors.carAvailable && (
              <div className="form-errors">{errors.carAvailable}</div>
            )}
          </div>
          <div className="radio-wrapper">
            <label className="form-label">
              {t('privatePantsuit')} *
              {PrivatePantSuit && (
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 100, hide: 400 }}
                  overlay={PrivatePantSuit}>
                  <svg
                    width="22"
                    height="23"
                    xmlns="http://www.w3.org/2000/svg"
                    className="popover-icon">
                    <g
                      transform="translate(0 .5)"
                      fill="none"
                      fillRule="evenodd">
                      <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                      <path
                        d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                        fill="#FFF"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </OverlayTrigger>
              )}
            </label>
            <div
              className={`radio-boxes ${
                touched.blackPantsuit && errors.blackPantsuit
                  ? 'form-group-errors'
                  : ''
              }`}>
              <Radio
                name="blackPantsuit"
                label={t('staffProfile.yes')}
                checked={values.blackPantsuit}
                onChange={() => setFieldValue('blackPantsuit', true)}
              />
              <Radio
                name="blackPantsuit"
                label={t('staffProfile.no')}
                checked={values.blackPantsuit === false}
                onChange={() => setFieldValue('blackPantsuit', false)}
              />
            </div>
            {touched.blackPantsuit && errors.blackPantsuit && (
              <div className="form-errors">{errors.blackPantsuit}</div>
            )}
          </div>
          <div className="radio-wrapper">
            <label className="form-label">
              {t('privateSkirt')} *
              {BlackSkirtBlazer && (
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 100, hide: 400 }}
                  overlay={BlackSkirtBlazer}>
                  <svg
                    width="22"
                    height="23"
                    xmlns="http://www.w3.org/2000/svg"
                    className="popover-icon">
                    <g
                      transform="translate(0 .5)"
                      fill="none"
                      fillRule="evenodd">
                      <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                      <path
                        d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                        fill="#FFF"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </OverlayTrigger>
              )}
            </label>
            <div
              className={`radio-boxes ${
                touched.blackSkirtBlazer && errors.blackSkirtBlazer
                  ? 'form-group-errors'
                  : ''
              }`}>
              <Radio
                name="blackSkirtBlazer"
                label={t('staffProfile.yes')}
                checked={values.blackSkirtBlazer}
                onChange={() => setFieldValue('blackSkirtBlazer', true)}
              />
              <Radio
                name="blackSkirtBlazer"
                label={t('staffProfile.no')}
                checked={values.blackSkirtBlazer === false}
                onChange={() => setFieldValue('blackSkirtBlazer', false)}
              />
            </div>
            {touched.blackSkirtBlazer && errors.blackSkirtBlazer && (
              <div className="form-errors">{errors.blackSkirtBlazer}</div>
            )}
          </div>
          <Input
            type="text"
            label={t('profileIdplaceholder')}
            name="profileId"
            disabled
            placeholder={t('profileIdplaceholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.profileId}
            errors={errors.profileId}
            value={values.profileId}
          />
          <Input
            type="text"
            label={t('addressLineTwo')}
            placeholder={t('addressLineTwoplaceholder')}
            name="addressLineTwo"
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (ALPHABETS_AND_NUMBERS.test(e.target.value) &&
                  e.target.value?.length <= 5)
              ) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.addressLineTwo}
            errors={errors.addressLineTwo}
            value={values.addressLineTwo}
          />
        </Col>
        <Col sm="6" xl="3">
          <Input
            type="phone"
            label={`${t('phone')} *`}
            placeholder="+43 660 123 456 87"
            name="phone"
            onChange={(e) => {
              if (e.target.value === '' || e.target.value?.length <= 20) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            touched={touched.phone}
            errors={errors.phone}
            value={values.phone}
          />
          <CustomSelect
            label={`${t('preferedJobs')} *`}
            name="preferedJobs"
            options={jobPositions}
            placeholder={t('preferedJobs')}
            handleChange={(value) => {
              setFieldValue('preferedJobs', value);
            }}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            handleBlur={() => {
              setFieldTouched('preferedJobs', true);
            }}
            touched={touched.preferedJobs}
            value={values.preferedJobs}
          />
          {touched.preferedJobs && errors.preferedJobs && (
            <span
              style={{ position: 'relative', top: '-20px' }}
              className="form-errors">
              {errors.preferedJobs}
            </span>
          )}
          <CustomSelect
            label={t('previousJobs')}
            name="previousJobs"
            options={jobPositions}
            placeholder={t('previousJobs')}
            handleChange={(value) => {
              setFieldValue('previousJobs', value);
            }}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            handleBlur={() => {
              setFieldTouched('previousJobs', true);
            }}
            touched={touched.previousJobs}
            value={values.previousJobs}
          />
          <Textarea
            label={t('jobExperience')}
            placeholder={t('jobExperienceplaceholder')}
            name="jobExperience"
            rows="6"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.jobExperience}
            value={
              values.jobExperience !== 'undefined' ? values.jobExperience : ''
            }
          />
          <CustomSelect
            label={`${t('preferedRegion')} *`}
            name="preferedJobRegion"
            options={jobRegions}
            placeholder={t('preferedRegion')}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            handleChange={(value) => {
              setFieldValue('preferedJobRegion', value);
            }}
            handleBlur={() => {
              setFieldTouched('preferedJobRegion', true);
            }}
            touched={touched.preferedJobRegion}
            // value={getDefaultRegionValues(values.preferedJobRegion)}
            value={values.preferedJobRegion}
          />
          {touched.preferedJobRegion && errors.preferedJobRegion && (
            <span
              style={{ position: 'relative', top: '-20px' }}
              className="form-errors">
              {errors.preferedJobRegion}
            </span>
          )}
          {showPassword && (
            <>
              <div>
                <span>
                  <Input
                    type="password"
                    label={t('passwordLogin')}
                    placeholder={t('passwordplaceholder')}
                    name="password"
                    tooltipTitle={t('passwordLogin')}
                    tooltipText={t('Password.length')}
                    value={values.password}
                    autoComplete="new-password"
                    onChange={handleChange}
                    touched={touched.password}
                    errors={errors.password}
                  />
                </span>
              </div>

              <Input
                type="password"
                label={t('confirmPassword')}
                placeholder={t('confirmPasswordplaceholder')}
                name="passwordConfirm"
                value={values.passwordConfirm}
                autoComplete="new-password"
                onChange={(e) => {
                  setFieldValue('passwordConfirm', e.target.value);
                  setPasswordConfirmError(
                    values.password,
                    values.passwordConfirm,
                    true,
                  );
                }}
                touched={touched.passwordConfirm}
                errors={errors.passwordConfirm}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

StaffForm.defaultProps = {
  showPassword: false,
};

export default StaffForm;
