import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import shortid from 'shortid';
import { useTable, useSortBy } from 'react-table';
import { Container } from 'react-bootstrap';
import Head from '../components/Head';
import Loader from '../components/Core/Loader';
// import { labelRoles } from '../utils/constants';
import api from '../utils/api';
import { handleError } from '../utils/handleError';
import { useTranslation } from 'react-i18next';

const Table = ({ columns, data }) => {
  // const { t, i18n } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table
        className="table remove-space request-payment-table"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'costs') {
                      return (
                        <td {...cell.getCellProps()} key={shortid.generate()}>
                          {/*<span className="placeholder-text">
                            Total excl. VAT*:
                      </span>*/}
                          <span className="total-amount">
                            {cell.render('Cell')}
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} key={shortid.generate()}>
                          <span>{cell.render('Cell')}</span>
                        </td>
                      );
                    }
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TableAddress = ({ columns, data }) => {
  const { t, i18n } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table className="table show-address-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={shortid.generate()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ProfileBillDetails = () => {
  const { t, i18n } = useTranslation();
  const [bill, setBill] = useState(null);
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);

  const [loading, setLoading] = useState(true);
  // const user = useSelector((state) => state.user);
  let { tid } = useParams();

  const history = useHistory();
  const [paymentdetails, setPaymentDetails] = useState(
    location.state.paymentDetails,
  );

  useEffect(() => {
    if (!location.state || !location.state.bill) {
      api
        .get(`/orders/getOrder/${tid}`)
        .then((res) => {
          setBill(res.data.data.order);

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          handleError(e);
        });
    } else {
      setBill(location.state.bill);
      setLoading(false);
    }
  }, [location]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'id',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventId')}`;
        },
        accessor: 'eventId',
      },
      {
        Header: () => {
          return `${t('requestOverview.position')}`;
        },
        accessor: 'position',
      },
      {
        Header: () => {
          return `${t('positionid')}`;
        },
        accessor: 'positionId',
      },
      {
        Header: () => {
          return `${t('profilebilldetailname')}`;
        },
        accessor: 'name',
      },
      {
        Header: () => {
          return `${t('Costs')}`;
        },
        accessor: 'costs',
      },
    ];
  }, []);

  const addcolumns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('profilebilldetailname')}`;
        },
        accessor: 'name',
      },
      {
        Header: () => {
          return `${t('profileOrg.billAddress')}`;
        },
        accessor: 'street',
      },
      {
        Header: () => {
          return `${t('postalCodeStaff')}`;
        },
        accessor: 'postCode',
      },
      {
        Header: () => {
          return `${t('city')}`;
        },
        accessor: 'city',
      },
      {
        Header: () => {
          return `${t('country')}`;
        },
        accessor: 'country',
      },
    ];
  }, []);

  const addressData = React.useMemo(() => {
    if (!bill) {
      return [];
    }
    const billingAddress = bill.billingAddress;
    return [
      {
        name: billingAddress.billingAddressName,
        street: `${billingAddress.billingAddressStreet}${
          billingAddress.billingAddressStreetTwo
            ? '' + billingAddress.billingAddressStreetTwo
            : ''
        }`,
        postCode: billingAddress.billingAddressPostCode,
        city: billingAddress.billingAddressCity,
        country: billingAddress.billingAddressCountry,
      },
    ];
  }, [bill]);

  // const paycolumns = React.useMemo(() => {
  //   return [
  //     {
  //       Header: () => {
  //         return `${t('cardMethod')}`;
  //       },
  //       accessor: 'method',
  //     },
  //     {
  //       Header: () => {
  //         return `${t('cardName')}`;
  //       },
  //       accessor: 'name',
  //     },
  //     {
  //       Header: () => {
  //         return `${t('cardNumber')}`;
  //       },
  //       accessor: 'number',
  //     },
  //     {
  //       Header: () => {
  //         return `${t('requestOverview.expirationDate')}`;
  //       },
  //       accessor: 'expDate',
  //     },
  //   ];
  // }, []);

  // const paydata = React.useMemo(() => {
  //   // console.log('paymentdetails', paymentdetails);

  //   // return [
  //   //   // first row data
  //   //   {
  //   //     method: 'Visa Credit Card',
  //   //     name: 'Hunter Smith',
  //   //     number: '**** **** **** **90',
  //   //     expDate: '02/2024',
  //   //   },
  //   // ];
  //   return [
  //     // first row data
  //     {
  //       method:
  //         paymentdetails && paymentdetails?.paymentMethod
  //           ? paymentdetails?.paymentMethod
  //           : '',
  //       name:
  //         paymentdetails && paymentdetails?.cardName
  //           ? paymentdetails?.cardName
  //           : '',
  //       number:
  //         paymentdetails && paymentdetails?.cardNumber
  //           ? paymentdetails?.cardNumber
  //               .substr(paymentdetails?.cardNumber?.length - 4)
  //               .padStart(16, '#')
  //           : '',
  //       expDate:
  //         paymentdetails && paymentdetails?.validThrough
  //           ? paymentdetails?.validThrough?.substring(3)
  //           : '',
  //     },
  //   ];
  // }, []);

  const renderTable = (bill) => {
    // console.log('bill', bill);
    if (!bill) {
      return <></>;
    }
    const jobs = bill.cartItems;
    const data = jobs.map((job, id) => {
      return {
        id: `${id + 1}`,
        eventId: job.eventID,
        position: job.cartItemPosition,
        positionId: 1234,
        name: job.cartItemName,
        costs: `€ ${job.cartItemPrice}`,
      };
    });
    return <Table columns={columns} data={data} />;
  };

  return (
    <section className="content-section">
      <Head title={t('title.profileBillDetails')} />
      <Container>
        <div className="box-wrapper">
          <div className="form-back" onClick={() => history.goBack()}></div>
          {!bill || loading ? (
            <Loader />
          ) : (
            <>
              <div className="book-pay-details">
                <h3>{t('summary')}</h3>
                {renderTable(bill)}
                <div className="d-flex justify-content-end align-items-center flex-wrap gap-2">
                  <div className="d-flex justify-content-end align-items-center">
                    <span className="placeholder-text">
                      {t('event.totalExcl')}
                    </span>
                    <span className="total-amount">
                      €{' '}
                      {!bill.discount
                        ? parseFloat(bill.cost).toFixed(2)
                        : parseFloat(bill.cost).toFixed(2) -
                          parseFloat(bill.discount).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="billing-details">
                <h3 className="headline-with-edit">
                  <span>{t('profileOrg.billAddress')}</span>
                </h3>
                <TableAddress columns={addcolumns} data={addressData} />
              </div>
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ProfileBillDetails;
