import React from 'react';
// import { Row, Col } from 'react-bootstrap';
// import { IMaskInput } from 'react-imask';
// import IMask from 'imask';
// import DatePicker from 'react-datepicker';
// import moment from 'moment';
import * as Yup from 'yup';
import shortid from 'shortid';
// import Input from '../Core/Input';
import visaIcon from '../../assets/images/icons/payment-visa.svg';
import masterCard from '../../assets/images/icons/payment-mastercard.svg';
import invoiceIcon from '../../assets/images/icons/payment-invoice.svg';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useBootstrapBreakpoints } from 'react-bootstrap/esm/ThemeProvider';
import { t } from 'i18next';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm';

export const paymentInitialValues = {
  paymentMethod: '',
  cardName: '',
  cardNumber: '',
  validThrough: '',
  cvv: '',
  chkSavePaymentDetails: '',
};

export const paymentValidationSchema = Yup.object({
  // paymentMethod: Yup.object().shape({
  //   label: Yup.string().required('Payment Method is required'),
  //   value: Yup.string().required('Payment Method is required'),
  // }),
  cardName: Yup.string().required(t('name')),
  cardNumber: Yup.string()
    .min(16, t('card.length'))
    .required(t('card.required')),
  validThrough: Yup.string().required(t('card.validThrough')),
  cvv: Yup.string()
    .length(3, t('card.cvvLength'))
    .matches(/[0-9]+/gi, 'CVV must be number')
    .required(t('card.cvvRequired')),
});
// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

export const payOnDelievery = 'onInvoice';

export const paymentTypes = [
  {
    label: 'VISA',
    value: 'visa',
    image: visaIcon,
  },
  {
    label: 'Master Card',
    value: 'masterCard',
    image: masterCard,
  },
  {
    label: 'On Invoice',
    value: payOnDelievery,
    image: invoiceIcon,
  },
];

const PaymentType = ({
  // formik,
  paymentType,
  setPaymentType,
  paymentMethods,
  clientSecret,
}) => {
  // const userData = useSelector((state) => state.user);
  // const user = userData.data;
  // const { employer } = user;
  const { t } = useTranslation();
  // const { paymentDetails } = employer;

  // const [expirationDateError, setExpirationDateError] = useState(null);
  // const { values, errors, touched, handleChange } = formik;
  // const handleAccept = (value, setFieldValue) => {
  //   if (value.length >= 5) {
  //     const newDate = moment(`01/${value}`, 'DD/MM/YYYY').format('x') * 1;
  //     const currTime = new Date().valueOf();
  //     if (newDate > currTime) {
  //       setExpirationDateError(null);
  //     } else {
  //       setExpirationDateError(t('cardexpired'));
  //     }
  //   } else {
  //     setExpirationDateError(null);
  //   }
  //   setFieldValue('validThrough', value);
  // };
  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  return (
    <div className="payment-methods">
      <p className="lead">{t('choosepaymentmethod')}</p>

      <div className="payment-method-options">
        {paymentTypes.map((type) => {
          if (paymentMethods?.find((i) => i === type.value)) {
            return (
              <>
                <div
                  className={`payment-method-option ${
                    paymentType === type.value ? 'active' : ''
                  }`}
                  onClick={() => setPaymentType(type.value)}
                  key={shortid.generate()}>
                  <img src={type.image} alt={type.label} />
                </div>
              </>
            );
          }
        })}
      </div>
      {/*{paymentType !== payOnDelievery &&
      paymentMethods?.find((i) => i !== payOnDelievery)
        ? // <form onSubmit={formik.handleSubmit}>
          //   <Row>
          //     <Col sm="6" md="3">
          //       <Input
          //         type="text"
          //         label={t('cardName')}
          //         placeholder={t('cardName')}
          //         name="cardName"
          //         value={values.cardName}
          //         onChange={handleChange}
          //         touched={touched.cardName}
          //         errors={errors.cardName}
          //       />
          //     </Col>
          //     <Col sm="6" md="3">
          //       <div className="form-group card-number-group">
          //         <label className="form-label">{t('cardNumber')}</label>
          //         <IMaskInput
          //           mask={'0000 0000 0000 0000'}
          //           unmask={true}
          //           name="cardNumber"
          //           placeholder={t('cardNumberplaceholder')}
          //           autoComplete="cc-number"
          //           value={`${values.cardNumber}`}
          //           onAccept={(value, mask) => {
          //             formik.setFieldValue('cardNumber', value);
          //           }}
          //           className={`form-control`}
          //         />
          //         {touched.cardNumber && errors.cardNumber && (
          //           <div className="form-errors">{errors.cardNumber}</div>
          //         )}
          //       </div>
          //     </Col>
          //     <Col sm="6" md="3" xl="2">
          //       <div className="form-group date-picker-group">
          //         <label className="form-label">
          //           {t('requestOverview.expirationDate')}
          //         </label>
          //         <IMaskInput
          //           value={values.validThrough}
          //           mask={'MM/YY'}
          //           blocks={{
          //             YY: {
          //               mask: '00',
          //             },
          //             MM: {
          //               mask: IMask.MaskedRange,
          //               from: 1,
          //               to: 12,
          //             },
          //           }}
          //           unmask={false}
          //           name="validThrough"
          //           onAccept={(value, mask) => {
          //             handleAccept(value, formik.setFieldValue);
          //           }}
          //           placeholder={t('validThroughplaceholder')}
          //           autoComplete="cc-exp"
          //           className="form-control"
          //         />
          //         {touched.validThrough && errors.validThrough && (
          //           <div className="form-errors">{errors.validThrough}</div>
          //         )}
          //         {expirationDateError && (
          //           <div className="form-errors">{expirationDateError}</div>
          //         )}
          //       </div>
          //     </Col>
          //     <Col sm="6" md="3">
          //       <label className="form-label">CVV/CVC</label>
          //       <IMaskInput
          //         mask={'000'}
          //         unmask={true}
          //         type="password"
          //         name="cvv"
          //         placeholder="***"
          //         autoComplete="cc-number"
          //         value={`${values.cvv}`}
          //         onAccept={(value, mask) => {
          //           formik.setFieldValue('cvv', value);
          //         }}
          //         className={`form-control`}
          //       />
          //       {touched.cvv && errors.cvv && (
          //         <div className="form-errors">{errors.cvv}</div>
          //       )}
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Col sm="6" md="3">
          //       <div className="form-group">
          //         <label className="custom-chk">
          //           {t('SavePaymentDetails')}
          //           <input
          //             type="checkbox"
          //             name="chkSavePaymentDetails"
          //             onChange={(e) => {
          //               formik.setFieldValue(
          //                 'chkSavePaymentDetails',
          //                 e.target.checked,
          //               );
          //             }}
          //           />
          //           <span className="checkmark"></span>
          //         </label>
          //       </div>
          //     </Col>
          //   </Row>
          // </form>
          clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )
        : // clientSecret && (
          //   <Elements options={options} stripe={stripePromise}>
          //     <CheckoutForm />
          //   </Elements>
          // )
          ''}*/}
    </div>
  );
};

export default PaymentType;
