import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Head from '../components/Head';
import Testimonial from '../components/Home/Testimonial';
import ImageText from '../components/Home/ImageText';
import AddJob from '../components/Home/AddJob';
import GlobalContext from '../context/GlobalContext';
import useQuery from '../utils/useQuery';
import { roles } from '../utils/constants';
import img1 from '../assets/images/pics-contact-cgk.jpg';
import img2 from '../assets/images/pics-register.jpg';
import videoback from '../assets/images/video-screen.png';
import video from '../assets/images/superstaff_video.mp4';

const HomePage = () => {
  let query = useQuery();
  const userData = useSelector((state) => state.user);
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -110,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const {
    showResetPassword,
    setShowResetPassword,
    setShowEmployerCompleteRegisterModal,
    setShowEmployeeCompleteRegisterModal,
  } = useContext(GlobalContext);
  const { resetToken, userId } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const role = query.get('role');
    if (resetToken) {
      setShowResetPassword({
        ...showResetPassword,
        visible: true,
        resetToken: resetToken,
      });
    }
    if (userId) {
      if (role === roles.employer) {
        setShowEmployerCompleteRegisterModal(true);
      } else if (role === roles.employee) {
        setShowEmployeeCompleteRegisterModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    //
    // Video.js
    //

    // const playArea = document.querySelectorAll('.video-poster-image');

    // if (playArea.length) {
    //   playArea.forEach((element) => {
    //     const url = element.parentElement
    //       .querySelector('.video-iframe')
    //       .getAttribute('src');
    //     const parser = document.createElement('a');

    //     element.addEventListener('click', () => {
    //       element.parentElement.classList.add('play-video');
    //       parser.classList.add('d-none');
    //       parser.href = url;
    //       if (parser.search) {
    //         element.parentElement.querySelector(
    //           '.video-iframe',
    //         ).src = `${url}&autoplay=1`;
    //       } else {
    //         element.parentElement.querySelector(
    //           '.video-iframe',
    //         ).src = `${url}?autoplay=1`;
    //       }
    //     });
    //   });
    // }
    const playBtn = document.querySelectorAll('.video-poster-image');

    if (playBtn.length) {
      playBtn.forEach((element) => {
        const mi = element.parentElement
          .querySelector('.my-tag')
          .getAttribute('src');
        const parser = document.createElement('a');

        element.addEventListener('click', () => {
          let vi = document.getElementById('videoTag');
          vi.play();
          document.getElementsByClassName('play-button')[0].hidden = true;
          document.getElementsByClassName(
            'video-poster-image',
          )[0].hidden = true;
        });
      });
    }
  }, []);

  return (
    <section className="homepage-section">
      <Head title={`${t('homePage')} | Superstaff`} />
      <aside className="testimonial-content">
        <Testimonial />
      </aside>
      <div className="content-main">
        <h2>{t('home.headingContent')}</h2>
        <AddJob />
        {userData && userData.data && userData.data._id ? (
          <></>
        ) : (
          <>
            <div className="two-col-wrap">
              <Row>
                <Col sm="6">
                  <ImageText
                    src={img1}
                    title={t('home.cgkDivTitle')}
                    text={t('home.cgkDivDesc')}
                    buttonText={t('contact')}
                    buttonLink="/contact"
                  />
                </Col>
                <Col sm="6">
                  <ImageText
                    src={img2}
                    title={t('home.regDivTitle')}
                    text={
                      t('home.regLiOne') +
                      '\n' +
                      t('home.regLiTwo') +
                      '\n' +
                      t('home.regLiThree')
                    }
                    buttonText={t('home.regLabel')}
                    isPopup
                  />
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className="two-col-wrap">
          <Row className="justify-content-md-center">
            <Col>
              <h3 className="mt-3 mt-md-4 mt-lg-5 mb-3 mb-md-4">
                {t('findbestemp')}
              </h3>
              <div className="video-iframe-wrapper frame">
                {/*<iframe
                  height="700"
                  title="myFrame"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  className="video-iframe lazy w-100"
                  src="https://www.youtube.com/embed/vsTg7JsqWR8"
                  allowFullScreen=""></iframe>*/}
                <video
                  width="100%"
                  height="100%"
                  poster={videoback}
                  id="videoTag"
                  preload="none"
                  controls>
                  <source src={video} type="video/mp4" className="my-tag" />
                </video>
                <div className="video-poster-image">
                  <div className="play-button">
                    <svg fill="none" viewBox="0 0 424 424">
                      <g filter="url(#filter0_d_1503_50432)" opacity=".6">
                        <circle
                          cx="212.224"
                          cy="206.201"
                          r="194.657"
                          fill="#F4F4F5"
                        />
                      </g>
                      <g filter="url(#filter1_d_1503_50432)">
                        <path
                          fill="#fff"
                          d="M263.897 206.909l-91.312 63.753V143.156l91.312 63.753z"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_1503_50432"
                          width="423.29"
                          height="423.29"
                          x=".58"
                          y=".219"
                          colorInterpolationFilters="sRGB"
                          filterUnits="userSpaceOnUse">
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="5.663" />
                          <feGaussianBlur stdDeviation="8.494" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1503_50432"
                          />
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1503_50432"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_1503_50432"
                          width="125.288"
                          height="161.482"
                          x="155.597"
                          y="128.999"
                          colorInterpolationFilters="sRGB"
                          filterUnits="userSpaceOnUse">
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="2.831" />
                          <feGaussianBlur stdDeviation="8.494" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1503_50432"
                          />
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1503_50432"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
