import { testimonialConstants } from './types';
import api from '../utils/api';
import i18n from '../i18n';

export const fetchTestimonials = () => {
  const request = () => {
    return { type: testimonialConstants.GET_TESTIMONIALS_REQUEST };
  };
  const success = ({ testimonials }) => {
    return {
      type: testimonialConstants.GET_TESTIMONIALS_SUCCESS,
      testimonials,
    };
  };
  const failure = (error) => {
    return { type: testimonialConstants.GET_TESTIMONIALS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const testimonials = await api.get('testimonials');
      dispatch(
        success({
          testimonials: testimonials.data.data.testimonialData,
        }),
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
      }
    }
  };
};
