import React from 'react';

export default function Play(props) {
  return (
    <svg viewBox="0 0 15 16" width="15" height="16">
      <g fill="#FFF" fill-rule="evenodd" opacity=".3">
        <path d="M0 16h5.6V0H0zM8.8 16h5.6V0H8.8z" />
      </g>
    </svg>
  );
}
