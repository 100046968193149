import React, { useEffect, useState, useContext } from 'react';
import { Modal, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import useQuery from '../../utils/useQuery';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalContext from '../../context/GlobalContext';
import Input from '../Core/Input';
import Button from '../Core/Button';
import FileUploadEmployer from '../Core/FileUploadEmployer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../Core/Select';
// import { handleError } from '../../utils/handleError';

import { completeRegistration } from '../../actions/authAction';
import api from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { ALPHABETS_ONLY1, NUMBER_ONLY, roles } from '../../utils/constants';
import PlacesAutocomplete, {
  geocodeByAddress,
  // getLatLng,
} from '@dsr/react-places-autocomplete';
import Loader from '../Core/Loader';

const EmployerCompleteRegister = () => {
  let query = useQuery();
  // const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [formSubmtting, setFormSubmtting] = useState(false);
  const { countriesOptions } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();

  const {
    showEmployerCompleteRegisterModal,
    setShowEmployerCompleteRegisterModal,
    showCompleteLoginModal,
    setShowCompleteLoginModal,
    setShowLoginModal,
  } = useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      companyName: '',
      password: '',
      passwordConfirm: '',
      country: '',
      city: '',
      postalCode: '',
      addressLineOne: '',
      addressLineTwo: '',
      companyVatNumber: '',
      phoneNumber: '',
      contactPerson: '',
      photo: [],
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required(t('companyName')),
      country: Yup.object().shape({
        label: Yup.string().required(t('Country')),
        value: Yup.string().required(t('Country')),
      }),
      password: Yup.string()
        .required(t('Password.required'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          t('Password.length'),
        ),
      // .min(8, 'Your password must be minimum 8 characters.'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], t('matchpassword'))
        .required(t('confirmpassword.required')),
      city: Yup.string().required(t('City')),
      postalCode: Yup.string()
        .required(t('PostalCode'))
        .matches(/^[0-9]/, t('postalcodenotnegative'))
        .min(4, t('Postalcodevalidation'))
        .max(5, t('Postalcodevalidation')),
      addressLineOne: Yup.string().required(t('AddressLineOne')),
      // companyVatNumber: Yup.string().required(t('companyVatNumber')),
      companyVatNumber: Yup.string()
        .required(t('companyVatNumber'))
        // .matches(/^\bATU[0-9]*$/, t('vatNumberFormat'))
        // .min(11, t('vatNumberFormat'))
        // .max(11, t('vatNumberFormat')),
        .matches(
          /^(AT)?U[0-9]{8}$|^(BE)?0[0-9]{9}$|^(BG)?[0-9]{9,10}$|^(CY)?[0-9]{8}L$|^(CZ)?[0-9]{8,10}$|^(DE)?[0-9]{9}$|^(DK)?[0-9]{8}$|^(EE)?[0-9]{9}$|^(EL|GR)?[0-9]{9}$|^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$|^(FI)?[0-9]{8}$|^(FR)?[0-9A-Z]{2}[0-9]{9}$|^(GB)?[0-9]{9}$|^(HU)?[0-9]{8}$|^(IE)?[0-9]S[0-9]{5}L$|^(IT)?[0-9]{11}$|^(LT)?([0-9]{9}|[0-9]{12})$|^(LU)?[0-9]{8}$|^(LV)?[0-9]{11}$|^(MT)?[0-9]{8}$|^(NL)?[0-9]{9}B[0-9]{2}$|^(PL)?[0-9]{10}$|^(PT)?[0-9]{9}$|^(RO)?[0-9]{2,10}$|^(SE)?[0-9]{12}$|^(SI)?[0-9]{8}$|^(SK)?[0-9]{10}$/,
          t('vatNumberFormat'),
        ),
      phoneNumber: Yup.number()
        .typeError(t('Phone.type'))
        .positive(t('Phone.type'))
        .integer(t('Phone.type'))
        .required(t('Phone.required'))
        .min(8),
      contactPerson: Yup.string()
        .required(t('contactperson.required'))
        .matches(/^([^0-9]*)$/, t('contactperson.match')),
    }),
    onSubmit: async (values) => {
      const role = query.get('role');
      const token = query.get('token');
      formik.setSubmitting(true);
      try {
        if (role === roles.employer) {
          const newValues = {
            ...values,
            country: values.country.value,
          };

          if (
            files[0] &&
            files[0] !== undefined &&
            files[0] !== null &&
            files[0] !== 'undefined' &&
            files[0] !== ''
          ) {
            newValues.photo = files[0];
          }

          let formData = new FormData();
          Object.entries(newValues).map(([key, value]) => {
            formData.append(key, value);
            return true;
          });

          setFormSubmtting(true);
          dispatch(
            completeRegistration({
              role: roles.employer,
              formData,
              token,
              callback: () => {
                setShowCompleteLoginModal({
                  ...showCompleteLoginModal,
                  visible: true,
                  role: roles.employer,
                });
                history.push('/');
                setShowEmployerCompleteRegisterModal(false);
              },
              setSubmitting: setFormSubmtting,
            }),
          );
        }
      } catch (e) {
        handleError(e);
      }
    },
  });

  useEffect(() => {
    let mounted = true;
    setDetailsLoading(true);
    api
      .get('/users/getMe', {
        headers: {
          Authorization: `Bearer ${query.get('token')}`,
        },
      })
      .then((res) => {
        if (mounted) {
          const user = res.data.data.user;
          setDetailsLoading(false);
          formik.setFieldValue('phoneNumber', user.phone);

          if (user.employee || user.employer) {
            history.push('/');
            setShowEmployerCompleteRegisterModal(false);
            setShowLoginModal({ visible: true, role: query.get('role') });
          }
        }
      })
      .catch((e) => {
        setDetailsLoading(false);
        setShowEmployerCompleteRegisterModal(false);
        history.push('/');
        toast.error(t('Faileduserdetails'));
      });
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedcity, setSelectedCity] = useState(formik.values?.city || '');
  const [addressline, setAddressLine] = useState(
    formik.values?.addressLineOne || '',
  );
  // useEffect(() => {
  //   formik.errors.city = '';
  //   formik.errors.addressLineOne = '';
  // });
  useEffect(() => {
    if (selectedcity === '') {
      formik.setFieldValue('city', '');
    } else if (formik.touched.city) {
      // formik.validateForm();
      // formRef?.current?.errors?.city = '';
    }
  }, [selectedcity]);
  useEffect(() => {
    if (addressline === '') {
      formik.setFieldValue('addressLineOne', '');
    } else if (formik.touched.addressLineOne) {
      // formik.validateForm();
      // formik.errors?.addressLineOne = '';
    }
  }, [addressline]);
  const renderSuggestionItem = (suggestion, type) => {
    if (type === 'sublocality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === 'locality') {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };
  const handleError = (name, error) => {
    // formik.setFieldError(name, error);
  };
  useEffect(() => {
    formik.validateForm();
  }, [formik.values.country, formik.values.addressLineOne, formik.values.city]);

  const handleSelect = async (address, name, type, suggestion) => {
    try {
      let returnAddress = address;
      if (type === 'sublocality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else if (type === 'locality') {
        returnAddress = suggestion?.formattedSuggestion?.mainText;
      } else {
        const res = await geocodeByAddress(address);
        // const rLatLang = await getLatLng(res[0]);

        if (type === 'sublocality' || type === 'locality') {
          returnAddress = res[0].address_components[0].short_name;
        }
      }
      if (name === 'city') {
        setSelectedCity(returnAddress);
        // formik.validateForm();
      } else {
        setAddressLine(returnAddress);
        // formik.validateForm();
      }

      formik.setFieldValue(name, returnAddress);
      formik.validateForm();
    } catch (error) {
      formik.setFieldError(name, error);
    }
  };

  return (
    <>
      <Modal
        show={showEmployerCompleteRegisterModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowEmployerCompleteRegisterModal(false);
          history.push('/');
        }}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {detailsLoading ? (
              <h3 className="visually-hidden">Register</h3>
            ) : (
              <h3>{t('register')}</h3>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailsLoading ? (
            <div className="d-flex align-items-center justify-content-center px-2 py-2">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              className="form form-complete-registration">
              <Row>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('company')} *`}
                    placeholder={t('company')}
                    name="companyName"
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        e.target.value?.length <= 50
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.companyName}
                    errors={formik.errors.companyName}
                    value={formik.values.companyName}
                  />
                </Col>
                <Col md="6">
                  <CustomSelect
                    label={`${t('country')} *`}
                    name={t('country')}
                    options={countriesOptions}
                    handleChange={(value) => {
                      formik.setFieldValue('country', value);
                    }}
                    handleBlur={() => {
                      formik.setFieldTouched('country', true);
                    }}
                    touched={formik.touched.country}
                    errors={
                      formik.errors.country ? formik.errors.country.value : ''
                    }
                    value={formik.values.country}
                  />
                </Col>
                <Col md="6">
                  <span>
                    <Input
                      type="password"
                      label={`${t('password')} *`}
                      placeholder={t('password')}
                      name="password"
                      tooltipTitle={t('passwordLogin')}
                      tooltipText={t('Password.length')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.password}
                      errors={formik.errors.password}
                      value={formik.values.password}
                    />

                    {/*<p
                      className="text-secondary"
                      style={{ fontSize: '14px', marginTop: '-50px' }}>
                      {t('Password.length')}
                  </p>*/}
                  </span>
                </Col>
                <Col md="6">
                  <Input
                    type="password"
                    label={`${t('confirmPassword')} *`}
                    placeholder={t('password')}
                    name="passwordConfirm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.passwordConfirm}
                    errors={formik.errors.passwordConfirm}
                    value={formik.values.passwordConfirm}
                  />
                </Col>
                <Col md="6">
                  <label className="form-label">{`${t('city')} *`}</label>
                  <PlacesAutocomplete
                    name={'city'}
                    id={'city'}
                    searchOptions={['(cities)']}
                    value={selectedcity}
                    onChange={(e) => {
                      formik.setFieldTouched('city', true);
                      handleSelect('', 'city', 'locality', '');
                      setSelectedCity(e);
                      setTimeout(() => {
                        formik.validateForm();
                      }, 200);
                    }}
                    onBlur={formik.handleBlur}
                    onSelect={(data, placeid, suggestion) =>
                      handleSelect(data, 'city', 'locality', suggestion)
                    }
                    onError={(error) => handleError('city', error)}>
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Input
                          {...getInputProps({
                            placeholder: t('City'),
                            className: 'location-search-input form-control',
                          })}
                          type="text"
                          // label="Street Line 1 *"
                          placeholder={t('City')}
                          name="city"
                          // onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            getInputProps()?.onBlur(e);
                            setTimeout(() => {
                              if (
                                formik.values.city !== '' &&
                                formik?.errors?.city &&
                                formik?.errors?.city !== ''
                              ) {
                                setSelectedCity('');
                              }
                            }, 200);
                          }}
                          touched={formik.touched.city}
                          errors={formik.errors.city}
                          value={formik.values.city}
                          formGroupClass={'location-search-input-wraper mb-0'}
                        />
                        {/* <input
                          {...getInputProps({
                            placeholder: 'City',
                            className: 'location-search-input form-control',
                          })}
                        /> */}
                        <div className="autocomplete-dropdown-container">
                          {loading && <Loader />}
                          {suggestions.map((suggestion) => {
                            if (suggestion?.types?.includes('locality')) {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}>
                                  <span>
                                    {renderSuggestionItem(
                                      suggestion,
                                      'locality',
                                    )}
                                    <small style={{ color: 'gray' }}>
                                      {suggestion?.formattedSuggestion?.mainText
                                        ? suggestion?.description?.replace(
                                            suggestion?.formattedSuggestion
                                              ?.mainText,
                                            '',
                                          )
                                        : suggestion?.description}
                                    </small>
                                  </span>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {/* {formik.errors?.city !== '' && (
                    <div class="form-errors">{formik.errors?.city}</div>
                  )} */}
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('postalCodeStaff')} *`}
                    placeholder={t('postalCodeStaff')}
                    name="postalCode"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        (NUMBER_ONLY.test(e.target.value) &&
                          e.target.value?.length <= 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.postalCode}
                    errors={formik.errors.postalCode}
                    value={formik.values.postalCode}
                  />
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">
                      {`${t('addressLineOne')} *`}
                    </label>
                    <PlacesAutocomplete
                      name={'addressLineOne'}
                      id={'addressLineOne'}
                      searchOptions={['address']}
                      value={addressline}
                      onChange={(value) => {
                        formik.setFieldTouched('addressLineOne', true);
                        handleSelect('', 'addressLineOne', 'sublocality', '');
                        setAddressLine(value);
                        setTimeout(() => {
                          formik.validateForm();
                        }, 200);
                      }}
                      onBlur={formik.handleBlur}
                      onSelect={(data, placeid, suggestion) =>
                        handleSelect(
                          data,
                          'addressLineOne',
                          'sublocality',
                          suggestion,
                        )
                      }
                      onError={(error) => handleError('addressLineOne', error)}>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            {...getInputProps({
                              placeholder: t('addressLineOne'),
                              className: 'location-search-input form-control',
                            })}
                            type="text"
                            // label="Street Line 1 *"
                            placeholder="Street Line 1"
                            name="addressLineOne"
                            // onChange={formik.handleChange}
                            touched={formik.touched.addressLineOne}
                            errors={formik.errors.addressLineOne}
                            value={formik.values.addressLineOne}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              getInputProps()?.onBlur(e);
                              setTimeout(() => {
                                if (
                                  formik.values.addressLineOne !== '' &&
                                  formik?.errors?.addressLineOne &&
                                  formik?.errors?.addressLineOne !== ''
                                ) {
                                  setAddressLine('');
                                }
                              }, 200);
                            }}
                            formGroupClass={'location-search-input-wraper mb-0'}
                          />
                          {/* <input
                            {...getInputProps({
                              placeholder: t('addressLineOne'),
                              className: 'location-search-input form-control',
                            })}
                          /> */}
                          <div className="autocomplete-dropdown-container">
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => {
                              if (!suggestion?.types?.includes('locality')) {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>
                                      {renderSuggestionItem(
                                        suggestion,
                                        'sublocality',
                                      )}
                                      <small style={{ color: 'gray' }}>
                                        {suggestion?.formattedSuggestion
                                          ?.mainText
                                          ? suggestion?.description?.replace(
                                              suggestion?.formattedSuggestion
                                                ?.mainText,
                                              '',
                                            )
                                          : suggestion?.description}
                                      </small>
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {/* {
                      formik.errors?.addressLineOne !== '' && (
                        <div class="form-errors">
                          {formik.errors?.addressLineOne}
                        </div>
                      )} */}
                  </div>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={t('addressLineTwo')}
                    placeholder={t('addressLineTwo')}
                    name="addressLineTwo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.addressLineTwo}
                    errors={formik.errors.addressLineTwo}
                    value={formik.values.addressLineTwo}
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('vat')} *`}
                    placeholder={t('vat')}
                    name="companyVatNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.companyVatNumber}
                    errors={formik.errors.companyVatNumber}
                    value={formik.values.companyVatNumber}
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('phone')} *`}
                    placeholder={t('phone')}
                    name="phoneNumber"
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        e.target.value?.length <= 20
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.phoneNumber}
                    errors={formik.errors.phoneNumber}
                    value={formik.values.phoneNumber}
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    label={`${t('contactPerson')} *`}
                    placeholder={t('contactPerson')}
                    name="contactPerson"
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        (ALPHABETS_ONLY1.test(e.target.value) &&
                          e.target.value?.length <= 50)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.contactPerson}
                    errors={formik.errors.contactPerson}
                    value={formik.values.contactPerson}
                  />
                </Col>
                <Col md="12">
                  <FileUploadEmployer
                    type="file"
                    label="Company logo"
                    placeholder="Company logo"
                    className="form-control--file-upload"
                    name="fileUpload"
                    onChange={(e) => {
                      formik.setFieldValue('fileUpload', e.target.files[0]);
                    }}
                    files={files}
                    setFiles={setFiles}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.fileUpload}
                    errors={formik.errors.fileUpload}
                    value={formik.values.fileUpload}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end form-bottom">
                <Button
                  type="submit"
                  label={t('myJob.confirm')}
                  className="btn primary-btn"
                  disabled={
                    formSubmtting || Object.entries(formik?.errors).length > 0
                  }
                />
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployerCompleteRegister;
