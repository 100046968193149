import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RegisterPendingModal = ({
  registerPendingModal,
  setRegisterPendingModal,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      show={registerPendingModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setRegisterPendingModal(false);
        history.push('/');
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('completelater')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-uppercase">
          <strong>{t('thankyouforinterest')}</strong>
        </p>
        <p>
          {t('pleasecompleteprocess')}
          <br /> {t('youcandoanytime')}
        </p>
        <p>{t('approveaccount')}</p>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterPendingModal;
