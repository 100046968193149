import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Button from '../Core/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { manageStartEndTime } from '../../utils/time';
// import api from '../../utils/api';
// import { handleError } from '../../utils/handleError';
import { updateJob } from '../../actions/jobAction';
import JobPositionForm, { initialJobPosition } from '../JobPositionForm';
import Loader from '../../components/Core/Loader';
import { getTimeDifferent } from '../../utils/helper';

const JobPosition = ({
  showJobModal,
  setShowJobModal,
  startDate,
  setStartDate,
  place,
  setPlace,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  handleJobPositionSubmit,
  handleJobPositionCancel,
}) => {
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // fetchPlaces();
  }, []);

  return (
    <Modal
      show={showJobModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={() => setShowJobModal({ ...showJobModal, visible: false })}
      className="add-job-login-modal contract-modal">
      {loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('requestOverview.addJobPosition')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            jobs: [{ ...initialJobPosition }],
          }}
          validationSchema={Yup.object({
            jobs: Yup.array().of(
              Yup.object().shape({
                place: Yup.object().shape({
                  address: Yup.string().required(t('place')),
                }),
                date: Yup.number()
                  .typeError(t('date.type'))
                  .required(t('date.required'))
                  .min(new Date(Date.now() - 864e5), t('date.minDate'))
                  .test('date', t('jobdatevalidation'), (value, fields) => {
                    const jobDate = fields.parent.date;
                    const eventExpirationDate = parseInt(
                      showJobModal.eventExpirationDate,
                    );
                    if (eventExpirationDate && eventExpirationDate > jobDate) {
                      return false;
                    } else {
                      return true;
                    }
                    // if false then error visible
                  }),
                start: Yup.date()
                  .test('start', t('startTime.addJob'), (value, fields) => {
                    if (!fields.parent.date) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .required(t('startTime.required'))
                  .test('start', t('startTime.duration'), (value, fields) => {
                    return (
                      manageStartEndTime(
                        moment(value).format('x') * 1,
                        fields.parent.date,
                      ) > new Date().valueOf()
                    );
                  }),
                end: Yup.date()
                  .required(t('endTime.required'))
                  .test('same', t('startendtimesameerror'), (value, fields) => {
                    let startDate = parseInt(
                      moment(fields.parent.start).format('x'),
                    );
                    let endDate = parseInt(
                      moment(fields.parent.end).format('x'),
                    );
                    if (
                      moment(startDate).format('HH:mm') ===
                      moment(endDate).format('HH:mm')
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .test('end', t('endTime.duration'), (value, fields) => {
                    const hoursDiff = getTimeDifferent(
                      fields.parent.start,
                      fields.parent.end,
                    );
                    return parseFloat(hoursDiff.toFixed(2)) <= 12;
                  }),
                // .required(t('endTime.required')),
              }),
            ),
          })}
          onSubmit={async (values) => {
            // debugger;
            const newValues = values.jobs.map((job) => {
              const jobStart = job.start;
              const jobEnd = job.end;
              const endTime =
                parseInt(moment(jobEnd).format('x')) <
                parseInt(moment(jobStart).format('x'))
                  ? parseInt(moment(jobEnd).format('x')) + 86400000
                  : parseInt(moment(jobEnd).format('x'));
              const jobDate = job.date;
              const jobPrice = parseFloat(Number(job?.price).toFixed(2)) || 0;
              const jobTravelCost =
                parseFloat(Number(job?.travelCosts).toFixed(2)) || 0;
              const employerDataJobPrice =
                parseFloat(Number(job?.employerData?.jobPrice).toFixed(2)) || 0;
              const employerDataJobTravelCost =
                parseFloat(
                  Number(job?.employerData?.jobTravelCost).toFixed(2),
                ) || 0;
              return {
                jobStart: manageStartEndTime(jobStart, jobDate),
                jobEnd: manageStartEndTime(endTime, jobDate),
                jobDate: jobDate,
                jobPlace: job.place.value,
                jobPlaceAddress: job.place,
                jobPrice,
                jobTravelCost,
                jobTotalCost: jobPrice + jobTravelCost,
                hourlyRate: job.hourlyRate,
                employerData: {
                  ...job.employerData,
                  hours: parseFloat(
                    Number(job?.employerData?.hours).toFixed(2),
                  ),
                  jobPrice: employerDataJobPrice,
                  jobTravelCost: employerDataJobTravelCost
                    ? employerDataJobTravelCost
                    : jobTravelCost,
                  jobTotalCost:
                    employerDataJobPrice +
                    (employerDataJobTravelCost
                      ? employerDataJobTravelCost
                      : jobTravelCost || 0),
                },
              };
            });
            const newJobPositions = [
              ...showJobModal.jobPositions,
              ...newValues,
            ];
            setFormSubmitting(true);
            setLoading(true);
            dispatch(
              updateJob({
                id: showJobModal.jobId,
                payload: { jobPositions: newJobPositions },
                callback: () => {
                  setFormSubmitting(false);
                  setLoading(false);
                  setShowJobModal({ ...showJobModal, visible: false });
                },
                failCallback: () => {
                  setFormSubmitting(false);
                },
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="form">
              <JobPositionForm
                values={values}
                errors={errors}
                touched={touched}
                formSubmitting={formSubmitting}
                setFormSubmitting={setFormSubmitting}
                setFieldValue={setFieldValue}
                places={places}
                jobName={showJobModal.jobName}
                jobBonus={showJobModal.jobBonus}
                setFieldTouched={setFieldTouched}
              />
              <div className="form-bottom">
                <Button
                  type="button"
                  label={t('requestOverview.modal.cancel')}
                  className="btn btn--outline"
                  onClick={handleJobPositionCancel}
                />
                <Button
                  type="submit"
                  label={t('requestOverview.modal.add')}
                  className="btn"
                  disabled={formSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default JobPosition;
