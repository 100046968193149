import React, { useContext, useState, useEffect } from 'react';
import { Modal, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../actions/eventAction';
import GlobalContext from '../../context/GlobalContext';
import CustomSelect from '../../components/Core/Select';
import Checkbox from '../Core/Checkbox';
import Button from '../../components/Core/Button';
// import MapComponent from '../MapComponent';
import api from '../../utils/api';
import { FILTER_SIZE_OPTIONS, labelRoles } from '../../utils/constants';
import Loader from '../Core/Loader';
import { useTranslation } from 'react-i18next';
// import i18n from '../../i18n';
import moment from 'moment';
import defaultAvatar from '../../assets/images/default-avatar.png';

import {
  updateJobPosition,
  // bookStaff,
  checkEmployeestatus,
  checkRejectedEmployeestatus,
} from '../../actions/jobAction';

// import { makeStyles, withStyles } from "@material-ui/core/styles";

import SweetAlert from 'react-bootstrap-sweetalert';
// import alertStyles from "";

// const useStyles = makeStyles({ ...alertStyles });

const searchkm = [
  { value: '10 km', label: '10 km' },
  { value: '25 km', label: '25 km' },
  { value: '50 km', label: '50 km' },
  { value: '75 km', label: '75 km' },
  { value: '100 km', label: '100 km' },
];

const genders = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' },
  { label: 'Other', value: 'other' },
];

const languages = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'Russian', value: 'ru' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
  { label: 'Chinese', value: 'zh' },
];

const hairColors = [
  { label: 'Red', value: 'red' },
  { label: 'Black', value: 'black' },
  { label: 'Brown', value: 'brown' },
  { label: 'Blond', value: 'blond' },
  { label: 'White', value: 'white' },
  { label: 'Gray', value: 'gray' },
  { label: 'Other_color', value: 'other' },
  { label: 'None', value: 'none' },
];

const getBookingApplicant = (applicant, applications) => {
  if (applicant) {
    const applicantToBook = applications.find((application) => {
      return application?.applicant?._id === applicant?.applicant?._id;
    });
    return applicantToBook ? applicantToBook?.applicant : applicant?.applicant;
  } else {
    return null;
  }
};

const getApplications = (applications, readyToBook) => {
  const { applicant } = readyToBook;

  if (applicant) {
    return applications.filter(
      (application) => application?.applicant?._id !== applicant?._id,
    );
  } else {
    return applications;
  }
};

const displaycartitems = ({ cartJobs, employee, startDate, endDate }) => {
  let flag = false;

  if (cartJobs && cartJobs.length) {
    cartJobs.some((cartJob) => {
      if (
        cartJob.staffToBook &&
        cartJob.staffToBook.applicant &&
        cartJob.staffToBook.applicant.blockedJobs &&
        cartJob.staffToBook.applicant.blockedJobs.length
      ) {
        if (cartJob.staffToBook.applicant._id === employee) {
          // flag = cartJob.staffToBook.applicant.blockedJobs.some((job) => {
          //   // return startDate >= job.jobEnd || endDate <= job.jobStart;
          //   return endDate > job.jobStart && job.jobEnd > startDate;
          // });

          return (flag =
            endDate > cartJob.jobStart && cartJob.jobEnd > startDate);
        }
      }
    });
    if (flag === true) {
      // setAddToListLoading(false);
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const checkAvailability = ({ applicant, jobStart, jobEnd }) => {
  let flag = false;
  try {
    if (applicant.blockedJobs && applicant.blockedJobs.length) {
      flag = applicant.blockedJobs.some((blockedJob) => {
        // return jobStart >= blockedJob.jobEnd || jobEnd <= blockedJob.jobStart;
        return jobStart < blockedJob.jobEnd && blockedJob.jobStart < jobEnd;
      });
    } else {
      flag = false;
    }
  } catch (err) {
    flag = false;
  }

  if (flag) {
    return false;
  } else {
    return true;
  }
};
const getUnique = (array) => {
  let uniqueArray = [];

  // Loop through array values
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
};

const availabilityIndicator = ({ applicant, job, event }) => {
  let jobDates = [];

  if (event && event.length) {
    event[0]?.jobs?.map((job) => {
      job?.jobPositions?.map((jobPosition, key) => {
        jobDates[key] = moment(jobPosition.jobDate).format('DD.MM.YYYY');
      });
    });
  }
  let uniquedates = getUnique(jobDates);

  let flag = false;
  // jobPositionCount = 0,
  // appliedBookedJobCount = 0;
  // job?.jobPositions?.map((jobPosition) => {
  //   jobPosition?.applications?.map((application) => {
  //     if (applicant?._id === application.applicant._id) {
  //       appliedBookedJobCount++;
  //     } else if (applicant?._id === jobPosition?.booked?.employee?._id) {
  //       appliedBookedJobCount++;
  //     }
  //   });
  //   jobPositionCount++;
  // });
  let appliedDates = [];

  if (event && event.length) {
    event[0]?.jobs?.map((job) => {
      job?.jobPositions?.map((jobPosition) => {
        jobPosition?.applications?.map((application) => {
          if (
            applicant?._id === application?.applicant?._id &&
            uniquedates.includes(
              moment(jobPosition.jobDate).format('DD.MM.YYYY'),
            )
          ) {
            if (
              !appliedDates.includes(
                moment(jobPosition.jobDate).format('DD.MM.YYYY'),
              )
            ) {
              appliedDates.push(
                moment(jobPosition.jobDate).format('DD.MM.YYYY'),
              );
            }
          }
        });
      });
    });
  }

  if (appliedDates.length === uniquedates.length) {
    flag = true;
  }
  return flag;
};

const StaffSelection = ({ showStaffModal, setShowStaffModal }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [cartJobs, setCartJobs] = useState([]);
  const eventsData = useSelector((state) => state.events);
  const { showBookingList, setShowBookingList } = useContext(GlobalContext);
  const [applicantsLoading, setApplicantsLoading] = useState(false);
  const [newApplications, setNewApplications] = useState([]);
  const [newReadytoBook, setNewReadytoBook] = useState();
  const [updateJob, setUpdateJob] = useState(false);
  const userData = useSelector((state) => state.user);
  const loggedinuser = userData.data.role;
  const { setListLoadingExtern } = useContext(GlobalContext);

  let currentevent = eventsData.allEvents.events.filter(function (event) {
    return event._id === showStaffModal.data.job.eventID;
  });
  // console.log('applications', showStaffModal.data.position.applications);

  // console.log('NewApplications', NewApplications);

  const [applications, setApplications] = useState(
    getApplications(newApplications, showStaffModal.data.position.readyToBook),
  );

  const [filteredApplications, setFilteredApplications] = useState(
    getApplications(newApplications, showStaffModal.data.position.readyToBook),
  );
  const [rejectedApplicants, setRejectedApplicants] = useState(
    showStaffModal.data.position.rejectedApplicants,
  );
  const [bookingList, setBookingList] = useState(
    getBookingApplicant(
      showStaffModal.data.position.readyToBook,
      newApplications,
    ),
  );
  const [sortType, setSortType] = useState(null);
  const [addToListLoading, setAddToListLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setBookingList(
      getBookingApplicant(
        showStaffModal.data.position.readyToBook,
        newApplications,
      ),
    );
    setFilteredApplications(
      getApplications(
        newApplications,
        showStaffModal.data.position.readyToBook,
      ),
    );
    setApplications(
      getApplications(
        newApplications,
        showStaffModal.data.position.readyToBook,
      ),
    );
  }, [newApplications, showStaffModal.data.position.readyToBook]);

  useEffect(() => {
    async function getdata() {
      setAddToListLoading(true);
      const NewApplications = showStaffModal.data.position.applications;
      const NewRejects = showStaffModal.data.position.rejectedApplicants;
      const NewReadyToBook = showStaffModal.data.position.readyToBook;
      if (NewApplications && NewApplications.length) {
        await Promise.all(
          NewApplications.map(async (app) => {
            if (
              app?.applicant &&
              app?.applicant?.images &&
              app?.applicant?.images?.length
            ) {
              if (
                typeof app?.applicant?.images[0] === 'string' &&
                !app?.applicant?.images[0].includes(
                  'https://storage.googleapis.com',
                ) &&
                (String(app?.applicant?.images[0]).startsWith('employee-') ||
                  String(app?.applicant?.images[0]).startsWith('user-'))
              ) {
                const image = app?.applicant?.images[0];
                const response = await api.post('users/getImageUrl', { image });
                const newimage = response.data.image;
                app.applicant.images[0] = newimage;
              }
              return;
            }
          }),
        );
      }
      if (NewRejects && NewRejects.length) {
        await Promise.all(
          NewRejects.map(async (app) => {
            if (
              app?.applicant &&
              app?.applicant?.images &&
              app?.applicant?.images?.length
            ) {
              if (
                typeof app?.applicant?.images[0] === 'string' &&
                !app?.applicant?.images[0].includes(
                  'https://storage.googleapis.com',
                ) &&
                (String(app?.applicant?.images[0]).startsWith('employee-') ||
                  String(app?.applicant?.images[0]).startsWith('user-'))
              ) {
                const image = app?.applicant?.images[0];
                const response = await api.post('users/getImageUrl', {
                  image,
                });
                const newimage = response.data.image;
                app.applicant.images[0] = newimage;
              }
              return;
            }
          }),
        );
      }
      if (NewReadyToBook) {
        if (
          NewReadyToBook?.applicant &&
          NewReadyToBook?.applicant?.images &&
          NewReadyToBook?.applicant?.images?.length
        ) {
          if (
            typeof NewReadyToBook?.applicant?.images[0] === 'string' &&
            !NewReadyToBook?.applicant?.images[0].includes(
              'https://storage.googleapis.com',
            ) &&
            (String(NewReadyToBook?.applicant?.images[0]).startsWith(
              'employee-',
            ) ||
              String(NewReadyToBook?.applicant?.images[0]).startsWith('user-'))
          ) {
            const image = NewReadyToBook?.applicant?.images[0];
            const response = await api.post('users/getImageUrl', { image });
            const newimage = response.data.image;
            NewReadyToBook.applicant.images[0] = newimage;
          }
        }
      }
      setNewReadytoBook(NewReadyToBook);
      setRejectedApplicants(NewRejects);
      // console.log('NewApplications', NewApplications);
      setNewApplications(NewApplications);
      // if (!addToListLoading) {
      if (updateJob === false) {
        setAddToListLoading(false);
      }
      // } else {
      // setAddToListLoading(true);
      // }
    }
    getdata();
  }, [showStaffModal.visible]);

  useEffect(() => {
    let localCartJobs = [];
    const events = eventsData.allEvents.events;
    if (events && events.length) {
      events.map((event) => {
        event.jobs.map((job) => {
          job.jobPositions.map((position) => {
            if (
              !position.booked &&
              position.staffToBook &&
              position.staffToBook.applicant
            ) {
              localCartJobs = [
                ...localCartJobs,
                {
                  ...position,
                  jobName: job.jobName,
                  eventName: job.eventName,
                  eventID: job.eventID,
                  companyName: job.companyName,
                },
              ];
            }
          });
        });
      });
    }
    setCartJobs([...localCartJobs]);
  }, [eventsData]);

  const warningWithConfirmMessage = (applicant, remarks) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block' }}
        title={t('Areyousure')}
        onConfirm={() => {
          addToBookingList(applicant, remarks);
          setAlert(null);
        }}
        onCancel={() => {
          hideAlert();
          setAddToListLoading(false);
        }}
        confirmBtnCssClass="btn--secondary px-5 shadow-none"
        cancelBtnCssClass="btn--secondary text-decoration-none"
        confirmBtnText={t('staffProfile.yes')}
        cancelBtnText={t('requestOverview.modal.cancel')}
        showCancel>
        {t('Areyousuretext')}
      </SweetAlert>,
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const formik = useFormik({
    initialValues: {
      kms: '',
      languages: [],
      hairColors: [],
      genders: [],
      sizes: [],
      driversLicense: false,
      carAvailable: false,
    },
    onSubmit: async (values) => {
      setApplicantsLoading(true);
      formik.setSubmitting(true);
      const position = showStaffModal.data.position;
      if (!applications || !applications.length) {
        return true;
      } else {
        // const allapplication = applications.map(async (app) => {
        //   return {
        //     applicant: {
        //       size: app.applicant.size,
        //       hairColor: app.applicant.hairColor,
        //       foreignLanguages: app.applicant.foreignLanguages,
        //       motherTongue: app.applicant.motherTongue,
        //       driversLicense: app.applicant.driversLicense,
        //       carAvailable: app.applicant.carAvailable,
        //       location: app?.applicant?.location,
        //     },
        //   };
        // });

        try {
          const { data } = await api.post('/filter-staff', {
            ...values,
            applications,
            // allapplication,
            position: position?.location,
          });

          formik.setSubmitting(false);
          setApplicantsLoading(false);
          setFilteredApplications(data.data.applications);
        } catch (err) {
          formik.setSubmitting(false);
          setApplicantsLoading(false);
        }
      }
    },
  });
  const clearFilter = () => {
    formik.resetForm();
    formik.submitForm();
    // filterFormInitData
  };
  React.useEffect(() => {
    if (sortType === null) {
      clearFilter();
    }
  }, [sortType]);

  const handleRejectApplicant = (applicant) => {
    const newApplications = [...applications].filter(
      (app) => app.applicant._id !== applicant._id,
    );
    const rejectedApplicant = [...applications].filter(
      (app) => app.applicant._id === applicant._id,
    );
    setRejectedApplicants([...rejectedApplicants, ...rejectedApplicant]);
    setApplications([...newApplications]);
    const newFilteredApplications = [...filteredApplications].filter(
      (app) => app.applicant._id !== applicant._id,
    );
    setFilteredApplications([...newFilteredApplications]);
    setAddToListLoading(false);
  };

  const addToBookingList = (applicant, remarks) => {
    const newApplications = [...applications].filter(
      (app) => app.applicant._id !== applicant._id,
    );
    setApplications([...newApplications]);
    setBookingList(applicant);
    const newFilteredApplications = [...filteredApplications].filter(
      (app) => app.applicant._id !== applicant._id,
    );
    setFilteredApplications([...newFilteredApplications]);

    setAddToListLoading(true);
    let newPosition = {
      ...showStaffModal.data.position,
      inReadyToBook: true,
    };
    delete newPosition.applications;
    delete newPosition._id;
    newPosition.readyToBook = {
      applicant: applicant._id,
      remarks: remarks,
    };
    // console.log("1.",newPosition);
    dispatch(
      updateJobPosition({
        id: showStaffModal.data.position._id,
        payload: newPosition,
        callback: () => {
          // setBookingList(applicant);
          setAddToListLoading(false);
          // dispatch(fetchCurrentEvents({ currPage: 1 }));
        },
        failCallback: () => {
          setAddToListLoading(false);
        },
      }),
    );
  };

  // const redirectToDetailsPage = (id) => {
  //   history.push(`/staff-details/${id}`);
  // };

  const renderApplicantImages = (applicants) => {
    return applicants.map(({ applicant, remarks, id }) => {
      return (
        <React.Fragment
          key={`${applicant ? applicant.images : Math.random() * id}`}>
          {/*{applicantsLoading && <Loader />}*/}
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose
            overlay={
              <Popover id="popover-basic" className="staff-actions-wrap">
                <Popover.Body className="">
                  <div
                    className="staff-action"
                    // onClick={() => redirectToDetailsPage(applicant._id)}
                    onClick={() => {
                      document.getElementById('img').click();
                      if (loggedinuser === 'superuser') {
                        window.open(`/staff-detail/${applicant?._id}`);
                      } else {
                        window.open(`/staff-details/${applicant?._id}`);
                      }
                    }}>
                    {t('ShowProfile')}
                  </div>
                  <div
                    className="staff-action"
                    onClick={() => {
                      document.getElementById('img').click();
                      setAddToListLoading(true);
                      let newPosition = { ...showStaffModal.data.position };
                      delete newPosition.applications;
                      delete newPosition._id;
                      newPosition.rejectedApplicants = [
                        ...newPosition.rejectedApplicants,
                        { applicant: applicant._id },
                      ];
                      dispatch(
                        updateJobPosition({
                          id: showStaffModal.data.position._id,
                          payload: newPosition,
                          callback: () => {
                            handleRejectApplicant(applicant);
                            // dispatch(fetchCurrentEvents({ currPage: 1 }));
                          },
                          failCallback: () => {
                            setAddToListLoading(false);
                          },
                        }),
                      );
                    }}>
                    {t('RejectApplicant')}
                  </div>
                  {!bookingList ? (
                    <div
                      className="staff-action"
                      onClick={() => {
                        document.getElementById('img').click();
                        setAddToListLoading(true);
                        // addToBookingList(applicant)
                        dispatch(
                          checkEmployeestatus({
                            jobId: showStaffModal.data.position._id,
                            employee: applicant,
                            jobStart: showStaffModal.data.position.jobStart,
                            jobEnd: showStaffModal.data.position.jobEnd,
                            callback: () => {
                              // setAddToListLoading(false);
                              warningWithConfirmMessage(applicant, remarks);
                            },
                            failCallback: () => {
                              setAddToListLoading(false);
                            },
                          }),
                        );
                        // eslint-disable-next-line no-lone-blocks
                        {
                          /* !checkAvailability({
                          applicant,
                           jobStart: showStaffModal.data.position.jobStart,
                         jobEnd: showStaffModal.data.position.jobEnd,
                         })
                           ? toast.error(t('staff.selectYourStaffredText'))
                        : warningWithConfirmMessage(applicant, remarks))*/
                        }
                      }}>
                      {t('AddApplicant')}
                    </div>
                  ) : (
                    ''
                  )}
                  {remarks && (
                    <div className="text-secondary">
                      <strong>{t('Notesfromapplicant')}</strong>
                      <p>{remarks}</p>
                    </div>
                  )}
                </Popover.Body>
              </Popover>
            }>
            <li
              id="img"
              style={{
                cursor: 'pointer',
                position: 'relative',
              }}
              className={`${
                availabilityIndicator({
                  applicant,
                  job: showStaffModal.data.job,
                  event: currentevent,
                })
                  ? 'booked-applicant'
                  : ''
              } ${
                !checkAvailability({
                  applicant,
                  jobStart: showStaffModal.data.position.jobStart,
                  jobEnd: showStaffModal.data.position.jobEnd,
                })
                  ? 'blocked-applicant'
                  : ''
              }`}
              // title={applicant?.name ?? ''}
            >
              {applicant && (
                <>
                  {/*<img
                    src={`${process.env.REACT_APP_BACKEND_URL}${
                      applicant.images ? applicant.images[0] : ''
                    }`}
                    alt="Profile"
                  />*/}
                  <img
                    src={`${
                      applicant.images &&
                      applicant.images[0] &&
                      typeof applicant.images[0] === 'string' &&
                      !applicant.images[0].startsWith('employee-') &&
                      !applicant.images[0].startsWith('user-')
                        ? applicant.images[0]
                        : defaultAvatar
                    }`}
                    alt={'Profile'}
                  />
                  <span
                    className={`staff-gender ${
                      applicant.gender === 'male'
                        ? 'male'
                        : applicant.gender === 'female'
                        ? 'female'
                        : 'other'
                    }`}></span>
                </>
              )}
              {!checkAvailability({
                applicant,
                jobStart: showStaffModal.data.position.jobStart,
                jobEnd: showStaffModal.data.position.jobEnd,
              }) && (
                <>
                  <span
                    style={{
                      height: '23px',
                      minWidth: '23px',
                      fontSize: '9px',
                      paddingTop: '1px',
                      left: '0',
                    }}
                    className="book-time">
                    45m
                  </span>
                  {/*<span
                    className="gray-overlay"
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      backgroundColor: 'rgba(208, 208, 208, 0.65)',
                      borderRadius: '50%',
                    }}></span>*/}
                </>
              )}
            </li>
          </OverlayTrigger>
        </React.Fragment>
      );
    });
  };

  const renderRejectedApplicantImages = (applicants) => {
    return applicants.map(({ applicant, id }) => {
      return (
        <React.Fragment
          key={`${applicant ? applicant.images : Math.random() * id}`}>
          {/*{applicantsLoading && <Loader />}*/}

          <li
            style={{ cursor: 'pointer' }}
            className={
              availabilityIndicator({
                applicant,
                job: showStaffModal.data.job,
                event: currentevent,
              })
                ? 'booked-applicant'
                : ''
            }>
            {applicant && (
              <>
                {/*<img
                  src={`${process.env.REACT_APP_BACKEND_URL}${
                    applicant.images ? applicant.images[0] : ''
                  }`}
                  alt="Profile"
                />*/}
                <img
                  src={`${
                    applicant.images &&
                    applicant.images[0] &&
                    typeof applicant.images[0] === 'string' &&
                    !applicant.images[0].startsWith('employee-') &&
                    !applicant.images[0].startsWith('user-')
                      ? applicant.images[0]
                      : defaultAvatar
                  }`}
                  alt="Profile"
                />
                <span
                  className={`staff-gender ${
                    applicant.gender === 'male'
                      ? 'male'
                      : applicant.gender === 'female'
                      ? 'female'
                      : 'other'
                  }`}></span>
              </>
            )}
          </li>
        </React.Fragment>
      );
    });
  };

  return (
    <Modal
      show={showStaffModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShowStaffModal({ ...showStaffModal, visible: false });
      }}
      backdrop={'static'}
      keyboard={false}
      className="staff-select-modal">
      {alert}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('staff.selectYourStaff')}</h3>
        </Modal.Title>
        <div className="staff-select-info">
          <span></span>
          <p>{t('staff.selectYourStaffText')}</p>
          <span className="red-circle"></span>
          <p>{t('staff.selectYourStaffredText')}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        {addToListLoading && <Loader />}
        {sortType && (
          <Row>
            <Col md="12">
              <Row className="staff-select-wrap">
                <Col xs="5">
                  <div className="staff-header-wrappar">
                    <span>{t('requestOverview.applications')}</span>
                    <div className="staff-custom-tabs">
                      {/* {sortType === 'map' && (
                        <div
                          className="sort-type-in"
                          onClick={() => setSortType(null)}>
                          {t('staff.map')}
                          <span className="icon-in">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              height="14"
                              width="15"
                              fill="#41A9C7">
                              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                            </svg>
                          </span>
                        </div>
                      )} */}
                      {sortType === 'filter' && (
                        <div
                          className="sort-type-in"
                          onClick={() => setSortType(null)}>
                          {t('staff.filter')}
                          <span className="icon-in">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              height="14"
                              width="15"
                              fill="#41A9C7">
                              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                            </svg>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="staff-header-wrappar">
                    {/*<span>{t('myJob.rejected')}</span>*/}
                  </div>
                </Col>
                <Col xs="4">
                  <div className="staff-header-wrappar">
                    {/*<span>{t('staff.readyToBook')}</span>*/}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="12">
              {/* {sortType === 'map' && (
                <div className="staff-map-block">
                  <MapComponent
                    location={showStaffModal.data.position.location}
                    applications={applications}
                  />
                </div>
              )} */}
              {sortType === 'filter' && (
                <div className="staff-filter-block">
                  <form className="form" onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md="6" lg="3" xxl="2">
                        <CustomSelect
                          label={t('staff.searchWithin')}
                          name="kms"
                          value={formik.values.kms}
                          options={searchkm}
                          classNamePrefix="c-select"
                          onChange={(e) => formik.setFieldValue('kms', e)}
                        />
                      </Col>
                      <Col xs="6" md="3" lg="2" xxl="1">
                        <div className="checkbox-wrapper">
                          <div className="checkbox-title">
                            <span>{t('size')}</span>
                            {/* <span className="staff-gender female"></span> */}
                          </div>
                          {FILTER_SIZE_OPTIONS.map((size, id) => (
                            <Checkbox
                              label={size.label}
                              key={size.label + id}
                              onChange={() => {
                                if (
                                  formik?.values?.sizes.includes(size.value)
                                ) {
                                  const newValues =
                                    formik?.values?.sizes.filter(
                                      (val) => val !== size.value,
                                    );
                                  formik.setFieldValue('sizes', [...newValues]);
                                } else {
                                  formik.setFieldValue('sizes', [
                                    ...formik?.values?.sizes,
                                    size.value,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </Col>
                      {/* <Col xs="6" md="3" xxl="1">
                        <div className="checkbox-wrapper">
                          <div className="checkbox-title">
                            <span>{t('size')}</span>
                            <span className="staff-gender male"></span>
                          </div>
                          {sizes.map((size, id) => (
                            <Checkbox
                              label={size.label}
                              key={size.label + id}
                              onChange={() => {
                                const { maleSizes } = formik.values;
                                if (maleSizes.includes(size.value)) {
                                  const newValues = maleSizes.filter(
                                    (val) => val !== size.value,
                                  );
                                  formik.setFieldValue('maleSizes', [
                                    ...newValues,
                                  ]);
                                } else {
                                  formik.setFieldValue('maleSizes', [
                                    ...maleSizes,
                                    size.value,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </Col> */}
                      <Col md="12" lg="4" xxl="3">
                        <div className="checkbox-wrapper lang-chk">
                          <div className="checkbox-title">
                            <span>{t('staff.language')}</span>
                          </div>
                          {languages.map((language, id) => (
                            <Checkbox
                              // label={language.label}
                              label={t(language.label)}
                              key={language.label + id}
                              onChange={() => {
                                const { languages } = formik.values;
                                if (languages.includes(language.value)) {
                                  const newValues = languages.filter(
                                    (val) => val !== language.value,
                                  );
                                  formik.setFieldValue('languages', [
                                    ...newValues,
                                  ]);
                                } else {
                                  formik.setFieldValue('languages', [
                                    ...languages,
                                    language.value,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="3" xxl="1">
                        <div className="checkbox-wrapper">
                          <div className="checkbox-title">
                            <span>{t('hairColor')}</span>
                          </div>
                          {hairColors.map((hairColor, id) => (
                            <Checkbox
                              // label={hairColor.label}
                              label={t(hairColor.label)}
                              key={hairColor.label + id}
                              onChange={() => {
                                const { hairColors } = formik.values;
                                if (hairColors.includes(hairColor.value)) {
                                  const newValues = hairColors.filter(
                                    (val) => val !== hairColor.value,
                                  );
                                  formik.setFieldValue('hairColors', [
                                    ...newValues,
                                  ]);
                                } else {
                                  formik.setFieldValue('hairColors', [
                                    ...hairColors,
                                    hairColor.value,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="3" xxl="2">
                        <div className="checkbox-wrapper">
                          <div className="checkbox-title">
                            <span>{t('gender')}</span>
                          </div>
                          {genders.map((gender, id) => (
                            <Checkbox
                              // label={gender.label}
                              label={t(gender.label)}
                              key={gender.label + id}
                              onChange={() => {
                                const { genders } = formik.values;
                                if (genders.includes(gender.value)) {
                                  const newValues = genders.filter(
                                    (val) => val !== gender.value,
                                  );
                                  formik.setFieldValue('genders', [
                                    ...newValues,
                                  ]);
                                } else {
                                  formik.setFieldValue('genders', [
                                    ...genders,
                                    gender.value,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="3" xxl="2">
                        <div className="checkbox-wrapper">
                          <div className="checkbox-title">
                            <span>{t('staff.mobility')}</span>
                          </div>
                          <Checkbox
                            label={t('staff.driversLicense')}
                            value={formik.values.driversLicense}
                            onChange={() => {
                              if (formik.values.driversLicense) {
                                formik.setFieldValue('driversLicense', false);
                              } else {
                                formik.setFieldValue('driversLicense', true);
                              }
                            }}
                          />
                          <Checkbox
                            label={t('staff.ownCar')}
                            value={formik.values.carAvailable}
                            onChange={() => {
                              if (formik.values.carAvailable) {
                                formik.setFieldValue('carAvailable', false);
                              } else {
                                formik.setFieldValue('carAvailable', true);
                              }
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 text-right">
                        <div className="staff-form-actions">
                          <Button
                            type="button"
                            label={t('requestOverview.modal.cancel')}
                            className="btn btn--outline"
                            onClick={() => {
                              setSortType(null);
                            }}
                          />
                          <Button
                            type="submit"
                            label={t('filterstaffsubmit')}
                            className="btn"
                            disabled={formik.isSubmitting}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </Col>
          </Row>
        )}

        <Row>
          <Col md="5">
            <div className="staff-header-wrappar">
              <span>{t('requestOverview.applications')}</span>
              <div className="staff-custom-tabs">
                {/* <div
                  className="staff-tab__filter"
                  onClick={() => setSortType('map')}>
                  <svg
                    width="22"
                    height="22"
                    xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <circle fill="#41A9C7" cx="11" cy="11" r="11" />
                      <g
                        transform="translate(5 4)"
                        stroke="#FFF"
                        strokeWidth="1.2">
                        <path d="M6 .6c1.159 0 2.207.479 2.966 1.251A4.313 4.313 0 0 1 10.2 4.884c0 1.642-1.54 4.337-4.488 8.106C3.138 9.222 1.8 6.525 1.8 4.884c0-1.184.471-2.257 1.234-3.033A4.146 4.146 0 0 1 6 .6Z" />
                        <ellipse cx="6" cy="4.87" rx="1.2" ry="1.226" />
                      </g>
                    </g>
                  </svg>
                </div> */}
                <div
                  className="staff-tab__map"
                  onClick={() => setSortType('filter')}>
                  <svg
                    width="24"
                    height="22"
                    xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#41A9C7" fill="none" fillRule="evenodd">
                      <g transform="translate(1 1)">
                        <circle strokeWidth="2" cx="8.5" cy="2.5" r="2.5" />
                        <path
                          strokeLinecap="square"
                          d="M12.5 2.5h9M12.5 3.5h9M0 2.5h5.5M0 3.5h5.5"
                        />
                      </g>
                      <g transform="translate(1 8)">
                        <circle strokeWidth="2" cx="16.5" cy="2.5" r="2.5" />
                        <path
                          strokeLinecap="square"
                          d="M19.15 2.5h2.7M19.15 3.5h2.7M0 2.5h13.75M0 3.5h13.75"
                        />
                      </g>
                      <g transform="translate(1 15)">
                        <circle strokeWidth="2" cx="5.5" cy="2.5" r="2.5" />
                        <path
                          strokeLinecap="square"
                          d="M7.75 2.5h13.5M7.75 3.5h13.5M0 2.5h2.75M0 3.5h2.75"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="staff-box-wrappar">
              <ul className="applicant-lists position-relative">
                {renderApplicantImages(filteredApplications)}
              </ul>
            </div>
          </Col>
          <Col md="3">
            <div className="staff-header-wrappar">
              <span>{t('myJob.rejected')}</span>
            </div>
            <div className="staff-box-wrappar">
              <div className="applicant-lists position-relative">
                {renderRejectedApplicantImages(rejectedApplicants)}
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="staff-header-wrappar">
              <span>{t('staff.readyToBook')}</span>
            </div>
            <div className="staff-box-wrappar">
              <div
                className={`staff-book-item  ${
                  !bookingList ? 'item--disabled' : ''
                }`}
                style={{
                  pointerEvents: bookingList ? 'auto' : 'none',
                }}>
                {bookingList ? (
                  <div className={`staff-book-item position-relative `}>
                    <Link
                      // title={bookingList?.name}
                      to={
                        loggedinuser === 'superuser'
                          ? `/staff-detail/${bookingList?._id}`
                          : `/staff-details/${bookingList?._id}`
                      }
                      target="_blank"
                      className={`staff-book-image  ${
                        availabilityIndicator({
                          applicant: bookingList,
                          job: showStaffModal?.data?.job,
                          event: currentevent,
                        })
                          ? 'booked-bookingList-applicant'
                          : ''
                      } ${
                        !checkAvailability({
                          applicant: bookingList,
                          jobStart: showStaffModal.data.position.jobStart,
                          jobEnd: showStaffModal.data.position.jobEnd,
                        })
                          ? 'blocked-bookingList-applicant'
                          : ''
                      }`}
                      style={{ display: 'flex' }}>
                      {/*<img
                      src={`${process.env.REACT_APP_BACKEND_URL}${
                        bookingList.images ? bookingList.images[0] : ''
                      }`}
                      alt="Staff"
                    />*/}
                      <img
                        src={`${
                          bookingList.images &&
                          typeof bookingList.images[0] === 'string' &&
                          !bookingList.images[0].startsWith('employee-') &&
                          !bookingList.images[0].startsWith('user-')
                            ? bookingList.images[0]
                            : defaultAvatar
                        }`}
                        style={{
                          margin: 'auto',
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                        alt="Profile"
                      />
                      <span
                        className={`staff-gender ${
                          bookingList.gender === 'male'
                            ? 'male'
                            : bookingList.gender === 'female'
                            ? 'female'
                            : 'other'
                        }`}></span>
                    </Link>
                  </div>
                ) : (
                  <div className="staff-book-image"></div>
                )}
                {/*{bookingList ? (
                  bookingList.map((bookingListData, key) => {
                    <Link
                      to={loggedinuser === "superuser" ? `/staff-detail/${bookingListData._id}` : `/staff-details/${bookingListData._id}`}
                      key={key}
                      className="staff-book-image">
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}${
                          bookingListData.images
                            ? bookingListData.images[0]
                            : defaultAvatar
                        }`}
                        alt="Profile"
                      />
                    </Link>;
                  })
                ) : (
                  <div className="staff-book-image"></div>
                )} */}
                <span
                  className="btn"
                  style={{
                    pointerEvents: `${
                      !bookingList || addToListLoading ? 'none' : 'auto'
                    } `,
                  }}
                  onClick={async () => {
                    dispatch(
                      checkRejectedEmployeestatus({
                        jobId: showStaffModal.data.position._id,
                        employee: bookingList,
                        callback: () => {
                          if (loggedinuser === 'superuser') {
                            history.push({
                              pathname: `/${labelRoles.superuser}/request-pay`,
                              state: {
                                jobs: [
                                  {
                                    ...showStaffModal.data.position,
                                    eventName:
                                      showStaffModal.data.job.eventName,
                                    eventID: showStaffModal.data.job.eventID,
                                  },
                                ],
                                employee: bookingList,
                              },
                            });
                          } else {
                            history.push({
                              pathname: `/${labelRoles.employer}/request-pay`,
                              state: {
                                jobs: [
                                  {
                                    ...showStaffModal.data.position,
                                    eventName:
                                      showStaffModal.data.job.eventName,
                                    eventID: showStaffModal.data.job.eventID,
                                    eventId: currentevent[0]?.eventID,
                                    jobName: showStaffModal?.data?.job?.jobName,
                                    companyName:
                                      showStaffModal?.data?.job?.companyName,
                                  },
                                ],
                                employee: bookingList,
                              },
                            });
                          }
                        },
                        failCallback: () => {
                          dispatch(fetchEvents({ currPage: 1 }, true, false));
                        },
                      }),
                    );
                  }}>
                  {t('profile.btnBookNow')}
                </span>
                <span
                  className="btn"
                  style={{
                    pointerEvents: `${
                      !bookingList || addToListLoading ? 'none' : 'auto'
                    } `,
                  }}
                  onClick={() => {
                    setAddToListLoading(true);
                    // eslint-disable-next-line no-lone-blocks
                    {
                      displaycartitems({
                        cartJobs: cartJobs,
                        employee: bookingList._id,
                        startDate: showStaffModal.data.position.jobStart,
                        endDate: showStaffModal.data.position.jobEnd,
                      })
                        ? dispatch(
                            checkEmployeestatus({
                              jobId: showStaffModal.data.position._id,
                              employee: bookingList,
                              jobStart: showStaffModal.data.position.jobStart,
                              jobEnd: showStaffModal.data.position.jobEnd,
                              callback: () => {
                                // setAddToListLoading(true);
                                let newPosition = {
                                  ...showStaffModal.data.position,
                                  inAddedList: true,
                                };
                                delete newPosition.applications;
                                delete newPosition._id;
                                newPosition.staffToBook = {
                                  applicant: bookingList._id,
                                  // remarks: userRemarks,
                                };
                                newPosition.readyToBook = {
                                  applicant:
                                    showStaffModal.data.position.readyToBook
                                      .applicant._id,
                                };
                                setUpdateJob(true);
                                dispatch(
                                  updateJobPosition({
                                    id: showStaffModal.data.position._id,
                                    payload: newPosition,
                                    callback: () => {
                                      setShowBookingList(true);
                                      setUpdateJob(false);
                                      setShowStaffModal({
                                        ...showStaffModal,
                                        visible: false,
                                      });
                                      setAddToListLoading(false);
                                      setListLoadingExtern(false);
                                    },
                                    failCallback: () => {
                                      setUpdateJob(false);
                                      setAddToListLoading(false);
                                    },
                                  }),
                                );
                              },
                              failCallback: () => {
                                setAddToListLoading(false);
                              },
                            }),
                          )
                        : toast.error(`${t('staff.thisStaff')}`) &&
                          setAddToListLoading(false);
                    }
                  }}>
                  {t('staff.addToList')}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default StaffSelection;
