import React from 'react';

const Textarea = ({
  type,
  label,
  value,
  onBlur,
  onChange,
  touched,
  errors,
  placeholder,
  name,
  rows,
}) => {
  return (
    <div
      className={`form-group ${touched && errors ? 'form-group-errors' : ''}`}>
      <label className="form-label">{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        cols="30"
        rows={rows}
        className="form-control"></textarea>
      {touched && errors && <div className="form-errors">{errors}</div>}
    </div>
  );
};

export default Textarea;
