import React, { useEffect, useRef, useState } from 'react';
// import GlobalContext from '../context/GlobalContext';
import Head from '../components/Head';
import { Container, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import Button from '../components/Core/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const cookies = useRef();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted) {
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = 'https://consent.cookiebot.com/0e382a25-ffef-4de8-93c5-aafef5604e4f/cd.js';
      script.async = true;

      cookies.current.appendChild(script);

      return () => {
        cookies.current.removeChild(script);
      }
    }
  }, [isMounted]);

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  return (
    <section className="workpage-section content-section">
      <Head title={t('privacy') + ' | Superstaff'} />
      <Container>
        <div className="box-wrapper">
          <h1 style={{ 'margin-bottom': '20px' }}>
            {t('privacy1')}&shy;{t('privacy2')}
          </h1>
          <Card>
            <Card.Body>
              {/*<Card.Title>{t('privacy')}</Card.Title>*/}

              <Card.Text>
                <div>
                  {/*Datenschutzerklärung*/}
                  Der Schutz Ihrer persönlichen Daten ist uns ein besonderes
                  Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf
                  Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003).
                  Diese Datenschutzerklärung klärt über die Art, den Umfang und
                  Zwecke der Erhebung und Verwendung von Daten der Besucher und
                  Nutzer durch die CGK Live Marketing GmbH & CO KG als
                  datenschutzrechtlich verantwortliche Stelle auf. <br />
                  <br />
                  Persönliche Daten <br />
                  <br />
                  Der Kunde erteilt seine Zustimmung, dass sämtliche
                  personenbezogenen Daten für die Abwicklung von Bestellungen,
                  die Lieferung von Waren und das Erbringen von Dienstleistungen
                  sowie die Abwicklung der Zahlung (bei Rechnungskauf auch für
                  erforderliche Prüfungen der Buchhaltung und der Kundenevidenz)
                  verwendet werden und von CGK Live Marketing GmbH & CO KG
                  gespeichert, verarbeitet und aktualisiert werden. Diese Daten
                  werden vertraulich behandelt, verschlüsselt übertragen und
                  nicht an Dritte weitergegeben, die nicht am Bestell-,
                  Auslieferungs- und Zahlungsvorgang beteiligt sind. Bei der
                  Kontaktaufnahme mit CGK Live Marketing GmbH & CO KG (per
                  Kontaktformular oder Email) werden die Angaben des Nutzers
                  zwecks Bearbeitung der Anfrage sowie für den Fall, dass
                  Anschlussfragen entstehen, gespeichert. CGK Live Marketing
                  GmbH & CO KG hat organisatorische, vertragliche und technische
                  Sicherheitsmaßnahmen getroffen, um sicher zu stellen, dass die
                  Vorschriften der Datenschutzgesetze eingehalten werden und
                  zufällige oder vorsätzliche Manipulationen, Verlust,
                  Zerstörung oder der Zugriff unberechtigter Personen verhindert
                  wird. <br />
                  <br />
                  Erhebung von Online-Zugriffsdaten
                  <br />
                  <br />
                  CGK Live Marketing GmbH & CO KG erhebt Daten über jeden
                  Zugriff auf das Onlineangebot (sogenannte Log files). Zu den
                  Zugriffsdaten gehören Name der abgerufenen Webseite, Datei,
                  Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung
                  über erfolgreichen Abruf, Browsertyp nebst Version, das
                  Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
                  Seite), IP-Adresse und der anfragende Provider. CGK Live
                  Marketing GmbH & CO KG verwendet die Protokolldaten ohne
                  Zuordnung zur Person des Nutzers oder sonstiger
                  Profilerstellung entsprechend den gesetzlichen Bestimmungen
                  nur für statistische Auswertungen zum Zweck des Betriebs, der
                  Sicherheit und der Optimierung des Onlineangebotes. Der
                  Anbieter behält sich jedoch vor, die Protokolldaten
                  nachträglich zu überprüfen, wenn aufgrund konkreter
                  Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen
                  Nutzung besteht. <br />
                  <br />
                  Auf dieser Website werden Webanalysetools der Google Inc. mit
                  Hauptsitz in den USA verwendet. Die Beziehung zum
                  Webanalyse-Anbieter basiert auf Standardvertragsklauseln bzw.
                  einem Angemessenheitsbeschluss der Europäischen Komission, in
                  diesem Fall „Privacy Shield“. Die Datenverarbeitung erfolgt
                  auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG
                  sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder f
                  (berechtigtes Interesse) der DSGVO. <br />
                  <br />
                  Mit diesen Tools werden Daten indirekt personenbezogen zur
                  statistischen Auswertung herangezogen, gesammelt und
                  gespeichert. Aus den Daten werden unter einem Pseudonym
                  anonyme Nutzungsprofile erstellt. CGK Live Marketing GmbH & CO
                  KG wird diese Informationen benutzen, um die Nutzung der
                  Website auszuwerten und um Reports über die
                  Website-Aktivitäten zu erstellen. Hierzu können „Cookies“
                  und/oder Textdateien eingesetzt werden, sowie eine Überprüfung
                  von Log files und anonymisierten IP-Adressen erfolgen. Die
                  Auswertung der Daten erfolgt zur Gänze in anonymisierter Form,
                  eine Verknüpfung mit an CGK Live Marketing GmbH & CO KG
                  bekanntgegebenen persönlichen Daten erfolgt nicht. Darüber
                  hinaus verwendet CGK Live Marketing GmbH & CO KG die Daten
                  ausschließlich für die erwähnten Zwecke, eine Weitergabe an
                  Dritte erfolgt nicht. Die Nutzerdaten werden für die Dauer von
                  24 Monaten aufbewahrt. <br />
                  <br />
                  Details zu Google Analytics
                  <br />
                  <br />
                  Diese Website nutzt den Dienst „Google Analytics“, welcher von
                  der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
                  94043, USA) angeboten wird, zur Analyse der Websitebenutzung
                  durch Nutzer. Der Dienst verwendet „Cookies“ – Textdateien,
                  welche auf Ihrem Endgerät gespeichert werden. Die durch die
                  Cookies gesammelten Informationen werden im Regelfall an einen
                  Google-Server in den USA gesandt und dort gespeichert. <br />
                  <br />
                  Auf dieser Website greift die IP-Anonymisierung. Die
                  IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der
                  EU und des Europäischen Wirtschaftsraum gekürzt. Durch diese
                  Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im Rahmen
                  der Vereinbarung zur Auftragsdatenvereinbarung, welche die
                  Websitebetreiber mit der Google Inc. geschlossen haben,
                  erstellt diese mithilfe der gesammelten Informationen eine
                  Auswertung der Websitenutzung und der Websiteaktivität und
                  erbringt mit der Internetnutzung verbundene Dienstleistungen.
                  Sie haben die Möglichkeit, die Speicherung des Cookies auf
                  Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser
                  entsprechende Einstellungen vornehmen. Es ist nicht
                  gewährleistet, dass Sie auf alle Funktionen dieser Website
                  ohne Einschränkungen zugreifen können, wenn Ihr Browser keine
                  Cookies zulässt.
                  <br />
                  <br />
                  Weiterhin können Sie durch ein Browser-Plugin verhindern, dass
                  die durch Cookies gesammelten Informationen (inklusive Ihrer
                  IP-Adresse) an die Google Inc. gesendet und von der Google
                  Inc. genutzt werden. Folgender Link führt Sie zu dem
                  entsprechenden Plugin:
                  https://tools.google.com/dlpage/gaoptout?hl=de
                  <br />
                  <br />
                  Alternativ verhindern Sie mit einem Klick auf diesen Link
                  (WICHTIG! Opt-Out-Link einfügen), dass Google Analytics
                  innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick
                  auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr
                  Browser muss die Speicherung von Cookies also hierzu
                  grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig,
                  ist ein erneuter Klick auf den Link bei jedem Besuch dieser
                  Website vonnöten.
                  <br />
                  <br />
                  Hier finden Sie weitere Informationen zur Datennutzung durch
                  die Google Inc.:
                  https://support.google.com/analytics/answer/6004245?hl=de
                  <br />
                  <br /> Verwendung von Cookies
                  <br />
                  <br />
                  <div ref={cookies}>
                  Cookies sind kleine Einheiten an Information, die entweder in
                  Ihrem Browser oder auf Ihrer Festplatte abgelegt werden. CGK
                  Live Marketing GmbH & CO KG verwendet nur "temporäre Cookies".
                  Das bedeutet, dass CGK Live Marketing GmbH & CO KG keine
                  Informationen langfristig auf Ihrem Computer speichert; nach
                  Schließen des Browsers ist diese Information verschwunden.
                  Diese Cookies erlauben CGK Live Marketing GmbH & CO KG
                  sicherzustellen, dass nur Sie Ihre eigenen Daten sehen und
                  verändern können und CGK Live Marketing GmbH & CO KG Ihnen
                  Services wie Onlineshopping oder den Informationszugang im
                  personalisierten Bereich zur Verfügung stellen kann. Die
                  meisten Internetbrowser geben Ihnen die Möglichkeit den
                  Gebrauch von Cookies zu kontrollieren. Bitte beachten Sie
                  jedoch, dass Sie bei Deaktivierung von Cookies nicht die
                  Möglichkeit haben, Online-Bestellungen durchzuführen oder sich
                  im personalisierten Bereich zu bewegen.
                  <br />
                  <br />
                  </div>
                  <br />
                  Verlinkung
                  <br />
                  <br /> Unser Angebot enthält Links zu externen Webseiten
                  Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die
                  Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                  oder Betreiber der Seiten verantwortlich. Für die Inhalte und
                  die Richtigkeit der Informationen verlinkter Websites fremder
                  Informationsanbieter wird keine Gewähr übernommen.
                  <br />
                  <br />
                  Bestellungen <br />
                  <br />
                  Zum Zweck der Vertragsabwicklung werden folgende Daten bei uns
                  gespeichert: Name, Anschrift, Firmenname, Steuernummer,
                  Telefonnummer. <br />
                  <br />
                  Die von Ihnen bereit gestellten Daten sind zur
                  Vertragserfüllung bzw zur Durchführung vorvertraglicher
                  Maßnahmen erforderlich. Ohne diese Daten können wir den
                  Vertrag mit Ihnen nicht abschließen. Eine Datenübermittlung an
                  Dritte erfolgt nicht. Im Falle eines Vertragsabschlusses
                  werden sämtliche Daten aus dem Vertragsverhältnis bis zum
                  Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre)
                  gespeichert. Wir weisen auch darauf hin, dass personenbezogene
                  Daten von ehemaligen Kunden herangezogen werden können für die
                  Aussendung aktuellen Informationen.
                  <br />
                  <br /> Die Datenverarbeitung erfolgt auf Basis der
                  gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6
                  Abs 1 lit a (Einwilligung) und/oder lit b (notwendig zur
                  Vertragserfüllung) der DSGVO. <br />
                  <br />
                  Auskunftsrecht
                  <br />
                  <br /> Sie haben jederzeit das Recht auf Auskunft über die
                  bezüglich Ihrer Person gespeicherten Daten, deren Herkunft und
                  Empfänger sowie den Zweck der Speicherung. Sie haben das
                  Recht, Ihre Einwilligung über die Speicherung Ihrer Daten
                  jederzeit mit Wirkung für die Zukunft zu widerrufen.
                  Ausgenommen bleiben Daten, deren Löschung gesetzliche,
                  satzungsmäßige oder vertragliche Aufbewahrungsfristen
                  entgegenstehen, außerdem Daten, die für die Begründung,
                  inhaltliche Ausgestaltung oder Änderung Ihres
                  Vertragsverhältnisses mit uns erforderlich sind. Nach dem
                  Bundesdatenschutzgesetz haben Sie ein Recht auf unentgeltliche
                  Auskunft über Ihre gespeicherten Daten sowie ggf. ein Recht
                  auf Berichtigung, Sperrung oder Löschung dieser Daten. <br />
                  <br />
                  Sie erreichen uns unter folgenden Kontaktdaten:
                  <br />
                  <br />
                  <br />
                  <br />
                  CGK Live Marketing GmbH & CO KG
                  <br />
                  A. Baumgartnerstr. 44
                  <br />
                  A-1230 Wien <br />
                  Tel. +43 (0)1/997 10 60 - 0
                  <br />
                  Fax +43 (0)1/997 10 60 - 50 <br />
                  E-Mail: office@cgk.co.at
                  <br />
                  <br />
                  Änderungen der Datenschutzerklärung
                  <br />
                  CGK Live Marketing GmbH & CO KG behält sich vor, die
                  Datenschutzerklärung zu ändern, um sie an geänderte Rechtslage
                  oder bei Änderungen des Dienstes sowie der Datenverarbeitung
                  anzupassen.
                  <br />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </section>
  );
};

export default TermsAndConditions;
