import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmHoursModal from '../EventDetails/ConfirmHoursModal';
import PositionRow from './PositionRow';

function JobRow({ job, currEvent, event, setEvent }) {
  const { t } = useTranslation();
  const [bookedStaff, setBookedStaff] = React.useState(0);
  const [confirmedStaff, setConfirmedStaff] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [conirmedTotalPrice, setconfirmedTotalPrice] = React.useState(0);
  const [showConfirmHoursModal, setShowConfirmHoursModal] = React.useState({
    visible: false,
    data: null,
  });
  React.useEffect(() => {
    let price = 0;
    let confirmedPrice = 0;
    if (job && job?.jobPositions?.length) {
      const confirmedStaffCount = job?.jobPositions.filter(
        (position, key) => position.isConfirmed,
      );
      const filteredPositions = job.jobPositions.filter(
        (p) => p.booked && Object.values(p.booked),
      );
      setConfirmedStaff(
        Math.min(filteredPositions.length, confirmedStaffCount?.length ?? 0),
      );
      setBookedStaff([...filteredPositions]);
      filteredPositions.map((position) => {
        price += Number(position.jobTravelCost + position.jobPrice);
        if (position?.employerData) {
          // confirmedPrice += Number((position?.employerData?.confirmJobPrice ? position?.employerData?.confirmJobPrice : position?.employerData?.jobPrice) + (position?.employerData?.jobTravelCost ? position?.employerData?.jobTravelCost : position.jobTravelCost || 0));

          if (
            position?.employerData?.confirmJobTotalCost ||
            position?.employerData?.jobTotalCost
          ) {
            confirmedPrice += Number(
              position?.employerData?.confirmJobTotalCost
                ? position?.employerData?.confirmJobTotalCost
                : position?.employerData?.jobTotalCost || 0,
            );
          } else {
            confirmedPrice += Number(
              position?.employerData?.confirmJobPrice
                ? position?.employerData?.confirmJobPrice +
                    position?.employerData?.jobTravelCost
                : position?.employerData?.jobPrice +
                    position?.employerData?.jobTravelCost || 0,
            );
          }
        }
        // else if (position.confirmJobPrice > 0) {
        //   confirmedPrice += Number(position.jobTravelCost + position.confirmJobPrice);
        // } else {
        //   confirmedPrice += Number(position.jobTravelCost + position.jobPrice);
        // }
        return true;
      });
    }
    setTotalPrice(price.toFixed(2));
    setconfirmedTotalPrice(confirmedPrice.toFixed(2));
  }, [job]);

  const renderBookedJobPositions = (job) => {
    const positions = job.jobPositions;
    let jobEndDates = [];
    if (!positions || !positions.length) {
      return <></>;
    } else {
      const filteredPositions = positions.filter(
        (p) => p.booked && Object.values(p.booked),
      );
      positions.map((position, key) => {
        // if (position.isConfirmed) {
        //     confirmedStaff++;
        // }
        jobEndDates[key] = position.jobEnd;
        return true;
      });
      // const eventEndDate = jobEndDates.reduce(
      //   (previousValue, currentValue) =>
      //     previousValue < currentValue ? currentValue : previousValue,
      //   jobEndDates[0],
      // );
      // const currentDate = new Date();
      // const dayBeforeCurrentDate = currentDate.getTime() - 86400000;
      // console.log("dcn", eventEndDate < currentDate.getTime(), "+++", currentDate.getTime()-86400000 )
      // const eventExpireDate = job.eventExpirationDate;
      // bookedStaff = [...bookedStaff, ...filteredPositions];
      if (!filteredPositions || !filteredPositions.length) {
        return (
          <tr>
            <td className="text-start" colSpan="9">
              {t('nobookedjob')}
            </td>
          </tr>
        );
      }

      // console.log('today', currentDate.getTime(), 'eventExpireDate', eventExpireDate);
      return filteredPositions.map((position, index) => {
        return (
          <PositionRow
            position={position}
            key={index}
            job={job}
            showConfirmHoursModal={showConfirmHoursModal}
            setShowConfirmHoursModal={setShowConfirmHoursModal}
          />
        );
      });
    }
  };

  return (
    <div className="form-wrapper">
      {showConfirmHoursModal.visible && (
        <ConfirmHoursModal
          showConfirmHoursModal={showConfirmHoursModal}
          setShowConfirmHoursModal={setShowConfirmHoursModal}
          setEvent={setEvent}
          event={event}
          jobs={currEvent?.jobs}
        />
      )}
      <h3 className="headline-with-line">{job.jobName}</h3>
      <div className="table-responsive">
        <table className="table remove-space job-details-table mb-0">
          <thead>
            <tr>
              <th>{t('dateLabel')}</th>
              <th>{t('placeplaceholder')}</th>
              <th>{t('startLabel')}</th>
              <th>{t('endLabel')}</th>
              <th>{t('requestOverview.price')}</th>
              <th>{t('requestOverview.travelCosts')}</th>
              <th>{t('event.hours')}</th>
              <th>{t('requestOverview.booked')}</th>
              <th>{t('OutstandingAmount')}</th>
              <th>
                {confirmedStaff}/{bookedStaff.length} {t('event.confirmed')}
              </th>
            </tr>
          </thead>
          <tbody>
            {renderBookedJobPositions(job)}
            <tr>
              <td colSpan="5" style={{ borderBottom: 0 }} className="py-0">
                <p className="small-text placeholder-text ms-0">
                  {/*{t('jobWallStaff.textOne')}*/}
                </p>
                <p className="small-text placeholder-text ms-0">
                  {/*{t('jobWallStaff.textThree')}*/}
                </p>
              </td>
              <td colSpan="3" style={{ borderBottom: 0 }} className="py-0">
                {/*{confirmedStaff}/{bookedStaff.length} {t('event.confirmed')}*/}
              </td>
              <td
                colSpan="2"
                style={{ whiteSpace: 'nowrap', borderBottom: 0 }}
                className="py-0">
                <span className="placeholder-text">{t('event.totalExcl')}</span>
                <strong className="total-amount">€ {conirmedTotalPrice}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JobRow;
