import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Head from '../components/Head';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="content-section">
      <Head title="404 | Page not found" />
      <Container>
        <div className="box-wrapper">
          <h2>404</h2>
          <p>{t('pagenotfound') + '!'}</p>
          <Link to="/" className="btn">
            {t('goback')}
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default NotFound;
