import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import GlobalContext from './context/GlobalContext';
import Header from './components/Header';
import Footer from './components/Footer';
import EmployerRegister from './components/Register/EmployerRegister';
import EmployerCompleteRegister from './components/Register/EmployerCompleteRegister';
import EmployeeRegister from './components/Register/EmployeeRegister';
import EmployeeCompleteRegister from './components/Register/EmployeeCompleteRegister';
import Login from './components/Login';
import CompleteLogin from './components/Login/CompleteLogin';
import ForgotLogin from './components/Login/ForgotLogin';
import ResetPassword from './components/Login/ResetPassword';
import ConfirmationModal from './components/Modal/ConfirmationModal';
import Routes from './routes';
import { subscribeUser } from './utils/subscription';
import { getMe } from './actions/userAction';
import { getSettings } from './actions/settingAction';
import useQuery from './utils/useQuery';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-h5-audio-player/lib/styles.css';
import './scss/main.scss';

const App = () => {
  let query = useQuery();
  const userData = useSelector((state) => state.user);
  const user = userData.data;
  const dispatch = useDispatch();
  const {
    showEmployerRegisterModal,
    setShowEmployerRegisterModal,
    showEmployerCompleteRegisterModal,
    showEmployeeRegisterModal,
    showEmployeeCompleteRegisterModal,
    showLoginModal,
    setShowLoginModal,
    showCompleteLoginModal,
    showForgotModal,
    showResetPassword,
  } = useContext(GlobalContext);
  const home = useLocation();

  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
  //   script.async = true;

  //   document.body.appendChild(script);

  //   // return () => {
  //   //   document.body.removeChild(script);
  //   // };
  // }, []);
  // zh%#sxD/MyNfv:2
  // REACT_APP_STRIPE_KEY =
  //   pk_test_51KhoRnSFXb7XevJhGuAS7ykwmms0ARwMCvzD2AogHK40UtrAn4ZimbSrgQyOGQHDbsjeSxttkcE44WABmbzPacgP0019eGNw79;

  useEffect(() => {
    const modalName = query.get('modal');

    if (modalName === 'login') {
      setShowLoginModal({ ...showLoginModal, visible: true });
    } else if (modalName === 'org-register') {
      setShowEmployerRegisterModal(true);
    }

    // const user = localStorage.getItem('user');
    // if (user) {
    //   subscribeUser();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home]);

  useEffect(() => {
    if (user.token) {
      dispatch(getMe());
    }
    dispatch(getSettings());
  }, []);

  return (
    <div className={`app ${home.pathname === '/' ? '' : 'sidebar-wrapper'}`}>
      <ToastContainer autoClose={4000} position="top-center" theme="colored" />
      {showEmployerRegisterModal && <EmployerRegister />}
      {showEmployerCompleteRegisterModal && <EmployerCompleteRegister />}
      {showEmployeeRegisterModal && <EmployeeRegister />}
      {showEmployeeCompleteRegisterModal && <EmployeeCompleteRegister />}
      {showLoginModal.visible && <Login />}
      {showCompleteLoginModal.visible && <CompleteLogin />}
      {showForgotModal && <ForgotLogin />}
      {showResetPassword.visible && <ResetPassword />}
      <Header />
      <main className="site-main">
        <Routes />
      </main>
      <Footer />
    </div>
  );
};

export default App;
