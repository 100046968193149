import * as Yup from 'yup';
import moment from 'moment';
// import React, { useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { EMAIL_VALIDATION } from '../../utils/constants';

export const getNewValues = (values) => ({
  ...values,
  images: values.images,
  audio:
    typeof values.audio === 'string'
      ? values.audio
      : values.audio === null
      ? null
      : values.audio.blob,
  dateOfBirth: new Date(values.dateOfBirth).valueOf(),
  foreignLanguages: values.foreignLanguages.map((lang) => lang.value),
  preferedJobRegion: values.preferedJobRegion.map((lang) => lang.value),
  preferedJobs: values.preferedJobs.map((lang) => lang.value),
  previousJobs: values.previousJobs.map((lang) => lang.value),
  gender: values.gender.value,
  hairColor: values.hairColor.value,
  motherTongue: values.motherTongue.value,
  nationality: values.nationality.value,
  country: values.country.value,
  size: values.size.value,
  shoeSize: values.shoeSize.value,
  height: values.height ? `${values.height}cm` : '',
  weight: values.weight ? `${values.weight}kg` : '',
});

export const getStaffInitialValues = (initialData) => ({
  ...initialData,
  gender: '',
  nationality: '',
  hairColor: '',
  dateOfBirth: '',
  height: '',
  weight: '',
  motherTongue: '',
  foreignLanguages: [],
  size: '',
  shoeSize: '',
  profileId: '',
  jobExperience: '',
  // preferedJobRegion: {
  //   value: '',
  // },
  preferedJobRegion: [],
  preferedJobs: [],
  previousJobs: [],
  piercings: undefined,
  visibleTattoos: undefined,
  driversLicense: undefined,
  carAvailable: undefined,
  blackPantsuit: undefined,
  blackSkirtBlazer: undefined,
  acceptTerms: false,
});

export const getStaffValidationSchema = () => {
  // const { t, i18n } = useTranslation();
  return {
    gender: Yup.object().shape({
      label: Yup.string().required(i18n.t('Gender')),
      value: Yup.string().required(i18n.t('Gender')),
    }),
    motherTongue: Yup.object().shape({
      label: Yup.string().required(i18n.t('MotherTongue')),
      value: Yup.string().required(i18n.t('MotherTongue')),
    }),
    nationality: Yup.object().shape({
      label: Yup.string().required(i18n.t('Nationality')),
      value: Yup.string().required(i18n.t('Nationality')),
    }),
    size: Yup.object().shape({
      label: Yup.string().required(i18n.t('Size')),
      value: Yup.string().required(i18n.t('Size')),
    }),
    shoeSize: Yup.object().shape({
      label: Yup.string().required(i18n.t('ShoeSize')),
      value: Yup.string().required(i18n.t('ShoeSize')),
    }),
    hairColor: Yup.object().shape({
      label: Yup.string().required(i18n.t('HairColor')),
      value: Yup.string().required(i18n.t('HairColor')),
    }),
    country: Yup.object().shape({
      label: Yup.string().required(i18n.t('Country')),
      value: Yup.string().required(i18n.t('Country')),
    }),
    // preferedJobRegion: Yup.object().shape({
    //   label: Yup.string().required('Prefered job Region is Required'),
    //   value: Yup.string().required('Prefered job Region is Required'),
    // }),
    preferedJobRegion: Yup.array().min(
      1,
      i18n.t('minimumonepreferedjobregion'),
    ),
    firstName: Yup.string().required(i18n.t('FirstName')),
    lastName: Yup.string().required(i18n.t('LastName')),
    email: Yup.string()
      .matches(EMAIL_VALIDATION, {
        message: i18n.t('Email.type'),
      })
      .required(i18n.t('Email.required')),
    phone: Yup.number()
      .typeError(i18n.t('Phone.type'))
      .positive(i18n.t('Phone.type'))
      .integer(i18n.t('Phone.type'))
      .required(i18n.t('Phone.required')),
    // .test(
    //   'maxDigits',
    //   i18n.t('Phone.length9'),
    //   (number) => String(number).length >= 9,
    // ),
    height: Yup.number()
      .typeError(i18n.t('Height.type'))
      .required(i18n.t('Height.required'))
      .test('height', i18n.t('height>0'), (value) => value >= 1),
    weight: Yup.number()
      .typeError(i18n.t('Weight.type'))
      .required(i18n.t('Weight.required'))
      .test('Weight', i18n.t('weight>0'), (value) => value >= 1),
    piercings: Yup.boolean().required(i18n.t('required')).oneOf([true, false]),
    visibleTattoos: Yup.boolean()
      .required(i18n.t('required'))
      .oneOf([true, false]),
    driversLicense: Yup.boolean()
      .required(i18n.t('required'))
      .oneOf([true, false]),
    carAvailable: Yup.boolean()
      .required(i18n.t('required'))
      .oneOf([true, false]),
    blackPantsuit: Yup.boolean()
      .required(i18n.t('required'))
      .oneOf([true, false]),
    blackSkirtBlazer: Yup.boolean()
      .required(i18n.t('required'))
      .oneOf([true, false]),
    preferedJobs: Yup.array().min(1, i18n.t('job')),
    city: Yup.string().required(i18n.t('City')),
    postalCode: Yup.string()
      .required(i18n.t('PostalCode'))
      .matches(/^[0-9]/, 'Postal Code can not be negative')
      .min(4, i18n.t('Postalcodevalidation'))
      .max(5, i18n.t('Postalcodevalidation')),
    // ssn: Yup.string().required('Social Security Number is Required'),
    addressLineOne: Yup.string().required(i18n.t('AddressLineOne')),
    acceptTerms: Yup.bool().oneOf([true], i18n.t('acceptcontract')),
    // acceptcontract: Yup.bool().oneOf([true], i18n.t('acceptcontract')),
    dateOfBirth: Yup.string()
      .required(i18n.t('dobrequired'))
      .test('DOB', i18n.t('minimum16yars'), (value) => {
        return (
          moment().diff(moment(value).startOf('day').toString(), 'years') >= 16
        );
      }),
  };
};
