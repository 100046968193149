import React, { useEffect } from 'react';
// import GlobalContext from '../context/GlobalContext';
import Head from '../components/Head';
import { Container, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
// import Button from '../components/Core/Button';
import { useTranslation } from 'react-i18next';

const Imprint = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  return (
    <section className="workpage-section content-section">
      <Head title={t('title.imprint')} />
      <Container>
        <div className="box-wrapper">
          <h1 style={{ 'margin-bottom': '20px' }}>{t('imprint')}</h1>
          <Card>
            <Card.Body>
              <Card.Title>CGK Live Marketing Gmbh & Co KG</Card.Title>
              <Card.Subtitle className="mb-2 text-muted text-capitalize">
                Werbeagentur <br />
                A. Baumgartnerstr. 44 <br />
                A-1230 Wien <br />
                Tel.: &nbsp;+43 (0)1/997 10 60 <br />
                Fax: &nbsp;+43 (0)1/997 10 60-50 <br />
                Mail: &nbsp;
                <Link
                  className="text-lowercase"
                  to="#"
                  onClick={() => (window.location = 'mailto:office@cgk.co.at')}
                >
                  office@cgk.co.at
                </Link>
                <br />
                Web: &nbsp;
                <Link
                  className="text-lowercase"
                  to={{
                    pathname: 'https://www.cgk.co.at',
                  }}
                  target="_blank"
                >
                  www.cgk.co.at
                </Link>
                <br />
              </Card.Subtitle>
              <Card.Text>
                Firmenbuch FN: 423129z <br />
                Gerichtsstand: Handelsgericht Wien
                <br />
                UID-Nr: ATU69037703
                <br />
                <br />
                <Card.Link
                  href="https://firmen.wko.at/ing-mag-%28fh%29-klemens-andreas-walter-koch-cgk-live-marketing/wien/?firmaid=e7749bff-4535-42d1-825e-a6f70ae2acc9"
                  target="_blank"
                >
                  Informationspflicht lt. §5 E-Commerce Gesetz
                </Card.Link>
                <div className="text-grey text-justigy">
                  <p style={{ textAlign: 'justify' }}>
                    Mitglied der Wirtschaftkammer Wien <br /> Fachgruppe Werbung
                    & Marktkommunikation <br />
                    Fachgruppe Gewerbliche Personaldienstleister
                    (Arbeitskräfteüberlasser) <br />
                    Hinweise: Alle Texte auf der Website von CGK Live Marketing
                    wurden gründlich und gewissenhaft geprüft. Trotzdem
                    übernimmt CGK Live Marketing keine Garantie für die
                    Richtigkeit, Vollständigkeit und Aktualität der Angaben.
                    <br />
                    Links zu Webseiten Dritter werden Ihnen auf dieser Webseite
                    als reine Serviceleistung bzw. als Hinweis angeboten. Für
                    die Inhalte, zu denen verlinkt wird, sind die Anbieter der
                    jeweiligen Webseiten selbst verantwortlich.
                    <br />
                    Das auf allen Texten der Homepage verwendete generische
                    Maskulinum bezieht selbstverständlich die weibliche Form mit
                    ein.
                    <br />
                    <br />
                    Urheberrechtsschutz: Diese Web Site und die auf ihr
                    bereitgestellten Informationen sind weltweit
                    urheberrechtlich aufgrund der Gesetze zum Schutz des
                    geistigen Eigentums geschützt. Die dadurch begründeten
                    Rechte, insbesondere die der Übersetzung, des Nachdrucks,
                    der Entnahme von Abbildungen, der Funksendung, der
                    Wiedergabe auf fotomechanischem oder ähnlichem Wege und der
                    Speicherung in Datenverarbeitungsanlagen bleiben
                    vorbehalten. Private Nutzer können einzelne Programme,
                    Dateien oder Inhalte herunterladen und nutzen, sofern
                    vorhandene Urheberrechtsvermerke nicht entfernt werden. In
                    keiner Form ist ohne schriftliche Zustimmung der CGK Live
                    Marketing eine gewerbliche Nutzung der Web Site und der
                    darin enthaltenen Inhalte zulässig
                  </p>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </section>
  );
};

export default Imprint;
