import { contractConstants } from '../actions/types';

const initialState = { loading: false, data: {}, error: '' };

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractConstants.GET_ACTIVE_CONTRACT:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case contractConstants.GET_ACTIVE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.contract,
        error: '',
      };
    case contractConstants.GET_ACTIVE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default contractReducer;
